import React from "react";
import ClickableContainer from "../ClickableContainer/ClickableContainer";
import { BsHeart } from "react-icons/bs";
import ProductCard from '../ProductCard/ProductCard';

export default function ProductVariation({ prodVariation, isSelected, onclick }) {

    const refProductVar = React.useRef(prodVariation);
    console.log('Product variation render');

    const headerExtra = () => {
        return (
            <div className="favorite"><BsHeart /><ClickableContainer onclick={() => { alert('favorite') }} /></div>
        );
    }

    return (
        <ProductCard prodVariation={prodVariation} className='productVariation' prodDescription={prodVariation.name} isSelected={isSelected} enableHover={true} onclick={() => { onclick(refProductVar.current) }} headerExtraRender={headerExtra} />
    );
}