import React from 'react';
import { BsSearch } from "react-icons/bs";
import ClickableContainer from "../ClickableContainer/ClickableContainer";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Chip from '@mui/material/Chip';
import ToggleButton from '@mui/material/ToggleButton';
import classNames from 'classnames';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function TabPanel({ children, value, index, className, ...other }) {
    const classes = classNames('tabpanel', className, {
        visible: value === index
    });

    return (
      <div
        role="tabpanel"
        // hidden={value !== index}
        // id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        // className={`tabpanel ${className}`}
        className={classes}
        {...other}
      >
        {/* {value === index && children} */}
        {children}
      </div>
    );
}

const categories = [
    {id: 1, name: 'Camicie'},
    {id: 2, name: 'Maglieria'},
    {id: 3, name: 'Felpe'},
    {id: 4, name: 'T-Shirt & Polo'},
    {id: 5, name: 'Giacche'},
    {id: 6, name: 'Pantaloni'},
    {id: 7, name: 'Jeans'},
    {id: 8, name: 'Bermude'},
];

const brands = [
    {id: 1, name: 'Adidas'},
    {id: 2, name: 'Nike'},
    {id: 3, name: 'Timberland'},
    {id: 4, name: 'Tommy Hilfiger'},
    {id: 5, name: 'Primitive'},
    {id: 6, name: 'Gucci'},
    {id: 7, name: 'Benetton'},
    {id: 8, name: 'Zara'},
    {id: 9, name: 'Mango'},
    {id: 10, name: 'SikSilk'},
];

const FiltersDialog = ({isOpen, handleClose}) => {
    const defaultPriceRange = [0, 0];
    const [tabValue, setTabValue] = React.useState(0);

    const [forceStateChange, setForceStateChange] = React.useState(false);
    const [selProdCat] = React.useState(new Map()); //non si usa setSelProdCat
    const [priceRange, setPriceRange] = React.useState(defaultPriceRange);
    const [priceDiscount, setPriceDiscount] = React.useState(false);

    const handleProdCatChange = (event, cat) => {
        if (selProdCat.has(cat.id)) selProdCat.delete(cat.id); else selProdCat.set(cat.id, cat);
        setForceStateChange(!forceStateChange);
    };

    const prodCatFilterRemoved = () => {
        selProdCat.clear();
        setForceStateChange(!forceStateChange);
    };

    const handlePriceChange = (event, newValue) => {
        setPriceRange(newValue);
    };

    const handlePriceDiscount = (event, newValue) => {
        setPriceDiscount(!priceDiscount);
    };

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handlePriceDelete = () => {
        setPriceRange(defaultPriceRange);
        setPriceDiscount(false);
    };

    const formatMap = (mapObj, label) => {
        //[...mapObj] return an array with the structure: [[key1, value1], [key2, value2], [key3, value3], ...]
        return label + [...mapObj].map(item => item[1].name).join(', ');
    };

    const formatPriceChip = () => {
        const stack = [];
        if (isValidPriceRange) stack.push(`price: $${priceRange[0]} - $${priceRange[1]}`);
        if (priceDiscount) stack.push('Discount');
        return stack.join(', ');
    };

    const showProdCatChip = selProdCat.size > 0;
    const isValidPriceRange = priceRange[1] > 0; // && priceRange[0] !== priceRange[1];
    const showPriceChip = isValidPriceRange || priceDiscount;

    return (
        <Dialog className='customMUIDialog smoothBorder' open={isOpen} TransitionComponent={Transition} transitionDuration={500} keepMounted onClose={handleClose} aria-describedby="Filters for searching">
            <DialogTitle>Filters</DialogTitle>
            <DialogContent>
                {(showProdCatChip || showPriceChip) &&
                    <Box className='filtersChipContainer'>
                        {showProdCatChip && <Chip variant="outlined" color="primary" label={formatMap(selProdCat, 'Category: ')} onDelete={prodCatFilterRemoved} /> }
                        {showPriceChip && <Chip variant="outlined" color="primary" label={formatPriceChip()} onDelete={handlePriceDelete} /> }
                    </Box>
                }
                <Tabs value={tabValue} variant="fullWidth" onChange={handleChange}>
                    <Tab label="Categories" />
                    <Tab label="Brands" />
                    <Tab label="Price" />
                </Tabs>    
                <Box className='filtersTabPanelContainer'>
                    <TabPanel value={tabValue} index={0} className='prodCatContainer'>
                        {/* {categories.map((cat) => <Box key={cat.id}>{cat.name}</Box>)} */}
                        {categories.map((cat) => <ToggleButton key={cat.id} value={cat} selected={selProdCat.has(cat.id)} color="primary" onChange={handleProdCatChange}>{cat.name}</ToggleButton>)}
                    </TabPanel>
                    <TabPanel value={tabValue} index={1} className='brandsContainer'>
                        {brands.map((brand) => <Box key={brand.id}>{brand.name}</Box>)}
                    </TabPanel>
                    <TabPanel value={tabValue} index={2} className='priceContainer'>
                        <Slider min={0} max={500} value={priceRange} disableSwap onChange={handlePriceChange} />
                        <FormGroup>
                            <FormControlLabel control={<Switch checked={priceDiscount} onClick={handlePriceDiscount} />} label="Discount" />
                        </FormGroup>
                    </TabPanel>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button startIcon={<BsSearch />} onClick={handleClose} >Search</Button>
            </DialogActions>
        </Dialog>
    )
}

const Filters = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleClose = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <div className="mainOption">
                <BsSearch />
                <ClickableContainer onclick={() => {setIsOpen(true)}} />
            </div>
            <FiltersDialog isOpen={isOpen} handleClose={handleClose}/>       
        </>
    )
};

export default Filters;
