import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import 'animate.css';
import App from './App';
import './fonts/Pushster/Pushster-Regular.ttf';
import './fonts/TheOutboxSt/TheOutboxSt-Regular.ttf';
// import reportWebVitals from './reportWebVitals';

window.markInit = 'Init';
window.markEnd = 'End';
performance.mark(window.markInit);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// //StrictMode is a tool for highlighting potential problems in an application, it does not render any visible UI.
// //Strict mode checks are run in development mode only; they do not impact the production build.
// //When you use Strict Mode, React renders each component TWICE!!! to help you find unexpected side effects
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    // <React.StrictMode>
        <App />
    // </React.StrictMode>
);


// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
