import {MeshBVH, getTriangleHitPointInfo} from 'three-mesh-bvh';
import * as THREE from 'three';
import * as ThreeManUtility from './ThreeManUtility.js';
import { enableDebugMode } from '@tensorflow/tfjs';
	
const getSkinnedToPoints = skinnedMesh => {		//PROVARE ... trasforma pointcloud in skinned
	//scene.updateMatrixWorld();
	skinnedMesh.skeleton.update();

	//camera.updateProjectionMatrix();

    const points = new THREE.Points(skinnedMesh.geometry, new THREE.MeshStandardMaterial({
    	skinning:true,
    	color:0xffffff
    }));
    //scene.add(points);

   	points.matrixWorld.copy(skinnedMesh.matrixWorld);
    
    points.skeleton = skinnedMesh.skeleton;
    points.bindMatrix = skinnedMesh.bindMatrix;
    points.bindMatrixInverse = skinnedMesh.bindMatrixInverse;
    points.bindMode = skinnedMesh.bindMode;
   // points.drawMode = skinnedMesh.drawMode;
    points.name = skinnedMesh.name;
    points.parent = skinnedMesh.parent;
    points.uuid = skinnedMesh.uuid;
    points.type = skinnedMesh.type;

    points.isSkinnedMesh = true;
    points.bind = skinnedMesh.bind;
    points.clone = skinnedMesh.clone;
    points.initBones = skinnedMesh.initBones;
    points.normalizeSkinWeights = skinnedMesh.normalizeSkinWeights;
    points.pose = skinnedMesh.pose;
 
    return points; 
}

    function transformSkinningPosition(skinnedMesh,idx){    //Ritorna posizione calcolando skinned e morph

        var realPoint = computeMorphing( skinnedMesh, idx );

		return computeSkinning( skinnedMesh, idx, realPoint );
    }

    
    /* prende in ingresso la mesh col morping e l'indice del punto e ritorna il punto reale calcolando il morphing */
	// https://github.com/mrdoob/three.js/blob/4d875bbd8f05be5eae13366b06c03e7aaae79cec/src/objects/Mesh.js#L375-L418
	function computeMorphing( morphedMesh, pointIndex ) {

		var morphTargets = morphedMesh.geometry.morphAttributes?.position;
        if(!morphTargets || morphTargets.length <1)return(null);

		var morphInfluences = morphedMesh.morphTargetInfluences;

		var positionMesh = morphedMesh.geometry.attributes.position;

		var point = new THREE.Vector3();
		point.fromBufferAttribute( positionMesh, pointIndex );

		var _morph = new THREE.Vector3();
		_morph.set( 0, 0, 0 );

		for ( var t = 0, tl = morphTargets.length; t < tl; t ++ ) {

			var influence = morphInfluences[ t ];

			if ( influence === 0 ) continue;

			var targets_pos = new THREE.Vector3();
			var targets = morphTargets[ t ];

			_morph.addScaledVector( targets_pos.fromBufferAttribute(targets, pointIndex ), influence ); // targets index must match vertex index

		}

		point.add( _morph ); // the transformed value

		return point;

	}

	function computeSkinning( skinned_mesh, pointIndex, point = null ) {

		var bindMatrix = skinned_mesh.bindMatrix;
		var bindMatrixInverse = skinned_mesh.bindMatrixInverse;
	
		var temp = new THREE.Vector3();
		var skinned = new THREE.Vector3();
		var skinIndicesMod = new THREE.Vector4();
		var skinWeightsMod = new THREE.Vector4();
		var boneMatrix = new THREE.Matrix4();
	
		if ( point === null ) {

			point = new THREE.Vector3();
			point.fromBufferAttribute( skinned_mesh.geometry.attributes.position, pointIndex );

		}

		skinIndicesMod.fromBufferAttribute( skinned_mesh.geometry.attributes.skinIndex, pointIndex );
		skinWeightsMod.fromBufferAttribute( skinned_mesh.geometry.attributes.skinWeight, pointIndex );
				
		// the following code section is normally implemented in the point shader
		point.applyMatrix4( bindMatrix ); // transform to bind space
		skinned.set( 0, 0, 0 );

		for ( var j = 0; j < 4; j ++ ) {

			var si = skinIndicesMod.getComponent( j );
			var sw = skinWeightsMod.getComponent( j );
			boneMatrix.fromArray( skinned_mesh.skeleton.boneMatrices, si * 16 );

			// weighted point transformation
			temp.copy( point ).applyMatrix4( boneMatrix ).multiplyScalar( sw );
			skinned.add( temp );

		}

		skinned.applyMatrix4( bindMatrixInverse ); // back to local space

		return skinned;

	}
	
function findNearestPosition(skinned_mesh, Pt) {
	let minDist = +Infinity, dist, v, idx = -1;

	for ( let j = 0; j < skinned_mesh.geometry.attributes.position.count; j ++ ) {
		v = ThreeManUtility.GetWordVertexCoord(skinned_mesh, j);
		//v = transformSkinningPosition(skinned_mesh, j);
		dist = Pt.distanceTo(v);
		if (dist < minDist) {
			minDist = dist;
			idx = j;
		}
	};

	return idx;
}	

function makeSkinnedMeshWitoutBVH(skinned_mesh,mesh_object,scene){
	if(!skinned_mesh.skeleton)return(mesh_object);

	let v = new THREE.Vector3();
	let getVector = new THREE.Vector4();
	let idx;

	skinned_mesh.updateMatrixWorld();
	mesh_object.updateMatrixWorld();

	if(!mesh_object.geometry.attributes.skinIndex){	//Se deve calcolare skinindex  (il file poteva contenere lo skinindex anche se è una mesh normale)
		const ResSkinIndices = [];
		const ResSkinWeights = [];


		const skinned_mesh_index = skinned_mesh.geometry.attributes.skinIndex;
		const skinned_mesh_weight = skinned_mesh.geometry.attributes.skinWeight;

		for ( var j = 0; j < mesh_object.geometry.attributes.position.count; j ++ ) {

			v = ThreeManUtility.GetWordVertexCoord(mesh_object, j);
			idx = findNearestPosition(skinned_mesh, v);
			
			if (idx > -1) {

				ResSkinIndices.push( getVector.fromBufferAttribute( skinned_mesh_index, idx ).x, getVector.fromBufferAttribute( skinned_mesh_index, idx ).y, getVector.fromBufferAttribute( skinned_mesh_index, idx ).z, getVector.fromBufferAttribute( skinned_mesh_index, idx ).w );
				ResSkinWeights.push( getVector.fromBufferAttribute( skinned_mesh_weight, idx ).x, getVector.fromBufferAttribute( skinned_mesh_weight, idx ).y, getVector.fromBufferAttribute( skinned_mesh_weight, idx ).z, getVector.fromBufferAttribute( skinned_mesh_weight, idx ).w );
			}
		}
		mesh_object.geometry.setAttribute( 'skinIndex', new THREE.Uint16BufferAttribute( ResSkinIndices, 4 ) );
		mesh_object.geometry.setAttribute( 'skinWeight', new THREE.Float32BufferAttribute( ResSkinWeights, 4 ) );

		//ThreeManUtility.downloadGlb(mesh_object,'canonical_face_model_skinned.glb')	//Usare questo file come standard per non rifare skinned tutte le volte ... (Se skinnedmesh non salva corretto...)...rifare solo se si cambia modello
	}

	const mesh = new THREE.SkinnedMesh( mesh_object.geometry, mesh_object.material );

	mesh.position.copy( mesh_object.position);
	mesh.rotation.copy(mesh_object.rotation);


	mesh.bind( new THREE.Skeleton( skinned_mesh.skeleton.bones ) );

	//material_mesh.wireframe=true;
	return mesh;

}

function makeSkinnedMeshBVH(skinned_mesh, mesh_object, scene) {
	if(!skinned_mesh.skeleton)return(mesh_object);

	if(!mesh_object.geometry.attributes.skinIndex){	//Se deve calcolare skinindex  (il file poteva contenere lo skinindex anche se è una mesh normale)
		skinned_mesh.updateMatrixWorld();
		mesh_object.updateMatrixWorld();
		const mesh_vertex = new THREE.Vector3();
		const position_mesh = mesh_object.geometry.attributes.position;
		const skinIndices = [];
		const skinWeights = [];

		const skinned_mesh_index = skinned_mesh.geometry.attributes.skinIndex;
		const skinned_mesh_weight = skinned_mesh.geometry.attributes.skinWeight;
		
		const bvh = new MeshBVH( skinned_mesh.geometry.clone() );
		const world_to_bvh = new THREE.Matrix4().copy( skinned_mesh.matrixWorld ).invert();
		const v4 = new THREE.Vector4();
		let idx;

		for ( var i = 0; i < position_mesh.count; i ++ ) {

			mesh_vertex.fromBufferAttribute( position_mesh, i );
			var real_mesh_vertex = mesh_object.localToWorld( mesh_vertex );
			real_mesh_vertex.applyMatrix4( world_to_bvh );

			const info = bvh.closestPointToPoint( real_mesh_vertex );
			const triangleInfo = getTriangleHitPointInfo(info.point, bvh.geometry, info.faceIndex);
			idx = triangleInfo.face.a;

			//#region Draw spheres
			// const geometry = new THREE.SphereGeometry(0.8, 0.3, 0.3);
			// const mat = new THREE.MeshBasicMaterial({color: 0xFFFF00});
			// const sphere = new THREE.Mesh(geometry, mat);
			// sphere.position.set(info.point.x, info.point.y, info.point.z);
			// scene.add(sphere);
			//#endregion

			v4.fromBufferAttribute(skinned_mesh_index, idx);
			skinIndices.push(v4.x, v4.y, v4.z, v4.w);

			v4.fromBufferAttribute(skinned_mesh_weight, idx);
			skinWeights.push(v4.x, v4.y, v4.z, v4.w);
			//ThreeManUtility.downloadGlb(mesh_object,'canonical_face_model_skinned.glb')	//Usare questo file come standard per non rifare skinned tutte le volte ... (Se skinnedmesh non salva corretto...)...rifare solo se si cambia modello

		}

		mesh_object.geometry.setAttribute( 'skinIndex', new THREE.Uint16BufferAttribute( skinIndices, 4 ) );
		mesh_object.geometry.setAttribute( 'skinWeight', new THREE.Float32BufferAttribute( skinWeights, 4 ) );
	}

    const mesh = new THREE.SkinnedMesh( mesh_object.geometry.clone(), mesh_object.material );

	mesh.scale.copy(mesh_object.scale);
	mesh.position.copy(mesh_object.position);
	mesh.rotation.copy(mesh_object.rotation);
    mesh.bind( new THREE.Skeleton( skinned_mesh.skeleton.bones ) );

    //scene.add(mesh);

    return mesh;

}

function convertSkinnedMeshBVH(skinnedMeshObj, meshObject, isAsync = true) {
    
    if ( !meshObject?.isMesh ) {
        if (isAsync) return Promise.reject(); else return;
    }
    
    let skinnedMeshes = [];
    const isValidSkinnedMesh = (mesh) => mesh?.isSkinnedMesh && mesh.geometry?.attributes?.skinIndex && mesh.geometry?.attributes?.skinWeight;

    if ( Array.isArray(skinnedMeshObj) ) {
        skinnedMeshes = skinnedMeshObj.filter(mesh => isValidSkinnedMesh(mesh));
    } else
    if ( skinnedMeshObj.isObject3D ) {
        skinnedMeshObj.traverse(obj => {
            if ( isValidSkinnedMesh(obj) ) skinnedMeshes.push(obj);
        });
    }

    //Si presuppone che tutti usano lo stesso Bone quindi basta controllare il primo
    if ( !skinnedMeshes[0]?.skeleton?.bones ) {
        if (isAsync) return Promise.reject(); else return;
    }

    function makeSkinnedMesh() {

        const minDistByVertex = new Array(meshObject.geometry.attributes.position.count).fill(Infinity);
        meshObject.updateMatrixWorld();

        const newGeometry = meshObject.geometry.clone();
        newGeometry.setAttribute('skinIndex', new THREE.Uint16BufferAttribute( new Uint16Array(meshObject.geometry.attributes.position.count * 4), 4 ));
        newGeometry.setAttribute('skinWeight', new THREE.Float32BufferAttribute( new Float32Array(meshObject.geometry.attributes.position.count * 4), 4 ));

        skinnedMeshes.forEach(skinnedMesh => {

            skinnedMesh.updateMatrixWorld();
            const meshVertex = new THREE.Vector3();
            const bvh = new MeshBVH( skinnedMesh.geometry.clone() );
            
            for ( let i = 0; i < meshObject.geometry.attributes.position.count; i ++ ) {
        
                meshVertex.fromBufferAttribute( meshObject.geometry.attributes.position, i );
                meshObject.localToWorld(meshVertex);
                skinnedMesh.worldToLocal(meshVertex);
        
                let info = bvh.closestPointToPoint(meshVertex);

                if ( info.distance < minDistByVertex[i] ) {

                    let triangleInfo = getTriangleHitPointInfo(info.point, bvh.geometry, info.faceIndex);
                    let idx = triangleInfo.face.a;
                    
                    newGeometry.attributes.skinIndex.copyAt(i, skinnedMesh.geometry.attributes.skinIndex, idx);
                    newGeometry.attributes.skinWeight.copyAt(i, skinnedMesh.geometry.attributes.skinWeight, idx);
                    
                    minDistByVertex[i] = info.distance;
                }
            }
        });

        const mesh = new THREE.SkinnedMesh( newGeometry, meshObject.material );
        
        mesh.scale.copy(meshObject.scale);
        mesh.position.copy(meshObject.position);
        mesh.rotation.copy(meshObject.rotation);
        mesh.bind( new THREE.Skeleton( skinnedMeshes[0].skeleton.bones ) );
        
        return mesh;
    }

    if (isAsync) {

        return new Promise((resolve, reject) => {
            
            setTimeout(() => {
                try {
                    const newSkinnedMesh = makeSkinnedMesh();
                    resolve(newSkinnedMesh);
                } catch(e) {
                    reject(e);
                }

            }, 100);

        });

    } else {

        return makeSkinnedMesh();

    }
}
            


export {makeSkinnedMeshBVH, convertSkinnedMeshBVH, transformSkinningPosition, makeSkinnedMeshWitoutBVH}