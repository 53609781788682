import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as Kalidokit from "kalidokit";
import { MMDAnimationHelper } from 'three/examples/jsm/animation/MMDAnimationHelper.js';
//import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';



/*riga 112 threeman.js
if(modelMeshRef.current){     //Reallusion model
                CAMANIMATION_GLB_Reallusion.animateModel_Reallusion(modelMeshRef.current, keyPts);
                render();
            }
*/
//riga 341
// CAMANIMATION_GLB_Reallusion.SetIKData_Reallusion(mesh); //add ik bones  



//const remap = Kalidokit.Utils.remap;
//const clamp = Kalidokit.Utils.clamp;
//const lerp = Kalidokit.Vector.lerp;


//Global
let ikHelper;
//var helper = new MMDAnimationHelper( { afterglow: 2.0 } );
//var transformControls = []; // now not use is to add control on IK
//var boneCaches = {};
//var boneCachesEuler = {};
//var boneRestPos = {};
var KeyArmL =['CC_Base_L_Clavicle','CC_Base_L_Upperarm','CC_Base_L_Forearm','CC_Base_L_Hand'];
var KeyArmR =['CC_Base_R_Clavicle','CC_Base_R_Upperarm','CC_Base_R_Forearm','CC_Base_R_Hand'];
var KeyLegL =['CC_Base_L_Thigh','CC_Base_L_Calf','CC_Base_L_Foot','CC_Base_L_ToeBaseShareBone'];
var KeyLegR =['CC_Base_R_Thigh','CC_Base_R_Calf','CC_Base_R_Foot','CC_Base_R_ToeBaseShareBone'];
var KeyArmL_IK =['CC_Base_L_Upperarm +','CC_Base_L_Forearm +','CC_Base_L_Hand +'];
var KeyArmR_IK =['CC_Base_R_Upperarm +','CC_Base_R_Forearm +','CC_Base_R_Hand +'];
var KeyCamera =['CC_Base_L_Clavicle','CC_Base_L_Upperarm','CC_Base_L_Forearm','CC_Base_L_Hand','CC_Base_R_Clavicle','CC_Base_R_Upperarm','CC_Base_R_Forearm','CC_Base_R_Hand','CC_Base_L_Thigh','CC_Base_L_Calf','CC_Base_L_Foot','CC_Base_L_ToeBaseShareBone','CC_Base_R_Thigh','CC_Base_R_Calf','CC_Base_R_Foot','CC_Base_R_ToeBaseShareBone','CC_Base_L_Upperarm +','CC_Base_L_Forearm +','CC_Base_L_Hand +','CC_Base_R_Upperarm +','CC_Base_R_Forearm +','CC_Base_R_Hand +'];

//Option
var FilterLerpIK=0.9;
var FilterLerpKalido=0.8;
var DamperArm=0.8;
var FilterLerpKalidoHand=0.8;
var UseIkarm=false;
var UseIkleg=false;
var StopLeg=true;
var StopHip=false;
var StopHeadRotate=false;
var StopHand=true;
var StopElbone=true;
					
function getSkinnedMesh(obj){       //Ritorna skinnedmesh da un modello...
    let r;
    obj.traverse( function ( child ) {
        if(child.isMesh){       // isSkinnedMesh
            r=child;
        }
    });
    return(r);
}

function loaderGLB_Reallusion(urlfName, callback) {               
    const loader = new GLTFLoader();
    loader.crossOrigin = "anonymous";
    // Import model from URL, add your own model here
    loader.load(urlfName, ( gltf )=> {			
        let mesh = gltf.scene.children[ 0 ].children[ 0 ].children[ 0 ];
       //  MakeTPose(mesh,90,0);  //Create Inititial pos T
         SetIKData_Reallusion(mesh.children[1]);
        if (callback) callback(mesh);
    },
    ( progress ) => console.log( 'Loading model...', 100.0 * ( progress.loaded / progress.total ), '%' ),
    ( error ) => {console.error('Error function -loader-  ! '+ error )   });
}




function getBoneByName( mesh, name ) {

    if ( mesh.geometry.userData.boneCaches[ name ] === undefined ) {
        if (mesh.skeleton !== undefined)   
                mesh.geometry.userData.boneCaches[ name ] = mesh.skeleton.getBoneByName( name );
        else if ( mesh.geometry.userData.boneCaches[ name ] === undefined ) {
            mesh.geometry.userData.boneCaches[ name ] = mesh.getObjectByName(name);
        }
        if ( mesh.geometry.userData.boneCaches[ name ] === undefined )
        {
            mesh.geometry.userData.boneCachesEuler[name] = new THREE.Euler();
            mesh.geometry.userData.boneCachesEuler[name].setFromQuaternion(mesh.geometry.userData.boneCaches[name].quaternion, 'XZY', true);
        }else
        {
            mesh.geometry.userData.boneCachesEuler[name] = new THREE.Euler();
            mesh.geometry.userData.boneCachesEuler[name].setFromQuaternion(mesh.geometry.userData.boneCaches[name].quaternion, 'XZY', true);         
        } 
    }
    return mesh.geometry.userData.boneCaches[ name ];
}

/*
function CopySkeletonAngle(SkeletonSrc,SkeletonDest)
{
  for (let i=0;i<SkeletonSrc.bones.length;i++)
  {
   // SkeletonDest.bones[i].copy(SkeletonSrc.bones[i]);  
   // SkeletonDest.bones[i].updateMatrixWorld();
   SkeletonDest.bones[i].quaternion.copy(SkeletonSrc.bones[i].quaternion);
  }
}
*/


function SetRestCamerakey(meshSkin)
{
	for(let i = 0; i < KeyCamera.length; ++i){
		//meshSkin.geometry.userData.boneCaches[KeyCamera[i]].quaternion.copy(meshSkin.geometry.userData.boneRestPos[KeyCamera[i]].quaternion);	
        meshSkin.geometry.userData.boneCaches[KeyCamera[i]].quaternion.slerp(meshSkin.geometry.userData.boneCaches[KeyCamera[i]].quaternion, FilterLerpIK); // interpolate
   
    }
}

function SetRestLegkey(meshSkin)
				{
					for(let i = 0; i < KeyLegL.length; ++i){
						meshSkin.geometry.userData.boneCaches[KeyLegL[i]].quaternion.copy(meshSkin.geometry.userData.boneRestPos[KeyLegL[i]].quaternion);	
					}
					for(let i = 0; i < KeyLegR.length; ++i){
						meshSkin.geometry.userData.boneCaches[KeyLegR[i]].quaternion.copy(meshSkin.geometry.userData.boneRestPos[KeyLegR[i]].quaternion);	
					}
				}

function SetRestLArmkey(meshSkin)
	            {
					for(let i = 0; i < KeyArmL.length; ++i){
						meshSkin.geometry.userData.boneCaches[KeyArmL[i]].quaternion.copy(meshSkin.geometry.userData.boneRestPos[KeyArmL[i]].quaternion);	
					}
					for(let i = 0; i < KeyArmR.length; ++i){
						meshSkin.geometry.userData.boneCaches[KeyArmR[i]].quaternion.copy(meshSkin.geometry.userData.boneRestPos[KeyArmR[i]].quaternion);	
					}
				}
				
function RestPos(meshSkin)
{
	for(let i = 0; i < meshSkin.skeleton.bones.length; ++i){
       if ("CC_Base_Hip"!=meshSkin.skeleton.bones[i].name)
            meshSkin.geometry.userData.boneCaches[meshSkin.skeleton.bones[i].name].quaternion.copy(meshSkin.geometry.userData.boneRestPos[meshSkin.skeleton.bones[i].name].quaternion);	
	}
}


function InitBoneData(meshSkin)
{
    for( var i=0;i<meshSkin.skeleton.bones.length;i++)
    {
        meshSkin.geometry.userData.boneRestPos[meshSkin.skeleton.bones[i].name]=meshSkin.skeleton.bones[i].clone();	
        meshSkin.geometry.userData.boneCaches[meshSkin.skeleton.bones[i].name]=meshSkin.skeleton.bones[i];	
        meshSkin.geometry.userData.boneCachesEuler[meshSkin.skeleton.bones[i].name] = new THREE.Euler();
        meshSkin.geometry.userData.boneCachesEuler[meshSkin.skeleton.bones[i].name].setFromQuaternion( meshSkin.geometry.userData.boneCaches[meshSkin.skeleton.bones[i].name].quaternion, 'XZY', true);
    }
}


//Function prepare T Pose
/*
const MakeTPose=(avatar_,armangle,legangle)=>{
    

//		let rotRightshoulder=avatar_.getObjectByName( 'CC_Base_R_Clavicle' ).rotation;
//		rotRightshoulder.z = (armangle) * THREE.MathUtils.DEG2RAD;
//		rotRightshoulder.y = (0) * THREE.MathUtils.DEG2RAD;
//		//rotRightshoulder.x = (armangle) * THREE.MathUtils.DEG2RAD;
//		avatar_.getObjectByName( 'CC_Base_R_Clavicle' ).updateMatrixWorld();
    
//		let rotLeftshoulder=avatar_.getObjectByName( 'CC_Base_L_Clavicle' ).rotation;
//		rotLeftshoulder.z = (-armangle) * THREE.MathUtils.DEG2RAD;
//		rotLeftshoulder.y = (0) * THREE.MathUtils.DEG2RAD;
//		//rotLeftshoulder.x = (-armangle) * THREE.MathUtils.DEG2RAD;
//		avatar_.getObjectByName( 'CC_Base_L_Clavicle' ).updateMatrixWorld();

    let rotRightArm=avatar_.getObjectByName( 'CC_Base_R_Upperarm' ).rotation;
    rotRightArm.z = (90- armangle) * THREE.MathUtils.DEG2RAD;
    avatar_.getObjectByName( 'CC_Base_R_Upperarm' ).updateMatrixWorld();
    
    let rotLeftArm=avatar_.getObjectByName( 'CC_Base_L_Upperarm' ).rotation;
    rotLeftArm.z = -( 90- armangle) * THREE.MathUtils.DEG2RAD;
    avatar_.getObjectByName( 'CC_Base_L_Upperarm' ).updateMatrixWorld();
    
    let rotLeftForeArm=avatar_.getObjectByName( 'CC_Base_L_Forearm' ).rotation;
    rotLeftForeArm.z = 0;
    avatar_.getObjectByName( 'CC_Base_L_Forearm' ).updateMatrixWorld();
    
    let rotRightForeArm=avatar_.getObjectByName( 'CC_Base_R_Forearm' ).rotation;
    rotRightForeArm.z = 0;
    avatar_.getObjectByName( 'CC_Base_R_Forearm' ).updateMatrixWorld();
                        
    avatar_.getObjectByName( 'CC_Base_R_Thigh' ).rotation.z = (180 - legangle) * THREE.MathUtils.DEG2RAD;
    avatar_.getObjectByName( 'CC_Base_R_Thigh' ).updateMatrixWorld();
    avatar_.getObjectByName( 'CC_Base_L_Thigh' ).rotation.z = -(180 - legangle) * THREE.MathUtils.DEG2RAD;
    avatar_.getObjectByName( 'CC_Base_L_Thigh' ).updateMatrixWorld();
    
}
*/

function updateResPos(mesh)	
{
    var meshSkin=mesh.getObjectByProperty( 'type','SkinnedMesh' ); 
   
    if  (meshSkin.geometry.userData.initIKData!== undefined) 
    {
         meshSkin.geometry.userData.updateResPos=true;
    }   
}
//Function IK MMD
function SetIKData_Reallusion(mesh)
{
    //var meshSkin=getSkinnedMesh(mesh);  
    var meshSkin=mesh.getObjectByProperty( 'type','SkinnedMesh' ); 
    if (meshSkin==undefined) return false;
    if  (meshSkin.geometry.userData.initIKData!== undefined) 
    {
        return meshSkin.geometry.userData.initIKData;
    }
    
    meshSkin.geometry.userData.MMD = {
            bones:[],
            iks: [],
            grants: [],
            rigidBodies: [],
            constraints: [],
            format: 'pmd'
    };
    meshSkin.geometry.userData.MMD.iks=[];
    for( var i=0;i<meshSkin.skeleton.bones.length;i++)
    {
        meshSkin.geometry.userData.MMD.bones.push({});		
    }
    meshSkin.geometry.userData.boneRestPos=[];
    meshSkin.geometry.userData.boneCaches=[];
    meshSkin.geometry.userData.boneCachesEuler=[];  

    setupIK( meshSkin );
    //helper.add( meshSkin, {
    //    //animation: animation,
    //    physics: false
    //} );

    meshSkin.geometry.userData.helper = new MMDAnimationHelper( { afterglow: 2.0 } );
    meshSkin.geometry.userData.helper.add( meshSkin, {
            //animation: animation,
            physics: false
    } );

    InitBoneData(meshSkin);	
    

    

    //ikHelper = meshSkin.geometry.userData.helper.objects.get( meshSkin ).ikSolver.createHelper();
    //ikHelper.visible = false;
    //scene.add( ikHelper );
    meshSkin.geometry.userData.initIKData=true;
    return meshSkin.geometry.userData.initIKData;
}

function addTransformControl( bone ) {              
    // var control = new TransformControls( camera, renderer.domElement );
    // control.addEventListener('mouseDown', function () {
    //     controls.enabled = false;
    // });
    // control.addEventListener('mouseUp', function () {
    //     controls.enabled = true;
    // });
    // control.showY=false;
    // control.showX=false;
    // control.showZ=false;
    // control.attach( bone );
    // //scene.add( control );
    // transformControls.push( control );
    // return 	control;
}



function solveIK(mesh) {
    if ((!UseIkarm)&&(!UseIkleg)) return;  
    var objects = mesh.geometry.userData.helper.objects.get( mesh );

    if ( ! objects ) 
                    return;
    var objhelper=  mesh.geometry.userData.helper.objects.get( mesh );
    if ( !objhelper) 
                    return;
    objhelper.ikSolver.update();
    if (UseIkarm) 
    {
     updateArmBones_Right(mesh);
     updateArmBones_Left(mesh);
    }
}
function updateArmBones_Left( mesh ){

    getBoneByName( mesh,'CC_Base_L_Upperarm' ).quaternion.copy(
        getBoneByName( mesh,'CC_Base_L_Upperarm +' ).quaternion );
    getBoneByName( mesh, 'CC_Base_L_Forearm' ).quaternion.copy(
        getBoneByName( mesh, 'CC_Base_L_Forearm +' ).quaternion );
    getBoneByName( mesh, 'CC_Base_L_Hand' ).quaternion.copy(
        getBoneByName( mesh, 'CC_Base_L_Hand +' ).quaternion );

}

function updateArmBones_Right( mesh ){

    getBoneByName( mesh,'CC_Base_R_Upperarm' ).quaternion.copy(
        getBoneByName( mesh,'CC_Base_R_Upperarm +' ).quaternion );
    getBoneByName( mesh, 'CC_Base_R_Forearm' ).quaternion.copy(
        getBoneByName( mesh, 'CC_Base_R_Forearm +' ).quaternion );
    getBoneByName( mesh, 'CC_Base_R_Hand' ).quaternion.copy(
        getBoneByName( mesh, 'CC_Base_R_Hand +' ).quaternion );

}


function setupIK( mesh ) {

    addArmIK( 'L', mesh,
        mesh.geometry.userData.MMD.iks,
        mesh.geometry.userData.MMD.bones );

    addArmIK( 'R', mesh,
        mesh.geometry.userData.MMD.iks,
        mesh.geometry.userData.MMD.bones );

    addTransformControl(
            getBoneByName( mesh, 'CC_Base_Hip' ) );

    addLegIK( 'L', mesh,
        mesh.geometry.userData.MMD.iks,
        mesh.geometry.userData.MMD.bones );

    addLegIK( 'R', mesh,
        mesh.geometry.userData.MMD.iks,
        mesh.geometry.userData.MMD.bones );	
    return;
}

function addArmIK( side, mesh, iks, boneParams ) {
    var ik = {};
    var bones = mesh.skeleton.bones;
    addArmPlusBones( side, mesh, iks, boneParams );
    var targetname;
    if (side==='L')
    targetname = 'armIK_L';
    else 
    targetname = 'armIK_R';
    var target = getBoneByName( mesh,targetname );
    var effector = getBoneByName( mesh,'CC_Base_'+side + '_Hand +' );
    ik.target = bones.indexOf( target );
    ik.effector = bones.indexOf( effector );
    var linkBone = effector.parent;
    var links = [];
    for ( var i = 0; i < 2; i ++ ) {
        //console.log( linkBone.name );
        var link = {};
        link.index = bones.indexOf( linkBone );
        link.enable = true;
        // heuristic
        if ( linkBone.name.indexOf( 'L_Upperarm' ) !== - 1 ) {
        //	link.rotationMin =  new THREE.Vector3(  - Math.PI/2,  - Math.PI/2, - Math.PI/2 );
        //	link.rotationMax =  new THREE.Vector3(  Math.PI/2 ,  Math.PI/2 ,  Math.PI/2 );
        }else if ( linkBone.name.indexOf( 'R_Upperarm' ) !== - 1 ) {
        //	link.rotationMin =  new THREE.Vector3(  - Math.PI/2,  - Math.PI/2, - Math.PI/2 );
        //	link.rotationMax =  new THREE.Vector3(  Math.PI/2 ,  Math.PI/2 ,  Math.PI/2 );
        }else if ( linkBone.name.indexOf( 'Forearm' ) !== - 1 ) {
        //	 link.rotationMin =  new THREE.Vector3(  - Math.PI,  - Math.PI, 0 );
        //	 link.rotationMax =  new THREE.Vector3(  Math.PI ,  Math.PI , 0 );
             link.limitation = new THREE.Vector3(
                linkBone.position.y, - linkBone.position.x, 0 ).normalize();
        }/* else if ( linkBone.name.indexOf( 'shoulder' ) !== - 1 ) {
            link.rotationMin = [ - Math.PI / 128, - Math.PI / 128, - Math.PI / 128 ];
            link.rotationMax = [ Math.PI / 128, Math.PI / 128, Math.PI / 128 ];
        } else {
            link.rotationMin = [ - Math.PI / 2, - Math.PI / 2, - Math.PI / 2 ];
            link.rotationMax = [ Math.PI / 2, Math.PI / 2, Math.PI / 2 ];
        }*/
        links.push( link );
        linkBone = linkBone.parent;
    }
    ik.iteration = 20;
    ik.maxAngle = 100000;
    ik.links = links;
    //console.log( ik );
    iks.push( ik );
    addTransformControl( target );
}

function addArmPlusBones( side, mesh, iks, boneParams ) {
    var skeleton = mesh.skeleton;
    var armBone = getBoneByName( mesh,'CC_Base_'+side +'_Upperarm' );
    var elbowBone = getBoneByName( mesh,'CC_Base_'+side + '_Forearm' );
    var wristBone = getBoneByName( mesh,'CC_Base_'+side + '_Hand' );
    var ikBone = wristBone.clone();
    if (side==='L')
    ikBone.name = 'armIK_L';
    else 
    ikBone.name = 'armIK_R';
    var meshWorldPosition = new THREE.Vector3();
    var wristWorldPosition = new THREE.Vector3();
    mesh.getWorldPosition( meshWorldPosition );
    wristBone.getWorldPosition( wristWorldPosition );
    ikBone.position.copy( wristWorldPosition.sub( meshWorldPosition ) );
    var armBonePlus = armBone.clone();
    armBonePlus.name += ' +';
    var elbowBonePlus = elbowBone.clone();
    elbowBonePlus.name += ' +';
    var wristBonePlus = wristBone.clone();
    wristBonePlus.name += ' +';
    armBone.parent.add( armBonePlus );
    armBonePlus.add( elbowBonePlus );
    elbowBonePlus.add( wristBonePlus );
    mesh.add( ikBone );
    skeleton.bones.push( armBonePlus );
    skeleton.bones.push( elbowBonePlus );
    skeleton.bones.push( wristBonePlus );
    skeleton.bones.push( ikBone );
    skeleton.boneInverses.push( new THREE.Matrix4() );
    skeleton.boneInverses.push( new THREE.Matrix4() );
    skeleton.boneInverses.push( new THREE.Matrix4() );
    skeleton.boneInverses.push( new THREE.Matrix4() );
    boneParams.push( {} );
    boneParams.push( {} );
    boneParams.push( {} );
    boneParams.push( {} );
}

function addLegIK( side, mesh, iks, boneParams ) {
    var ik = {};
    var bones = mesh.skeleton.bones;
    addLegPlusBones( side, mesh, iks, boneParams );
    var targetname;
    if (side==='L')
    targetname = 'legIK_L';
    else 
    targetname = 'legIK_R';
    var target = getBoneByName( mesh,targetname );
    var effector = getBoneByName( mesh,'CC_Base_'+side + '_Foot' );
    ik.target = bones.indexOf( target );
    ik.effector = bones.indexOf( effector );
    var linkBone = effector.parent;
    var links = [];
    for ( var i = 0; i < 2; i ++ ) {
        //console.log( linkBone.name );
        var link = {};
        link.index = bones.indexOf( linkBone );
        link.enable = true;       
        if ( linkBone.name.indexOf( 'L_Calf' ) !== - 1 ) {
            link.rotationMin =  new THREE.Vector3(  - Math.PI,    0,   0 );
            link.rotationMax =  new THREE.Vector3(    0 ,   0 ,  0 );
        }else if ( linkBone.name.indexOf( 'R_Calf' ) !== - 1 ) {
            link.rotationMin =  new THREE.Vector3(- Math.PI, 0, 0);
            link.rotationMax =  new THREE.Vector3( 0, 0, 0 );
        }
        links.push( link );
        linkBone = linkBone.parent;
    }
    ik.iteration = 40;
    ik.maxAngle = 2;
    ik.links = links;
    //console.log( ik );
    iks.push( ik );
    addTransformControl( target );
}

function addLegPlusBones( side, mesh, iks, boneParams ) {
    var skeleton = mesh.skeleton;
    var footBone = getBoneByName( mesh,'CC_Base_'+side + '_Foot' );
    var ikBone = footBone.clone();
    if (side==='L')
    ikBone.name = 'legIK_L';
    else 
    ikBone.name = 'legIK_R';
    var meshWorldPosition = new THREE.Vector3();
    var wristWorldPosition = new THREE.Vector3();
    mesh.getWorldPosition( meshWorldPosition );
    footBone.getWorldPosition( wristWorldPosition );
    ikBone.position.copy( wristWorldPosition.sub( meshWorldPosition ) );
    mesh.add( ikBone );
    skeleton.bones.push( ikBone );
    skeleton.boneInverses.push( new THREE.Matrix4() );
    boneParams.push( {} );
}
// Animate Position Helper Function
/*const rigPosition = (mesh,name, position = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
    if (!mesh) {
        return;
    }
    const Part = getBoneByName(mesh,name);
    if (!Part) {
        return;
    }
    let vector = new THREE.Vector3(position.x * dampener, position.y * dampener, position.z * dampener);
    Part.position.lerp(vector, lerpAmount); // interpolate
};
*/
const rigRotationHead = (mesh,name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
    if (!mesh) {
        return;
    }
    const Part = getBoneByName(mesh,name);
    if (!Part) {
        return;
    }
    let eulerLast = mesh.geometry.userData.boneCachesEuler[name];	
    let euler = new THREE.Euler( -rotation.x * dampener, rotation.y * dampener , -rotation.z * dampener );
    if (StopHeadRotate)
    {
        euler.z=eulerLast.z;
        euler.x=eulerLast.x;
        euler.y=eulerLast.y;   
    }
    let quaternion = new THREE.Quaternion().setFromEuler(euler);
    //Part.quaternion.copy(quaternion);
    Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
};
const rigRotation = (mesh,name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
    if (!mesh) {
        return;
    }
    const Part = getBoneByName(mesh,name);
    if (!Part) {
        return;
    }
    let eulerLast = mesh.geometry.userData.boneCachesEuler[name];	
    let euler = new THREE.Euler( -rotation.x * dampener, rotation.y * dampener , -rotation.z * dampener );
    if (StopHeadRotate)
    {
        euler.z=eulerLast.z;
        euler.x=eulerLast.x;
        euler.y=eulerLast.y;   
    }
    let quaternion = new THREE.Quaternion().setFromEuler(euler);
    //Part.quaternion.copy(quaternion);
    Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
};
const rigRotationHips = (mesh,name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
    if (!mesh) {
        return;
    }
    const Part = getBoneByName(mesh,name);
    if (!Part) {
        return;
    }
    let eulerRest = mesh.geometry.userData.boneRestPos[name];			 
    let eulerLast = mesh.geometry.userData.boneCachesEuler[name];			
    let euler = new THREE.Euler( rotation.x * dampener, rotation.y * dampener , -rotation.z * dampener );
    if (StopHip)
    {
        euler.z=eulerLast.z;
        euler.x=eulerLast.x;
        euler.y=eulerLast.y;
    }else 
    {
        euler.z=eulerLast.z-0.05;
        euler.x=eulerLast.x;        
    }
    let quaternion = new THREE.Quaternion().setFromEuler(euler);
    //Part.quaternion.copy(quaternion);
    Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
};
const rigRotationLeftLeg = (mesh,name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
    if (!mesh) {
        return;
    }
    const Part = getBoneByName(mesh,name);
    if (!Part) {
        return;
    }
    let eulerLast = mesh.geometry.userData.boneCachesEuler[name];					
    
    if (rotation.x<-0.1)
					  rotation.x=rotation.x+0.2;
	let euler = new THREE.Euler( rotation.x * dampener, rotation.y * dampener , -rotation.z * dampener );
	euler.x=euler.x+eulerLast.x;
    euler.y=euler.y+eulerLast.y;
    euler.z=euler.z+eulerLast.z;
    let quaternion = new THREE.Quaternion().setFromEuler(euler);
    //Part.quaternion.copy(quaternion);
    Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
};

const rigRotationLeftLegUp = (mesh,name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
    if (!mesh) {
        return;
    }
    const Part = getBoneByName(mesh,name);
    if (!Part) { 	
        return;
    }

    let eulerLast = mesh.geometry.userData.boneCachesEuler[name];

    
    let euler = new THREE.Euler( -rotation.x * dampener, rotation.y * dampener,-rotation.z * dampener);
    euler.x=euler.x+eulerLast.x;
    euler.y=euler.y+eulerLast.y;
    euler.z=euler.z+eulerLast.z;
    let quaternion = new THREE.Quaternion().setFromEuler(euler);
    //Part.quaternion.copy(quaternion);
    Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
};

const rigRotationRightArm = (mesh,name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
    if (!mesh) {
        return;
    }
    const Part = getBoneByName(mesh,name);
    if (!Part) {
        return;
    }
    //let eulerLast = mesh.geometry.userData.boneCachesEuler[name];
    let euler = new THREE.Euler( rotation.y * dampener, rotation.x * dampener , -rotation.z * dampener );
    let quaternion = new THREE.Quaternion().setFromEuler(euler);
    //Part.quaternion.copy(quaternion);
    Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
};
const rigRotationLeftArm = (mesh,name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
    if (!mesh) {
        return;
    }
    const Part = getBoneByName(mesh,name);
    if (!Part) {
        return;
    }
    //let eulerLast = mesh.geometry.userData.boneCachesEuler[name];	
    let euler = new THREE.Euler( -rotation.y * dampener, rotation.x * dampener , -rotation.z * dampener );
    //euler.z=euler.z+eulerLast.z;
    let quaternion = new THREE.Quaternion().setFromEuler(euler);
    //Part.quaternion.copy(quaternion);
    Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
};
const rigRotationFingerLeft = (mesh,name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1.6, lerpAmount = 0.3) => {
    if (!mesh) {
        return;
    }
    const Part = getBoneByName(mesh,name);
    if (!Part) {
        return;
    }
    let eulerLast = mesh.geometry.userData.boneCachesEuler[name];					
    let euler = new THREE.Euler( -rotation.y * dampener, rotation.x * dampener , -rotation.z * dampener );
    euler.z=euler.z+(eulerLast.z*0.5);
    let quaternion = new THREE.Quaternion().setFromEuler(euler);
    //Part.quaternion.copy(quaternion);
    Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
};				
const rigRotationFingerRight = (mesh,name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1.6, lerpAmount = 0.3) => {
    if (!mesh) {
        return;
    }
    const Part = getBoneByName(mesh,name);
    if (!Part) {
        return;
    }
    let eulerLast = mesh.geometry.userData.boneCachesEuler[name]; //last position
   let euler = new THREE.Euler( rotation.y * dampener, rotation.x * dampener , -rotation.z * dampener );
    euler.z=euler.z+(eulerLast.z*0.5);
    let quaternion = new THREE.Quaternion().setFromEuler(euler);
    //Part.quaternion.copy(quaternion);
    Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
};
const rigFace = (mesh,riggedFace) => {
    if (!mesh) {
        return;
    }
    rigRotationHead(mesh,"CC_Base_NeckTwist01", riggedFace.head, 0.7);					
};	
	
/* Model Character Animator */
			
const animateModelIK = (mesh, results) =>{
    if ((!UseIkarm)&&(!UseIkleg)) return;
    SetRestCamerakey();  
    // Pose 3D Landmarks are with respect to Hip distance in meters
    //const pose3DLandmarks = results.poseWorldLandmarks;
    const pose3DLandmarks = results.ea;
    var wristLocalPosition = new THREE.Vector3();
    var wristWorldPosition = new THREE.Vector3();
    var wristWorldPosition2 = new THREE.Vector3();
    var footLocalPosition = new THREE.Vector3();
    var footWorldPosition = new THREE.Vector3();
    var footWorldPosition2 = new THREE.Vector3();

    var HipWorldPosition= new THREE.Vector3();
    let bone_IK;

    let Mark_Hips=getBoneByName( mesh, 'CC_Base_Hip' );
    Mark_Hips.getWorldPosition(HipWorldPosition);
    if (pose3DLandmarks !== undefined && pose3DLandmarks[15] !== undefined)  
    {
        bone_IK=getBoneByName( mesh, 'armIK_R' );	
        if (pose3DLandmarks[15].visibility>0.6) {
            wristWorldPosition2.x=-(pose3DLandmarks[15].x*100)-10;
            wristWorldPosition2.y=-(pose3DLandmarks[15].y*100);
            wristWorldPosition2.y=wristWorldPosition2.y+(HipWorldPosition.y-10);
            wristWorldPosition2.z=-(pose3DLandmarks[15].z*100);
            wristLocalPosition=wristWorldPosition2.clone();
            bone_IK.parent.worldToLocal(wristLocalPosition);
            // bone_IK.position.x=wristLocalPosition.x;
            // bone_IK.position.y=wristLocalPosition.y;	
            // bone_IK.position.z=wristLocalPosition.z;	
            bone_IK.position.lerp(wristLocalPosition,FilterLerpIK); // interpolate						
        } else 
        {						
            //SetRestPos(KeyArmR);
            /*wristLocalPosition=HipWorldPosition.clone();
            bone_IK.parent.worldToLocal(wristLocalPosition);
            wristLocalPosition.x=wristLocalPosition.x-30;
            wristLocalPosition.y=wristLocalPosition.y-30;	
            wristLocalPosition.z=wristLocalPosition.z+10;
            //bone_IK.position=wristLocalPosition;	
            bone_IK.position.lerp(wristLocalPosition, 0.8); // interpolate
            */
        }
    } 
    
    if (pose3DLandmarks !== undefined && pose3DLandmarks[16] !== undefined)  
    {
        bone_IK=getBoneByName( mesh, 'armIK_L' ); 
        if (pose3DLandmarks[16].visibility>0.6) {  
            wristWorldPosition2.x=-(pose3DLandmarks[16].x*100)+10;
            wristWorldPosition2.y=-(pose3DLandmarks[16].y*100);
            wristWorldPosition2.y=wristWorldPosition2.y+(HipWorldPosition.y-10);
            wristWorldPosition2.z=-(pose3DLandmarks[15].z*100);
            wristLocalPosition=wristWorldPosition2.clone();
            bone_IK.parent.worldToLocal(wristLocalPosition);
            // bone_IK.position.x=wristLocalPosition.x;
            // bone_IK.position.y=wristLocalPosition.y;	
            // bone_IK.position.z=wristLocalPosition.z;	
            bone_IK.position.lerp(wristLocalPosition,FilterLerpIK); // interpolate	
        }else 
        {		
            //SetRestPos(KeyArmL);
            /*					
            wristLocalPosition=HipWorldPosition.clone();
            bone_IK.parent.worldToLocal(wristLocalPosition);
            wristLocalPosition.x=wristLocalPosition.x-30;
            wristLocalPosition.y=wristLocalPosition.y-30;	
            wristLocalPosition.z=wristLocalPosition.z+10;
            //bone_IK.position=wristLocalPosition;	
            bone_IK.position.lerp(wristLocalPosition, 0.8); // interpolate
            */
        }
    }
        
    if (pose3DLandmarks !== undefined && pose3DLandmarks[27] !== undefined && (!StopLeg))  
    {
        let bone_IK=getBoneByName( mesh, 'legIK_R' );	
        if (pose3DLandmarks[27].visibility>0.6) {
            footWorldPosition2.x=-(pose3DLandmarks[27].x*100);
            footWorldPosition2.y=(pose3DLandmarks[27].y*100);
            footWorldPosition2.y=-(footWorldPosition2.y-(HipWorldPosition.y-30));
            footWorldPosition2.z=-footWorldPosition2.z*100;
            footWorldPosition=footWorldPosition2.clone();
            bone_IK.parent.worldToLocal(footWorldPosition);
        //	bone_IK.position.x=footWorldPosition.x;
        //	bone_IK.position.y=footWorldPosition.y;
        //	bone_IK.position.z=footWorldPosition.z;
            bone_IK.position.lerp(footWorldPosition, FilterLerpIK); // interpolate	
        }else 
        {
            //SetRestPos(KeyLegR);
        }
    }

    if (pose3DLandmarks !== undefined && pose3DLandmarks[28] !== undefined && (!StopLeg))  
    {
        let bone_IK=getBoneByName( mesh, 'legIK_L' );	
        if (pose3DLandmarks[28].visibility>0.6) {
            footWorldPosition2.x=-(pose3DLandmarks[28].x*100);
            footWorldPosition2.y=(pose3DLandmarks[28].y*100);
            footWorldPosition2.y=-(footWorldPosition2.y-(HipWorldPosition.y-30));
            footWorldPosition2.z=-footWorldPosition2.z*100;
            footWorldPosition=footWorldPosition2.clone();
            bone_IK.parent.worldToLocal(footWorldPosition);
        //	bone_IK.position.x=footWorldPosition.x;
        //	bone_IK.position.y=footWorldPosition.y;
        //	bone_IK.position.z=footWorldPosition.z;
            bone_IK.position.lerp(footWorldPosition,FilterLerpIK); // interpolate	
        }else 
        {
            //SetRestPos(KeyLegL);
        }
    }
}		
const animateModelKalido = (mesh, results) => {
    if (!mesh) {
        return;
    }
    if (mesh.geometry.userData.updateResPos!=undefined)
        if (mesh.geometry.userData.updateResPos) 
        {
            InitBoneData(mesh);
            mesh.geometry.userData.updateResPos=false;  
        }
    // Take the results from `Holistic` and animate character based on its Face, Pose, and Hand Keypoints.
    let riggedPose, riggedLeftHand, riggedRightHand, riggedFace;
    const faceLandmarks = results.faceLandmarks;
    // Pose 3D Landmarks are with respect to Hip distance in meters
    const pose3DLandmarks = results.ea;
    // Pose 2D landmarks are with respect to videoWidth and videoHeight
    const pose2DLandmarks = results.poseLandmarks;
    // Be careful, hand landmarks may be reversed
    const leftHandLandmarks = results.rightHandLandmarks;
    const rightHandLandmarks = results.leftHandLandmarks;  
    // Animate Face
    if (faceLandmarks) {
        riggedFace = Kalidokit.Face.solve(faceLandmarks, {
            runtime:"mediapipe"//, video:videoElement
        });
        rigFace(mesh,riggedFace);
    }
    
    RestPos(mesh);

    //if (pose3DLandmarks[14].visibility>0.7)
    //{  
        if (getBoneByName(mesh,"CC_Base_R_Clavicle"))
        {
            let  eulertmp = new THREE.Euler();
            eulertmp.setFromQuaternion(mesh.geometry.userData.boneCaches["CC_Base_R_Clavicle"].quaternion, 'XZY', true);
            eulertmp.x=-120*THREE.MathUtils.DEG2RAD;
            let quaternion = new THREE.Quaternion().setFromEuler(eulertmp);
            //mesh.geometry.userData.boneCaches["CC_Base_R_Clavicle"].quaternion.copy(quaternion);
            mesh.geometry.userData.boneCaches["CC_Base_R_Clavicle"].quaternion.slerp(quaternion,0.8); // interpolate
        }
    //}else{
    //    if (getBoneByName(mesh,"CC_Base_R_Clavicle"))
    //   {
    //        let  eulertmp = new THREE.Euler();
    //        eulertmp.setFromQuaternion(mesh.geometry.userData.boneRestPos["CC_Base_R_Clavicle"].quaternion, 'XZY', true);
    //        let quaternion = new THREE.Quaternion().setFromEuler(eulertmp);
            //mesh.geometry.userData.boneCaches["CC_Base_R_Clavicle"].quaternion.copy(quaternion);
    //        mesh.geometry.userData.boneCaches["CC_Base_R_Clavicle"].quaternion.slerp(quaternion,0.8);
    //    }
    //}

//    if (pose3DLandmarks[13].visibility>0.7)
//    { 
        if (getBoneByName(mesh,"CC_Base_L_Clavicle"))
        {
            let  eulertmp = new THREE.Euler();
            eulertmp.setFromQuaternion(mesh.geometry.userData.boneCaches["CC_Base_L_Clavicle"].quaternion, 'XZY', true);
            eulertmp.x=-106*THREE.MathUtils.DEG2RAD;
            let quaternion = new THREE.Quaternion().setFromEuler(eulertmp);
            //mesh.geometry.userData.boneCaches["CC_Base_L_Clavicle"].quaternion.copy(quaternion);
            mesh.geometry.userData.boneCaches["CC_Base_L_Clavicle"].quaternion.slerp(quaternion,0.8);
        }
//    }else{
//        if (getBoneByName(mesh,"CC_Base_L_Clavicle"))
//        {
//            let  eulertmp = new THREE.Euler();
//            eulertmp.setFromQuaternion(mesh.geometry.userData.boneRestPos["CC_Base_L_Clavicle"].quaternion, 'XZY', true);
//            let quaternion = new THREE.Quaternion().setFromEuler(eulertmp);
            //mesh.geometry.userData.boneCaches["CC_Base_L_Clavicle"].quaternion.copy(quaternion);
//            mesh.geometry.userData.boneCaches["CC_Base_L_Clavicle"].quaternion.slerp(quaternion,0.8);
//        }
//    }





   

    // Animate Pose
    if (pose2DLandmarks && pose3DLandmarks) {
        riggedPose = Kalidokit.Pose.solve(pose3DLandmarks, pose2DLandmarks, {
            runtime:"mediapipe"//, video:videoElement
        });
        rigRotationHips(mesh,"CC_Base_Hip", riggedPose.Hips.rotation, 1);
        //rigPosition(
        //	"mixamorigHips",
        //	{
        //		x: -riggedPose.Hips.position.x + 0.2, // Reverse direction
        //		y: riggedPose.Hips.position.y + 1, // Add a bit of height
        //		z: -riggedPose.Hips.position.z, // Reverse direction
        //	},
        //	1,
        //	0.07
        //);    
        rigRotation(mesh,"CC_Base_Spine01", riggedPose.Spine,0.25,FilterLerpKalido); //"Chest"
        rigRotation(mesh,"CC_Base_Waist", riggedPose.Spine, 0.25,FilterLerpKalido);  //"Spine"
        if (!UseIkarm)
        {	
			rigRotationRightArm(mesh,"CC_Base_R_Upperarm", riggedPose.RightUpperArm, DamperArm, FilterLerpKalido);//"RightUpperArm"
            rigRotationRightArm(mesh,"CC_Base_R_Forearm", riggedPose.RightLowerArm, DamperArm, FilterLerpKalido);//"RightLowerArm"
            
            rigRotationLeftArm(mesh,"CC_Base_L_Upperarm", riggedPose.LeftUpperArm, DamperArm, FilterLerpKalido);     //"LeftUpperArm"
            rigRotationLeftArm(mesh,"CC_Base_L_Forearm", riggedPose.LeftLowerArm, DamperArm, FilterLerpKalido); //"LeftLowerArm"
            
            if (pose3DLandmarks[27].visibility>0.6 && (!StopLeg) ) { 
                rigRotationLeftLegUp(mesh,"CC_Base_L_Thigh", riggedPose.LeftUpperLeg, 1, FilterLerpKalido); //"LeftUpperLeg"
                rigRotationLeftLeg(mesh,"CC_Base_L_Calf", riggedPose.LeftLowerLeg, 1, FilterLerpKalido); //"LeftLowerLeg"
            }
            if (pose3DLandmarks[28].visibility>0.6 && (!StopLeg)) { 
                rigRotationLeftLegUp(mesh,"CC_Base_R_Thigh", riggedPose.RightUpperLeg, 1, FilterLerpKalido); //"RightUpperLeg"
                rigRotationLeftLeg(mesh,"CC_Base_R_Calf", riggedPose.RightLowerLeg, 1, FilterLerpKalido);   //"RightLowerLeg"
            }
            if(StopLeg)
            {
                SetRestLegkey(mesh);
            } 
        }	
    
    }
    // Animate Hands
    if (leftHandLandmarks) {
        riggedLeftHand = Kalidokit.Hand.solve(leftHandLandmarks, "Left");
        if (!StopElbone)
        {
        rigRotationLeftArm(mesh,"CC_Base_L_Hand", {
            // Combine pose rotation Z and hand rotation X Y
            z: riggedPose.LeftHand.z,
            y: riggedLeftHand.LeftWrist.y,
            x: riggedLeftHand.LeftWrist.x,
        }, 1, FilterLerpKalidoHand);
        }
        if (!StopHand )
        {
        //rigRotationLeftArm("CC_Base_L_Hand", riggedLeftHand.LeftWrist);
        rigRotationFingerLeft(mesh,"CC_Base_L_Ring1", riggedLeftHand.LeftRingProximal, 1, FilterLerpKalidoHand);
        rigRotationFingerLeft(mesh,"CC_Base_L_Ring2", riggedLeftHand.LeftRingIntermediate, 1, FilterLerpKalidoHand);
        rigRotationFingerLeft(mesh,"CC_Base_L_Ring3", riggedLeftHand.LeftRingDistal, 1, FilterLerpKalidoHand);
        rigRotationFingerLeft(mesh,"CC_Base_L_Index1", riggedLeftHand.LeftIndexProximal, 1, FilterLerpKalidoHand);
        rigRotationFingerLeft(mesh,"CC_Base_L_Index2", riggedLeftHand.LeftIndexIntermediate, 1, FilterLerpKalidoHand);
        rigRotationFingerLeft(mesh,"CC_Base_L_Index3", riggedLeftHand.LeftIndexDistal, 1, FilterLerpKalidoHand);
        rigRotationFingerLeft(mesh,"CC_Base_L_Mid1", riggedLeftHand.LeftMiddleProximal, 1, FilterLerpKalidoHand);
        rigRotationFingerLeft(mesh,"CC_Base_L_Mid2", riggedLeftHand.LeftMiddleIntermediate, 1, FilterLerpKalidoHand);
        rigRotationFingerLeft(mesh,"CC_Base_L_Mid3", riggedLeftHand.LeftMiddleDistal, 1, FilterLerpKalidoHand);
        rigRotationFingerLeft(mesh,"CC_Base_L_Thumb1", riggedLeftHand.LeftThumbProximal, 2, FilterLerpKalidoHand);
        rigRotationFingerLeft(mesh,"CC_Base_L_Thumb2", riggedLeftHand.LeftThumbIntermediate, 1, FilterLerpKalidoHand);
        rigRotationFingerLeft(mesh,"CC_Base_L_Thumb3", riggedLeftHand.LeftThumbDistal, 1, FilterLerpKalidoHand);
        rigRotationFingerLeft(mesh,"CC_Base_L_Pinky1", riggedLeftHand.LeftLittleProximal, 1, FilterLerpKalidoHand);
        rigRotationFingerLeft(mesh,"CC_Base_L_Pinky2", riggedLeftHand.LeftLittleIntermediate, 1, FilterLerpKalidoHand);
        rigRotationFingerLeft(mesh,"CC_Base_L_Pinky1", riggedLeftHand.LeftLittleDistal, 1, FilterLerpKalidoHand);
       }    
    }
    if (rightHandLandmarks) {
        riggedRightHand = Kalidokit.Hand.solve(rightHandLandmarks, "Right");	
        if (!StopElbone)
        {
            rigRotationRightArm(mesh,"CC_Base_R_Hand", {
                // Combine Z axis from pose hand and X/Y axis from hand wrist rotation
                z: riggedPose.RightHand.z,
                y: riggedRightHand.RightWrist.y,
                x: riggedRightHand.RightWrist.x,
            }, 1, FilterLerpKalidoHand);
        }
        if (!StopHand )
        {
        rigRotationFingerRight(mesh,"CC_Base_R_Ring1", riggedRightHand.RightRingProximal, 1, FilterLerpKalidoHand);
		rigRotationFingerRight(mesh,"CC_Base_R_Ring2", riggedRightHand.RightRingIntermediate, 1, FilterLerpKalidoHand);
		rigRotationFingerRight(mesh,"CC_Base_R_Ring3", riggedRightHand.RightRingDistal, 1, FilterLerpKalidoHand);
		rigRotationFingerRight(mesh,"CC_Base_R_Index1", riggedRightHand.RightIndexProximal, 1, FilterLerpKalidoHand);
		rigRotationFingerRight(mesh,"CC_Base_R_Index2", riggedRightHand.RightIndexIntermediate, 1, FilterLerpKalidoHand);
		rigRotationFingerRight(mesh,"CC_Base_R_Index3", riggedRightHand.RightIndexDistal, 1, FilterLerpKalidoHand);
		rigRotationFingerRight(mesh,"CC_Base_R_Mid1", riggedRightHand.RightMiddleProximal, 1, FilterLerpKalidoHand);
		rigRotationFingerRight(mesh,"CC_Base_R_Mid2", riggedRightHand.RightMiddleIntermediate, 1, FilterLerpKalidoHand);
		rigRotationFingerRight(mesh,"CC_Base_R_Mid2", riggedRightHand.RightMiddleDistal, 1, FilterLerpKalidoHand);
		rigRotationFingerRight(mesh,"CC_Base_R_Thumb1", riggedRightHand.RightThumbProximal, 2, FilterLerpKalidoHand);				
		rigRotationFingerRight(mesh,"CC_Base_R_Thumb2", riggedRightHand.RightThumbIntermediate, 1, FilterLerpKalidoHand);
		rigRotationFingerRight(mesh,"CC_Base_R_Thumb3", riggedRightHand.RightThumbDistal, 1, FilterLerpKalidoHand);
		rigRotationFingerRight(mesh,"CC_Base_R_Pinky1", riggedRightHand.RightLittleProximal, 1, FilterLerpKalidoHand);
		rigRotationFingerRight(mesh,"CC_Base_R_Pinky2", riggedRightHand.RightLittleIntermediate, 1, FilterLerpKalidoHand);
		rigRotationFingerRight(mesh,"CC_Base_R_Pinky3", riggedRightHand.RightLittleDistal, 1, FilterLerpKalidoHand);
        }			
    }
};
const animateModel_Reallusion = (mesh, results) => {
    var obj=mesh.getObjectByProperty( 'type','SkinnedMesh' ); //getSkinnedMesh(mesh);
    animateModelIK(obj, results);
    animateModelKalido(obj, results);
    solveIK(obj);
};

export {  loaderGLB_Reallusion,updateResPos,SetIKData_Reallusion,animateModel_Reallusion} 