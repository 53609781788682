import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as Kalidokit from "kalidokit";
import { MMDAnimationHelper } from 'three/examples/jsm/animation/MMDAnimationHelper.js';
//import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';

//const remap = Kalidokit.Utils.remap;
//const clamp = Kalidokit.Utils.clamp;
//const lerp = Kalidokit.Vector.lerp;

//Global
var helper = new MMDAnimationHelper( { afterglow: 2.0 } );
//var transformControls = []; // now not use is to add control on IK
var boneCaches = {};
var boneCachesEuler = {};
//Option
var FilterLerpIK=0.1;
var FilterLerpKalido=0.3;
var UseIkarm=false;
var UseIkleg=true;
var StopHip=true;
var StopHeadRotate=true;
						
function loaderGLB(urlfName, callback) {               
    const loader = new GLTFLoader();
    loader.crossOrigin = "anonymous";
    // Import model from URL, add your own model here
    loader.load(urlfName, ( gltf )=> {			
        let mesh = gltf.scene.children[ 0 ].children[ 0 ].children[ 0 ];
       //  MakeTPose(mesh,90,0);  //Create Inititial pos T
         SetIKData(mesh.children[1]);
        if (callback) callback(mesh);
    },
    ( progress ) => console.log( 'Loading model...', 100.0 * ( progress.loaded / progress.total ), '%' ),
    ( error ) => {console.error('Error function -loaderVRMR-  ! '+ error )   });
}


function getBoneByName( mesh, name ) {

    if ( boneCaches[ name ] === undefined ) {
        if (mesh.skeleton !== undefined)   
            boneCaches[ name ] = mesh.skeleton.getBoneByName( name );
        else if ( boneCaches[ name ] === undefined ) {
          boneCaches[ name ] = mesh.getObjectByName(name);
        }
        boneCachesEuler[name] = new THREE.Euler();
        boneCachesEuler[name].setFromQuaternion(boneCaches[name].quaternion, 'XZY', true);
    }
    return boneCaches[ name ];
}

//Function prepare T Pose
/*
const MakeTPose=(avatar_,armangle,legangle)=>{
    //shuolder
    let rotRightshoulder=avatar_.getObjectByName( 'mixamorigRightShoulder' ).rotation;
    rotRightshoulder.z = (armangle) * THREE.MathUtils.DEG2RAD;
    rotRightshoulder.y = (0) * THREE.MathUtils.DEG2RAD;
    avatar_.getObjectByName( 'mixamorigRightShoulder' ).updateMatrixWorld();   
    let rotLeftshoulder=avatar_.getObjectByName( 'mixamorigLeftShoulder' ).rotation;
    rotLeftshoulder.z = (-armangle) * THREE.MathUtils.DEG2RAD;
    rotLeftshoulder.y = (0) * THREE.MathUtils.DEG2RAD;
    avatar_.getObjectByName( 'mixamorigLeftShoulder' ).updateMatrixWorld();
    //arm
    let rotRightArm=avatar_.getObjectByName( 'mixamorigRightArm' ).rotation;
    rotRightArm.x = (90- armangle) * THREE.MathUtils.DEG2RAD;
    avatar_.getObjectByName( 'mixamorigRightArm' ).updateMatrixWorld();
    let rotLeftArm=avatar_.getObjectByName( 'mixamorigLeftArm' ).rotation;
    rotLeftArm.x = ( 90- armangle) * THREE.MathUtils.DEG2RAD;
    avatar_.getObjectByName( 'mixamorigLeftArm' ).updateMatrixWorld();
    let rotLeftForeArm=avatar_.getObjectByName( 'mixamorigLeftForeArm' ).rotation;
    rotLeftForeArm.z = 0;
    avatar_.getObjectByName( 'mixamorigLeftForeArm' ).updateMatrixWorld();
    let rotRightForeArm=avatar_.getObjectByName( 'mixamorigRightForeArm' ).rotation;
    rotRightForeArm.z = 0;
    avatar_.getObjectByName( 'mixamorigRightForeArm' ).updateMatrixWorld();
    //leg                    
    avatar_.getObjectByName( 'mixamorigRightUpLeg' ).rotation.z = (180 - legangle) * THREE.MathUtils.DEG2RAD;
    avatar_.getObjectByName( 'mixamorigRightUpLeg' ).updateMatrixWorld();
    avatar_.getObjectByName( 'mixamorigLeftUpLeg' ).rotation.z = -(180 - legangle) * THREE.MathUtils.DEG2RAD;
    avatar_.getObjectByName( 'mixamorigLeftUpLeg' ).updateMatrixWorld();
}
*/
//Function IK MMD

function SetIKData(mesh)
{
    var obj=mesh.getObjectByProperty( 'type','SkinnedMesh' ); 
     //Create init IK Data
     obj.geometry.userData.MMD = {
        bones:[],
        iks: [],
        grants: [],
        rigidBodies: [],
        constraints: [],
        format: 'pmd'
    };
    obj.geometry.userData.MMD.iks=[];
    for( var i=0;i<obj.skeleton.bones.length;i++)
        {
            obj.geometry.userData.MMD.bones.push({});		
        }
    setupIK( obj);
    // helper ik 
    helper.add( obj, {
            //animation: animation,
            physics: false
    } );            
    //ikHelper = helper.objects.get( mesh ).ikSolver.createHelper();
    //ikHelper.visible = false;
    //scene.add( ikHelper );
}

function addTransformControl( bone ) {              
    // var control = new TransformControls( camera, renderer.domElement );
    // control.addEventListener('mouseDown', function () {
    //     controls.enabled = false;
    // });
    // control.addEventListener('mouseUp', function () {
    //     controls.enabled = true;
    // });
    // control.showY=false;
    // control.showX=false;
    // control.showZ=false;
    // control.attach( bone );
    // //scene.add( control );
    // transformControls.push( control );
    // return 	control;
}


function solveIK(mesh) {
    if ((!UseIkarm)&&(!UseIkleg)) return;  
    var objects = helper.objects.get( mesh );

    if ( ! objects ) return;

    helper.objects.get( mesh ).ikSolver.update();
    if (UseIkarm) 
    {
     updateArmBones_Right(mesh);
     updateArmBones_Left(mesh);
    }
}
function updateArmBones_Left( mesh ){

    getBoneByName( mesh,'mixamorigLeftArm' ).quaternion.copy(
        getBoneByName( mesh,'mixamorigLeftArm +' ).quaternion );
    getBoneByName( mesh, 'mixamorigLeftForeArm' ).quaternion.copy(
        getBoneByName( mesh, 'mixamorigLeftForeArm +' ).quaternion );
    getBoneByName( mesh, 'mixamorigLeftHand' ).quaternion.copy(
        getBoneByName( mesh, 'mixamorigLeftHand +' ).quaternion );

}
function updateArmBones_Right( mesh ){

    getBoneByName( mesh,'mixamorigRightArm' ).quaternion.copy(
        getBoneByName( mesh,'mixamorigRightArm +' ).quaternion );
    getBoneByName( mesh, 'mixamorigRightForeArm' ).quaternion.copy(
        getBoneByName( mesh, 'mixamorigRightForeArm +' ).quaternion );
    getBoneByName( mesh, 'mixamorigRightHand' ).quaternion.copy(
        getBoneByName( mesh, 'mixamorigRightHand +' ).quaternion );

}

function setupIK( mesh ) {

    addArmIK( 'Left', mesh,
        mesh.geometry.userData.MMD.iks,
        mesh.geometry.userData.MMD.bones );

    addArmIK( 'Right', mesh,
        mesh.geometry.userData.MMD.iks,
        mesh.geometry.userData.MMD.bones );

    addTransformControl(
        getBoneByName( mesh, 'mixamorigHips' ) );

    addLegIK( 'Left', mesh,
        mesh.geometry.userData.MMD.iks,
        mesh.geometry.userData.MMD.bones );

    addLegIK( 'Right', mesh,
        mesh.geometry.userData.MMD.iks,
        mesh.geometry.userData.MMD.bones );	
    return;
}

function addArmIK( side, mesh, iks, boneParams ) {
    var ik = {};
    var bones = mesh.skeleton.bones;
    addArmPlusBones( side, mesh, iks, boneParams );
    var targetname;
    if (side==='Left')
    targetname = 'armIK_L';
    else 
    targetname = 'armIK_R';
    var target = getBoneByName( mesh,targetname );
    var effector = getBoneByName( mesh,'mixamorig'+side + 'Hand +' );
    ik.target = bones.indexOf( target );
    ik.effector = bones.indexOf( effector );
    var linkBone = effector.parent;
    var links = [];
    for ( var i = 0; i < 2; i ++ ) {
        //console.log( linkBone.name );
        var link = {};
        link.index = bones.indexOf( linkBone );
        link.enable = true;
        // heuristic
        if ( linkBone.name.indexOf( 'LeftArm' ) !== - 1 ) {
        //	link.rotationMin =  new THREE.Vector3(  - Math.PI/2,  - Math.PI/2, - Math.PI/2 );
        //	link.rotationMax =  new THREE.Vector3(  Math.PI/2 ,  Math.PI/2 ,  Math.PI/2 );
        }else if ( linkBone.name.indexOf( 'RightArm' ) !== - 1 ) {
        //	link.rotationMin =  new THREE.Vector3(  - Math.PI/2,  - Math.PI/2, - Math.PI/2 );
        //	link.rotationMax =  new THREE.Vector3(  Math.PI/2 ,  Math.PI/2 ,  Math.PI/2 );
        }else if ( linkBone.name.indexOf( 'ForeArm' ) !== - 1 ) {
        //	 link.rotationMin =  new THREE.Vector3(  - Math.PI,  - Math.PI, 0 );
        //	 link.rotationMax =  new THREE.Vector3(  Math.PI ,  Math.PI , 0 );
             link.limitation = new THREE.Vector3(
                linkBone.position.y, - linkBone.position.x, 0 ).normalize();
        }/* else if ( linkBone.name.indexOf( 'shoulder' ) !== - 1 ) {
            link.rotationMin = [ - Math.PI / 128, - Math.PI / 128, - Math.PI / 128 ];
            link.rotationMax = [ Math.PI / 128, Math.PI / 128, Math.PI / 128 ];
        } else {
            link.rotationMin = [ - Math.PI / 2, - Math.PI / 2, - Math.PI / 2 ];
            link.rotationMax = [ Math.PI / 2, Math.PI / 2, Math.PI / 2 ];
        }*/
        links.push( link );
        linkBone = linkBone.parent;
    }
    ik.iteration = 20;
    ik.maxAngle = 100000;
    ik.links = links;
    //console.log( ik );
    iks.push( ik );
    addTransformControl( target );
}

function addArmPlusBones( side, mesh, iks, boneParams ) {
    var skeleton = mesh.skeleton;
    var armBone = getBoneByName( mesh,'mixamorig'+side +'Arm' );
    var elbowBone = getBoneByName( mesh,'mixamorig'+side + 'ForeArm' );
    var wristBone = getBoneByName( mesh,'mixamorig'+side + 'Hand' );
    var ikBone = wristBone.clone();
    if (side==='Left')
    ikBone.name = 'armIK_L';
    else 
    ikBone.name = 'armIK_R';
    var meshWorldPosition = new THREE.Vector3();
    var wristWorldPosition = new THREE.Vector3();
    mesh.getWorldPosition( meshWorldPosition );
    wristBone.getWorldPosition( wristWorldPosition );
    ikBone.position.copy( wristWorldPosition.sub( meshWorldPosition ) );
    var armBonePlus = armBone.clone();
    armBonePlus.name += ' +';
    var elbowBonePlus = elbowBone.clone();
    elbowBonePlus.name += ' +';
    var wristBonePlus = wristBone.clone();
    wristBonePlus.name += ' +';
    armBone.parent.add( armBonePlus );
    armBonePlus.add( elbowBonePlus );
    elbowBonePlus.add( wristBonePlus );
    mesh.add( ikBone );
    skeleton.bones.push( armBonePlus );
    skeleton.bones.push( elbowBonePlus );
    skeleton.bones.push( wristBonePlus );
    skeleton.bones.push( ikBone );
    skeleton.boneInverses.push( new THREE.Matrix4() );
    skeleton.boneInverses.push( new THREE.Matrix4() );
    skeleton.boneInverses.push( new THREE.Matrix4() );
    skeleton.boneInverses.push( new THREE.Matrix4() );
    boneParams.push( {} );
    boneParams.push( {} );
    boneParams.push( {} );
    boneParams.push( {} );
}

function addLegIK( side, mesh, iks, boneParams ) {
    var ik = {};
    var bones = mesh.skeleton.bones;
    addLegPlusBones( side, mesh, iks, boneParams );
    var targetname;
    if (side==='Left')
    targetname = 'legIK_L';
    else 
    targetname = 'legIK_R';
    var target = getBoneByName( mesh,targetname );
    var effector = getBoneByName( mesh,'mixamorig'+side + 'Foot' );
    ik.target = bones.indexOf( target );
    ik.effector = bones.indexOf( effector );
    var linkBone = effector.parent;
    var links = [];
    for ( var i = 0; i < 2; i ++ ) {
        //console.log( linkBone.name );
        var link = {};
        link.index = bones.indexOf( linkBone );
        link.enable = true;       
        if ( linkBone.name.indexOf( 'LeftLeg' ) !== - 1 ) {
            link.rotationMin =  new THREE.Vector3(  - Math.PI,    0,   0 );
            link.rotationMax =  new THREE.Vector3(    0 ,   0 ,  0 );
        }else if ( linkBone.name.indexOf( 'RightLeg' ) !== - 1 ) {
            link.rotationMin =  new THREE.Vector3(- Math.PI, 0, 0);
            link.rotationMax =  new THREE.Vector3( 0, 0, 0 );
        }
        links.push( link );
        linkBone = linkBone.parent;
    }
    ik.iteration = 40;
    ik.maxAngle = 2;
    ik.links = links;
    //console.log( ik );
    iks.push( ik );
    addTransformControl( target );
}

function addLegPlusBones( side, mesh, iks, boneParams ) {
    var skeleton = mesh.skeleton;
    var footBone = getBoneByName( mesh,'mixamorig'+side + 'Foot' );
    var ikBone = footBone.clone();
    if (side==='Left')
    ikBone.name = 'legIK_L';
    else 
    ikBone.name = 'legIK_R';
    var meshWorldPosition = new THREE.Vector3();
    var wristWorldPosition = new THREE.Vector3();
    mesh.getWorldPosition( meshWorldPosition );
    footBone.getWorldPosition( wristWorldPosition );
    ikBone.position.copy( wristWorldPosition.sub( meshWorldPosition ) );
    mesh.add( ikBone );
    skeleton.bones.push( ikBone );
    skeleton.boneInverses.push( new THREE.Matrix4() );
    boneParams.push( {} );
}
				// Animate Position Helper Function
				/*const rigPosition = (mesh,name, position = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
					if (!mesh) {
						return;
					}
					const Part = getBoneByName(mesh,name);
					if (!Part) {
						return;
					}
					let vector = new THREE.Vector3(position.x * dampener, position.y * dampener, position.z * dampener);
					Part.position.lerp(vector, lerpAmount); // interpolate
				};
                */
				const rigRotation = (mesh,name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
					if (!mesh) {
						return;
					}
					const Part = getBoneByName(mesh,name);
					if (!Part) {
						return;
					}
					let eulerLast = boneCachesEuler[name];	
					let euler = new THREE.Euler( -rotation.x * dampener, rotation.y * dampener , -rotation.z * dampener );
					if (StopHeadRotate)
                    {
                      euler.z=eulerLast.z;
                      euler.x=eulerLast.x;
                      euler.y=eulerLast.y;   
                    }
                    let quaternion = new THREE.Quaternion().setFromEuler(euler);
					//Part.quaternion.copy(quaternion);
					Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
				};
				const rigRotationHips = (mesh,name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
					if (!mesh) {
						return;
					}
					const Part = getBoneByName(mesh,name);
					if (!Part) {
						return;
					}
					let eulerLast = boneCachesEuler[name];			
					let euler = new THREE.Euler( -rotation.x * dampener, rotation.y * dampener , -rotation.z * dampener );
					if (StopHip)
                    {
                     euler.z=eulerLast.z;
                     euler.x=eulerLast.x;
                     euler.y=eulerLast.y;
                    }
					let quaternion = new THREE.Quaternion().setFromEuler(euler);
					//Part.quaternion.copy(quaternion);
					Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
				};
				const rigRotationLeftLeg = (mesh,name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
					if (!mesh) {
						return;
					}
					const Part = getBoneByName(mesh,name);
					if (!Part) {
						return;
					}
					let eulerLast = boneCachesEuler[name];					
					let euler = new THREE.Euler( rotation.x * dampener, rotation.y * dampener , -rotation.z * dampener );
					euler.x=euler.x+eulerLast.x;
					euler.y=euler.y+eulerLast.y;
					euler.z=euler.z+eulerLast.z;
					let quaternion = new THREE.Quaternion().setFromEuler(euler);
					//Part.quaternion.copy(quaternion);
					Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
				};
				
				const rigRotationRightArm = (mesh,name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
					if (!mesh) {
						return;
					}
					const Part = getBoneByName(mesh,name);
					if (!Part) {
						return;
					}
					//let eulerLast = boneCachesEuler[name];
					let euler = new THREE.Euler( -rotation.z * dampener, rotation.x * dampener , -rotation.y * dampener );
					let quaternion = new THREE.Quaternion().setFromEuler(euler);
					//Part.quaternion.copy(quaternion);
					Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
				};
				const rigRotationLeftArm = (mesh,name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
					if (!mesh) {
						return;
					}
					const Part = getBoneByName(mesh,name);
					if (!Part) {
						return;
					}
					let eulerLast = boneCachesEuler[name];	
					let euler = new THREE.Euler( rotation.z * dampener, rotation.x * dampener,-rotation.y * dampener   );
					euler.z=euler.z+eulerLast.z;
					let quaternion = new THREE.Quaternion().setFromEuler(euler);
					//Part.quaternion.copy(quaternion);
					Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
				};
				const rigRotationFingerLeft = (mesh,name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
					if (!mesh) {
						return;
					}
					const Part = getBoneByName(mesh,name);
					if (!Part) {
						return;
					}
					let eulerLast = boneCachesEuler[name];					
					let euler = new THREE.Euler( rotation.z * dampener, rotation.x * dampener ,- rotation.y * dampener );
					euler.z=euler.z+(eulerLast.z*0.5);
					let quaternion = new THREE.Quaternion().setFromEuler(euler);
					//Part.quaternion.copy(quaternion);
					Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
				};				
				const rigRotationFingerRight = (mesh,name, rotation = { x: 0, y: 0, z: 0 }, dampener = 1, lerpAmount = 0.3) => {
					if (!mesh) {
						return;
					}
					const Part = getBoneByName(mesh,name);
					if (!Part) {
						return;
					}
					let eulerLast = boneCachesEuler[name]; //last position
					let euler = new THREE.Euler( -rotation.z * dampener, -rotation.x * dampener , -rotation.y * dampener );
					euler.z=euler.z+(eulerLast.z*0.5);
					let quaternion = new THREE.Quaternion().setFromEuler(euler);
					//Part.quaternion.copy(quaternion);
					Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
				};
				const rigFace = (mesh,riggedFace) => {
					if (!mesh) {
						return;
					}
					rigRotation(mesh,"mixamorigNeck", riggedFace.head, 0.7);					
				};	
	
/* Model Character Animator */
			
const animateModelIK = (mesh, results) =>{
    // Pose 3D Landmarks are with respect to Hip distance in meters
    //const pose3DLandmarks = results.poseWorldLandmarks;
    const pose3DLandmarks = results.ea;
    var wristLocalPosition = new THREE.Vector3();
    var wristWorldPosition = new THREE.Vector3();
    var footLocalPosition = new THREE.Vector3();
    var footWorldPosition = new THREE.Vector3();
  

    var HipWorldPosition= new THREE.Vector3();
    let bone_IK;

    let Mark_Hips=getBoneByName( mesh, 'mixamorigHips' );
    Mark_Hips.getWorldPosition(HipWorldPosition);
    if (pose3DLandmarks !== undefined && pose3DLandmarks[15] !== undefined)  
    {
        bone_IK=getBoneByName( mesh, 'armIK_R' );	
        if (pose3DLandmarks[15].visibility>0.6) {
            wristWorldPosition.x=-(pose3DLandmarks[15].x*100)-10;
            wristWorldPosition.y=-(pose3DLandmarks[15].y*100);
            wristWorldPosition.y=wristWorldPosition.y+(HipWorldPosition.y-10);
            wristWorldPosition.z=-(pose3DLandmarks[15].z*100);
            wristLocalPosition=wristWorldPosition.clone();
            bone_IK.parent.worldToLocal(wristLocalPosition);
            bone_IK.position.lerp(wristLocalPosition,FilterLerpIK); // interpolate						
        } else 
        {							
            wristLocalPosition=HipWorldPosition.clone();
            bone_IK.parent.worldToLocal(wristLocalPosition);
            wristLocalPosition.x=wristLocalPosition.x-30;
            wristLocalPosition.y=wristLocalPosition.y-30;	
            wristLocalPosition.z=wristLocalPosition.z+10;	
            bone_IK.position.lerp(wristLocalPosition,FilterLerpIK); // interpolate
        }
    } 
    
    if (pose3DLandmarks !== undefined && pose3DLandmarks[16] !== undefined)  
    {
        bone_IK=getBoneByName( mesh, 'armIK_L' ); 
        if (pose3DLandmarks[16].visibility>0.6) {  
            wristWorldPosition.x=-(pose3DLandmarks[16].x*100)+10;
            wristWorldPosition.y=-(pose3DLandmarks[16].y*100);
            wristWorldPosition.y=wristWorldPosition.y+(HipWorldPosition.y-10);
            wristWorldPosition.z=-(pose3DLandmarks[15].z*100);
            wristLocalPosition=wristWorldPosition.clone();
            bone_IK.parent.worldToLocal(wristLocalPosition);	
            bone_IK.position.lerp(wristLocalPosition,FilterLerpIK); // interpolate	
        }else 
        {							
            wristLocalPosition=HipWorldPosition.clone();
            bone_IK.parent.worldToLocal(wristLocalPosition);
            wristLocalPosition.x=wristLocalPosition.x-30;
            wristLocalPosition.y=wristLocalPosition.y-30;	
            wristLocalPosition.z=wristLocalPosition.z+10;
            bone_IK.position.lerp(wristLocalPosition,FilterLerpIK); // interpolate
        }
    }
        
    if (pose3DLandmarks !== undefined && pose3DLandmarks[27] !== undefined)  
    {
        
        let bone_IK=getBoneByName( mesh, 'legIK_R' );	
        if (pose3DLandmarks[27].visibility>0.7) {
            footWorldPosition.x=-(pose3DLandmarks[27].x*100);
            footWorldPosition.y=(pose3DLandmarks[27].y*100);
            footWorldPosition.y=-(footWorldPosition.y-(HipWorldPosition.y-20));
            footWorldPosition.z=-footWorldPosition.z*100;
            footLocalPosition=footWorldPosition.clone();
            bone_IK.parent.worldToLocal(footLocalPosition);
            bone_IK.position.lerp(footLocalPosition,FilterLerpIK); // interpolate	
        }
    }

    if (pose3DLandmarks !== undefined && pose3DLandmarks[28] !== undefined)  
    {
        let bone_IK=getBoneByName( mesh, 'legIK_L' );	
        if (pose3DLandmarks[28].visibility>0.7) {
            footWorldPosition.x=-(pose3DLandmarks[28].x*100);
            footWorldPosition.y=(pose3DLandmarks[28].y*100);
            footWorldPosition.y=-(footWorldPosition.y-(HipWorldPosition.y-20));
            footWorldPosition.z=-footWorldPosition.z*100;
            footLocalPosition=footWorldPosition.clone();
            bone_IK.parent.worldToLocal(footLocalPosition);
            bone_IK.position.lerp(footLocalPosition,FilterLerpIK); // interpolate	
        }
    }
}		
const animateModelKalido = (mesh, results) => {
    if (!mesh) {
        return;
    }
    // Take the results from `Holistic` and animate character based on its Face, Pose, and Hand Keypoints.
    let riggedPose, riggedLeftHand, riggedRightHand, riggedFace;
    const faceLandmarks = results.faceLandmarks;
    // Pose 3D Landmarks are with respect to Hip distance in meters
    const pose3DLandmarks = results.ea;
    // Pose 2D landmarks are with respect to videoWidth and videoHeight
    const pose2DLandmarks = results.poseLandmarks;
    // Be careful, hand landmarks may be reversed
    const leftHandLandmarks = results.rightHandLandmarks;
    const rightHandLandmarks = results.leftHandLandmarks;  
    // Animate Face
    if (faceLandmarks) {
        riggedFace = Kalidokit.Face.solve(faceLandmarks, {
            runtime:"mediapipe"//, video:videoElement
        });
        rigFace(mesh,riggedFace);
    }
    
    // Animate Pose
    if (pose2DLandmarks && pose3DLandmarks) {
        riggedPose = Kalidokit.Pose.solve(pose3DLandmarks, pose2DLandmarks, {
            runtime:"mediapipe"//, video:videoElement
        });
        rigRotationHips(mesh,"mixamorigHips", riggedPose.Hips.rotation, 0.7);
        //rigPosition(
        //	"mixamorigHips",
        //	{
        //		x: -riggedPose.Hips.position.x + 0.2, // Reverse direction
        //		y: riggedPose.Hips.position.y + 1, // Add a bit of height
        //		z: -riggedPose.Hips.position.z, // Reverse direction
        //	},
        //	1,
        //	0.07
        //);    
        rigRotation(mesh,"mixamorigSpine1", riggedPose.Spine, 0.25,FilterLerpKalido); //"Chest"
        rigRotation(mesh,"mixamorigSpine", riggedPose.Spine, 0.25, FilterLerpKalido);  //"Spine"
        if (!UseIkarm)
        {	
            rigRotationRightArm(mesh,"mixamorigRightArm", riggedPose.RightUpperArm, 1,FilterLerpKalido);//"RightUpperArm"
            rigRotationRightArm(mesh,"mixamorigRightForeArm", riggedPose.RightLowerArm, 1, FilterLerpKalido);//"RightLowerArm"
            
            rigRotationLeftArm(mesh,"mixamorigLeftArm", riggedPose.LeftUpperArm, 1, FilterLerpKalido);     //"LeftUpperArm"
            rigRotationLeftArm(mesh,"mixamorigLeftForeArm", riggedPose.LeftLowerArm, 1, FilterLerpKalido); //"LeftLowerArm"

            rigRotationLeftLeg(mesh,"mixamorigLeftUpLeg", riggedPose.LeftUpperLeg, 1, FilterLerpKalido); //"LeftUpperLeg"
            rigRotationLeftLeg(mesh,"mixamorigLeftLeg", riggedPose.LeftLowerLeg, 1, FilterLerpKalido); //"LeftLowerLeg"
            
            rigRotationLeftLeg(mesh,"mixamorigRightUpLeg", riggedPose.RightUpperLeg, 1, FilterLerpKalido); //"RightUpperLeg"
            rigRotationLeftLeg(mesh,"mixamorigRightLeg", riggedPose.RightLowerLeg, 1, FilterLerpKalido);   //"RightLowerLeg"
        }	
    
    }
    // Animate Hands
    if (leftHandLandmarks) {
        riggedLeftHand = Kalidokit.Hand.solve(leftHandLandmarks, "Left");
        rigRotationLeftArm(mesh,"mixamorigLeftHand", {
            // Combine pose rotation Z and hand rotation X Y
            z: riggedPose.LeftHand.z,
            y: riggedLeftHand.LeftWrist.y,
            x: riggedLeftHand.LeftWrist.x,
        }, 1, FilterLerpKalido);
        //rigRotationLeftArm("mixamorigLeftHand", riggedLeftHand.LeftWrist);
        rigRotationFingerLeft(mesh,"mixamorigLeftHandRing1", riggedLeftHand.LeftRingProximal, 1, FilterLerpKalido);
        rigRotationFingerLeft(mesh,"mixamorigLeftHandRing2", riggedLeftHand.LeftRingIntermediate, 1, FilterLerpKalido);
        rigRotationFingerLeft(mesh,"mixamorigLeftHandRing3", riggedLeftHand.LeftRingDistal, 1, FilterLerpKalido);
        rigRotationFingerLeft(mesh,"mixamorigLeftHandIndex1", riggedLeftHand.LeftIndexProximal, 1, FilterLerpKalido);
        rigRotationFingerLeft(mesh,"mixamorigLeftHandIndex2", riggedLeftHand.LeftIndexIntermediate, 1, FilterLerpKalido);
        rigRotationFingerLeft(mesh,"mixamorigLeftHandIndex3", riggedLeftHand.LeftIndexDistal, 1, FilterLerpKalido);
        rigRotationFingerLeft(mesh,"mixamorigLeftHandMiddle1", riggedLeftHand.LeftMiddleProximal, 1, FilterLerpKalido);
        rigRotationFingerLeft(mesh,"mixamorigLeftHandMiddle2", riggedLeftHand.LeftMiddleIntermediate, 1, FilterLerpKalido);
        rigRotationFingerLeft(mesh,"mixamorigLeftHandMiddle3", riggedLeftHand.LeftMiddleDistal, 1, FilterLerpKalido);
        rigRotationFingerLeft(mesh,"mixamorigLeftHandThumb1", riggedLeftHand.LeftThumbProximal, 1, FilterLerpKalido);
        rigRotationFingerLeft(mesh,"mixamorigLeftHandThumb2", riggedLeftHand.LeftThumbIntermediate, 1, FilterLerpKalido);
        rigRotationFingerLeft(mesh,"mixamorigLeftHandThumb3", riggedLeftHand.LeftThumbDistal, 1, FilterLerpKalido);
        rigRotationFingerLeft(mesh,"mixamorigLeftHandPinky1", riggedLeftHand.LeftLittleProximal, 1, FilterLerpKalido);
        rigRotationFingerLeft(mesh,"mixamorigLeftHandPinky2", riggedLeftHand.LeftLittleIntermediate, 1, FilterLerpKalido);
        rigRotationFingerLeft(mesh,"mixamorigLeftHandPinky1", riggedLeftHand.LeftLittleDistal, 1, FilterLerpKalido);
    }
    if (rightHandLandmarks) {
        riggedRightHand = Kalidokit.Hand.solve(rightHandLandmarks, "Right");	
        rigRotationRightArm(mesh,"mixamorigRightHand", {
            // Combine Z axis from pose hand and X/Y axis from hand wrist rotation
            z: riggedPose.RightHand.z,
            y: riggedRightHand.RightWrist.y,
            x: riggedRightHand.RightWrist.x,
        }, 1, FilterLerpKalido);
        rigRotationFingerRight(mesh,"mixamorigRightHandRing1", riggedRightHand.RightRingProximal, 1, FilterLerpKalido);
        rigRotationFingerRight(mesh,"mixamorigRightHandRing2", riggedRightHand.RightRingIntermediate, 1, FilterLerpKalido);
        rigRotationFingerRight(mesh,"mixamorigRightHandRing3", riggedRightHand.RightRingDistal, 1, FilterLerpKalido);
        rigRotationFingerRight(mesh,"mixamorigRightHandIndex1", riggedRightHand.RightIndexProximal, 1, FilterLerpKalido);
        rigRotationFingerRight(mesh,"mixamorigRightHandIndex2", riggedRightHand.RightIndexIntermediate, 1, FilterLerpKalido);
        rigRotationFingerRight(mesh,"mixamorigRightHandIndex3", riggedRightHand.RightIndexDistal, 1, FilterLerpKalido);
        rigRotationFingerRight(mesh,"mixamorigRightHandMiddle1", riggedRightHand.RightMiddleProximal, 1, FilterLerpKalido);
        rigRotationFingerRight(mesh,"mixamorigRightHandMiddle2", riggedRightHand.RightMiddleIntermediate, 1, FilterLerpKalido);
        rigRotationFingerRight(mesh,"mixamorigRightHandMiddle2", riggedRightHand.RightMiddleDistal, 1, FilterLerpKalido);
        rigRotationFingerRight(mesh,"mixamorigRightHandThumb1", riggedRightHand.RightThumbProximal, 1, FilterLerpKalido);
        rigRotationFingerRight(mesh,"mixamorigRightHandThumb2", riggedRightHand.RightThumbIntermediate, 1, FilterLerpKalido);
        rigRotationFingerRight(mesh,"mixamorigRightHandThumb3", riggedRightHand.RightThumbDistal, 1, FilterLerpKalido);
        rigRotationFingerRight(mesh,"mixamorigRightHandPinky1", riggedRightHand.RightLittleProximal, 1, FilterLerpKalido);
        rigRotationFingerRight(mesh,"mixamorigRightHandPinky2", riggedRightHand.RightLittleIntermediate, 1, FilterLerpKalido);
        rigRotationFingerRight(mesh,"mixamorigRightHandPinky3", riggedRightHand.RightLittleDistal, 1, FilterLerpKalido);
    
    }
};
const animateModel = (mesh, results) => {
    var obj=mesh.getObjectByProperty( 'type','SkinnedMesh' ); 
    animateModelIK(obj, results);
    animateModelKalido(obj, results);
    solveIK(obj);
};

export {  loaderGLB,SetIKData,animateModel} 