import React from 'react';
import AppName from '../../components/AppName/AppName';
import QRCode from "react-qr-code";
import CustomDialog from '../CustomDialog/CustomDialog';

const Share = ({qrValue, isOpen, handleClose}) => {

    return (
        
        <CustomDialog title='Share' isOpen={isOpen} onClose={handleClose} >
            {/* <img src='images/QR-Logica.jpg' alt='QR code' /> */}
            <div style={{ background: 'white', padding: '5px' }}>
                <QRCode value={qrValue} />
            </div>
            <br />
            <AppName />
        </CustomDialog>       
    )
};

export default Share;
