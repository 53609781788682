import React from "react";

//IMPORTANT: This component renders the HTML element with absolute position so at some high level there would be a positioned ancestor
export default function ClickableContainer({ onclick }) {

    return (
        <span onClick={onclick} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", cursor: "pointer", zIndex: 1 }}></span>
    );

    // <a href onClick={onclick}>
    // </a>
}