//https://github.com/gkjohnson/three-gpu-pathtracer         PathTracer example

//https://github.com/w-okada/image-analyze-workers          TensorFlow examples

//https://github.com/terryky/tfjs_webgl_app                 TensorFlow usato attualmente per segmentazione

//https://github.com/pschroen/alien.js                      UI Interfaccia java per prendere colore, label sull'oggetto , effetti vari...

import React from "react";
import * as THREE from 'three';
import { drawConnectors } from '@mediapipe/drawing_utils';
import { FACEMESH_TESSELATION } from '@mediapipe/holistic';
import * as CAMANIMATION_GLB from '../../Utility/GLB_CamAnimation.js';
import * as CAMANIMATION_GLB_Reallusion from '../../Utility/GLB_Reallusion_CamAnimation.js';
import { useLoadingContext } from '../../components/Loading/Loading';
import * as UTILITY_VRM from '../../Utility/VRM_CamAnimation.js';
import * as UTILITY from '../../Utility/ThreeManUtility.js';
import * as ReallusionUtility from '../../Utility/ReallusionUtility.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { Lensflare, LensflareElement } from 'three/examples/jsm/objects/Lensflare.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import {usePopupMsgContext} from '../../components/PopupMsg/PopupMsg';
import * as SkinningUtility from '../../Utility/SkinningUtility.js';
import {Genders} from '../GenderOption/GenderOption.js';

//Post processing
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { LuminosityShader } from 'three/examples/jsm/shaders/LuminosityShader.js';
import { SobelOperatorShader } from 'three/examples/jsm/shaders/SobelOperatorShader.js';
import { log } from "@tensorflow/tfjs";
import { isMobile } from "../../Utility/Common.js";
import Stats from 'react-canvas-stats';
//import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

//import Stats from 'three/examples/jsm/libs/stats.module.js';

const CameraType = { PERSPECTIVE: 0, ORTHOGRAPHIC: 1,}

const ThreeMan = ({ page, sceneURL, modelURL, gender, setIsGestureOn, product, prodVariation, keyPts, faceDrawerChange, productSelectedChange, 
                    userFaceData, menuItem, faceFromPicture, keepHair, showStats, useGesture, screenshotCallback, cameraOn, setWebCamera, setCameraSuspend }) => {

    const canvasRef = React.useRef(null);
    const rendererRef = React.useRef(null); 
    const sceneRef = React.useRef(null);
    //const sceneModelRef = React.useRef(null);    //Scena per modello per poter gestire layers
    const faceGlbMeshRef= React.useRef(null);   //Mesh faccia da camera da sovrapporre al modello
    //const nLayerRef= React.useRef(0);

    const {showPopupMsg} = usePopupMsgContext();    //Popup informazioni
    const {setIsLoading} = useLoadingContext();     //Progress bar 

    const fontRef = React.useRef(null);
    const particleLightRef = React.useRef(null);

    //Cameras
    const cameraPerspRef = React.useRef(null);
    const cameraOrtoRef = React.useRef(null);
    const cameraRef = React.useRef([]);
    const modelMeshRef = React.useRef(null);
    const orbitControlsRef = React.useRef(null);
    const gizmoRef = React.useRef(null); //Pick from mouse
    const ctrlKeyRef  = React.useRef(false);//Indica se tasto Ctrl premuto
    const modelVrmRef = React.useRef(null);
    const mixerAnimationRef= React.useRef(null);
    var TWEEN = require('@tweenjs/tween.js');
    //const keyPts_right_hand = React.useRef([]);
    

    const postprocessingRef= React.useRef(null);

    const clockRef = React.useRef(null);  // Main Render Loop
    
    const recorder = React.useRef({isRecording: false, capturer: null});
    const [timestamp, setTimestamp] = React.useState(null);
    const showStatsRef = React.useRef(false);
    React.useEffect(() => {
        showStatsRef.current = showStats;
    }, [showStats]);

   // const statsRef= React.useRef(null);

/*
    function moveNodeKeyPoints(keypoints, KeyNodes, ratioW, ratioH) {
        if (keypoints.length === KeyNodes.length)
            for (let i = 0; i < keypoints.length; i++) {
                KeyNodes[i].position.set((keypoints[i].x * ratioW) - (ratioW / 2), (-keypoints[i].y * ratioH) + 170, 50);
            }
    }

    function createNodeKeyPoints(keypoints) {
        let node_array = [];
        for (let i = 0; i < keypoints.length; i++) {
            const geometry = new THREE.CircleGeometry(0.2, 4);
            const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
            switch (i) {
                //case 1: case 2: case 3: case 4: material.color = new THREE.Color(0xff0000); break;
                default: material.color = new THREE.Color(0xffff00); break;
            }
            const circle = new THREE.Mesh(geometry, material);
            sceneRef.current.add(circle);
            node_array.push(circle);
        }
        return node_array
    }*/

    React.useEffect(() => {
        if (keyPts !== null && rendererRef.current && sceneRef.current) {
            
            if(faceFromPicture){      //Mesh da photo una sola volta
                //Crea mesh faccia dalla camera
                playSkeletonAnimation(false);
                if(!faceGlbMeshRef.current){    //Legge da file la prima volta
                        UTILITY.loaderMesh('/scenes/canonical_face_model.glb',false, faceGlbMesh => {   //Forse non serve mai leggere canonical_face_model_skinned perchè ricrea lo skinning.... 
                       // UTILITY.loaderMesh('/scenes/canonical_face_model_skinned.glb',false, faceGlbMesh => {   //_skinned: legge la faccia già skinnata...se si cambia modello rifare in SkinningUtility...
                        //faceGlbMeshRef.current=faceGlbMesh;   //Non funziona perchè la seconda volta che disegna la faccia rimangono settati valori di prima...
                        startMergeMPipeMeshToModel(faceGlbMesh.clone());
                    })
                }else{
                    startMergeMPipeMeshToModel(faceGlbMeshRef.current.clone()); 
                }
                postProcessingStart(false);
                return;
            }

            // if (keyPts.faceLandmarks && (keyPts.faceLandmarks.length > 0)) {
            //     if (keyPts_face.current.length < 1) keyPts_face.current = createNodeKeyPoints(keyPts.faceLandmarks);
            //     moveNodeKeyPoints(keyPts.faceLandmarks, keyPts_face.current, 60, 60);
            // }
            // if (keyPts.rightHandLandmarks && (keyPts.rightHandLandmarks.length > 0)) {
            //     if (keyPts_right_hand.current.length < 1) keyPts_right_hand.current = createNodeKeyPoints(keyPts.rightHandLandmarks);
            //     moveNodeKeyPoints(keyPts.rightHandLandmarks, keyPts_right_hand.current, 130, 170);
            // }
            // render();

            // if (modelMeshRef.current) {
            //     if (keyPts.closeRightHand) {
            //         modelMeshRef.current.children[1].material.color = new THREE.Color(0x000000);
            //     } else {
            //         modelMeshRef.current.children[1].material.color = new THREE.Color(0xffffff);
            //     }
            // }

            if(modelVrmRef.current){
                UTILITY_VRM.animateVRM(modelVrmRef.current, keyPts);
                render();
            }
            //if(modelMeshRef.current){     //MakeHuman model
            //    CAMANIMATION_GLB.animateModel(modelMeshRef.current, keyPts);
            //    render();
            //}
            
            if(modelMeshRef.current&&(page===2)){     //Reallusion animation from camera model        Bisogna anche abilitare una funzione IK cercando questa riga "add ik bones "
                CAMANIMATION_GLB_Reallusion.animateModel_Reallusion(modelMeshRef.current, keyPts);
                render();
            }

            if(modelMeshRef.current && modelMeshRef.current.userData.ageGender){    //Esce popup con info età e gender
                let gender;
                if(modelMeshRef.current.userData.ageGender.gender){gender=' Maschio '}else{gender=' Femmina '}
                showPopupMsg({
                    show: true,
                    title: ' Gender : '+gender,
                    msg: ' Età : '+modelMeshRef.current.userData.ageGender.age,
                    autoClose: true
                });
                modelMeshRef.current.userData.ageGender=undefined;
            }

            if(modelMeshRef.current && keyPts?.onShotPhoto){    //Mette foto faccia su una mesh sopra al modello 
            }
            

            if(true && modelMeshRef.current){
                if(keyPts?.faceIsOverAvatar.Center && keyPts?.frameNumber >0){    //Mette foto faccia su una mesh sopra al modello 
                    if((!TWEEN_ModelProgressBar.current.placedFace | TWEEN_ModelProgressBar.current.deletingFace) && !TWEEN_ModelProgressBar.current.placingFace){ //Se non l'aveva già piazzata
                        if(keyPts.faceDimension.CenterHorz <10 && keyPts.faceDimension.CenterObliquo <10 && keyPts.faceDimension.ImmagineFerma)
                        TWEEN_ModelProgressBar.current.run(keyPts,true);
                    }

                }else{
                    //if((TWEEN_ModelProgressBar.current.placedFace | TWEEN_ModelProgressBar.current.placingFace) && !TWEEN_ModelProgressBar.current.deletingFace){ //Se l'aveva piazzata la toglie
                    if(TWEEN_ModelProgressBar.current.placedFace && !TWEEN_ModelProgressBar.current.deletingFace){ 
                        if(keyPts?.frameNumber===0 ){  //|| !keyPts?.faceLandmarks ){ //Se l'aveva piazzata la toglie  Toglie se è il primo frame oppure se non trova nessuna faccia
                            TWEEN_ModelProgressBar.current.run(keyPts,false);   //Sconnette la camera dalla faccia...
                        }
                    }
                }    
            } 

            
            //Stato per indicare se printare la mesh della faccia    
            if(keyPts?.faceIsOverAvatar.Center && UTILITY.sceneFindItemCategoryRoot(sceneRef.current , UTILITY.MeshesType.FACEWEBCAM)?.bolFind){faceDrawerChange(false);}else{faceDrawerChange(true); }
            if(TWEEN_ModelProgressBar.current){
                if(TWEEN_ModelProgressBar.current.placingFace || TWEEN_ModelProgressBar.current.placedFace){
                    faceDrawerChange(false);
                }
            }
            
        }

    }, [keyPts]);

    React.useEffect(() => {
        //Render
        rendererRef.current = new THREE.WebGLRenderer({ canvas: canvasRef.current, antialias: true, logarithmicDepthBuffer:true,alpha: true }); //,alpha: true  Forse serve ?
        rendererRef.current.outputEncoding=THREE.sRGBEncoding;
        //rendererRef.current.phisicallyCorrectLights=
        // rendererRef.current.setClearColor('#000000', 0);
        rendererRef.current.setAnimationLoop(animateRef.current);
        //Serve se si usano più scene per overlap delle mesh...
        /*rendererRef.current.autoClear = false; 
        rendererRef.current.autoClearDepth = false; 
        rendererRef.current.autoClearColor = false;
        rendererRef.current.autoClearStencil= false;*/

        
        //Camera
        const CAMERA_FOV = 28.5;
		const CAMERA_Z   = 420;
		const CAMERA_NEAR = 0.1;
		const CAMERA_FAR = 3000;
		const CAMERA_POS = {
            x: 0,
            y: 100,
            z: CAMERA_Z,
        };
        cameraPerspRef.current = new THREE.PerspectiveCamera(CAMERA_FOV, 1, CAMERA_NEAR, CAMERA_FAR);
        cameraPerspRef.current.position.set(CAMERA_POS.x, CAMERA_POS.y, CAMERA_POS.z);
        
        cameraOrtoRef.current = new THREE.OrthographicCamera(1, -1, 1, -1, CAMERA_NEAR, CAMERA_FAR); //dopo si aggiorna correttamente con updateSize;
        cameraOrtoRef.current.position.set(CAMERA_POS.x, CAMERA_POS.y, CAMERA_POS.z);
        cameraOrtoRef.current.rotation.set(0, 0, 0);
        cameraOrtoRef.current.zoom = 1;
        setCameraType(CameraType.PERSPECTIVE);
        // setCameraType(CameraType.ORTHOGRAPHIC);
        updateSize();

        clockRef.current = new THREE.Clock();    // Main Render Loop

        //Scene e Light le compila in loaderScene
        sceneRef.current=new THREE.Scene();
        //sceneModelRef.current=new THREE.Scene();
        
        orbitControlsRef.current = new OrbitControls(cameraRef.current, rendererRef.current.domElement);
        orbitControlsRef.current.target.x=cameraRef.current.position.x;
        orbitControlsRef.current.target.y=cameraRef.current.position.y;
        orbitControlsRef.current.addEventListener('start', (e) => {e.target.isOrbiting = true;}, false);
        orbitControlsRef.current.addEventListener('end', (e) => {e.target.isOrbiting = false;}, false);
        //orbitControlsRef.current.target.z=cameraRef.current.position.z;   //Se setto la z poi non và più...
        //orbitControlsRef.current.screenSpacePanning=false;    //Panning solo in orizzontale
        orbitControlsRef.current.update();

        gizmoRef.current = new TransformControls( cameraRef.current, rendererRef.current.domElement );  //Control pick from mouse
		gizmoRef.current.addEventListener( 'change', render );
		gizmoRef.current.addEventListener( 'dragging-changed', function ( event ) {
			orbitControlsRef.current.enabled = ! event.value;
		} );
        sceneRef.current.add(gizmoRef.current);

        fontRef.current =new FontLoader().parse(require('three/examples/fonts/gentilis_regular.typeface.json'))
        if(false) sceneRef.current.add(UTILITY.addLabel(fontRef.current,'Prova',new THREE.Vector3( - 30, 80, 0 )));//Esempio font...

        //Luce animata
        particleLightRef.current = new THREE.PointLight( 0xffffff, 2, 800 );
        particleLightRef.current.color.setHSL( 0.1, 0.7, 0.3 );
        const lensflare = new Lensflare();
        const textureLoader = new THREE.TextureLoader();
		const textureFlare0 = textureLoader.load( '/images/lensflare/lensflare0.png' );
		const textureFlare3 = textureLoader.load( '/images/lensflare/lensflare3.png' );            
		lensflare.addElement( new LensflareElement( textureFlare0, 500, 0 , particleLightRef.current.color ) );
		lensflare.addElement( new LensflareElement( textureFlare3, 60, 0.6, particleLightRef.current.color ) );
		lensflare.addElement( new LensflareElement( textureFlare3, 70, 0.7, particleLightRef.current.color ) );
		lensflare.addElement( new LensflareElement( textureFlare3, 120, 0.9, particleLightRef.current.color ) );
		lensflare.addElement( new LensflareElement( textureFlare3, 70, 1, particleLightRef.current.color ) );
		particleLightRef.current.add( lensflare );
        sceneRef.current.add(particleLightRef.current);
        particleLightRef.current.enabled=false;

        //WebGLRenderer.info per sapere quantità di memoria in uso e vedere eventuali memory leaks

        //statsRef.current = new Stats();

        const resizeHandler = () => {
            updateSize();
            render();
        }
        window.addEventListener('resize', resizeHandler, false);
        
        const mousemoveHandler = () => {
            //Esempio da coordinate mouse trova coordinate 3D ad una certa Z...
            // .position.set( From2dCanvsTo3d(event.clientX,event.clientY).x, From2dCanvsTo3d(event.clientX,event.clientY).y, 0.0 );
        }
        window.addEventListener('mousemove', mousemoveHandler, false);
        
        const pointermoveHandler = (e) => {
            if (e.isPrimary) {
                // UTILITY.rayCheckIntersection( event.clientX, event.clientY, cameraRef.current, window, sceneRef.current  );
            }
        }
        window.addEventListener('pointermove', pointermoveHandler, false);   //Prova raycast sul modello
        
        const dblclickHandler = (e) => {
            var raycaster = new THREE.Raycaster();
            var mouse = new THREE.Vector2();
            mouse.x = (e.clientX / rendererRef.current.domElement.clientWidth) * 2 - 1;
            mouse.y = -((e.clientY) / rendererRef.current.domElement.clientHeight) * 2 + 1;
            raycaster.setFromCamera(mouse, cameraRef.current);
            var intersects = raycaster.intersectObjects(sceneRef.current.children);
            gizmoRef.current.visible=false;
            gizmoRef.current.detach();
            if (intersects.length > 0 ) { //Seleziona oggetto 
                for(var i=0; i<intersects.length; i++){
                    if (intersects[i].object.isMesh && !intersects[i].object.parent._gizmo ) {
                        gizmoRef.current.visible=true;
                        gizmoRef.current.attach(intersects[i].object);
                        break;
                    }
                }
            }else{
                
            }
        }
		window.addEventListener('dblclick', dblclickHandler, false);

        const mousedownHandler = (e) => {
            //UTILITY.rayCheckIntersection( e.clientX, e.clientY, cameraRef.current, window, sceneRef.current  );
        }
        window.addEventListener('mousedown', mousedownHandler, false);
        
        const keydownHandler = (e) => {
            switch ( e.keyCode ) {
                case 16: // Shift
                    gizmoRef.current.setTranslationSnap( 100 );
                    gizmoRef.current.setRotationSnap( THREE.MathUtils.degToRad( 15 ) );
                    gizmoRef.current.setScaleSnap( 0.25 );
                    break;
                case 17: // Ctrl
                    ctrlKeyRef.current=true;
                    break;
                case 46: // Del
                    if(gizmoRef.current.visible && gizmoRef.current.object){  //Cancella eventuali oggetti selezionati
                        if(ctrlKeyRef.current){     //Se anche CTRL toglie eventuale skinnedmesh
                            gizmoRef.current.object.isSkinnedMesh=!gizmoRef.current.object.isSkinnedMesh;
                        }else{      //Cancella oggetto selezionato
                            gizmoRef.current.object.parent.remove(gizmoRef.current.object);   
                            gizmoRef.current.detach();
                        }
                        render()
                    }
                    break;
                case 87: // W
                    gizmoRef.current.setMode( 'translate' );
                break;
                case 68: // D      //Debug function....
                break;
                case 69: // E
                    gizmoRef.current.setMode( 'rotate' );
                break;
                case 82: // R
                    gizmoRef.current.setMode( 'scale' );
                break;
            }
        }
        window.addEventListener('keydown', keydownHandler, false);

        const keyupHandler = (e) => {
            switch ( e.keyCode ) {
    
                case 16: // Shift
                    gizmoRef.current.setTranslationSnap( null );
                    gizmoRef.current.setRotationSnap( null );
                    gizmoRef.current.setScaleSnap( null );
                    break;
                case 17: // Ctrl
                    ctrlKeyRef.current=false;
                    break;
    
            }
        }
        window.addEventListener('keyup', keyupHandler, false);
        
        return () => {
            window.removeEventListener('resize', resizeHandler, false);
            window.removeEventListener('mousemove', mousemoveHandler, false);
            window.removeEventListener('pointermove', pointermoveHandler, false);
            window.removeEventListener('dblclick', dblclickHandler, false);
            window.removeEventListener('mousedown', mousedownHandler, false);
            window.removeEventListener('keydown', keydownHandler, false);
            window.removeEventListener('keyup', keyupHandler, false);
        }

    }, []);

    const prodCatRef = React.useRef();
    prodCatRef.current = product?.categories;
    React.useEffect(() => {
        if (prodVariation !== null && prodVariation.file3D !== undefined){
            if(cameraOn)setCameraSuspend(true); //Sospende camera per ottimizzare velocità
            
            UTILITY.loaderMesh(prodVariation.file3D,true, mesh => {
                //mesh.children[0].children[0].material.depthWrite=true;

                UTILITY.sceneAddCategory(sceneRef.current, mesh, prodCatRef.current, UTILITY.MeshesType.MESHVARIANTI );//{categories: [...prodCatRef.current]});
                UTILITY.sceneAddProdVariation(mesh, prodVariation);
                playSkeletonAnimation(false);    //Stoppa eventuale animazione se c'era ed era running ed imposta anche la posa iniziale
                
/*                nLayerRef.current++;  Leyers
                UTILITY.traverseSetRenderOrder(mesh,nLayerRef.current);
                UTILITY.traverseSetFunctionBeforeRender(mesh);
                UTILITY.traverse_Set_Reset_Transparent(mesh,true);
                UTILITY.traverseSetSide(mesh,0);    //Front*/

                if(UTILITY.getSkinnedMesh(mesh).isSkinnedMesh){
                    UTILITY.getSkinnedMesh(mesh).skeleton.pose();
                }
                if(cameraOn)setCameraSuspend(false); //Sospende camera per ottimizzare velocità
                TWEEN_Mesh_ExchangeRef.current.run(mesh);
            })    
        }
        
    }, [prodVariation]);
    
    React.useEffect(() => {
        if (!screenshotCallback) return;
        if (typeof screenshotCallback !== 'function') return;

        render();
        screenshotCallback(UTILITY.createCanvas(canvasRef.current));

    }, [screenshotCallback]);

    //Attenzione: questo effetto va messo PRIMA dell'effetto collegato a modelURL
    React.useEffect(() => {
        setIsLoading(true);
        //if(sceneRef.current)sceneRef.current.clear(); CANCELLA eventuali cose inserite in precedenza...luci ...
        UTILITY.loaderScene(sceneURL,rendererRef.current, (scena) => {
            setIsLoading(false);
            sceneRef.current.fog=scena.fog;
            sceneRef.current.background=scena.background;
            while(scena.children.length >0){
                /*scena.children[0].traverse((child) => {     //Test per leggere capelli da TurboSquid...da scena.json perchè da GLB non salva alphaMap...
                    if (child.isMesh){
                        child.material.alphaMap=UTILITY.filterAlphaScale(child.material.alphaMap,2);
                        child.material.format = THREE.RGBAFormat;
                        child.material.premultipliedAlpha=true;
                        child.material.alphaToCoverage=true;    //Risultato leggermente migliore
                        child.material.map.flipY=false;
                        child.material.alphaMap.flipY=false;
                        child.material.transparent=true;
                        child.material.depthTest=true;
                        child.material.depthWrite=true;
                        //UTILITY.downloadImage(child.material.alphaMap.image);
                    }
                });*/
                sceneRef.current.add(scena.children[0]);
            }
            render();
        });

    }, [sceneURL, setIsLoading]);

    React.useEffect(() => {
        playSkeletonAnimation(false)

        if(page===4 ){
            setCameraType(CameraType.ORTHOGRAPHIC)
        }else {
            setCameraType(CameraType.PERSPECTIVE)
        }

        if(page===3){
            if(!modelVrmRef.current){
                setIsLoading(true);
                UTILITY_VRM.loaderVRM('/scenes/Assistent_girl.vrm', (vrm) =>{   //modelli : https://hub.vroid.com/en/tags/VRoidStudio
                    modelVrmRef.current = vrm;
                    UTILITY.sceneAddCategory(sceneRef.current, vrm.scene,undefined ,UTILITY.MeshesType.MODELVRM );
                    setIsLoading(false);
                    render();
                })
            }
        }else {
            if(modelVrmRef.current){
                sceneRef.current.remove(modelVrmRef.current.scene);
                modelVrmRef.current=null;
            }
        }

        if(page===2){  
           
            CAMANIMATION_GLB_Reallusion.SetIKData_Reallusion(modelMeshRef.current); //add ik bones 
            skeletonAnimationInitialPose();
            CAMANIMATION_GLB_Reallusion.updateResPos(modelMeshRef.current);      
        }else {
        }

        render();
    }, [page, setIsLoading]);

    React.useEffect(() => {

        if (!menuItem) return;

        //faceFromPicture usa l'evento in un'altro useEffect...

        if (menuItem.value === 'StartPauseRecordCanvas') {

            if (!recorder.current.capturer) {
                recorder.current.capturer = new window.CCapture( { format: 'webm', display: true, framerate: 30, quality: 50 } );
                recorder.current.capturer.start();
                console.log('Start recording');
            }
            
            recorder.current.isRecording = !recorder.current.isRecording;
            
        } else
        if (menuItem.value === 'StopRecordCanvas') {
            
            if (recorder.current.capturer) {
                recorder.current.capturer.stop();
                recorder.current.capturer.save();
                recorder.current.capturer = null;
                recorder.current.isRecording = false;
            }
        } else
        if (menuItem.value === 'OpenGLB' && menuItem.data.length > 0) {     //Apre file GLB
            if(true){  //Legge modello nuovo
                LoadModel('/scenes/'+menuItem.data[0].name);
            }else{  //Legge capelli
                    UTILITY.sceneRemoveCategoryType(sceneRef.current,UTILITY.MeshesType.HAIR);
                    ReallusionUtility.visibleStandardHair(modelMeshRef.current,false);
                    // UTILITY.loaderMesh('/scenes/hair2d/download.glb',false, testGlbMesh => {   //_skinned: legge la faccia già skinnata...se si cambia modello rifare in SkinningUtility...
                    UTILITY.loaderMesh(menuItem.data, false, testGlbMesh => {   //_skinned: legge la faccia già skinnata...se si cambia modello rifare in SkinningUtility...
                        testGlbMesh=SkinningUtility.makeSkinnedMeshBVH(ReallusionUtility.getMeshHead(modelMeshRef.current),UTILITY.getSkinnedMesh(testGlbMesh), sceneRef.current);
                        UTILITY.sceneAddCategory(sceneRef.current, testGlbMesh,undefined ,UTILITY.MeshesType.HAIR);
                        render();
                    })
            }
        } else
        if (menuItem.value === 'OpenGLB') {
        } else
        if (menuItem.value === 'SaveGLB') {
            UTILITY.downloadGlb(sceneRef.current, test.glb);
        } else
        if (menuItem.value === 'OpenScene') {
        } else
        if (menuItem.value === 'SaveScene') {
        } else
        if (menuItem.value === 'InitialPose') {
            skeletonAnimationInitialPose();
        } else
        if (menuItem.value === 'RemoveClothes') {
            UTILITY.sceneRemoveCategoryAll(sceneRef.current);
            render();
        } else
        if (menuItem.value === 'StartAnimation') {
            playSkeletonAnimation(true);    
        } else
        if (menuItem.value === 'StopAnimation') {
            playSkeletonAnimation(false);    
        } else
        if (menuItem.value === 'ConvertToSkinnedMesh') {

            if (!gizmoRef?.current?.object) return;

               

function ApplayGroupMatrix(obj)
{
  
    obj.traverse(traveseobj => {
        //if (traveseobj?.scale?.x !=1) {
            let needupdatematrix=false;
            traveseobj.traverse(mesh => {
               if (mesh?.geometry) 
               {
                needupdatematrix=true;
                mesh?.geometry?.applyMatrix4( traveseobj.matrix );
                console.log('forEach ApplayGroupMatrix');
               }
            }); 
            if(needupdatematrix)
            { 
              traveseobj?.rotation?.set( 0, 0, 0 );
              traveseobj?.postion?.set( 0, 0, 0 );
              traveseobj?.scale?.set( 1, 1, 1 );
              traveseobj?.updateMatrix();
            }   
        //}
    });

   /*
    object.children[2].children[0].children.forEach( function(mesh) {
        mesh.geometry.applyMatrix( object.children[2].matrix );
        console.log('forEach ApplayGroupMatrix');
    });

    object.children[2].rotation.set( 0, 0, 0 );
    object.children[2].updateMatrix();
    console.log('end ApplayGroupMatrix');
    */
}

function SkinPromise(object)
{
    const allPromises = [];
    let prom;    
    object.traverse(obj => {
               
        if ( obj?.isMesh ) {
            prom =SkinningUtility.convertSkinnedMeshBVH(modelMeshRef.current, obj);
            prom.then(newSkinnedMesh => {
                const parent = obj.parent;
                obj.removeFromParent();
                gizmoRef.current.detach();                
                parent.add(newSkinnedMesh);
                console.log('Skined mesh');
            });
            allPromises.push(prom);
        }
         
    });
    if  (allPromises == []) 
        return Promise.resolve();
    else 
        return Promise.all(allPromises).then(
        resolve=>{
            setIsLoading(false);
            console.log('End all Skinned mesh');
        }
    );
    
}

function ChangeMatirialPromise(object)
{
    return new Promise(function(resolve, reject) {   
        object.traverse(obj => {   
            if ( obj?.isMesh ) {   
                    let modelMeshMat=obj.material;
                    if (modelMeshMat)
                    {
                     modelMeshMat.roughness=1;
                     modelMeshMat.metalness=0;
                    }    
            }
        });
        console.log('End change material');
        resolve(object);
    });
}

function SaveGLB(obj)
{
    //Save GLB
    let newSkinnedMesh=obj;
    newSkinnedMesh.traverseAncestors(parent => {
        if (parent.userData?.tryon && !group) {
            group = parent;
        }
    });

    if (group) {
        if (!newSkinnedMesh.skeleton)
        {
            let Ske=UTILITY.getSkinnedMesh(modelMeshRef.current).skeleton
            if (Ske){  
                newSkinnedMesh.skeleton=Ske;
            }
        }

        if (! newSkinnedMesh.skeleton) return;

        const savedBoneParent = newSkinnedMesh.skeleton.bones[0].parent;
        const savedUserData = group.userData;
        group.add(newSkinnedMesh.skeleton.bones[0]);
        group.userData = {};
        newSkinnedMesh.isSkinnedMesh = false;
        UTILITY.downloadGlb(group, 'skinnedMesh.glb');
        newSkinnedMesh.isSkinnedMesh = true;
        savedBoneParent.add(newSkinnedMesh.skeleton.bones[0]);
        
        group.userData = savedUserData;
    }
}

    setIsLoading(true);
    //find group 
    let group,object;
    gizmoRef.current.object.traverseAncestors(parent => {
        if (parent.userData?.tryon && !group) {
            group = parent;
        }
    });

    if (group) {
        object=group;
    }else
    {
        object=gizmoRef.current.object; 
    }
    let promise = new Promise(function(resolve, reject) {
        console.log('Applay matrix rotation');
        ApplayGroupMatrix(group); 
        resolve();
    });
    promise.then 
    (
       // resolve=>{ChangeMatirialPromise(object).then(         //Change Matirial for shima model
            resolve=>{SkinPromise(object).then(
                resolve=>{
                            console.log('Start save');
                            SaveGLB(object);
                            setIsLoading(false);
                        }
                                                )}
      //                                              )}   
    )  
            /* Pocedura originale
            SkinningUtility.convertSkinnedMeshBVH(modelMeshRef.current, gizmoRef.current.object).then(newSkinnedMesh => {
                
                const parent = gizmoRef.current.object.parent;
                gizmoRef.current.object.removeFromParent();
                gizmoRef.current.detach();                
                parent.add(newSkinnedMesh);
                
                //Save GLB
                let group;
                newSkinnedMesh.traverseAncestors(parent => {
                    if (parent.userData?.tryon && !group) {
                        group = parent;
                    }
                });

                if (group) {
                    const savedBoneParent = newSkinnedMesh.skeleton.bones[0].parent;
                    const savedUserData = group.userData;

                    group.add(newSkinnedMesh.skeleton.bones[0]);
                    group.userData = {};
                    // newSkinnedMesh.isSkinnedMesh = false;
                    UTILITY.downloadGlb(group, 'skinnedMesh.glb');
                    // newSkinnedMesh.isSkinnedMesh = true;

                    savedBoneParent.add(newSkinnedMesh.skeleton.bones[0]);
                    group.userData = savedUserData;
                }
            }).catch(reason => {
                console.log(reason);
            }).finally(() => {
                setIsLoading(false);
            });
            */
        }
        
    }, [menuItem]);     //Popup menu debug interno
    
    //Attenzione: questo effetto va messo DOPO dell'effetto collegato a sceneURL
    React.useEffect(() => {
        LoadModel(modelURL);
    }, [modelURL]);

    React.useEffect(() => {
        if (page === 5) {
        }
    }, [userFaceData, page]);

    function playSkeletonAnimation(start) {
        if(modelMeshRef.current && UTILITY.getSkinnedMesh(modelMeshRef.current).skeleton && modelMeshRef.current.animations.length >0){    
            if(!start){
                if (mixerAnimationRef.current) mixerAnimationRef.current.stopAllAction();
                //mixerAnimationRef.current.clipAction( modelMeshRef.current.animations[ 0 ] ).reset();     //Chiamare il pose solo appena prima del bind...
                //mixerAnimationRef.current.clipAction( modelMeshRef.current.animations[ 0 ] ).paused=true;
            }else{
                mixerAnimationRef.current = new THREE.AnimationMixer( sceneRef.current );
                mixerAnimationRef.current.clipAction( modelMeshRef.current.animations[ 0 ] ).reset();
                mixerAnimationRef.current.clipAction( modelMeshRef.current.animations[ 0 ] ).play();
            }
            UTILITY.getSkinnedMesh(modelMeshRef.current).skeleton.pose();
        }
    }

    function skeletonAnimationInitialPose() {
        playSkeletonAnimation(false);    
        playSkeletonAnimation(true);    
        if (mixerAnimationRef.current && mixerAnimationRef.current) {
            mixerAnimationRef.current.clipAction( modelMeshRef.current.animations[ 0 ] ).paused=true;
        }
    }

    function startMergeMPipeMeshToModel(faceGlbMesh){
        let getAgeGender=useGesture;    //Se usa gesture allora chiede anche AgeGender  , solo la prima volta ci mette più tempo...
        if(ReallusionUtility.mergeMPipeMeshToModel(keyPts, modelMeshRef.current, faceGlbMesh, sceneRef.current, keepHair,getAgeGender)){
            return true;
        }
    }

    function generateTexture() {

        const canvas = document.createElement( 'canvas' );
        canvas.width = 2;
        canvas.height = 2;

        const context = canvas.getContext( '2d' );
        context.fillStyle = 'white';
        context.fillRect( 0, 1, 2, 1 );

        return canvas;

    }

    function setCameraType(camType) {
        if(cameraRef.current){
            cameraRef.current = (camType === CameraType.PERSPECTIVE) ? cameraPerspRef.current : cameraOrtoRef.current;
            //Cambia camera anche nel postprocessing
            if(postprocessingRef.current){
                for (let i = 0; i < postprocessingRef.current?.composer.passes.length; i++) {
                    if(postprocessingRef.current.composer.passes[i].camera)postprocessingRef.current.composer.passes[i].camera=cameraRef.current;
                }
            }

        }
    }

    // eslint-disable-next-line
    function getCameraType() {
        return (cameraRef.current === cameraPerspRef.current) ? CameraType.PERSPECTIVE : CameraType.ORTHOGRAPHIC;
    }

    function LoadModel(modelURL){
        if (!sceneRef.current) return;
        setIsLoading(true);
        const mBefDownFile = '1', mAftDownFile = '2', mBefAddScene = '3', mAftAddScene = '4', mBefRender = '5', mAftRender = '6';
        performance.mark(mBefDownFile);
        ReallusionUtility.loaderMesh(modelURL,true, mesh => {
            performance.mark(mAftDownFile);
            /*            let modelMeshMat=UTILITY.getSkinnedMesh(mesh).material;   //Test MakeHuman model
            if(true){//Setta piano alpha per mettere trasparente la faccia del modello
                //modelMeshMat.alphaMap = new THREE.TextureLoader().load( '/scenes/texture/Aksel_Skin_diffuse-GrayScale-Buco.png' );
                //modelMeshMat.alphaMap.flipY=false;
                //modelMeshMat._alphaTest=0.04;  //Serve per avere la completa trasparenza del piano alpha della texture
                //modelMeshMat.transparent=false;
                
                (new THREE.TextureLoader()).load('/scenes/Texture/Aksel_Skin_diffuse-GrayScale-BucoA.png', (mesh) => {
                    modelMeshMat.map = mesh;
                    modelMeshMat.map.flipY = false;
                    modelMeshMat.needsUpdate = true;
                });//Skin.png' );//Legge anche la texture per la pelle
                //modelMeshMat.wireframe=true;
            }

            if(modelMeshMat.map){ //se con texture...
                modelMeshMat.metalness=0;      
                modelMeshMat.roughness=0.52;   
            }else{ //Se senza texture ....
                modelMeshMat.metalness=0.76;     
                modelMeshMat.roughness=0.76;   //aumentando si scurisce...
            }
            */            // console.log(mesh);
            UTILITY.sceneRemoveCategoryAll(sceneRef.current);
            
            UTILITY.sceneRemoveCategoryType(sceneRef.current, UTILITY.MeshesType.FACEWEBCAM);
            
            UTILITY.sceneRemoveCategoryType(sceneRef.current, UTILITY.MeshesType.MODELBASE);
            productSelectedChange(UTILITY.getProdVariations(sceneRef.current));
            modelMeshRef.current = mesh;
            //CAMANIMATION_GLB.SetIKData(modelMeshRef.current); //add ik bones
            //CAMANIMATION_GLB_Reallusion.SetIKData_Reallusion(modelMeshRef.current); //add ik bones 
            performance.mark(mBefAddScene);
            UTILITY.sceneAddCategory(sceneRef.current, mesh,undefined ,UTILITY.MeshesType.MODELBASE );
            performance.mark(mAftAddScene);
            
            //Imposta la posizione Pose....
            //let sk=UTILITY.getSkinnedMesh(modelMeshRef.current).skeleton;
            UTILITY.traverseGetSkeleton(modelMeshRef.current);
            //sk.calculateInverses();
            //sk.update();
            //sk.pose();

            // const line = new THREE.LineSegments( new THREE.EdgesGeometry( UTILITY.getSkinnedMesh(mesh).geometry,0), new THREE.LineBasicMaterial( { color: 0xffffff } ) );
            // sceneRef.current.add(line);

            postProcessingStart(modelURL!== "/scenes/womanReallusion.glb"); //Siluette solo se manichino uomo
            performance.mark(mBefRender);
            render();
            performance.mark(mAftRender);
            
            setIsLoading(false);
            performance.mark(window.markEnd);

            performance.measure('Download file time', mBefDownFile, mAftDownFile);
            performance.measure('Add scene time', mBefAddScene, mAftAddScene);
            performance.measure('First render time', mBefRender, mAftRender);
            performance.measure('Ready time', window.markInit, window.markEnd);
            performance.getEntriesByType("measure").forEach(mObj => console.log(`${mObj.name}: ${mObj.duration}`));
        });
    }

    function postProcessingStart(start){
        if(!postprocessingRef.current){
            initPostprocessing();   //Effetti vari
        }
        if(!modelMeshRef.current)return;
        let mesh=UTILITY.getSkinnedMesh(modelMeshRef.current);
        if(!mesh)return;
        postprocessingRef.current.enabled=start;
        if(start){
            skeletonAnimationInitialPose();
            UTILITY.sceneRemoveCategoryType(sceneRef.current, UTILITY.MeshesType.FACEWEBCAM);
            UTILITY.traverse_Set_Reset_Transparent(modelMeshRef.current,true,false);   //Setta trasparente per consentire alla opacità di avere effetto
            UTILITY.traverseSetOpacity(modelMeshRef.current,0.0);      
            postprocessingRef.current.outlinePass.selectedObjects=[modelMeshRef.current];
        }else{
            UTILITY.traverse_Set_Reset_Transparent(modelMeshRef.current,true,true);   //Ripristina trasparente come era in origine
            UTILITY.traverseSetOpacity(modelMeshRef.current,1);      
        }
        render();
    }

    const TWEEN_Mesh_ExchangeRef= React.useRef({run:null, tween:null});
    TWEEN_Mesh_ExchangeRef.current.run = (meshIn) => {        //Animation Mesh add          TWEEN EASING EXAMPLES: https://github.com/tweenjs/tween.js/blob/master/examples/03_graphs.html
        if(cameraOn)setCameraSuspend(true); //Sospende camera per ottimizzare velocità
        let from = { x: 0, y: 80, z: 600, opacity: 3 };
        let to;
        let meshOut =UTILITY.sceneFindItemCategory(sceneRef.current,meshIn);
        if(meshOut){UTILITY.traverse_Set_Reset_Transparent(meshOut,true)};  //Mette trasparente per fare in modo che poi funzioni il cambio di opacità
        if(modelMeshRef.current){to = { x: modelMeshRef.current.position.x, y: 0, z: modelMeshRef.current.position.z, opacity: 0.0 }} else {to = { x: 0, y: 0, z: 0, opacity: 0.0 }};
        if(TWEEN_Mesh_ExchangeRef.current.tween){   //Azzera eventuali TWEEN running
            TWEEN.remove(TWEEN_Mesh_ExchangeRef.current.tween); 
            TWEEN_Mesh_ExchangeRef.current.tween=null;
        }
        TWEEN_Mesh_ExchangeRef.current.tween=new TWEEN.Tween(from)
            .to(to, 2000)
            .easing(TWEEN.Easing.Cubic.Out)    //TWEEN.Easing.Elastic.InOut)
            .onUpdate(function () {
                meshIn.position.set(from.x+0, from.y, from.z);
                if (meshOut) {
                    UTILITY.traverseSetOpacity(meshOut,from.opacity)
                }
                render();
            })
            .onComplete(function () {
                TWEEN_Mesh_ExchangeRef.current.tween=null;
                if(meshOut){
                    UTILITY.sceneRemoveCategory(sceneRef.current, meshIn);  //Rimuove Mesh con stessa categoria.id (non rimuove se stesso meshIn...)
                    render();
                }    
                if(true && UTILITY.getSkinnedMesh(meshIn).isSkinnedMesh){   //Binda skinned mesh allo skeleton per l'animazione
                    UTILITY.traverseBindSkeleton(meshIn,modelMeshRef.current);
                    //playSkeletonAnimation(true);
                }
                // productSelectedChange(UTILITY.sceneGetItemsCategoryProdCatRoot(sceneRef.current));  //Evento quando cambia ProdCat
                productSelectedChange(UTILITY.getProdVariations(sceneRef.current));  //Evento quando cambia ProdCat
                if(cameraOn)setCameraSuspend(false); //Ripristina camera se era stata sospesa per ottimizzare velocità
            })
            .delay(0)
            .repeat(0)
            .start();
    }

    const TWEEN_ModelProgressBar= React.useRef({run:null, tween:null});
    TWEEN_ModelProgressBar.current.run = (keyPts,bEnter) => {        //Animation progress bar model          TWEEN EASING EXAMPLES: https://github.com/tweenjs/tween.js/blob/master/examples/03_graphs.html
        if(cameraOn)setCameraSuspend(true); //Sospende camera per ottimizzare velocità

        UTILITY.sceneRemoveCategoryType(sceneRef.current, UTILITY.MeshesType.PROGRESSMODELFACE);
        if(TWEEN_ModelProgressBar.current.tween){
            TWEEN.remove(TWEEN_ModelProgressBar.current.tween); 
            TWEEN_ModelProgressBar.current.tween=null;
        }
        TWEEN_ModelProgressBar.current.placingFace=bEnter;
        TWEEN_ModelProgressBar.current.deletingFace=!bEnter;
        if(!bEnter && !TWEEN_ModelProgressBar.current.placedFace){render(); return;}    //Se esce ma non c'era la faccia....
        if(bEnter && TWEEN_ModelProgressBar.current.placedFace){render(); return;}      //se entra ma c'era già una faccia...

        const geometryGlass = new THREE.CylinderGeometry( 35,35, 150, 50,20,true );
        const texture = new THREE.CanvasTexture( generateTexture() );
        texture.magFilter = THREE.NearestFilter;
        texture.wrapT = THREE.RepeatWrapping;
        texture.wrapS = THREE.RepeatWrapping;
        texture.repeat.set( 1, 9 );

        let meshProgress = new THREE.Mesh( geometryGlass, new THREE.MeshStandardMaterial({
            transparent:true, 
            map:texture, 
            side: THREE.DoubleSide,
            opacity:0.4,
            metalness: 1,
            roughness: 0.3,
            color:'white'
                }) );
        meshProgress.geometry.computeBoundingBox()
        const boxSize = new THREE.Vector3();
        meshProgress.geometry.boundingBox.getSize(boxSize)
        let center=(boxSize.y/2)+10;
        meshProgress.material.depthWrite=false;
        meshProgress.renderOrder=1000;
        meshProgress.visible=false;
        UTILITY.sceneAddCategory(sceneRef.current, meshProgress,undefined ,UTILITY.MeshesType.PROGRESSMODELFACE);
        let from,to;
        if(bEnter){
            from = { y: center-boxSize.y, opacity: 3   };
            to   = { y: center,  opacity: 0.0 };
        } else {
            from = { y: center, opacity: 3   };
            to   = { y: center-boxSize.y,  opacity: 0.0 };
        }
        TWEEN_ModelProgressBar.current.tween=new TWEEN.Tween(from)
            .to(to, 2000)
            .easing(TWEEN.Easing.Cubic.Out)    //TWEEN.Easing.Elastic.InOut)
            .onUpdate(function () {
                meshProgress.visible=true;
                meshProgress.position.y= from.y;
                render();
            })
            .onComplete(function () {
                TWEEN_ModelProgressBar.current.tween=null;
                sceneRef.current.remove( meshProgress );
                UTILITY.sceneRemoveCategoryType(sceneRef.current, UTILITY.MeshesType.PROGRESSMODELFACE);
                setIsGestureOn(bEnter); //Gestisce anche la gesture quando si aggancia....
                if(bEnter){   
                    TWEEN_ModelProgressBar.current.placingFace=false;
                    TWEEN_ModelProgressBar.current.placedFace=true;             //Converte frame webcam to mesh faccia
                    UTILITY.sceneRemoveCategoryType(sceneRef.current, UTILITY.MeshesType.FACEWEBCAM);
               
                    playSkeletonAnimation(false);

                        UTILITY.loaderMesh('/scenes/canonical_face_model.glb',false, faceGlbMesh => {
                            // let meshFace=UTILITY.PhotoFaceToMesh(keyPts, modelMeshRef.current, faceGlbMesh, sceneRef.current);    //Prima versione , non si usa più...
                            // if(meshFace){
                            //     UTILITY.sceneAddCategory(sceneRef.current, meshFace,undefined ,UTILITY.MeshesType.FACEWEBCAM);
                            //     postProcessingStart(false);
                            // }

                            if (keyPts.image) {

                                let tarCnv = UTILITY.imgToCanvas(keyPts.image);
                                
                                //Filtro per colore e luminosità per amalgamare meglio con il modello...
//                                if (gender === Genders.Male){
//                                    UTILITY.filterRGBToImg(214, 166, 141, -180, tarCnv);    //Applica un filtro colore e luminosità
//                                }else{
//                                    UTILITY.filterRGBToImg(227, 186, 170, -180, tarCnv);    //Applica un filtro colore e luminosità
//                                }
                                
                                createImageBitmap(tarCnv).then(bmp => {
                                    keyPts.image = bmp;

                                    if (startMergeMPipeMeshToModel(faceGlbMesh)){                            
                                        if (!useGesture) setWebCamera(false);    //Stacca la WebCamera in automatico... 
                                        postProcessingStart(false);
                                        skeletonAnimationInitialPose();
                                    }
                                });

                                
                            }
                            
                        })
                }else{              //Disconnette faccia
                    TWEEN_ModelProgressBar.current.deletingFace=false;
                    TWEEN_ModelProgressBar.current.placedFace=false;            
                    UTILITY.sceneRemoveCategoryType(sceneRef.current, UTILITY.MeshesType.FACEWEBCAM);
                    postProcessingStart(true);
                }
                render();
                if(cameraOn){
                    setCameraSuspend(false); //Se aveva sospeso camera per ottimizzare velocità
                }
            })
            .delay(1000)
            .repeat(0)
            .start();
    }

    function updateSize() {
        function resizeRendererToDisplaySize() {
            let result = null;
            const canvas = rendererRef.current.domElement;
            const pixelRatio = window.devicePixelRatio;
            const newWidth  = canvas.clientWidth  * pixelRatio | 0;
            const newHeight = canvas.clientHeight * pixelRatio | 0;
            const needResize = canvas.width !== newWidth || canvas.height !== newHeight;
            if (needResize) {
                rendererRef.current.setSize(newWidth, newHeight, false);
                result = {width: newWidth, height: newHeight}
            }
            return result;
        }

        function updateCamera(newWidth, newHeight) {
            let modelHeight = 190;

            if (cameraPerspRef.current) {
                cameraPerspRef.current.aspect = newWidth / newHeight;
                cameraPerspRef.current.updateProjectionMatrix();
            }
            
            if (cameraOrtoRef.current) {
                const asp = newWidth / newHeight;
                let h = modelHeight + 20;
                let w = h * asp;
                
                cameraOrtoRef.current.left = w / -2;
                cameraOrtoRef.current.right = w / 2;
                cameraOrtoRef.current.top = h / 2;
                cameraOrtoRef.current.bottom = h / -2;
                cameraOrtoRef.current.updateProjectionMatrix();
            }
        }

        const newSize = resizeRendererToDisplaySize();
        if (newSize) {
            if(cameraRef.current) updateCamera(newSize.width, newSize.height);
            if(postprocessingRef.current ) postprocessingRef.current.composer?.setSize(newSize.width, newSize.height);
        }
    }

    const postprocessingChangeValue = function (focus,aperture,maxblur ) {
        postprocessingRef.current.bokeh.uniforms[ "focus" ].value = focus;
        postprocessingRef.current.bokeh.uniforms[ "aperture" ].value = aperture * 0.00001;
        postprocessingRef.current.bokeh.uniforms[ "maxblur" ].value = maxblur;
    };

    function initPostprocessing() {

        const renderPass = new RenderPass( sceneRef.current, cameraRef.current );
        
        //Profondita di campo
        const bokehPass = new BokehPass( sceneRef.current, cameraRef.current, {
            focus: cameraRef.current.position.z,
            aperture:9 * 0.00001,
            maxblur: 0.05,//0.01,

            width: window.innerWidth,
            height: window.innerHeight
        } );

        //Linea di contorno
        const outlinePass = new OutlinePass( new THREE.Vector2( window.innerWidth, window.innerHeight ), sceneRef.current, cameraRef.current );
        outlinePass.edgeStrength=3;
        //outlinePass.edgeGlow=outlinePass.edgeGlow;
        //outlinePass.edgeThickness=outlinePass.edgeThickness;
        outlinePass.pulsePeriod=3;
        outlinePass.usePatternTexture=false;
        outlinePass.visibleEdgeColor.set('#ffffff');
        outlinePass.hiddenEdgeColor.set('#ffffff')//  ('#190a05');

        //const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
        // bloomPass.threshold = 0;
        // bloomPass.strength = 3;
        // bloomPass.threshold=0; 
        // bloomPass.radius = 0;

        //Serve solo per correggere gamma anche nel renderpass come rendererRef.current.outputEncoding=THREE.sRGBEncoding;
        var gammaCorrection = new ShaderPass( GammaCorrectionShader );

        const effectGrayScale = new ShaderPass( LuminosityShader );
        
        const composer = new EffectComposer( rendererRef.current );

        const effectSobel = new ShaderPass( SobelOperatorShader );
        effectSobel.uniforms[ 'resolution' ].value.x = window.innerWidth * window.devicePixelRatio;
        effectSobel.uniforms[ 'resolution' ].value.y = window.innerHeight * window.devicePixelRatio;

        composer.addPass( renderPass );
        if(false)composer.addPass( bokehPass );
        composer.addPass( outlinePass );
        //composer.addPass( bloomPass );
        if(false)composer.addPass( effectGrayScale );
        if(false)composer.addPass( effectSobel );
        composer.addPass( gammaCorrection );//Importante per risultato come sRGBEncoding

        postprocessingRef.current={};

        postprocessingRef.current.composer = composer;
        postprocessingRef.current.bokeh = bokehPass;
        postprocessingRef.current.outlinePass = outlinePass;  //https://threejs.org/examples/#webgl_postprocessing_outline

        if(false)postprocessingChangeValue(cameraRef.current.position.z,9,0.05); //Promemoria per cambiare i valori runtime

    }

    const render = () => {
        if (rendererRef.current && sceneRef.current && cameraRef.current) {

            if(orbitControlsRef.current)orbitControlsRef.current.update();

            if (modelVrmRef.current) {
                // Update model to render physics
                modelVrmRef.current.update(clockRef.current.getDelta());
            }
    
            if(postprocessingRef.current && postprocessingRef.current.enabled){
                postprocessingRef.current.composer?.render( );
            }else{
                //rendererRef.current.clear();
                //rendererRef.current.clearDepth();
                rendererRef.current.render(sceneRef.current, cameraRef.current);
                //rendererRef.current.render(sceneModelRef.current, cameraRef.current);
                
                //const image.src = rendererRef.current.domElement.toDataURL("image/jpeg");   settare:   var renderer = new THREE.WebGLRenderer({alpha: true,preserveDrawingBuffer: true });
            }

            // if (recorder.current.isRecording) { //To Puase the recorder bbut it doesn't work
            if (recorder.current.capturer) {
                recorder.current.capturer.capture(rendererRef.current.domElement);
            }
            //console.log('three.js render');
        }

        //esempio per renderizzare diverse aree dello screen e in un certo ordine... settare renderer.autoClear = false; // important!
        /*
        renderer.clear();

        // render to texture
        renderer.render( texture_scene, ortho_camera, renderTarget, true );
        
        // render scene first
        renderer.setViewport( 0, 0, window.innerWidth, window.innerHeight );
        renderer.render( scene, camera );
        
        // render inset second
        renderer.clearDepth(); // clear the depth buffer
        renderer.setViewport( 10, window.innerHeight - insetHeight - 10, insetWidth, insetHeight );
        renderer.render( inset_scene, ortho_camera );
        */

    };

    const animateRef = React.useRef();
    animateRef.current = () => {
        let needsUpdate = false;

        if (mixerAnimationRef.current) {
            mixerAnimationRef.current.update(clockRef.current.getDelta()); 
            needsUpdate = true;
        }

        if (orbitControlsRef.current && orbitControlsRef.current.isOrbiting) needsUpdate = true; //Serve solo se si vuole l'inerzia nel movimento...

        //L'intenzione era fermare il postprocessing nella versione mobile ma non si riesce 
        //perche' needsUpdate e' true dal controllo precedente della animazione
        if (postprocessingRef.current && postprocessingRef.current.enabled && !isMobile()) needsUpdate = true;

        if (modelVrmRef.current)needsUpdate =true;

        TWEEN.update();

        if(particleLightRef.current.enabled){  
            particleLightRef.current.visible=true;  
            const timer = Date.now() * 0.00025;
            particleLightRef.current.position.set(Math.sin( timer * 5 ) * 50, 120+((Math.cos( timer * 3 ) * 80)), Math.cos( timer * 1 ) * 50);
            needsUpdate =true;
        }else{
            particleLightRef.current.visible=false;  
        }    

        if (needsUpdate) render();
        if (showStatsRef.current) setTimestamp({timestamp: Date.now()});
    };

    const posRef = React.useRef(null);
    if (cameraRef.current && canvasRef.current) {
        posRef.current = UTILITY.from3DTo2D(new THREE.Vector3(0, 154, 50), cameraRef.current, canvasRef.current);
    }

    return (
        <>
            <canvas ref={canvasRef} style={{display: 'block', position: 'absolute', width: '100%', height: '100%'}}/>
            {showStats && 
                <div style={{position: 'absolute', left: '25%', top: '100px', zIndex: 100}}>
                    <Stats timestamp={timestamp} />
                </div>}
            {false && posRef.current && 
                <div style={{position: 'absolute', left: posRef.current.x, top: posRef.current.y, width: 5, height: 5, backgroundColor: 'red', transform: 'translate(-50%, -50%)'}}/>
            }
        </>
    );

}

export { ThreeMan };