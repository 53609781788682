import React from 'react';
import classNames from 'classnames';

const CornerFlag = ({text, isFull}) => {

    const mainClasses = classNames('cornerFlag');

    return (
        <div className={mainClasses}>
            {/* <div class='text'> */}
                {text}
            {/* </div> */}
        </div>
    )
};

export default CornerFlag;
