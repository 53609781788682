import React from 'react';
import { BsShareFill } from "react-icons/bs";
import ClickableContainer from "../ClickableContainer/ClickableContainer";
import { useLoadingContext } from '../../components/Loading/Loading';
import ShareDialog from './../ShareDialog/ShareDialog';

const Share = ({getInfoToShare}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [qrValue, setQRValue] = React.useState('');
    const { setIsLoading } = useLoadingContext();

    const handleClose = () => {
        setIsOpen(false);
    }

    const handleClick = () => {
        setIsLoading(true);
        getInfoToShare().then(info => {
            setQRValue(info);
            setIsOpen(true);
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <>
            <div className="mainOption">
                <BsShareFill />
                {/* <ClickableContainer onclick={() => {setIsOpen(true)}} /> */}
                <ClickableContainer onclick={handleClick} />
            </div>
            <ShareDialog qrValue={qrValue} isOpen={isOpen} handleClose={handleClose} />
                   
        </>
    )
};

export default Share;

/*


<CustomDialog title='Share' isOpen={isOpen} onClose={handleClose}>
                <div style={{ background: 'white', padding: '5px' }}>
                    <QRCode value={qrValue} />
                </div>
                <br />
                <AppName />
            </CustomDialog>

*/
