import React from "react";
import { drawConnectors } from '@mediapipe/drawing_utils'
import { FACEMESH_TESSELATION } from '@mediapipe/holistic'
import { normCoordScaler } from '../../Utility/ThreeManUtility.js';
//import { autocompleteClasses } from "@mui/material";
//import { BsTranslate } from "react-icons/bs";

export default function ConnectorDrawer({keyPts}) {

    const canvasRef = React.useRef();

    let angle = 0, arrowPos = {x: -100, y: -100};
        
    if (keyPts?.faceLandmarks && canvasRef.current) {
        let ctx = canvasRef.current.getContext('2d');
        let origAspect = keyPts.image.width / keyPts.image.height;
        //Sovrascrive eventuale style impostato
        canvasRef.current.style.width ='';
        canvasRef.current.style.height='';
        if(origAspect >1){ //se schiacciato
            canvasRef.current.width = window.innerWidth;
            canvasRef.current.height= canvasRef.current.width / origAspect;    
            // canvasRef.current.style.top=(window.innerHeight-canvasRef.current.height)/2 +'px'; //Centra il canvas 
        }else {             //Se allungato
            canvasRef.current.height= window.innerHeight ;    
            canvasRef.current.width = canvasRef.current.height * origAspect;
            canvasRef.current.style.left=(window.innerWidth-canvasRef.current.width)/2 +'px'; //Centra il canvas 
        }

        if(false){
            ctx.drawImage(keyPts.image, 0, 0, canvasRef.current.width, canvasRef.current.height);
        }

        const scaler = normCoordScaler(canvasRef.current.width, canvasRef.current.height);
        let gridNormalCol = 'yellow', gridMatchCol = 'green';
        
        //#region Draw a line from face landmark center to model face center
        const pt1 = scaler({x: keyPts.faceIsOverAvatar.CenterX, y: keyPts.faceIsOverAvatar.CenterY});
        const pt2 = scaler(keyPts.faceLandmarks[5]);
        ctx.save();
        ctx.setLineDash([4, 2]);
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(pt1.x, pt1.y);
        ctx.lineTo(pt2.x, pt2.y);
        ctx.strokeStyle = gridMatchCol;
        ctx.stroke();
        ctx.restore();
        
        const radius = 3;
        ctx.beginPath();
        ctx.arc(pt1.x, pt1.y, radius, 0, 2 * Math.PI);
        ctx.arc(pt2.x, pt2.y, radius, 0, 2 * Math.PI);
        ctx.fillStyle = 'lime';
        ctx.fill();
        //#endregion

        const gridCol = keyPts.faceIsOverAvatar.Center ? gridMatchCol : gridNormalCol;
        drawConnectors(ctx, keyPts.faceLandmarks, FACEMESH_TESSELATION, { color: gridCol, lineWidth: 1 });
        
        angle = Math.atan2(pt2.y - pt1.y, pt2.x - pt1.x);
        const dist = 30;
        arrowPos = {x: pt1.x + dist * Math.cos(angle), y: pt1.y + dist * Math.sin(angle)};
    }

    // if (keyPts?.faceLandmarks) {
    //     canvasRef.current.width = canvasRef.current.clientWidth;
    //     canvasRef.current.height = canvasRef.current.clientHeight;
    //     let origAspect = keyPts.image.width / keyPts.image.height;
    //     let destAspect = canvasRef.current.width / canvasRef.current.height;

    //     let fitWidth, fitHeight;
    //         if (origAspect > 1)
    //         {
    //         fitWidth = (destAspect > 1) ? canvasRef.current.height * origAspect : canvasRef.current.width;
    //         fitHeight = (destAspect > 1) ? canvasRef.current.height : canvasRef.current.width * 1 / origAspect;
    //         } else
    //         {
    //             fitWidth = (destAspect > 1) ? canvasRef.current.height * origAspect : canvasRef.current.width;
    //             fitHeight = (destAspect > 1) ? canvasRef.current.height : canvasRef.current.width * 1 / origAspect;
    //         }
    //     ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    //     if(true){
    //         let dx = (canvasRef.current.clientWidth / 2) - (fitWidth / 2);
    //         let dy = (canvasRef.current.clientHeight / 2) - (fitHeight / 2);
    //         ctx.drawImage(keyPts.image, dx, dy, fitWidth, fitHeight);
    //     }
    //     ctx.translate((canvasRef.current.width - fitWidth) / 2, (canvasRef.current.height - fitHeight) / 2);
    //     ctx.scale(fitWidth / canvasRef.current.width, fitHeight / canvasRef.current.height);
    //     let GridCol='green';
    //     if(keyPts.faceIsOverAvatar.Center)GridCol='yellow';
    //     drawConnectors(ctx, keyPts.faceLandmarks, FACEMESH_TESSELATION, { color: GridCol, lineWidth: 1 });
    //     ctx.setTransform(1, 0, 0, 1, 0, 0);
    // }else{      
    //     ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);    
    // }


    return (
        <div className="connectorDrawer" style={{position: 'relative', pointerEvents: 'none'}}>
            <style>
            </style>
            <img src="images/arrow3D.gif" alt="Arrow animation" style={{position: 'absolute', left: arrowPos.x, top: arrowPos.y, width: '3em', transform: `translate(-50%, -50%) rotate(${angle}rad)`}}/>
            <canvas id='test' style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%'}} ref={canvasRef} />
        </div>
    );

}