import React from 'react';
import { point } from '@flatten-js/core';
import classNames from 'classnames';
import { IoHandLeft, IoHandRight } from "react-icons/io5";
import { FaHandRock } from "react-icons/fa";

function HandTracker({ handPts, isLeft, position, isClosed, isClicked, children, className, clickRender }) {
    const realPosition = React.useRef(point(-1000, -1000)); //default value. Si usa un ref per poter usarlo bene dal useEffect
    
    React.useEffect(() => {
        // console.log(`enter click effect: ${isClicked}`);
        let clickOffset = 5;
        if (isClicked) {
            const el = document.elementFromPoint(realPosition.current.x + clickOffset, realPosition.current.y - clickOffset);
            if (el && (typeof el.click === 'function')) {
                el.click();
                // console.log(`clicked ${el.tagName} class: ${el.className}`);
            }
        };        
    }, [isClicked]);
    
    //Tentativo MouseOver: You can't. It's not a trusted event: https://newbedev.com/how-do-i-simulate-a-mouseover-in-pure-javascript-that-activates-the-css-hover
    // React.useEffect(() => {
        //     let x = lastPosRef.current.x - 5;
        //     let y = lastPosRef.current.y - 5;
        //     const el = document.elementFromPoint(x, y);
        //     if (el) {
            //         el.dispatchEvent(new MouseEvent('mouseover', { 'view': window, 'bubbles': true, 'cancelable': true, 'screenX': x, 'screenY': y }));
            //         console.log(`mouseover ${el.tagName} class: ${el.className}`);
            //     }
            // });
            
    let activeClass = false;
    if (position) {
        const transformNormalizedVal = (normVal, segmentLength, factor) => {
            let segLenScaled = segmentLength * factor;
            return normVal * segLenScaled  - ((segLenScaled - segmentLength) / 2);
        };
        
        let x =0;
        if(true){   //Fissa coordinate X
            if (isLeft){
                x = transformNormalizedVal(1-0.09, window.innerWidth, 1);
            }else{
                x = transformNormalizedVal(0.09, window.innerWidth, 1);
            } 
        }else{
            let x = transformNormalizedVal(position.x, window.innerWidth, 1);
        }
        let y = transformNormalizedVal(position.y, window.innerHeight, 4.8);//2.8);
        
        realPosition.current = point(x, y);
        activeClass = true;
    };
    
    let classes = classNames('gestureTracker', className, {
        'active': activeClass
    });
    
    const styledObj = {
        position: 'absolute',
        width: 'max-content',
        height: 'max-content',
        left: realPosition.current.x,
        top: realPosition.current.y,
        // transform: 'translate(-50%, -50%)',
        zIndex: 1000
    };
    
    return (
        <div className={classes} style={styledObj}>
            {(isClosed && clickRender && clickRender()) || children}
        </div>
    )
    
};

export default function GestureTracker ({ keyPts }) {

    // const trackerSize = '50px';
    // const trackerSize = '32px';
    const trackerSize = '3em';
    function clickRender() {
        return <FaHandRock fontSize={trackerSize} />
    }

    return (
        <>
            <HandTracker handPts={keyPts?.leftHandLandmarks} isLeft={true} position={keyPts?.posLeftHand} isClosed={keyPts?.closeLeftHand} isClicked={keyPts?.onClickLeftHand} clickRender={clickRender} >
                {/* <CircularBox width={trackerSize} /> */}
                {/* <BsCursorFill fontSize={trackerSize} /> */}
                <IoHandRight fontSize={trackerSize} />
            </HandTracker>

            <HandTracker handPts={keyPts?.rightHandLandmarks} isLeft={false} position={keyPts?.posRightHand} isClosed={keyPts?.closeRightHand} isClicked={keyPts?.onClickRightHand} clickRender={clickRender} >
                {/* <CircularBox width={trackerSize} /> */}
                {/* <BsCursorFill fontSize={trackerSize} className="flipH" /> */}
                <IoHandLeft fontSize={trackerSize} />
            </HandTracker>
        </>
    );

};