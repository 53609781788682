import React from "react";
import Pagination from '@mui/material/Pagination';
import { BsCart3  } from "react-icons/bs";
import AppName from '../../components/AppName/AppName';
import {usePopupMsgContext} from '../../components/PopupMsg/PopupMsg';
import Button from '@mui/material/Button';
// import TempPopupMenu from "../TempPopupMenu/TempPopupMenu";

function Footer({pageChange, children, handleContextMenu, handleCartClick}) {
    console.log('Render Footer');

    const isMsgOpen = React.useRef(false);
    const {showPopupMsg} = usePopupMsgContext();

    // const handleAddToCart = () => {
    //     isMsgOpen.current = !isMsgOpen.current;
    //     showPopupMsg({
    //         show: isMsgOpen.current,
    //         title: 'A great title',
    //         msg: 'Put your message here',
    //     });
    // }


    return (
        <>
            <div className='footer' onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
                <img src="/images/try-on-logo.png" alt="Try On logo" />
                {/* <span className="tryonLabel">SelfTryOn</span> */}
                <AppName />
                <Pagination className="pagination" count={5} size="small" onChange={pageChange} color={"primary"} />
                <Button className="addToCart buyColor" variant="contained" endIcon={<BsCart3 />} onClick={handleCartClick} disableRipple><span className="text">+ Add to cart</span></Button>
                
                 
                {/* <BsCart4 /> */}
                {children}
            </div>
            {/* <PopupMsg title='Title' left={200} top={150} msg='A great message here!!!' show={isMsgOpen} /> */}
        </>
    );
};

export default React.memo(Footer);