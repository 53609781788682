import React from "react";
import { BsCameraFill, BsFillHeartFill } from "react-icons/bs";
import ClickableContainer from "../ClickableContainer/ClickableContainer";
import Share from "../Share/Share";
import Filters from "../Filters/Filters";

const MainOptions = ({ searchClick, isCameraOn, cameraOnClick, getInfoToShare }) => {
    return (
        <div id="mainGroup" className="mainOptions">
            {/* <div className="mainOption">
                <BsSearch />
                <ClickableContainer onclick={() => {searchClick()} } />
            </div> */}
            <Filters />
            <Share getInfoToShare={getInfoToShare} />
            <div className={`mainOption ${!isCameraOn ? 'animate__animated animate__pulse animate__infinite' : ''}`}>
                <BsCameraFill />
                <ClickableContainer onclick={() => { cameraOnClick(false) }} />
            </div>
            <div className="mainOption">
                <BsFillHeartFill id="favMainOptionIcon" />
                <ClickableContainer />
            </div>
        </div>
    );
}

export default React.memo(MainOptions);