import React from "react";
import ClickableContainer from "../ClickableContainer/ClickableContainer";
import { FaMale, FaFemale } from "react-icons/fa";

const Genders = {
    Male: 0,
    Female: 1
};

const GenderOption = React.memo(({ gender, onclick }) => {
    return (
        <div id="genderGroup" className="mainOptions">
            <div className={`mainOption ${(gender === Genders.Male) ? "selected" : ""}`}>
                <FaMale />
                <ClickableContainer onclick={() => { onclick(Genders.Male) }} />
            </div>
            <div className={`mainOption ${(gender === Genders.Female) ? "selected" : ""}`}>
                <FaFemale />
                <ClickableContainer onclick={() => { onclick(Genders.Female) }} />
            </div>
        </div>
    );
});

export { Genders, GenderOption };