import React from 'react';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';

export const PopupMsgContext = React.createContext();

const PopupMsgProvider = ({ value, children }) => {
    return (
        <PopupMsgContext.Provider value={value}>
            {children}
        </PopupMsgContext.Provider>
    );
};

const usePopupMsgContext = () => React.useContext(PopupMsgContext);

const PopupMsg = React.memo((props) => {

    const isCentered = (props.left === undefined) && (props.top === undefined) && (props.right === undefined) && (props.bottom === undefined);

    function RealPopupMsg(props) {

        const customStyle = {};
        if (props.translate) {
            customStyle.transform = 'translate(-50%, -50%)';
        }
        if (props.left) {
            customStyle.left = props.left;
        }
        if (props.top) {
            customStyle.top = props.top;
        }

        return (
            // <Box className='popupMsg smoothBorder'>
                <div className='popupMsg smoothBorder' style={customStyle}>
                    <span>{props.title}</span>
                    <hr className='lineHorSeparator w100pc' />
                    <span>{props.msg}</span>
                </div>
            // </Box>
        );

    }

    let content;
    if (isCentered) {
        // content = React.forwardRef((props, ref) =>{
        //     return (
        //         <CenterPopupMsg ref={ref}>
        //             <RealPopupMsg translate={true} {...props} />
        //         </CenterPopupMsg>
        //     );
        // });

        // content = (
        //     <CenterPopupMsg>
        //         <RealPopupMsg translate={true} {...props} />
        //     </CenterPopupMsg>
        // );

        content = (
            <Box sx={{position: 'absolute', left: '50%', top: '50%'}}>
                <RealPopupMsg translate={true} {...props} />
            </Box>
        );
    } else {
        content = (
            <Box sx={{position: 'absolute', left: 0, top: 0}}>
                <RealPopupMsg {...props} />
            </Box>
        );
    }

    return (
        <Slide direction='down' in={props.show} mountOnEnter unmountOnExit timeout={500} onEntered={props.afterEnterCallback} >
            {content}
        </Slide>
    )
});

// export default React.memo(PopupMsg);
// export {React.memo(PopupMsg) as default};
// const def = React.memo(PopupMsg);
export {PopupMsg as default, PopupMsgProvider, usePopupMsgContext};