var ResponseMock = { // eslint-disable-line no-unused-vars
    body: {
        products: [
            {
                id: 100,
                name: "Camicia Quadro A",
                brand: {
                    id: 99,
                    name: "Primitive",
                    logo: ""
                },
                categories: [
                    [
                        {
                            id: 1,
                            "name": "man"
                        },
                        {
                            id: 10,
                            "name": "Abbligliamento"
                        },
                        {
                            id: 11,
                            "name": "Camicia"
                        },
                    ]
                ],
                variations: [
                    {
                        id: 1000,
                        name: "blue",
                        price: 23,
                        discount: 0,
                        previewFront: "products/CamiciaQuadroA/variations/blue/CamiciaQuadroA_Blue.png",
                        file3D: "products/CamiciaQuadroA/variations/blue/CamiciaQuadroA_Blue.glb",
                    },
                    {
                        id: 1001,
                        name: "brown",
                        price: 23,
                        discount: 0,
                        previewFront: "products/CamiciaQuadroA/variations/brown/CamiciaQuadroA_Brown.png",
                        file3D: "products/CamiciaQuadroA/variations/brown/CamiciaQuadroA_Brown.glb",
                    },
                    {
                        id: 1002,
                        name: "red",
                        price: 23,
                        discount: 25,
                        previewFront: "products/CamiciaQuadroA/variations/red/CamiciaQuadroA_Red.png"
                    },
                    {
                        id: 1003,
                        name: "white",
                        price: 23,
                        discount: 0,
                        previewFront: "products/CamiciaQuadroA/variations/white/CamiciaQuadroA_White.png"
                    },
                    {
                        id: 1004,
                        name: "yellow",
                        price: 23,
                        discount: 0,
                        previewFront: "products/CamiciaQuadroA/variations/yellow/CamiciaQuadroA_Yellow.png"
                    },
                    {
                        id: 1005,
                        name: "brown",
                        price: 23,
                        discount: 0,
                        previewFront: "products/CamiciaQuadroA/variations/brown/CamiciaQuadroA_Brown.png",
                    },
                    {
                        id: 1006,
                        name: "red",
                        price: 23,
                        discount: 25,
                        previewFront: "products/CamiciaQuadroA/variations/red/CamiciaQuadroA_Red.png"
                    },
                    {
                        id: 1007,
                        name: "white",
                        price: 23,
                        discount: 0,
                        previewFront: "products/CamiciaQuadroA/variations/white/CamiciaQuadroA_White.png"
                    },
                ]
            },
            {
                id: 101,
                name: "Cappotto A",
                brand: {
                    id: 98,
                    name: "Timberland",
                    logo: ""
                },
                categories: [
                    [
                        {
                            id: 1,
                            "name": "man"
                        },
                        {
                            id: 10,
                            "name": "Abbligliamento"
                        },
                        {
                            id: 12,
                            "name": "Cappotto"
                        },
                    ]
                ],
                variations: [
                    {
                        id: 2004,
                        name: "mustard",
                        price: 23,
                        discount: 0,
                        previewFront: "products/CappottoA/variations/mustard/CappottoA_Mustard.png",
                        file3D: "products/CappottoA/variations/mustard/CappottoA_Mustard.glb",
                    }
                ]
            },
            {
                id: 102,
                name: "Cappotto Elegante A",
                brand: {
                    id: 97,
                    name: "Tommy Hilfiger",
                    logo: ""
                },
                variations: [
                    {
                        id: 1005,
                        name: "grey",
                        price: 23,
                        discount: 0,
                        previewFront: "products/CappottoEleganteA/variations/grey/CappottoEleganteA_Grey.png"
                    }
                ]
            },
            {
                id: 103,
                name: "Cappotto Elegante B",
                brand: {
                    id: 96,
                    name: "PierOne",
                    logo: ""
                },
                variations: [
                    {
                        id: 1006,
                        name: "yellow",
                        price: 23,
                        discount: 0,
                        previewFront: "products/CappottoEleganteB/variations/yellow/CappottoEleganteB_Yellow.png"
                    }
                ]
            },
            {
                id: 104,
                name: "Felpa A",
                brand: {
                    id: 97,
                    name: "Tommy Hilfiger",
                    logo: ""
                },
                variations: [
                    {
                        id: 1007,
                        name: "blue",
                        price: 23,
                        discount: 0,
                        previewFront: "products/FelpaA/variations/blue/FelpaA_Blue.png"
                    }
                ]
            },
            {
                id: 105,
                name: "Giacca A",
                brand: {
                    id: 97,
                    name: "Tommy Hilfiger",
                    logo: ""
                },
                variations: [
                    {
                        id: 1008,
                        name: "blue",
                        price: 23,
                        discount: 0,
                        previewFront: "products/GiaccaA/variations/blue/GiaccaA_Blue.png"
                    }
                ]
            },
            {
                id: 106,
                name: "Jean A",
                brand: {
                    id: 97,
                    name: "Tommy Hilfiger",
                    logo: ""
                },
                categories: [
                    [
                        {
                            id: 1,
                            "name": "man"
                        },
                        {
                            id: 10,
                            "name": "Abbligliamento"
                        },
                        {
                            id: 110,
                            "name": "Pantalone"
                        },
                    ]
                ],
                variations: [
                    {
                        id: 1009,
                        name: "blue",
                        price: 23,
                        discount: 0,
                        previewFront: "products/JeanA/variations/blue/JeanA_Blue.png",
                        file3D: "products/JeanA/variations/blue/Pantalone.glb",
                    }
                ]
            },
            {
                id: 107,
                name: "Pantalone Cargo A",
                brand: {
                    id: 97,
                    name: "Tommy Hilfiger",
                    logo: ""
                },
                variations: [
                    {
                        id: 1010,
                        name: "green",
                        price: 23,
                        discount: 0,
                        previewFront: "products/PantaloneCargoA/variations/green/PantaloneCargoA_Green.png"
                    }
                ]
            },
            {
                id: 108,
                name: "Pantalone Cargo B",
                brand: {
                    id: 97,
                    name: "Tommy Hilfiger",
                    logo: ""
                },
                variations: [
                    {
                        id: 1011,
                        name: "green",
                        price: 23,
                        discount: 0,
                        previewFront: "products/PantaloneCargoA/variations/green/PantaloneCargoA_Green.png"
                    }
                ]
            },
            {
                id: 109,
                name: "Pantalone Cargo B",
                brand: {
                    id: 97,
                    name: "Tommy Hilfiger",
                    logo: ""
                },
                variations: [
                    {
                        id: 1012,
                        name: "green",
                        price: 23,
                        discount: 0,
                        previewFront: "products/PantaloneCargoA/variations/green/PantaloneCargoA_Green.png"
                    }
                ]
            },
            {
                id: 110,
                name: "Pantalone Cargo B",
                brand: {
                    id: 97,
                    name: "Tommy Hilfiger",
                    logo: ""
                },
                variations: [
                    {
                        id: 1013,
                        name: "green",
                        price: 23,
                        discount: 0,
                        previewFront: "products/PantaloneCargoA/variations/green/PantaloneCargoA_Green.png"
                    }
                ]
            },
            {
                id: 111,
                name: "Pantalone Cargo B",
                brand: {
                    id: 97,
                    name: "Tommy Hilfiger",
                    logo: ""
                },
                variations: [
                    {
                        id: 1014,
                        name: "green",
                        price: 23,
                        discount: 0,
                        previewFront: "products/PantaloneCargoA/variations/green/PantaloneCargoA_Green.png"
                    }
                ]
            },
            {
                id: 112,
                name: "Pantalone Cargo B",
                brand: {
                    id: 97,
                    name: "Tommy Hilfiger",
                    logo: ""
                },
                variations: [
                    {
                        id: 1015,
                        name: "green",
                        price: 23,
                        discount: 0,
                        previewFront: "products/PantaloneCargoA/variations/green/PantaloneCargoA_Green.png"
                    }
                ]
            },
            {
                id: 113,
                name: "Pantalone Cargo B",
                brand: {
                    id: 97,
                    name: "Tommy Hilfiger",
                    logo: ""
                },
                variations: [
                    {
                        id: 1016,
                        name: "green",
                        price: 23,
                        discount: 0,
                        previewFront: "products/PantaloneCargoA/variations/green/PantaloneCargoA_Green.png"
                    }
                ]
            },
            {
                id: 114,
                name: "Pantalone Cargo B",
                brand: {
                    id: 97,
                    name: "Tommy Hilfiger",
                    logo: ""
                },
                variations: [
                    {
                        id: 1017,
                        name: "green",
                        price: 23,
                        discount: 0,
                        previewFront: "products/PantaloneCargoA/variations/green/PantaloneCargoA_Green.png"
                    }
                ]
            },
            {
                id: 115,
                name: "Pantalone Cargo B",
                brand: {
                    id: 97,
                    name: "Tommy Hilfiger",
                    logo: ""
                },
                variations: [
                    {
                        id: 1018,
                        name: "green",
                        price: 23,
                        discount: 0,
                        previewFront: "products/PantaloneCargoA/variations/green/PantaloneCargoA_Green.png"
                    }
                ]
            },
            {
                id: 116,
                name: "Pantalone Cargo B",
                brand: {
                    id: 97,
                    name: "Tommy Hilfiger",
                    logo: ""
                },
                variations: [
                    {
                        id: 1019,
                        name: "green",
                        price: 23,
                        discount: 0,
                        previewFront: "products/PantaloneCargoA/variations/green/PantaloneCargoA_Green.png"
                    }
                ]
            },
        ]
    }
}

const TryOnAPI = {
    products: {
        get: async () => {
            // return [];
            // const products = await fetch('/pindex.json').then(response => response.json);
            // return ResponseMock.body.products;
            return fetch('/pindex.json').then(response => response.json()).then(products => {
                if (Array.isArray(products)) {
                    products.forEach(product => {
                        product.variations.forEach(variation => {
                            variation.parent = product;
                            variation.discountPrice = function() {
                                return this.price - this.price * this.discount / 100;
                            }
                        })

                    })
                }
                return products;
            });
        }
    }
}

export default TryOnAPI;