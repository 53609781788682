/*
    -GLB convertito dal FBX esportato da Reallusion
        -Leggere da THREEJS ed esportarlo GLB (crea tante mesh nel body )
            (invece di lasciare 1 unica mesh ed usare gli slot per gestire i gruppi di materiale , funziona solo come FBX ma poi non rimane indicizzato...) 
        -Poi da Blender vedere i vertici della mesh relativa alla testa...
*/
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as ThreeManUtility from './ThreeManUtility.js';
import * as MediaPipeUtility from './MediaPipeUtility.js';
import * as SkinningUtility from './SkinningUtility.js';
import * as Segmentation from '../components/Tfjs-Webgl/face_segmentation/face_segmentation.js';
import {usePopupMsgContext} from '../components/PopupMsg/PopupMsg';  //Popup info age....
import { enableDebugMode, IFFT } from '@tensorflow/tfjs';
import { Box2, BoxBufferGeometry, Vector2 } from 'three';

const idxMentoMesh=3887;        //3878 , 46;     //Vertice mento nella faccia
const idxFaceLeft =1259       //1140, 1240 , 1242 , 1243 , 1246 , 1247 , 1249 , 1259 , 319 ;      //Vertice faccia left
const idxFaceRight=3426       //3299, 3407 , 3409 , 3408 , 3413 , 3412 , 3417 , 3426 , 2429;     //Vertice faccia right
const idxFaceBottom=idxMentoMesh;    //Vertice faccia basso
const idxFaceTop=41         //2123 ,  41 , 51 , 50;        //Vertice faccia alto
const idxFaceCenter=52;    //Vertice faccia considerato come centro
//Occhi
const idxEyeLeft_InternalSide=2933;
const idxEyeLeft_ExternalSide=3193;
const idxEyeRight_InternalSide=794;
const idxEyeRight_ExternalSide=1035;
//Bocca
const idxBoccaLeft= 3143;
const idxBoccaRight=886;
//Naso 
const idxNasoPunta=1703;
const idxNasoInizio=47;     //Combacia con centro dei 2 occhi

function idxFaceBoundRect(modelHead){
    return(new THREE.Box2(new THREE.Vector2(getUV(modelHead,idxFaceLeft,false).x,getUV(modelHead,idxFaceTop,false).y),new THREE.Vector2(getUV(modelHead,idxFaceRight,false).x,getUV(modelHead,idxFaceBottom,false).y)))
}

function idxFaceBoundRectBox3d(modelHead){
    return(new THREE.Box2(new THREE.Vector2(new THREE.Vector3().fromBufferAttribute(modelHead.geometry.attributes.position,idxFaceLeft).x,new THREE.Vector3().fromBufferAttribute(modelHead.geometry.attributes.position,idxFaceBottom).y),new THREE.Vector2(new THREE.Vector3().fromBufferAttribute(modelHead.geometry.attributes.position,idxFaceRight).x,new THREE.Vector3().fromBufferAttribute(modelHead.geometry.attributes.position,idxFaceTop).y)))
}


function loaderMesh(urlfName, checkParam, callback) {   //Loader specifico per Reallusion , Controlla parametri leggendo modello ...
    const loader = new GLTFLoader();
    loader.load(urlfName, (gltf) => {
        var object = gltf.scene;
        object.animations=gltf.animations;
        if(checkParam){
            object.traverse((child) => {
                if (child.isMesh){
                    if(!isHair(child)) {
                        child.material.depthWrite=true; //A volte questo parametro è sbagliato...
                    }else{  //Prova a sistemare i capelli da donna...
/*                   
                        //child.material.map=ThreeManUtility.filterAlphaScale(child.material.map,1.2);
                        child.material.premultipliedAlpha=true;
                    //    child.material.alphaToCoverage=true;    //Risultato leggermente migliore
                        child.material.map.flipY=false;
                        child.material.map.encoding=THREE.sRGBEncoding; 

                        child.material.depthWrite=true;    
                        child.material.alphaTest=0.18;
                        child.material.transparent=false;
                        */
                    }
                    child.material.format = THREE.RGBAFormat;   //Altrimenti a volte non funziona bene opacità....per sfumare durante il cambio di prodotto...se animato...
                }
            });
        }
        if (callback) callback(object);
    });
}


function getMeshHead(obj){ //Dal gruppo ritorna la mesh corrispondente alla testa del modello
    let r;
    obj.traverse( function ( child ) {
        if(child.isMesh){    
            if(child.material){
                if(child.material){
                    if(child.material.name.indexOf('Std_Skin_Head')>-1 && child.geometry.attributes.position.count===4354){
                        r=child;
                    }
                }
            }   
        }
    });
    return(r);
}

function getUV(modelHead,idx,normalize){    //Dal vertice ritorna la posizione nel texture
    let uv2d=new THREE.Vector2().fromBufferAttribute(modelHead.geometry.attributes.uv,idx);
    if(!normalize){uv2d.multiply(new THREE.Vector2(modelHead.material.map.image.width,modelHead.material.map.image.height))}
    return(uv2d);
}

function getUVMiddle(modelHead,idx,idx1,normalize){    //Dal vertice ritorna la posizione nel texture a metà
    let p= getUV(modelHead,idx,normalize);
    let p1= getUV(modelHead,idx1,normalize);
    let pres=new THREE.Vector2;
    pres.x =p.x- ((p.x - p1.x)/2);
    pres.y =p.y- ((p.y - p1.y)/2);
    return(pres)
}

function isHair(child){
    return(child.isMesh && (child.name.indexOf('Hair')>-1 || child.name.indexOf('Messy')>-1 || 
                            child.name.indexOf('hair')>-1 || child.name.indexOf('messy')>-1 ||
                            //child.name.indexOf('Air')>-1    ||    Toglie anche le scarpe
                            child.name.indexOf('Half_up')>-1    ||
                            child.name.indexOf('Space_bun')>-1
                            ))
}

function visibleStandardHair(modelMesh,BolVisible){
    modelMesh.traverse((child) => {
        if (isHair(child)) {
            child.visible=BolVisible;
        }
    })
}

function setfaceGlbMeshOverModel(modelMesh, faceGlbMesh,scene){     //Posiziona maschera sopra alla faccia del modello
    if(faceGlbMesh){

        //Posiziona e ridimensiona la mesh GLB fissa che si userà come riferimento
        faceGlbMesh.updateMatrixWorld(true);
        //let modelPtWord=ThreeManUtility.GetWordVertexCoord(modelMesh, idxMentoMesh);
        let modelPtWord=SkinningUtility.transformSkinningPosition(modelMesh, idxMentoMesh);
        let faceGlbPtWord=ThreeManUtility.GetWordVertexCoord(faceGlbMesh, MediaPipeUtility.idxMentoMesh);

        //faceGlbMesh.position.set(modelPtWord.x-faceGlbPtWord.x+0,(modelPtWord.y-faceGlbPtWord.y)+1.9,(modelPtWord.z-faceGlbPtWord.z)+2.4);
        faceGlbMesh.position.set(modelPtWord.x-faceGlbPtWord.x+0,(modelPtWord.y-faceGlbPtWord.y),(modelPtWord.z-faceGlbPtWord.z+0));

        //let sphere = new THREE.Mesh( new THREE.SphereGeometry( 0.2, 5, 5 ), new THREE.MeshBasicMaterial( { color: 0xff00ff } ) );    
        //sphere.position.set(modelPtWord.x,modelPtWord.y,modelPtWord.z);
        //scene.add( sphere );     

        faceGlbMesh.updateMatrixWorld(true);
        //faceGlbMesh.material.wireframe=true;
        return(faceGlbMesh);
    }

}


function imageSegmentationToHairPointCloud(keyPts, scene, segmCnv, modelHead) {    //Da segmentazione capelli crea pointcloud
    let scale3D=1;
    //Create a container for the THREE.PointCloud particles
    var geometry = new THREE.BufferGeometry();
    //Create the THREE.PointCloud texture
    var material = new THREE.PointCloudMaterial({size:0.1, vertexColors:true, transparent:true, depthTest:true});   //, transparent:true, depthWrite:false , color:0xffffff
    material.opacity=1;
    var positions = [];
    var colors = [];
    
    //Canvas immagine da camera
    const cvCamera = MediaPipeUtility.filterMask(keyPts, segmCnv);
    const cameraCtx = cvCamera.getContext('2d');
    const cameraData = cameraCtx.getImageData(0, 0, cvCamera.width, cvCamera.height).data;
    
    //Canvas segmentazione capelli
    const segmCtx = segmCnv.getContext('2d');
    const segmentationData = segmCtx.getImageData(0, 0, segmCnv.width, segmCnv.height).data;

    //ThreeManUtility.downloadCanvas(cvCamera)

    //Loop if hair from segmentattion adds the color and position of particles to the mesh
    let idx=0;
    let pix=[]
    let z=-1;//new THREE.Vector3().fromBufferAttribute(modelHead.geometry.attributes.position,idxFaceTop).z;
    for (var y = 0; y <segmCnv.height; y++) {
        for (var x = 0; x <segmCnv.width; x++) {
            let color=[segmentationData[idx+0],segmentationData[idx+1],segmentationData[idx+2],255];
            if(color.join()===Segmentation.segmColors.hair.join() || color.join()===Segmentation.segmColors.leftEar.join()  || color.join()===Segmentation.segmColors.rightEar.join()){
                colors.push(cameraData[idx+0]/255,cameraData[idx+1]/255,cameraData[idx+2]/255);
                positions.push(x*scale3D,y*-scale3D,z);
            }
            idx+=4;
        }
    }

    if(positions.length>0){
        geometry.setAttribute("position", new THREE.Float32BufferAttribute(positions, 3) );
        geometry.setAttribute("color",  new THREE.Float32BufferAttribute(colors, 3) );
        geometry.computeBoundingBox();
        var cloud = new THREE.PointCloud(geometry, material);
        //modelHead.attach(cloud);
        //scene.add(cloud)
        return(cloud);
    }
}

function imageSegmentationToHair(keyPts, scene, segmCnv, modelHead) {  //Da segmentazione capelli crea la mesh
    //Canvas immagine da camera
    const cvCamera = MediaPipeUtility.filterMask(keyPts, segmCnv);
    const cameraCtx = cvCamera.getContext('2d');
    const cameraData = cameraCtx.getImageData(0, 0, cvCamera.width, cvCamera.height);
    
    //Canvas segmentazione capelli
    const segmCtx = segmCnv.getContext('2d');
    const segmentationData = segmCtx.getImageData(0, 0, segmCnv.width, segmCnv.height);

    //ThreeManUtility.downloadCanvas(segmCnv)

    //Canvas result
    const cnv = document.createElement('canvas');
    cnv.width = cvCamera.width;
    cnv.height= cvCamera.height;
    const cnvCtx = cnv.getContext('2d');
    const cnvData=cnvCtx.getImageData(0, 0, cnv.width, cnv.height)

    let idx=0;
    //new THREE.Vector3().fromBufferAttribute(modelHead.geometry.attributes.position,idxFaceTop).z;
    for (var y = 0; y <segmCnv.height; y++) {
        for (var x = 0; x <segmCnv.width; x++) {
            let color=[segmentationData.data[idx+0],segmentationData.data[idx+1],segmentationData.data[idx+2],255];
            //if(color.join()===Segmentation.segmColors.hair.join() || color.join()===Segmentation.segmColors.leftEar.join()  || color.join()===Segmentation.segmColors.rightEar.join()){
            if(color.join()===Segmentation.segmColors.hair.join() ){
                cnvData.data[idx+0]=cameraData.data[idx+0];
                cnvData.data[idx+1]=cameraData.data[idx+1];
                cnvData.data[idx+2]=cameraData.data[idx+2];
                cnvData.data[idx+3]=cameraData.data[idx+3];   
                //cnvData.data[idx+3]=segmentationData.data[idx+3];   //Prende sfumatura da segmentazione
            }
            idx+=4;
        }
    }
    cnvCtx.putImageData(cnvData, 0, 0, 0, 0, cnvData.width, cnvData.height);

    ThreeManUtility.filterAlphaBorder(cnv,10);//Crea sfumatura sul bordo dei capelli

    let material = new THREE.MeshBasicMaterial({
           //metalness:0,      
           // roughness:1,   
            transparent: true,
            //wireframe:true,
            side:2,
            //depthTest:false,
            map:new THREE.CanvasTexture( cnv )
    });
    material.map.encoding=THREE.sRGBEncoding;
    const geometry = new THREE.PlaneGeometry( cvCamera.width, cvCamera.height, 1,1);//cvCamera.width,cvCamera.height );
    let mesh = new THREE.Mesh( geometry, material );
    mesh.position.set(cvCamera.width/2,-(cvCamera.height/2),0+60)
    mesh.frustumCulled=false;

    return(mesh);
}

function segmentationFilterMask(segmCnv,keyPts) {  //Da segmentazione capelli ritorna maschera con solo capelli e faccia
    //Canvas result
    const cnv = document.createElement('canvas');
    cnv.width = segmCnv.width;
    cnv.height= segmCnv.height;
    const cnvCtx = cnv.getContext('2d');
    const cnvData=cnvCtx.getImageData(0, 0, cnv.width, cnv.height)
    
    //Canvas segmentazione capelli
    const segmCtx = segmCnv.getContext('2d');
    const segmentationData = segmCtx.getImageData(0, 0, segmCnv.width, segmCnv.height);

    let ptSideMento=MediaPipeUtility.getUV(keyPts,MediaPipeUtility.idxSideMento);  //Posizione sotto l'orecchio inizio mento

    let idx=0;
    for (var y = 0; y <segmCnv.height; y++) {
        let marginHair=new THREE.Box2();
        for (var x = 0; x <segmCnv.width; x++) {
            let color=[segmentationData.data[idx+0],segmentationData.data[idx+1],segmentationData.data[idx+2],255];
            
            if(color.join()===Segmentation.segmColors.hair.join() ){    //color.join()===Segmentation.segmColors.skin.join()
                    cnvData.data[idx+0]=segmentationData.data[idx+0];
                    cnvData.data[idx+1]=segmentationData.data[idx+1];
                    cnvData.data[idx+2]=segmentationData.data[idx+2];
                    cnvData.data[idx+3]=segmentationData.data[idx+3];   
                    marginHair.expandByPoint(new Vector2(x,y));
            }
            idx+=4;
        }
        //Collega estremi delle coordinate dei capelli nella zona dove trova la faccia
        if(marginHair.min.x !==Infinity){
            if(y <ptSideMento.y ){ //Sotto l'orecchio non considera colore rosso faccia
                idx=idx-(segmCnv.width*4);
                for (var x = marginHair.min.x; x <=marginHair.max.x; x++) {
                    let color=[segmentationData.data[idx+0],segmentationData.data[idx+1],segmentationData.data[idx+2],255];
                    if(true || color.join()===Segmentation.segmColors.skin.join()){
                        cnvData.data[idx+0]=segmentationData.data[idx+0];
                        cnvData.data[idx+1]=segmentationData.data[idx+1];
                        cnvData.data[idx+2]=segmentationData.data[idx+2];
                        cnvData.data[idx+3]=segmentationData.data[idx+3];   
                    }
                    idx+=4;
                }
            }
        }

    }
    cnvCtx.putImageData(cnvData, 0, 0, 0, 0, cnvData.width, cnvData.height);
    return cnv;
}

function smoothPolygonMask(ctx, points, distance) {     //Disegna maschera poligono con sfumatura nel bordo esterno
    const minStep = 2;    
    const maxStep = 5;
    if (distance < minStep) return;

    //For debug
    // const colors = ['black', 'red', 'green', 'blue', 'pink', 'yellow', 'cyan', 'magenta'];
    // let colIdx = 0;
    
    //#region Auxiliary function to draw polygon on canvas
    function drawPolygon(aPolygon, opacity) {
        const posAttr = aPolygon.getAttribute('position');
        
        ctx.beginPath();
        // ctx.fillStyle = colors[colIdx % colors.length];
        ctx.fillStyle = `rgba(255, 0, 0, ${opacity})`;
        ctx.moveTo(posAttr.array[0], posAttr.array[1]);
        for(let i = 1; i < posAttr.count; i++) {
            ctx.lineTo(posAttr.array[i * 3], posAttr.array[i * 3 + 1]);
        }
        ctx.closePath();
        ctx.fill();
        // colIdx++;
    }
    //#endregion

    //#region Create polygon geometry from points
    const polygon = new THREE.BufferGeometry();
    polygon.setFromPoints(points);
    polygon.computeBoundingBox();
    const center = new THREE.Vector3();
    polygon.boundingBox.getCenter(center);
    //#endregion
    
    //#region Calculate algorithm parameters
    let step = Math.trunc(distance / 10); //10%
    step = Math.max(step, minStep);
    step = Math.min(step, maxStep);
    const count = Math.trunc(distance / step); 
    const alphaStep = 255 / count;
    //#endregion
    
    //#region Reduce polygon loop
    let scale, offset, opac;
    const tmpCenter = new THREE.Vector3();
    ctx.save();
    for (let i = 1; i <= count; i++) {
        opac = alphaStep / (255 - alphaStep * (i - 1));
        // drawPolygon(polygon, opac * i);
        drawPolygon(polygon, opac);

        //Calculate scale on each iteration
        polygon.computeBoundingBox();
        const currBox = polygon.boundingBox;
        const newBox = currBox.clone().expandByScalar(-(step + 1));
        scale = (newBox.max.x - newBox.min.x) / (currBox.max.x - currBox.min.x);

        //Scale and center
        polygon.scale(scale, scale, scale);
        polygon.computeBoundingBox();
        polygon.boundingBox.getCenter(tmpCenter);
        offset = center.clone().sub(tmpCenter);
        polygon.translate(offset.x, offset.y, offset.z);
    }
    ctx.restore();
    //#endregion
    
    // ThreeManUtility.downloadCanvas(ctx.canvas);
}

function maskSmootFace(cvFace,smootSize,ptLandmark){     //Maschera la faccia per agevolare morph con texture modello
    let maskCanvas = document.createElement('canvas');
    maskCanvas.width  = cvFace.width;
    maskCanvas.height = cvFace.height;
    let maskCtx = maskCanvas.getContext('2d');
    let CtxFace = cvFace.getContext('2d');
    CtxFace.drawImage(cvFace, 0, 0);

    let flatUVFacePointsStrech=ptLandmark; 

    //Crea maschera solo nel poligono interno della faccia 
    let innerCirc=MediaPipeUtility.faceBoundaryInnerLR();

    let br=new THREE.Box2();
    let pp=[];
    for(var i=0; i<innerCirc.length; i++){
        pp.push(new THREE.Vector2(flatUVFacePointsStrech[innerCirc[i]].x* cvFace.width,flatUVFacePointsStrech[innerCirc[i]].y* cvFace.height));
        br.expandByPoint(pp[pp.length-1])
    }

    smoothPolygonMask(maskCtx, pp,(br.max.x-br.min.x)*smootSize);

    let rslCanvas = document.createElement('canvas');
    rslCanvas.width  = cvFace.width;
    rslCanvas.height = cvFace.height;
    let rslCtx = rslCanvas.getContext('2d');
    rslCtx.save();
    rslCtx.drawImage(maskCanvas, 0, 0, rslCanvas.width, rslCanvas.height, 0, 0, rslCanvas.width, rslCanvas.height);
    
    rslCtx.globalCompositeOperation = 'source-in';
    rslCtx.drawImage(cvFace, 0, 0, rslCanvas.width, rslCanvas.height, 0, 0, rslCanvas.width, rslCanvas.height);
    rslCtx.restore();
    return(rslCanvas);
}

function maskSmootFace_Mesh(faceGlbMesh,smootSize,ptLandmark){     //Maschera la faccia per agevolare morph con texture modello
    ThreeManUtility.getSkinnedMesh(faceGlbMesh).material.map=new THREE.CanvasTexture(maskSmootFace(ThreeManUtility.imgToCanvas(ThreeManUtility.getSkinnedMesh(faceGlbMesh).material.map.image),smootSize,ptLandmark))
    ThreeManUtility.getSkinnedMesh(faceGlbMesh).material.map.flipY=false;
    ThreeManUtility.getSkinnedMesh(faceGlbMesh).material.map.minFilter=THREE.LinearFilter
    ThreeManUtility.getSkinnedMesh(faceGlbMesh).material.map.encoding=THREE.sRGBEncoding;
    ThreeManUtility.getSkinnedMesh(faceGlbMesh).material.map.format=THREE.RGBAFormat
 }

function copyCanvasToCtx(canvasSorg,ctx,areaSorg,areaDest){
    ctx.drawImage(canvasSorg, areaSorg.min.x, areaSorg.min.y, areaSorg.max.x-areaSorg.min.x, areaSorg.max.y-areaSorg.min.y, areaDest.min.x, areaDest.min.y, areaDest.max.x-areaDest.min.x, areaDest.max.y-areaDest.min.y);
}

function cloneMaterialFromMesh(Mesh){
    let MeshMat=ThreeManUtility.getSkinnedMesh(Mesh).material.clone();
    let material = new THREE.MeshStandardMaterial({
            metalness:MeshMat.metalness,      
            roughness:MeshMat.roughness,   //aumentando si scurisce...
            transparent: true,
            //wireframe:true,
            side:2,
            //depthTest:false,
    });
    return(material);
}

function mergeMPipeMeshToModel(keyPts, modelMesh, faceGlbMesh, scene, segmentazioneCapelli, getAgeGender){
    if(!modelMesh)return;
    
    // if (keyPts.image) {
    //     const tmpCnv = ThreeManUtility.imgToCanvas(keyPts.image);
    //     ThreeManUtility.applyRGBToImg(214, 166, 141, 0, tmpCnv);
    //     keyPts.image = createImageBitmap(tmpCnv);
    // }

    let modelHead=getMeshHead(modelMesh);   //Dal modello ricava la mesh relativa alla parte della testa
    let faceGlbGeom=ThreeManUtility.getSkinnedMesh(faceGlbMesh).geometry;
    let hairFaceMesh;
    let matCanvas;
    ThreeManUtility.getSkinnedMesh(faceGlbMesh).material=cloneMaterialFromMesh(modelHead);
    ThreeManUtility.getSkinnedMesh(faceGlbMesh).material.depthTest=false;
    ThreeManUtility.getSkinnedMesh(faceGlbMesh).material.side=0;    //Front perchè quando ruota con depthTest funziona meglio
    ThreeManUtility.getSkinnedMesh(faceGlbMesh).material.roughness=1;//Altrimenti si vede un alone con la luce...
    if(modelHead){

        if(keyPts){
            matCanvas   = ThreeManUtility.imgToCanvas(modelHead.material.map.image);    //Crea canvas e context del materiale del modello
            modelHead.material.map= new THREE.CanvasTexture( matCanvas )
            modelHead.material.map.encoding=THREE.sRGBEncoding; 
            modelHead.material.map.flipY=false;
            //modelHead.material.map.magFilter=THREE.NearestFilter;//Provare !!!! Sembra che non cambia niente...
            //modelHead.material.map.minFilter=THREE.NearestFilter;
            let matCtx      = matCanvas.getContext('2d');
    
            let boxTextureModel    =idxFaceBoundRect(modelHead);    //Setta le coordinate della texture del modello per copiare la faccia
            let boxTextureModelW=boxTextureModel.max.x-boxTextureModel.min.x; let boxTextureModelH=boxTextureModel.max.y-boxTextureModel.min.y;  
    
            //Calcola scala 3d da usare
            let scale3D_X=(idxFaceBoundRectBox3d(modelHead).max.x-idxFaceBoundRectBox3d(modelHead).min.x) / (MediaPipeUtility.idxFaceBoundRect(keyPts).max.x-MediaPipeUtility.idxFaceBoundRect(keyPts).min.x)
            let scale3D_Y=(idxFaceBoundRectBox3d(modelHead).max.y-idxFaceBoundRectBox3d(modelHead).min.y) / (MediaPipeUtility.idxFaceBoundRect(keyPts).max.y-MediaPipeUtility.idxFaceBoundRect(keyPts).min.y)
            if(true){   
                if(idxFaceBoundRectBox3d(modelHead).max.x-idxFaceBoundRectBox3d(modelHead).min.x>14.68){
                    scale3D_X=scale3D_X*(scale3D_Y/scale3D_X);  // riscala guardando la proporzione fra x ed y
                    scale3D_X=scale3D_X*0.96;                   //Rimpicciolisce un pò se uomo
                    scale3D_Y=scale3D_X;                        //Mantiene la proporzione 
                }else{      //Se donna
                    scale3D_X=scale3D_X*(0.95+0.03);                   //Rimpicciolisce se donna
                    scale3D_Y=scale3D_Y*(0.90+0.03);    //+0.01 meglio se fede...                     
                }
            }
            //Compila UV texture nella faceGlbMesh , resiza e addrizza mesh se photo storta...
            MediaPipeUtility.MeshGLBApplyFromMPipe(ThreeManUtility.getSkinnedMesh(faceGlbMesh),keyPts, scale3D_X, scale3D_Y) 
            //Posizione faceGlbMesh sovrapponendola al modello (Anche la mesh dei capelli (hairFaceMesh) in automatico)
            setfaceGlbMeshOverModel(modelHead, ThreeManUtility.getSkinnedMesh(faceGlbMesh),scene);   
            //scene.add(faceGlbMesh)

            if(getAgeGender){   //La prima volta è un pò lento...
                Segmentation.SegMain(ThreeManUtility.imgToCanvas(keyPts.image)).then(	
                    function(ResSeg) {
                        if(ResSeg.ages.length>0)modelMesh.userData.ageGender=ResSeg.ages[0];    //Si ricorda dati età e gender per poterlo poi visualizzare
                    }
                );
            }

            if(segmentazioneCapelli){   //Se si vuole fare segmantazione capelli tensor-flow
                visibleStandardHair(modelMesh,false)    //Toglie o mette i capelli

                //Segmentazione per vedere i capelli
                Segmentation.SegMain(ThreeManUtility.FilterImgLightness(ThreeManUtility.imgToCanvas(keyPts.image), 5)).then(	//se si vuole mascherare il bkg ... ma sembra peggio MediaPipeUtility.filterMask(keyPts)
                        function(ResSeg) {
                            if(ResSeg.ages.length>0)modelMesh.userData.ageGender=ResSeg.ages[0];    //Si ricorda dati età e gender per poterlo poi visualizzare
                            
                            if(true){   //Se si vuole mettere i capelli con mesh intorno alla testa del modello (usa segmentazione)
                                //hairFaceMesh=imageSegmentationToHairPointCloud(keyPts,scene,ResSeg.canvasResult, modelHead);
                                hairFaceMesh=imageSegmentationToHair(keyPts,scene,ResSeg.canvasResult, modelHead);
                                //ThreeManUtility.downloadCanvas(ResSeg.canvasResult);
                                if(hairFaceMesh){
                                    //hairFaceMesh.updateMatrixWorld();
                                    //hairFaceMesh=SkinningUtility.makeSkinnedMeshBVH(modelHead,ThreeManUtility.getSkinnedMesh(hairFaceMesh), scene);
                                    faceGlbMesh.add(hairFaceMesh);      //Prende posizione scala e rotazione dalla faccia...
                                    faceGlbMesh.updateMatrixWorld();
                                }
                            }
                            
                            if(false){   //Se si vuole riportare fronte e parte dei capelli sulla texture del modello
                                let maskFromMediaPipe=MediaPipeUtility.filterMask(keyPts,segmentationFilterMask(ResSeg.canvasResult,keyPts)); //Maschera image da MPipe togliendo lo sfondo
                                //Debug
        /*                        let tmpCtx      = ResSeg.canvasResult.getContext('2d');
                                let PtsLand=keyPts.faceLandmarks;
                                for (let i = 0; i < PtsLand.length; i++){
                                    tmpCtx.beginPath();
                                    tmpCtx.ellipse(PtsLand[i].x*maskFromMediaPipe.width,PtsLand[i].y*maskFromMediaPipe.height, 4, 4, 0, 0, 2*Math.PI);
                                    tmpCtx.stroke();
                                    tmpCtx.closePath();
                                };    
                                ThreeManUtility.downloadCanvas(ResSeg.canvasResult);
        */
                                let boxTextureMediaPipe=MediaPipeUtility.idxFaceBoundRect(keyPts);
                                let boxTextureMediaPipeW=boxTextureMediaPipe.max.x-boxTextureMediaPipe.min.x; let boxTextureMediaPipeH=boxTextureMediaPipe.max.y-boxTextureMediaPipe.min.y;  
                                let scaleLeftRight=0.05;
                                let scaleTop=0.44
                                let box1=new Box2(new Vector2(boxTextureModel.min.x-(boxTextureModelW*scaleLeftRight),boxTextureModel.min.y-(boxTextureModelH*scaleTop)),new Vector2(boxTextureModel.max.x+(boxTextureModelW*scaleLeftRight),boxTextureModel.max.y+(boxTextureModelH*(scaleTop))));
                                let box2=new Box2(new Vector2(boxTextureMediaPipe.min.x-(boxTextureMediaPipeW*scaleLeftRight),boxTextureMediaPipe.min.y-(boxTextureMediaPipeH*scaleTop)),new Vector2(boxTextureMediaPipe.max.x+(boxTextureMediaPipeW*scaleLeftRight),boxTextureMediaPipe.max.y+(boxTextureMediaPipeH*(scaleTop))));
                /*                matCtx.beginPath();
                                matCtx.rect(boxTextureModel.min.x,boxTextureModel.min.y,boxTextureModelW,boxTextureModelH)
                                matCtx.rect(box1.min.x,box1.min.y,box1.max.x-box1.min.x,box1.max.y-box1.min.y);
                                matCtx.stroke();
                                matCtx.closePath();
                                let tmpCtx      = maskFromMediaPipe.getContext('2d');   //Debug
                                let stretch2DPts=keyPts.faceLandmarks;
                                for (let i = 0; i < stretch2DPts.length; i++){
                                    tmpCtx.beginPath();
                                    tmpCtx.ellipse(stretch2DPts[i].x*maskFromMediaPipe.width,stretch2DPts[i].y*maskFromMediaPipe.height, 4, 4, 0, 0, 2*Math.PI);
                                    tmpCtx.stroke();
                                    tmpCtx.closePath();
                                };    
                                tmpCtx.beginPath();
                                tmpCtx.rect(boxTextureMediaPipe.min.x,boxTextureMediaPipe.min.y,boxTextureMediaPipeW,boxTextureMediaPipeH)
                                tmpCtx.rect(box2.min.x,box2.min.y,box2.max.x-box2.min.x,box2.max.y-box2.min.y)
                                tmpCtx.stroke();
                                tmpCtx.closePath();
                                ThreeManUtility.downloadCanvas(maskFromMediaPipe);
                */
                                copyCanvasToCtx(maskFromMediaPipe,matCtx,box2,box1);
                                modelHead.material.needsUpdate=true;
                                modelHead.material.map.needsUpdate=true;
                                //ThreeManUtility.downloadCanvas(matCanvas);
                            }
                        },   
                );
                
            }
            
            if(false){   //Se si vuole riportare sulla faccia della texture del modello...(Non serve perchè poi sovrappone faceGlbMesh sopra...)
                   //Modifica Mesh per diventare una superfice piatta
                let cloneTmp=new THREE.Mesh(ThreeManUtility.getSkinnedMesh(faceGlbMesh).geometry.clone(), ThreeManUtility.getSkinnedMesh(faceGlbMesh).material.clone())
                let Pts2d=MediaPipeUtility.flatUVFacePoints;
                for (let i = 0; i < Pts2d.length; i++){
                    cloneTmp.geometry.attributes.position.setXYZ(i, Pts2d[i].x*(keyPts.image.width/50), (1-Pts2d[i].y)*(keyPts.image.height/50),0)    
                }
            
                //Centra la bocca
                //MediaPipeUtility.TranslateLip(cloneTmp, 0,-0.7,0);//Sposta labbra verso il basso

                //Proietta mesh su un canvas
                let tmpCv=ThreeManUtility.meshToCanvas(cloneTmp,boxTextureModel.max.x-boxTextureModel.min.x);
                //ThreeManUtility.downloadCanvas(tmpCv);
                let boxTextureMediaPipe=new THREE.Box2(new THREE.Vector2(0,0),new THREE.Vector2(tmpCv.width,tmpCv.height));

                //Maschera la faccia della texture con trasparenze per agevolare morph con texture modello
                tmpCv=maskSmootFace(tmpCv,0.08,MediaPipeUtility.flatUVFacePointsStrech());

    /*            let tmpCtx      = tmpCv.getContext('2d');   //Debug
                let stretch2DPts=MediaPipeUtility.flatUVFacePointsStrech();
                for (let i = 0; i < stretch2DPts.length; i++){
                    tmpCtx.beginPath();
                    tmpCtx.ellipse(stretch2DPts[i].x*tmpCv.width,stretch2DPts[i].y*tmpCv.height, 4, 4, 0, 0, 2*Math.PI);
                    tmpCtx.stroke();
                    tmpCtx.closePath();
                };    
    */
                //Converte in scala di grigi
                //ThreeManUtility.filterGreyScale(tmpCv , -50) ;
                //ThreeManUtility.filterGreyScale(matCanvas , 0) ;
                //Copia la faccia nel map del materiale  (matCtx)
                copyCanvasToCtx(tmpCv,matCtx,boxTextureMediaPipe,boxTextureModel);
    /*
                //Maschera la faccia per tenere solo la perte interna
                let maskFromMediaPipe=maskCanvas(keyPts,keyPts.image,keyPts.segmentationMask,100);
                ThreeManUtility.filterGreyScale(maskFromMediaPipe , -50) ;
                ThreeManUtility.filterGreyScale(matCanvas , 0) ;
                //Setta le coordinate per copiare la faccia
                let boxTextureModel    =idxFaceBoundRect(modelHead);
                let boxTextureMediaPipe=MediaPipeUtility.idxFaceBoundRect(keyPts);
                //Copia la faccia nel map del materiale  (matCtx)
                copyCanvasToCtx(maskFromMediaPipe,matCtx,boxTextureMediaPipe,boxTextureModel);*/
            }
    /*
            let maskColor=`${255}, ${0}, ${0}`;             //Debug pprinta posizioni del modello
            matCtx.strokeStyle = `rgba(${maskColor}, 1)`;
            matCtx.fillStyle   = `rgba(${maskColor}, 1)`;
            matCtx.beginPath();
            matCtx.ellipse(getUV(modelHead,idxEyeLeft_InternalSide,false).x,getUV(modelHead,idxEyeLeft_InternalSide,false).y, 4, 4, 0, 0, 2*Math.PI);
            matCtx.ellipse(getUV(modelHead,idxEyeLeft_ExternalSide,false).x,getUV(modelHead,idxEyeLeft_ExternalSide,false).y, 4, 4, 0, 0, 2*Math.PI);
            matCtx.fill() 
            matCtx.stroke();
            matCtx.closePath();
            matCtx.beginPath();
            matCtx.ellipse(getUV(modelHead,idxEyeRight_InternalSide,false).x,getUV(modelHead,idxEyeRight_InternalSide,false).y, 4, 4, 0, 0, 2*Math.PI);
            matCtx.ellipse(getUV(modelHead,idxEyeRight_ExternalSide,false).x,getUV(modelHead,idxEyeRight_ExternalSide,false).y, 4, 4, 0, 0, 2*Math.PI);
            matCtx.fill() 
            matCtx.stroke();
            matCtx.closePath();
            matCtx.beginPath();
            matCtx.ellipse(getUV(modelHead,idxBoccaLeft,false).x,getUV(modelHead,idxBoccaLeft,false).y, 4, 4, 0, 0, 2*Math.PI);
            matCtx.ellipse(getUV(modelHead,idxBoccaRight,false).x,getUV(modelHead,idxBoccaRight,false).y, 4, 4, 0, 0, 2*Math.PI);
            matCtx.fill() 
            matCtx.stroke();
            matCtx.closePath();
            matCtx.beginPath();
            matCtx.ellipse(getUV(modelHead,idxNasoPunta,false).x,getUV(modelHead,idxNasoPunta,false).y, 4, 4, 0, 0, 2*Math.PI);
            matCtx.ellipse(getUV(modelHead,idxNasoInizio,false).x,getUV(modelHead,idxNasoInizio,false).y, 4, 4, 0, 0, 2*Math.PI);
            matCtx.fill() 
            matCtx.stroke();
            matCtx.closePath();
    */
        }
        //Maschera la mesh GLB faccia sovrapposta per agevolare morph con texture modello
        if(keyPts?.faceLandmarks){
            maskSmootFace_Mesh(faceGlbMesh,0.08,keyPts.faceLandmarks)     
        }
        
        //ThreeManUtility.downloadGlb(faceGlbMesh,'Test.glb')//faceGlbMesh,'Test.glb')

        //ThreeManUtility.downloadCanvas(matCanvas);//maskFromMediaPipe);//matCanvas);

        modelHead.geometry.attributes.uv.needsUpdate=true;
        
        //Crea lo skinned mesh se serve
        // faceGlbMesh=SkinningUtility.makeSkinnedMeshWitoutBVH(modelHead,ThreeManUtility.getSkinnedMesh(faceGlbMesh), scene);
        faceGlbMesh=SkinningUtility.makeSkinnedMeshBVH(modelHead,ThreeManUtility.getSkinnedMesh(faceGlbMesh), scene);
        
        ThreeManUtility.sceneRemoveCategoryType(modelHead, ThreeManUtility.MeshesType.FACEWEBCAM);
        ThreeManUtility.sceneAddCategory(modelHead, faceGlbMesh,undefined ,ThreeManUtility.MeshesType.FACEWEBCAM )

        //modelMesh.add(faceGlbMesh);
        //scene.add(SkinningUtility.makeSkinnedMeshWitoutBVH(modelHead,ThreeManUtility.getSkinnedMesh(faceGlbMesh), scene));
        //scene.add(faceGlbMesh, scene);

        //ThreeManUtility.downloadGlb(faceGlbMesh,'Test.glb')//faceGlbMesh,'Test.glb')
        ThreeManUtility.traverseSetFrustumCull(modelMesh,false)
        return true;

    }

}


export {getMeshHead, mergeMPipeMeshToModel,loaderMesh,visibleStandardHair,
    idxMentoMesh }