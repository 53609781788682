import React from 'react';
import CustomDialog from '../CustomDialog/CustomDialog';
import ProductCard from '../ProductCard/ProductCard';
import { BsTrash } from "react-icons/bs";
import Button from '@mui/material/Button';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import QRCode from "react-qr-code";
import { useLoadingContext } from '../Loading/Loading';

const CartDialog = ({ isOpen, selectedProducts, cartProducts, size = 'm', handleCartClose, getInfoToShare }) => {

    //a trick to force render
    const [, setTime] = React.useState(Date.now());
    const refSize = React.useRef(size);
    const [qrValue, setQRValue] = React.useState('');
    const { setIsLoading } = useLoadingContext();

    const handleBuyLinkClick = () => {
        setIsLoading(true);
        setTimeout(() => {

            getInfoToShare(cartProducts).then(info => {

                const sizeVal = refSize.current;
                const buyUrl = 'https://futurecloshop.it/products/sweter-000';
                const finalUrl = `http://tryon.logicanetsolution.com/buy.php?imgurl=${encodeURIComponent(info)}&buyurl=${encodeURIComponent(buyUrl)}`;
                setQRValue(finalUrl);

            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setIsLoading(false);
            });

        }, 10);
    }

    function buttons() {

        return (

            <Button className='buyColor' onClick={handleBuyLinkClick}>Buy link</Button>

        )

    };

    function handleOnClose() {

        handleCartClose && handleCartClose();
        setTimeout(() => {
            setQRValue('');
        }, 100);

    }

    function rerender() {
        setTime(Date.now());
    }

    function sizeChange(e) {
        refSize.current = e.target.value;
        rerender();
    }
    
    React.useEffect(() => {
        refSize.current = size;        
    }, [size]);


    return (
        <>
                
                <CustomDialog className='cart' title='Cart' isOpen={isOpen} onClose={handleOnClose} otherButtons={buttons}>
                {
                    qrValue ?
                        <div style={{ background: 'white', padding: '5px' }}>
                            <QRCode value={qrValue} />
                        </div>
    
                    :
                        <>
                            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                            <div style={{width: '100%', textAlign: 'right'}}>

                                {/* <Select labelId="demo-simple-select-label" id="demo-simple-select" value={size} label="Age" onChange={sizeChange} >
                                <MenuItem value={'xs'}>XS</MenuItem>
                                <MenuItem value={'s'}>S</MenuItem>
                                <MenuItem value={'m'}>M</MenuItem>
                                <MenuItem value={'l'}>L</MenuItem>
                                <MenuItem value={'xl'}>XL</MenuItem>
                                </Select> */}
                                <span style={{marginRight: '10px'}}>Size</span>
                                <select ref={refSize} name="" id="" value={refSize.current} onChange={sizeChange}>
                                    <option value='xs'>XS</option>
                                    <option value='s'>S</option>
                                    <option value='m'>M</option>
                                    <option value='l'>L</option>
                                    <option value='xl'>XL</option>
                                </select>
                            </div>
                            <table style={{width: '400px'}}>
                                <tbody>
                                    { 
                                        (cartProducts.length === 0) ?

                                            // <div>No items to buy</div>
                                            <tr><td>No items to buy</td></tr>

                                        :
                                        
                                            cartProducts.map((prodVariation, index) => 
                                            (
                                                <tr key={prodVariation.id}>

                                                    <td style={{width: '100px'}}><ProductCard prodVariation={prodVariation} /></td>
                                                    <td>
                                                        <div>
                                                            <div>{prodVariation.parent.name}</div>
                                                            <div>({prodVariation.name})</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <BsTrash style={{cursor: 'pointer'}} onClick={() => {
                                                        
                                                            cartProducts.splice(index, 1);
                                                            rerender();
                                                        
                                                        }} />
                                                    </td>
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </>
                            
                }
                </CustomDialog>
        </>
    );
  
};

export default CartDialog;
