import React from 'react';
import ClickableContainer from "../ClickableContainer/ClickableContainer";
import IntlFormatter from "../IntlFormatter/IntlFormatter";
import classNames from 'classnames';
import CornerFlag from '../CornerFlag/CornerFlag';
// import CornerFullFlag from '../CornerFullFlag/CornerFullFlag';

const ProductCard = ({prodVariation, className, headerText, prodDescription, isSelected, enableHover = false, onclick, headerExtraRender, footerExtraRender}) => {
    const refProductVar = React.useRef(prodVariation);
    console.log('ProductCard render');
    
    const productClasses = classNames('productCard', 'product', className, {
        selected: isSelected,
        hover: enableHover,
    });

    const priceClasses = classNames({
        oldPrice: prodVariation.discount > 0
    });

    return (
        <article className={productClasses}>
            <header>
                {headerText}
                {headerExtraRender && headerExtraRender()}
            </header>
            <div className='articleContent'>
                <img src={prodVariation.previewFront} className="productImage" alt={prodVariation.name}></img>
                <ClickableContainer onclick={() => { onclick(refProductVar.current) }} />
            </div>
            <footer>
                <div className="prodVarName">{prodDescription}</div>
                <div className="price priceRow">
                    <span className={priceClasses}><IntlFormatter value={prodVariation.price} /></span>
                    {prodVariation.discount > 0 && <span><IntlFormatter value={prodVariation.discountPrice()} /></span>}
                </div>
                {footerExtraRender && footerExtraRender()}
            </footer>
            {/* {prodVariation.discount > 0 && <CornerFullFlag text={`${prodVariation.discount}% OFF`} />} */}
            {prodVariation.discount > 0 && <CornerFlag text={`${prodVariation.discount}% OFF`} />}
        </article>
    );
};

export default ProductCard;
