import React from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Room from './views/Room/Room';
import { default as Loading, LoadingProvider } from './components/Loading/Loading';
import { default as PopupMsg, PopupMsgProvider } from './components/PopupMsg/PopupMsg';
import Hypnosis from "react-cssfx-loading/lib/Hypnosis";
import 'canvas-roundrect-polyfill';
import 'ecma-proposal-math-extensions';

const App = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [popupMsgProps, setPopupMsgProps] = React.useState({});
    const showPopupMsg = (newProps) => {
        if (newProps?.show && newProps.autoClose) {
            const delay = typeof newProps.autoClose === 'number' ? newProps.autoClose : 5000;
            newProps.afterEnterCallback = () => {
                setTimeout(() => {
                    //popupMsgProps is empty quando si esegue per prima volta il setTimeout perche' e' il valore salvato nel closure.
                    //Facendo cosi' sparisce il testo durante l'Exit del popup. Per questo motivo si fa il merge con newProps
                    // setPopupMsgProps({...popupMsgProps, ...{show: false}}); 
                    setPopupMsgProps({...popupMsgProps, ...newProps, ...{show: false}});
                }, delay);
            }
        }
        setPopupMsgProps({...popupMsgProps, ...newProps});
    }

    return (
        <StyledEngineProvider injectFirst>
            <div className="App">
                <LoadingProvider value={{setIsLoading}}>
                    <PopupMsgProvider value={{showPopupMsg}}>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Room />} />
                            </Routes>
                        </BrowserRouter>
                        <PopupMsg {...popupMsgProps} />
                    </PopupMsgProvider>
                    {isLoading &&
                    <Loading text='Loading...' >
                        <Hypnosis width="100px" height="100px" duration="2s" />
                    </Loading>
                    }
                </LoadingProvider>
            </div>
        </StyledEngineProvider>
    );
};

export default App;