import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { VRM,VRMUtils,VRMSchema } from '@pixiv/three-vrm';
import * as Kalidokit from "kalidokit";

// const remap = Kalidokit.Utils.remap;
const clamp = Kalidokit.Utils.clamp;
const lerp = Kalidokit.Vector.lerp;

function loaderVRM(urlfName, callback) {                 //modelli : https://hub.vroid.com/en/tags/VRoidStudio
    const loader = new GLTFLoader();
    loader.crossOrigin = "anonymous";
    // Import model from URL, add your own model here
    loader.load(
    urlfName,
    (gltf) => {
        VRMUtils.removeUnnecessaryJoints(gltf.scene);
        VRM.from(gltf).then(vrm => {
            let modelVrm = vrm;
            modelVrm.scene.rotation.y = Math.PI; // Rotate model 180deg to face camera
            modelVrm.scene.scale.set(110, 110, 110);
            modelVrm.scene.position.set(50-50, 0, -100+100);
            modelVrm.humanoid.getBoneNode( VRMSchema.HumanoidBoneName.LeftUpperArm).rotation.z  = 1.2;   //Braccio dalla spalla
            modelVrm.humanoid.getBoneNode( VRMSchema.HumanoidBoneName.RightUpperArm).rotation.z = -1.2;  //Braccio dalla spalla
            modelVrm.humanoid.getBoneNode( VRMSchema.HumanoidBoneName.RightUpperArm).rotation.x = 0;     //Braccio dalla spalla viene in avanti
            modelVrm.humanoid.getBoneNode( VRMSchema.HumanoidBoneName.Neck ).rotation.y         = -0.5;  //Testa ruota
            //modelVrm.blendShapeProxy.setValue( VRMSchema.BlendShapePresetName.Lookup, 1 );
         
            if (callback) callback(modelVrm);
        });
    },
    ( progress ) => console.log( 'Loading model...', 100.0 * ( progress.loaded / progress.total ), '%' ),
    ( error ) => {console.error('Error function -loaderVRMR-  ! '+ error )   });
}

    // Animate Rotation Helper function
    const rigRotation = (
        modelVrm,
        name,
        rotation = { x: 0, y: 0, z: 0 },
        dampener = 1,
        lerpAmount = 0.3
    ) => {
        if (!modelVrm) {return}
        const Part = modelVrm.humanoid.getBoneNode(
        VRMSchema.HumanoidBoneName[name]
        );
        if (!Part) {return}
        
        let euler = new THREE.Euler(
        rotation.x * dampener,
        rotation.y * dampener,
        rotation.z * dampener
        );
        let quaternion = new THREE.Quaternion().setFromEuler(euler);
        Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
    };
    
    // Animate Position Helper Function
    const rigPosition = (
        modelVrm,
        name,
        position = { x: 0, y: 0, z: 0 },
        dampener = 1,
        lerpAmount = 0.3
    ) => {
        if (!modelVrm) {return}
        const Part = modelVrm.humanoid.getBoneNode(
        VRMSchema.HumanoidBoneName[name]
        );
        if (!Part) {return}
        let vector = new THREE.Vector3(
        position.x * dampener,
        position.y * dampener,
        position.z * dampener
        );
        Part.position.lerp(vector, lerpAmount); // interpolate
    };
    
    let oldLookTarget = new THREE.Euler()
    const rigFace = (modelVrm,riggedFace) => {
        if(!modelVrm){return}
        rigRotation(modelVrm,"Neck", riggedFace.head, 0.7);
    
        // Blendshapes and Preset Name Schema
        const Blendshape = modelVrm.blendShapeProxy;
        const PresetName = VRMSchema.BlendShapePresetName;
        
        // Simple example without winking. Interpolate based on old blendshape, then stabilize blink with `Kalidokit` helper function.
        // for VRM, 1 is closed, 0 is open.
        riggedFace.eye.l = lerp(clamp(1 - riggedFace.eye.l, 0, 1),Blendshape.getValue(PresetName.Blink), .5)
        riggedFace.eye.r = lerp(clamp(1 - riggedFace.eye.r, 0, 1),Blendshape.getValue(PresetName.Blink), .5)
        riggedFace.eye = Kalidokit.Face.stabilizeBlink(riggedFace.eye,riggedFace.head.y)
        Blendshape.setValue(PresetName.Blink, riggedFace.eye.l);
        
        // Interpolate and set mouth blendshapes
        Blendshape.setValue(PresetName.I, lerp(riggedFace.mouth.shape.I,Blendshape.getValue(PresetName.I), .5));
        Blendshape.setValue(PresetName.A, lerp(riggedFace.mouth.shape.A,Blendshape.getValue(PresetName.A), .5));
        Blendshape.setValue(PresetName.E, lerp(riggedFace.mouth.shape.E,Blendshape.getValue(PresetName.E), .5));
        Blendshape.setValue(PresetName.O, lerp(riggedFace.mouth.shape.O,Blendshape.getValue(PresetName.O), .5));
        Blendshape.setValue(PresetName.U, lerp(riggedFace.mouth.shape.U,Blendshape.getValue(PresetName.U), .5));
    
        //PUPILS
        //interpolate pupil and keep a copy of the value
        let lookTarget =
            new THREE.Euler(
            lerp(oldLookTarget.x , riggedFace.pupil.y, .4),
            lerp(oldLookTarget.y, riggedFace.pupil.x, .4),
            0,
            "XYZ"
            )
        oldLookTarget.copy(lookTarget)
        modelVrm.lookAt.applyer.lookAt(lookTarget);
    }
    
    /* VRM Character Animator */
    function animateVRM (vrm, results) {
        if (!vrm) {
            return;
        }   
        // Take the results from `Holistic` and animate character based on its Face, Pose, and Hand Keypoints.
        let riggedPose, riggedLeftHand, riggedRightHand, riggedFace;
    
        const faceLandmarks = results.faceLandmarks;
        // Pose 3D Landmarks are with respect to Hip distance in meters
        const pose3DLandmarks = results.ea;
        // Pose 2D landmarks are with respect to videoWidth and videoHeight
        const pose2DLandmarks = results.poseLandmarks;
        // Be careful, hand landmarks may be reversed
        const leftHandLandmarks = results.rightHandLandmarks;
        const rightHandLandmarks = results.leftHandLandmarks;
    
        // Animate Face
        if (faceLandmarks) {
            riggedFace = Kalidokit.Face.solve(faceLandmarks, {
                runtime:"mediapipe"//, video:videoElement
            });
            rigFace(vrm,riggedFace)
        }
    
        // Animate Pose
        if ( pose2DLandmarks && pose3DLandmarks) {
        riggedPose = Kalidokit.Pose.solve(pose3DLandmarks, pose2DLandmarks, {
            runtime: "mediapipe"//, video:videoElement,
        });
        riggedPose.Hips.rotation.z=0;                                               //Blocca rotazione circolare
        riggedPose.Hips.rotation.x=0;                                               //Blocca rotazione circolare
        rigRotation(vrm,"Hips", riggedPose.Hips.rotation, 0.7);                         //Ruota modello 
        rigPosition(                                                              //Posiziona modello
            vrm,
            "Hips",
            {
            x: 0,//-riggedPose.Hips.position.x, // Reverse direction
            y: 0.83,//riggedPose.Hips.position.y + 1, // Add a bit of height          
            z: -riggedPose.Hips.position.z // Reverse direction
            },
            1,
            0.07
        );
    
        rigRotation(vrm,"Chest", riggedPose.Spine, 0.25, .3);
        rigRotation(vrm,"Spine", riggedPose.Spine, 0.45, .3);
    
        rigRotation(vrm,"RightUpperArm", riggedPose.RightUpperArm, 1, .3);
        rigRotation(vrm,"RightLowerArm", riggedPose.RightLowerArm, 1, .3);
        rigRotation(vrm,"LeftUpperArm", riggedPose.LeftUpperArm, 1, .3);
        rigRotation(vrm,"LeftLowerArm", riggedPose.LeftLowerArm, 1, .3);
    
        rigRotation(vrm,"LeftUpperLeg", riggedPose.LeftUpperLeg, 1, .3);
        rigRotation(vrm,"LeftLowerLeg", riggedPose.LeftLowerLeg, 1, .3);
        rigRotation(vrm,"RightUpperLeg", riggedPose.RightUpperLeg, 1, .3);
        rigRotation(vrm,"RightLowerLeg", riggedPose.RightLowerLeg, 1, .3);
        }
    
        // Animate Hands
        if (leftHandLandmarks) {
        riggedLeftHand = Kalidokit.Hand.solve(leftHandLandmarks, "Left");
        rigRotation(vrm,"LeftHand", {
            // Combine pose rotation Z and hand rotation X Y
            z: riggedPose?.LeftHand.z,
            y: riggedLeftHand.LeftWrist.y,
            x: riggedLeftHand.LeftWrist.x
        });
        rigRotation(vrm,"LeftRingProximal", riggedLeftHand.LeftRingProximal);
        rigRotation(vrm,"LeftRingIntermediate", riggedLeftHand.LeftRingIntermediate);
        rigRotation(vrm,"LeftRingDistal", riggedLeftHand.LeftRingDistal);
        rigRotation(vrm,"LeftIndexProximal", riggedLeftHand.LeftIndexProximal);
        rigRotation(vrm,"LeftIndexIntermediate", riggedLeftHand.LeftIndexIntermediate);
        rigRotation(vrm,"LeftIndexDistal", riggedLeftHand.LeftIndexDistal);
        rigRotation(vrm,"LeftMiddleProximal", riggedLeftHand.LeftMiddleProximal);
        rigRotation(vrm,"LeftMiddleIntermediate", riggedLeftHand.LeftMiddleIntermediate);
        rigRotation(vrm,"LeftMiddleDistal", riggedLeftHand.LeftMiddleDistal);
        rigRotation(vrm,"LeftThumbProximal", riggedLeftHand.LeftThumbProximal);
        rigRotation(vrm,"LeftThumbIntermediate", riggedLeftHand.LeftThumbIntermediate);
        rigRotation(vrm,"LeftThumbDistal", riggedLeftHand.LeftThumbDistal);
        rigRotation(vrm,"LeftLittleProximal", riggedLeftHand.LeftLittleProximal);
        rigRotation(vrm,"LeftLittleIntermediate", riggedLeftHand.LeftLittleIntermediate);
        rigRotation(vrm,"LeftLittleDistal", riggedLeftHand.LeftLittleDistal);
        }
        if (rightHandLandmarks) {
        riggedRightHand = Kalidokit.Hand.solve(rightHandLandmarks, "Right");
        rigRotation(vrm,"RightHand", {
            // Combine Z axis from pose hand and X/Y axis from hand wrist rotation
            z: riggedPose?.RightHand.z,
            y: riggedRightHand.RightWrist.y,
            x: riggedRightHand.RightWrist.x
        });
        rigRotation(vrm,"RightRingProximal", riggedRightHand.RightRingProximal);
        rigRotation(vrm,"RightRingIntermediate", riggedRightHand.RightRingIntermediate);
        rigRotation(vrm,"RightRingDistal", riggedRightHand.RightRingDistal);
        rigRotation(vrm,"RightIndexProximal", riggedRightHand.RightIndexProximal);
        rigRotation(vrm,"RightIndexIntermediate",riggedRightHand.RightIndexIntermediate);
        rigRotation(vrm,"RightIndexDistal", riggedRightHand.RightIndexDistal);
        rigRotation(vrm,"RightMiddleProximal", riggedRightHand.RightMiddleProximal);
        rigRotation(vrm,"RightMiddleIntermediate", riggedRightHand.RightMiddleIntermediate);
        rigRotation(vrm,"RightMiddleDistal", riggedRightHand.RightMiddleDistal);
        rigRotation(vrm,"RightThumbProximal", riggedRightHand.RightThumbProximal);
        rigRotation(vrm,"RightThumbIntermediate", riggedRightHand.RightThumbIntermediate);
        rigRotation(vrm,"RightThumbDistal", riggedRightHand.RightThumbDistal);
        rigRotation(vrm,"RightLittleProximal", riggedRightHand.RightLittleProximal);
        rigRotation(vrm,"RightLittleIntermediate", riggedRightHand.RightLittleIntermediate);
        rigRotation(vrm,"RightLittleDistal", riggedRightHand.RightLittleDistal);
        }

    };

export {  loaderVRM , animateVRM } 