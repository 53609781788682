import React from "react";
import Product from "../Product/Product";
import ProductVariation from "../ProductVariation/ProductVariation";
import { useScrollBoost } from 'react-scrollbooster'
import TryOnAPI from "../../services/TryOnAPI";


function ProductContainer({ prodVariationChange }) {
    const [products, setProducts] = React.useState([]);
    const [selectedProd, setSelectedProd] = React.useState({});
    const [selectedProdVar, setSelectedProdVar] = React.useState({});
    const prodVarCnerRef = React.useRef();

    var options = {
        scrollMode: "transform",
        direction: "vertical",
        emulateScroll: true
    };
    
    const [vpProd] = useScrollBoost(options);
    const [vpProdVar] = useScrollBoost(options);
    
    //Soluzione per passare la ref a ScrollBoost e lascire anche una copia locale
    //https://github.com/oberonamsterdam/react-scrollbooster
    const setRefs = React.useCallback(node => {
        // Ref's from useRef needs to have the node assigned to `current`
        prodVarCnerRef.current = node;
        vpProdVar(node)
    }, [vpProdVar]);

    React.useEffect(() => {
        TryOnAPI.products.get().then(products => {setProducts(products)});
    }, []);

    let animateName = 'animate__slideInUp';
    const productClick = (product) => {
        //Col primo click del prodotto, non esiste ancora la ref
        if (prodVarCnerRef.current) {
            // Trick to trigger a DOM reflow so when add the animation again the browser execute it 
            prodVarCnerRef.current.classList.remove(animateName);
            void prodVarCnerRef.current.offsetWidth;
            prodVarCnerRef.current.classList.add(animateName);
        }
        setSelectedProd(product);
        setSelectedProdVar({});
    };

    const productVariationClick = (prodVariation) => {
        setSelectedProdVar(prodVariation);
        prodVariationChange(selectedProd, prodVariation);
    };

    console.log('Product container render');

    return (
        <>
            <div className={`productContainer left animate__animated ${animateName}`} ref={vpProd} >
                <div className="content">
                    {products.map((product) => <Product key={product.id} product={product} isSelected={product.id === selectedProd.id} onclick={productClick} />)}
                </div>
            </div>

            {Object.keys(selectedProd).length > 0 &&
                <div className={`productContainer right animate__animated ${animateName}`} ref={setRefs}>
                    <div className="content">
                        {selectedProd.variations.map((productVariation) => <ProductVariation key={productVariation.id} prodVariation={productVariation} isSelected={productVariation.id === selectedProdVar.id} onclick={productVariationClick} />)}
                    </div>
                </div>
            }
        </>
    );
}

export default React.memo(ProductContainer);
