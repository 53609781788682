import React from "react";

export const LoadingContext = React.createContext();

export const LoadingProvider = ({ value, children }) => {
    return (
        <LoadingContext.Provider value={value}>
            {children}
        </LoadingContext.Provider>
    );
};

export const useLoadingContext = () => React.useContext(LoadingContext);

export default function Loading({text, children, className, ...rest}) {
    let styledObj = {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0px',
        left: '0px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#00000055',
        zIndex: 2000
    }

    return (
        <div className={`loading ${className}`} style={styledObj} {...rest}>
            {children}
            {text && <span>{text}</span>}         
        </div>
    );
}