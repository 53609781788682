import React, { useRef, useEffect } from "react"
import * as THREE from 'three';
// import Webcam from "react-webcam"
import { Holistic } from '@mediapipe/holistic' 
import { Camera } from '@mediapipe/camera_utils'
import * as MediaPipeUtility from '../../Utility/MediaPipeUtility.js';
import { useLoadingContext } from '../../components/Loading/Loading';
import * as UTILITY from '../../Utility/ThreeManUtility.js';
// import { drawLandmarks, drawConnectors } from '@mediapipe/drawing_utils'
//import { control_utils } from '@mediapipe/control_utils'
// import { FACEMESH_TESSELATION, POSE_CONNECTIONS, HAND_CONNECTIONS } from '@mediapipe/holistic'

export const VirtualPose = ({ cameraOn, cameraSuspend, faceFromPicture, keyPtsChange }) => {

    const videoRef = useRef(null);
    const cameraRef = useRef(null);
    const canvasRef = useRef(null);
    const holisticRef = useRef(null);
    const cameraSuspendRef = useRef(null);
    const lastClickRightHandRef=useRef(false);
    const lastClickLeftHandRef=useRef(false);
    const lastfaceIsOverAvatar=useRef(false);
    const lastShotingPhotoTimeRef=useRef(0);
    const {setIsLoading} = useLoadingContext();
    const FilterRightHandRef = React.useRef([]);
    const FilterLefttHandRef = React.useRef([]);
    const FilterFaceRef      = React.useRef([]);
    const FilterFacePosRef   = React.useRef([]);    //Filtro per controllare se la faccia era ferma o se era in movimento per non usare foto sfuocata
    const frameNumberRef    =useRef(0);     //Serve solo per sapere se era il primo frame e magari doveva sganciarsi dall'immagine precedente
    const FilterSmoot=5;

    function keyPtsFilterByName(KeyPtsFilter,Key_Pts,FilterSmoot,KeepLastKpts) {    //Filtro per attenuare vibrazioni
        let val={x:0,y:0,z:0}
        if(KeepLastKpts){
            if (!Key_Pts || Key_Pts?.length < 1) {   //se non ha trovato keyPt mantiene l'ultima posizione
                if (KeyPtsFilter.current.length >0)Key_Pts=KeyPtsFilter.current[KeyPtsFilter.current.length-1];
            }
        }    
        KeyPtsFilter.current.push(Key_Pts);                                         //Inserisce keypts
        if (KeyPtsFilter.current.length >FilterSmoot) KeyPtsFilter.current.shift(); //Toglie il primo array se troppo lungo
        if (Key_Pts && (Key_Pts.length > 0)) {
            for (let jx = 0; jx < Key_Pts.length; jx++) {
                val.x=Key_Pts[jx].x;
                val.y=Key_Pts[jx].y;
                val.z=Key_Pts[jx].z;
                let count=1;
                for (let j = 0; j < KeyPtsFilter.current.length; j++) {
                    if (KeyPtsFilter.current[j] && (KeyPtsFilter.current[j].length > 0)) {
                        val.x =val.x+ KeyPtsFilter.current[j][jx].x;
                        val.y =val.y+ KeyPtsFilter.current[j][jx].y;
                        val.z =val.z+ KeyPtsFilter.current[j][jx].z;
                        ++count;  
                    }    
                }
                Key_Pts[jx].x=val.x /count;
                Key_Pts[jx].y=val.y /count;
                Key_Pts[jx].z=val.z /count;
            }    
        }
        return Key_Pts;    
    }    

    function keyPtsFilter(results) {    //Filtro per attenuare vibrazioni
        if (FilterSmoot > 0) {
            results.leftHandLandmarks = keyPtsFilterByName(FilterLefttHandRef,results.leftHandLandmarks,FilterSmoot,true);
            results.rightHandLandmarks = keyPtsFilterByName(FilterRightHandRef,results.rightHandLandmarks,FilterSmoot,true);
            results.faceLandmarks = keyPtsFilterByName(FilterFaceRef,results.faceLandmarks,2,false);     
        }
        //Filtro per controllare se immagine ferma
        if(results.faceLandmarks){
            FilterFacePosRef.current.push(results.faceLandmarks[MediaPipeUtility.idxFaceCenter]);
            if (FilterFacePosRef.current.length >50) FilterFacePosRef.current.shift(); //Toglie il primo array se troppo lungo
        }
    }

    function closeHand(HandLandmarks) {         //Per gesture vedere : https://andypotato.github.io/fingerpose/dist/index.html    https://github.com/andypotato/fingerpose
       // let pollice = false;
        let indice  = false;
        let Medio   = false;
        let anulare = false;
        let mano    = false;
        //let mignolo = false;
        if (HandLandmarks && (HandLandmarks.length > 20)) {
            var Polso3D  = new THREE.Vector3(HandLandmarks[0   ].x,HandLandmarks[0   ].y,HandLandmarks[0   ].z);
            //var pollice1  = new THREE.Vector3(HandLandmarks[1+0 ].x,HandLandmarks[1+0 ].y,HandLandmarks[1+0 ].z);
            var pollice2  = new THREE.Vector3(HandLandmarks[4+0 ].x,HandLandmarks[4+0 ].y,HandLandmarks[4+0 ].z);
            var indice1  = new THREE.Vector3(HandLandmarks[1+4 ].x,HandLandmarks[1+4 ].y,HandLandmarks[1+4 ].z);
            var indice2  = new THREE.Vector3(HandLandmarks[4+4 ].x,HandLandmarks[4+4 ].y,HandLandmarks[4+4 ].z);
            var medio1   = new THREE.Vector3(HandLandmarks[1+8 ].x,HandLandmarks[1+8 ].y,HandLandmarks[1+8 ].z);
            var medio2   = new THREE.Vector3(HandLandmarks[4+8 ].x,HandLandmarks[4+8 ].y,HandLandmarks[4+8 ].z);
            var anulare1 = new THREE.Vector3(HandLandmarks[1+12].x,HandLandmarks[1+12].y,HandLandmarks[1+12].z);
            var anulare2 = new THREE.Vector3(HandLandmarks[4+12].x,HandLandmarks[4+12].y,HandLandmarks[4+12].z);
           // pollice =Polso3D.distanceTo(pollice1)>Polso3D.distanceTo(pollice2);
            indice =Polso3D.distanceTo(indice1)>Polso3D.distanceTo(indice2);
            Medio  =Polso3D.distanceTo(medio1)>Polso3D.distanceTo(medio2);
            anulare=Polso3D.distanceTo(anulare1)>Polso3D.distanceTo(anulare2);
            mano   =Polso3D.distanceTo(pollice2)>Polso3D.distanceTo(indice2);   //Distanza tra punta pollice e polso > punta indice e polso
        }
        return indice || Medio || anulare || mano;
    }

    function faceIsOverAvatar(landmarks) {      //Vera se i landmarks della faccia sono sulla faccia dell'avatar
        let BoundR=0.032;           //Distanza entro la quale il punto viene considerato in centro
        let BoundRLarge=BoundR;//*40;   //Distanza dal centro più larga
        let PosY,bolDistCenter  ;                 //Posizione Y dove considera il centro della faccia nel modello
        let KeyPtCenter=5;      //indice KeyPts considerato come centro faccia 
        if (landmarks.image && landmarks.faceLandmarks && (landmarks.faceLandmarks.length > 0)) {
            let screenWidth=landmarks.image.width;
            let screenHeight=landmarks.image.height;
            if(screenWidth >screenHeight){PosY=0.35}else{PosY=0.123};
            let pt={x:landmarks.faceLandmarks[KeyPtCenter].x, y:landmarks.faceLandmarks[KeyPtCenter].y};//Se risoluzione in larghezza momentaneamente sposta il centro
            let ptScreen={x:0.5, y:PosY};
            let distCenter=new THREE.Vector2(pt.x,pt.y).distanceTo(new THREE.Vector2(ptScreen.x,ptScreen.y))

            if(lastfaceIsOverAvatar.current){   //Se era già dentro quando esce considera un rettangolo più grande
                bolDistCenter=distCenter< BoundRLarge
            }else{
                bolDistCenter=distCenter< BoundR
            }
            return {Center:bolDistCenter ,  LargeCenter:false, CenterX:ptScreen.x, CenterY:ptScreen.y}; 
        }
        return {Center:false,  LargeCenter:false, CenterX:0, CenterY:PosY};
    }

    function faceDimension(landmarks) {                                 // keypts faccia :   https://github.com/google/mediapipe/blob/master/mediapipe/modules/face_geometry/data/canonical_face_model_uv_visualization.png
        if (landmarks.image && landmarks.faceLandmarks && (landmarks.faceLandmarks.length > 0)) {
            let faceSize=UTILITY.faceLandmarksGetInfo(landmarks.faceLandmarks);
            let maskWidth =faceSize.w;
            let maskHeight=faceSize.h;
            
            let halfWidthLeft =faceSize.halfWidthLeft;
            let halfWidthRight=faceSize.halfWidthRight;

            let CenterHorz=(Math.abs(halfWidthLeft-halfWidthRight)*1000);    //Movimento orizzontale (zero indica centrato)

            let CenterObliquo=(Math.abs(landmarks.faceLandmarks[152].x -landmarks.faceLandmarks[10].x)*1000);  //Movimento obliquo (testa piegata da una parte) (zero indica centrato)

            let ImmagineFerma=false;
            //console.log(CenterHorz);

            //console.log(CenterObliquo);
            if(false){  //Toglie controllo testa centrata...
                CenterHorz=0;
                CenterObliquo=0;
            }

            //Controlla che immagine non sia mossa guardando la posizione 50 frame indietro
            if(FilterFacePosRef.current.length>=50){
                let dist= new THREE.Vector2(FilterFacePosRef.current[0].x,FilterFacePosRef.current[0].y).distanceTo(new THREE.Vector2(FilterFacePosRef.current[49].x,FilterFacePosRef.current[49].y))
                let dist1= new THREE.Vector2(FilterFacePosRef.current[10].x,FilterFacePosRef.current[10].y).distanceTo(new THREE.Vector2(FilterFacePosRef.current[49].x,FilterFacePosRef.current[49].y))
                let dist2= new THREE.Vector2(FilterFacePosRef.current[20].x,FilterFacePosRef.current[20].y).distanceTo(new THREE.Vector2(FilterFacePosRef.current[49].x,FilterFacePosRef.current[49].y))
                let dist3= new THREE.Vector2(FilterFacePosRef.current[30].x,FilterFacePosRef.current[30].y).distanceTo(new THREE.Vector2(FilterFacePosRef.current[49].x,FilterFacePosRef.current[49].y))
                let tollerance=0.05;    //0.01
                if(dist >tollerance || dist1 >tollerance || dist2 >tollerance || dist3 >tollerance){
                    ImmagineFerma=false; 
                }else{
                    ImmagineFerma=true; 
                }
            }


            return {x:maskWidth*landmarks.image.width, y:maskHeight*landmarks.image.height, CenterHorz:CenterHorz, CenterObliquo:CenterObliquo, ImmagineFerma:ImmagineFerma};
        }
        return {x:0, y:0, CenterHorz:0, CenterObliquo:0, ImmagineFerma:false}
    }

    const onResultRef = React.useRef(null);         //  esempi mesh 3d che combacia con texture faccia ... https://github.com/spite/FaceMeshFaceGeometry
    onResultRef.current = (results) => {      
        // if (canvasRef.current) {        //Se si vuole disegnare sul canvas...  sandbox da ... https://github.com/sanamumtaz/react-mediapipe-video/tree/main/       https://codesandbox.io/s/p8nrp?file=/src/components/FaceFilter.js:324-336
        //     const videoWidth = webcamRef.current.video.videoWidth
        //     const videoHeight = webcamRef.current.video.videoHeight

        //     canvasRef.current.width = videoWidth
        //     canvasRef.current.height = videoHeight
        //     const canvasElement = canvasRef.current
        //     const canvasCtx = canvasElement.getContext("2d")
        //     canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height)
        //     canvasCtx.drawImage(
        //         results.image, 0, 0, canvasRef.current.width, canvasRef.current.height);
        //     if (results.poseLandmarks !== undefined) {
        //         drawConnectors(canvasCtx, results.poseLandmarks, POSE_CONNECTIONS,
        //             { color: '#00FF00', lineWidth: 4 });
        //         drawLandmarks(canvasCtx, results.poseLandmarks,
        //             { color: '#FF0000', lineWidth: 1 });
        //     }
        //     if (results.faceLandmarks !== undefined) {
        //         drawConnectors(canvasCtx, results.faceLandmarks, FACEMESH_TESSELATION,
        //             { color: '#C0C0C070', lineWidth: 1 });
        //     }
        //     if (results.rightHandLandmarks !== undefined) {
        //         drawConnectors(canvasCtx, results.rightHandLandmarks, HAND_CONNECTIONS,
        //             { color: '#00CC00', lineWidth: 5 });
        //         drawLandmarks(canvasCtx, results.rightHandLandmarks,
        //             { color: '#FF0000', lineWidth: 2 });
        //     }
        //     if (results.leftHandLandmarks !== undefined) {
        //         drawConnectors(canvasCtx, results.leftHandLandmarks, HAND_CONNECTIONS,
        //             { color: '#CC0000', lineWidth: 5 });
        //         drawLandmarks(canvasCtx, results.leftHandLandmarks,
        //             { color: '#00FF00', lineWidth: 2 });
        //     }
        //     if (results.multiFaceGeometry !== undefined) {
        //     }
        // };
        
        //multiFaceGeometry se parametro true
        //segmentationMask per maschera segmantazione
        if (cameraOn) keyPtsFilter(results);  //Applica filtro

        results.closeRightHand=closeHand(results.rightHandLandmarks);
        results.closeLeftHand =closeHand(results.leftHandLandmarks);
      
        if(results.closeRightHand && lastClickRightHandRef.current !==results.closeRightHand){
            results.onClickRightHand=true;      //Evento true solo una volta
        }else results.onClickRightHand=false;

        if(results.closeLeftHand && lastClickLeftHandRef.current !==results.closeLeftHand){
            results.onClickLeftHand=true;       //Evento true solo una volta
        }else results.onClickLeftHand=false;

        if (results.rightHandLandmarks) results.posRightHand = results.rightHandLandmarks[0];
        if (results.leftHandLandmarks) results.posLeftHand = results.leftHandLandmarks[0];

        results.faceIsOverAvatar= faceIsOverAvatar(results);
        //if (results.faceIsOverAvatar.Center) console.log('Face over');

        if(!lastfaceIsOverAvatar.current && results.faceIsOverAvatar.Center){
            lastShotingPhotoTimeRef.current=Date.now();
        };
        if(lastfaceIsOverAvatar.current && lastShotingPhotoTimeRef && lastShotingPhotoTimeRef.current !==0 && results.faceIsOverAvatar.Center){
            var elapsedTime=(Date.now()-lastShotingPhotoTimeRef.current)/1000;
            if(elapsedTime >4){
                results.onShotPhoto=true;
                lastShotingPhotoTimeRef.current=0; //Per fare in modo che fino a quando non esce non scatta più foto
            }
        }

        lastClickRightHandRef.current=results.closeRightHand;
        lastClickLeftHandRef.current =results.closeLeftHand;
        lastfaceIsOverAvatar.current=results.faceIsOverAvatar.Center;

        results.faceDimension= faceDimension(results);
        //console.log(results.faceDimension);

        results.frameNumber=frameNumberRef.current; //Serve solo per sapere se era il primo frame e magari doveva sganciarsi dall'immagine precedente
        if (frameNumberRef.current <1000) frameNumberRef.current++;

        keyPtsChange(results);
    }
    
    useEffect(() => {
        if (cameraOn) frameNumberRef.current=0;
        FilterFacePosRef.current.length=0;
        
        if (!holisticRef.current) {
            holisticRef.current = new Holistic({
                locateFile: (file) => {
                    // return `https://cdn.jsdelivr.net/npm/@mediapipe/holistic/${file}`;
                    return `mediapipe-holistic-files/${file}`;
                }
            });
            //selfieMode si setta dopo, dipende di cameraOn/faceFromPicture
            holisticRef.current.setOptions({
                enableFaceGeometry: false,
                modelComplexity: 2,     //Complessità del modello da usare... 
                smoothLandmarks: true,  //Filtro vibrazione
                enableSegmentation: true,
                smoothSegmentation: false,
                refineFaceLandmarks: true,
                minDetectionConfidence: 0.5,
                minTrackingConfidence: 0.5  //Alto più robusto ma più lento
            });
        }
        
        //Conviene riasegnare il handler cosi' al interno della funzione onResultRef si possono usare i valori attuali delle props
        holisticRef.current.onResults(onResultRef.current);
        
        let bolStart=false;
        if (cameraOn) {
            
            setIsLoading(true);

            // const video = document.createElement('video')     //Cerca risoluzione video...
            // navigator.mediaDevices.getUserMedia({
            //     video: true,
            //     audio:false
            // }).then((stream) => {
            //     try {
            //         video.srcObject  = stream
            //     } catch (error) {
            //         video.srcObject  = window.URL.createObjectURL(stream)
            //     }
            //     video.play()
            //     video.onloadedmetadata = function() {
            //         console.log('resolution: ' + this.videoWidth + ' x ' + this.videoHeight);
            //     };
            // });

            if (typeof videoRef.current !== "undefined" && videoRef.current !== null) {
                //webcamRef.current.video.crossOrigin = "anonymous";
                const videoEl = videoRef.current;
                const camWidth = 1280 ; //     640 x 480      1280 x 1024      HD=1920 x 1080  
                const camHeight = 1024;

                holisticRef.current.setOptions({selfieMode: true});
                cameraRef.current = new Camera(videoEl, {
                    onFrame: async () => {
                        if (cameraSuspendRef.current) return;
                        const rotate90 = false;
                        let cnv;
                        if (rotate90)
                        {
                            cnv = document.createElement('canvas');
                            cnv.width = videoEl.videoHeight;
                            cnv.height = videoEl.videoWidth;
                            const ctx = cnv.getContext('2d');
                            ctx.translate(cnv.width, cnv.height / cnv.width);
                            ctx.rotate(Math.PI / 2);
                            ctx.drawImage(videoEl, 0, 0, videoEl.videoWidth, videoEl.videoHeight);
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                        } else
                            cnv = videoEl;
                            
                        await holisticRef.current.send({ image: cnv});

                        if (!bolStart) setIsLoading(false);
                        bolStart = true;
                    },
                    width: camWidth,
                    height: camHeight,
                });
                videoEl.width = 0;
                videoEl.height = 0;
                // videoEl.width = camWidth;
                // videoEl.height = camHeight;
            }
            
            if (cameraRef.current) cameraRef.current.start();

        } else
        if (faceFromPicture) { //Se usa photo statica

            setIsLoading(true);
            
            //Si usa un piccolo setTimeout perche altrimenti non si vede il Loading la seconda volta che si carica una imagine.
            setTimeout(() => {
                holisticRef.current.setOptions({selfieMode: false});
                holisticRef.current.reset();
                
                holisticRef.current.send({ image: faceFromPicture }).finally(() => {
                    setIsLoading(false);
                });
            }, 100);
                
        }
    }, [cameraOn, faceFromPicture, setIsLoading]);

    useEffect(() => {

        cameraSuspendRef.current = cameraSuspend;

    }, [cameraSuspend]);

    // const videoConstraints = {
    //     // width: { min: 1920 },
    //     // height: { min: 720 },
    //     width: 640,
    //     height: 480,
    //     // aspectRatio: 1,
    //     facingMode: "user",
    // };
    
    return (
        <>
            {cameraOn && (
                // <Webcam
                //     audio={false}
                //     screenshotFormat="image/jpeg"
                //     // width={0}
                //     // height={0}
                //     mirrored={true}
                //     videoConstraints={videoConstraints}
                //     ref={webcamRef}
                //     onUserMedia={onLoadMedia}
                // />
                <video ref={videoRef} />
            )}
            {false && (
                <canvas
                    ref={canvasRef}
                    style={{
                        position: "absolute",
                        //            marginLeft: "auto",
                        //            marginRight: "auto",
                        left: 0,
                        //            right: 0,
                        top: 0,
                        //            textAlign: "center",
                        zindex: 9,
                        width: 1280,
                        height: 720
                    }}
                />
            )}
        </>
    )
};

