// import logo from './logo.svg';
import React from 'react';
// import TryOnAPI from "./services/TryOnAPI";
// import ThreeComp from './components/ThreeComp/ThreeComp';
import MainOptions from '../../components/MainOptions/MainOptions';
import ProductContainer from '../../components/ProductContainer/ProductContainer';
import { ThreeMan } from '../../components/ThreeMan/ThreeMan';
import MediaPipeFaceDrawer from '../../components/MediaPipeFaceDrawer/MediaPipeFaceDrawer';
import Footer from '../../components/Footer/Footer';
import { Genders, GenderOption } from '../../components/GenderOption/GenderOption';
import ProductsSummary from '../../components/ProductsSummary/ProductsSummary';
import { VirtualPose } from '../../components/VirtualPose/VirtualPose';
import GestureTracker from '../../components/GestureTracker/GestureTracker';
import TempPopupMenu from "../../components/TempPopupMenu/TempPopupMenu";
import {isMobile} from "../../Utility/Common.js";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { IntlCurrencyFormat } from '../../components/IntlFormatter/IntlFormatter';
// import * as ThreeManUtility from '../../Utility/ThreeManUtility.js';
import CartDialog from '../../components/CartDialog/CartDialog';

const computeProductsSummary = (products) => {
    const summary = {
        count: products.length,
        total: 0,
        totalDiscount: 0,
    };

    products.reduce((result, product) => {
        result.total += product.price;
        result.totalDiscount += product.discountPrice();
        return result;
    }, summary);

    return summary;
}

const Room = () => {
    // const [products, setProducts] = React.useState([]);
    const genderURL = React.useRef({
        desktop: ['/scenes/manReallusion.glb', '/scenes/womanReallusion2.glb'],
        mobile: ['/scenes/manReallusion-lite.glb', '/scenes/womanReallusion-lite.glb'],
        desktop: ['/scenes/manReallusion-lite.glb', '/scenes/womanReallusion-lite.glb'],
    });     //manReallusion
    const sceneURL = React.useRef({
        desktop: '/scenes/scene.json',
        mobile: '/scenes/scene-lite.json',
        desktop: '/scenes/scene-lite.json',
    }); 
    const fileInput = React.useRef(null);
    const [gender, setGender] = React.useState(Genders.Male);    
    const [page, setPage] = React.useState(1);    
    const [tmpPopupMenuItem, setTmpPopupMenuItem] = React.useState(null);    
    const [isGestureOn, setIsGestureOn] = React.useState(false); // eslint-disable-line
    const [product, setProduct] = React.useState(null);
    const [prodVariation, setProdVariation] = React.useState(null);

    const [cameraOn, setWebCamera] = React.useState(false);
    const [cameraSuspend, setCameraSuspend] = React.useState(false);
    const [keyPts, setKeyPts] = React.useState(null);

    //Popup menu options
    const [faceFromPicture, setFaceFromPicture] = React.useState(null);
    const [keepHair, setKeepHair] = React.useState(false);
    const [showStats, setShowStats] = React.useState(false);
    const [useGesture, setUseGesture] = React.useState(false);

    const [drawFace, setDrawFace] = React.useState(true); //Indica se printare la mesh della faccia

    const [selectedProducts, setSelectedProducts] = React.useState([]);

    const [isCartOpen, setIsCartOpen] = React.useState(false);
    const [cartProducts, setCartProducts] = React.useState([]);

    // const [canRender, setCanRender] = React.useState(false);

    //#region Temporary context menu
    const handleFileChange = React.useCallback(() => {
        if (typeof openFileCallbackRef.current === 'function') 
            openFileCallbackRef.current(fileInput.current.files);
    }, []);
    const openFileCallbackRef = React.useRef(null);

    const openFileDialog = (callback, multiple = false) => {
        fileInput.current.multiple = multiple;
        openFileCallbackRef.current = callback;

        fileInput.current.parentElement.reset();
        
        fileInput.current.click();
    }

    //obj must have properties: "desktop" and "mobile"
    const selectByMobile = (obj) => {
        if (typeof obj !== 'object') return;

        if (isMobile())
            return obj.mobile
        else
            return obj.desktop;

    }

    React.useEffect(() => {
        //setProducts(TryOnAPI.products.get());
        fileInput.current.addEventListener('change', handleFileChange, false);
        
        // return () => {
        //     fileInput.current.removeEventListener('change', handleFileChange);
        // }
    }, []);
    //#endregion

    // const HandleManClick = (e) => {
    //     // setCanRender(true);
    //     setModelURL(manURL);
    //     // console.log(document.querySelector('canvas').width, document.querySelector('canvas').height);
    // }
    // const HandleWomanClick = (e) => {
    //     setModelURL(womanURL);
    // }
    const HandleGenderClick = (gender) => {
        setGender(gender);
    }

    const productSelectedChange = (selectedProducts) => {
        setSelectedProducts(selectedProducts);
    }

    const keyPtsChange = (Key_Pts) => {
        setKeyPts(Key_Pts);
    }

    const faceDrawerChange = (value) => {
        setDrawFace(value);
    }

    const prodVariationChange = React.useCallback((product, prodVariation) => {
        setProdVariation(prodVariation);
        setProduct(product);
    }, []);

    const pageChange = React.useCallback((ev, page) => {
        setPage(page);
    }, []);

    //#region MainOptions
    const searchClickHandle = () => {
    };

    const uploadS3 = (region, bucket, objKey, obj) => {

        const s3Client = new S3Client({
            region: region,
            credentials: {
                accessKeyId: 'AKIAX2EL74IORITXOMDL',
                secretAccessKey: '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',
            },
        });
        
        const _promise = s3Client.send(new PutObjectCommand({
            Bucket: bucket,
            Key: objKey,
            ContentType: obj.type,
            Body: obj,
        }));

        return _promise;
    };

    const [screenshotCallback, setScreenshotCallback] = React.useState(null);

    const getInfoToShare = (products) => {

        //The same as ProductSummary component
        const drawProductsSummary = (cnv) => {

            products = products ?? selectedProducts;
            const summary = computeProductsSummary(products);
            const hasDiscount = summary.total !== summary.totalDiscount;
            
            //Get the same values used in ProductsSummary from CSS
            const fontDefault = getComputedStyle(document.documentElement).getPropertyValue('--fontDefault');
            const smoothBorderOpacity = getComputedStyle(document.documentElement).getPropertyValue('--smoothBorderOpacity');
            let smoothBorderRadius = getComputedStyle(document.documentElement).getPropertyValue('--smoothBorderRadius').match(/\d+/);
            smoothBorderRadius = smoothBorderRadius ? smoothBorderRadius[0] : 0;
            const fontSize = cnv.height * 0.02594; //2%
            const fontSizeTryOn = cnv.height * 0.03242; //3%
            
            const ctx = cnv.getContext('2d');
            const txtPos = {x: 20, y: 20};
            const lineSpace = 15;
            const rectPadd = 5;
            ctx.fillStyle = '#AAAAAA';
            ctx.strokeStyle = ctx.fillStyle;
            
            function drawText(textObj, y, alpha) {
                ctx.globalAlpha = alpha;
                ctx.textBaseline = textObj.baseline; 
                ctx.font = textObj.font; 
                ctx.fillText(textObj.text, txtPos.x, y);
            }
            const texts = [
                {
                    text: `Products: ${summary.count}`,
                    font: `${fontSize}px ${fontDefault}`,
                    baseline: 'top',
                    height: 0
                },
                {
                    text: `Total: ${IntlCurrencyFormat(summary.total)}`,
                    font: `${fontSize-3}px ${fontDefault}`,
                    baseline: 'top',
                    height: 0,
                },
                {
                    text: `With discount: ${IntlCurrencyFormat(summary.totalDiscount)}`,
                    font: `${fontSize-3}px ${fontDefault}`,
                    baseline: 'top',
                    height: 0,
                    includeInMaxWidth: hasDiscount
                },
                {
                    text: 'SelfTryOn',
                    font: `${fontSizeTryOn}px Pushster, cursive`,
                    baseline: 'bottom',
                    height: 0,
                    includeInMaxWidth: false
                },
            ];

            const maxWidth = texts.reduce((result, textObj) => {
                if (textObj.includeInMaxWidth === false) return result;

                ctx.font = textObj.font;
                ctx.textBaseline = textObj.baseline;
                const textMet = ctx.measureText(textObj.text);
                textObj.height = textMet.actualBoundingBoxDescent + textMet.actualBoundingBoxAscent;
                return Math.max(result, textMet.width);
            }, 0);

            //SelfTryOn text
            drawText(texts[3], cnv.height - 10, 1);

            if (summary.count === 0) return;
            
            let y;
            y = txtPos.y;
            drawText(texts[0], y, 1);
            y += texts[0].height + lineSpace;
                
            ctx.globalAlpha = smoothBorderOpacity;
            ctx.beginPath();
            ctx.moveTo(txtPos.x, y);
            ctx.lineTo(txtPos.x + maxWidth, y);
            ctx.stroke();
            y += lineSpace;
            
            drawText(texts[1], y, 1);
            y += texts[1].height + lineSpace;
            
            //if some discount exists
            if (hasDiscount) {
                y -= Math.trunc(lineSpace / 2);
                drawText(texts[2], y, 1);

                y += texts[2].height + lineSpace;                
            }

            //ProductsSummary rectangle
            ctx.globalAlpha = smoothBorderOpacity;
            ctx.globalAlpha += 0.2;
            ctx.roundRect(txtPos.x - rectPadd, txtPos.y - rectPadd, maxWidth + 2 * rectPadd, y - txtPos.y - lineSpace + 2 * rectPadd, [smoothBorderRadius]);
            ctx.stroke();
            

            // ThreeManUtility.downloadCanvas(cnv);
        }
                
        return new Promise((resolve, reject) => {
            //passing a function to a state setter has a special meaning in React. It is used
            //when you want to compute the next state from the current state so to set a function
            //as value state the setter function has to return our desired function
            setScreenshotCallback(() => (cnv) => {

                drawProductsSummary(cnv);
                resolve('https://www.google.com');
                return;

                cnv.toBlob((blobObj) => {
                    const REGION = 'eu-south-1';
                    const BUCKET = 'img.tryon.logicanetsolution.com';
                    const objKey = `${Date.now()}.jpg`;
                    uploadS3(REGION, BUCKET, objKey, blobObj)
                    .then(resp => {
                        // resolve(`https://${BUCKET}.s3.${REGION}.amazonaws.com/${objKey}`);
                        resolve(`http://${BUCKET}/${objKey}`);
                    })
                    .catch(err => {
                        //reject(err);
                        fetch('/logica.vcf')
                        .then(response => response.text())
                        .then(vCard => resolve(vCard));
                    })
                    .finally(() => {
                        setScreenshotCallback(null);
                    });
                }, 'image/jpeg', 0.8);

            });
        });
    };
    //#endregion

    //#region Temporary context menu
    const [contextMenu, setContextMenu] = React.useState(null);
    const handleContextMenu = React.useCallback((event) => {
        event.preventDefault();
        
        const newContentMenu = contextMenu === null ? {mouseX: event.clientX - 2, mouseY: event.clientY - 4} : null;
        // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
        // Other native context menus might behave different.
        // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.

        setContextMenu(newContentMenu);
    }, []);
    const handleContextMenuClose = () => {
      setContextMenu(null);
    };
    const handleItemClick = (menuItem) => {
        handleContextMenuClose();

        if (menuItem.value === 'OpenGLB') {
            openFileDialog((files) => {
                //console.log(files);
                setTmpPopupMenuItem(Object.assign({...menuItem}, {data: files}));
            });
        } else
        if (menuItem.value === 'FaceFromPicture') {
            openFileDialog((files) => {
                setWebCamera(false);

                const image = new Image();
                image.src = URL.createObjectURL(files[0]);
                image.decode().then(() => {
                    setFaceFromPicture(image);
                });
            });
        } else
        if (menuItem.value === 'ShowStats') {
            setShowStats(!showStats);
        } else
        if (menuItem.value === 'KeepHair') {
            setKeepHair(!keepHair);
        } 
        if (menuItem.value === 'UseGesture') {
            setUseGesture(!useGesture);
        } 
        else
            setTmpPopupMenuItem({...menuItem});
        
    }
    //#endregion
    
    //#region Cart

    const handleCartClick = () => {
        
        cartProducts.length = 0;
        selectedProducts.forEach(selectedProd => { cartProducts.push(selectedProd) })
        setIsCartOpen(true);
        
    };
    
    const handleCartClose = () => {
        setIsCartOpen(false);
    };
    //#endregion

    return (
        <div className='fixedViewport'>
            <div className='table w100pc h100pc'>
                <div className='table-row' id="canvasViewport">
                    <div className='table-cell'>
                        <ThreeMan page={page} sceneURL={selectByMobile(sceneURL.current)} modelURL={selectByMobile(genderURL.current)[gender]} gender={gender} setIsGestureOn={setIsGestureOn} product={product} prodVariation={prodVariation} keyPts={keyPts} faceDrawerChange={faceDrawerChange} productSelectedChange={productSelectedChange} menuItem={tmpPopupMenuItem} faceFromPicture={faceFromPicture} keepHair={keepHair} showStats={showStats} useGesture={useGesture} screenshotCallback={screenshotCallback} cameraOn={cameraOn} setWebCamera={setWebCamera} setCameraSuspend={setCameraSuspend} />
                        {selectedProducts.length > 0 && <ProductsSummary summary={computeProductsSummary(selectedProducts)} />}
                        {cameraOn && drawFace && page < 5 && <MediaPipeFaceDrawer keyPts={keyPts} />}
                    </div>
                </div>
                <div className='table-row'>
                    <div className='table-cell'>
                        <Footer pageChange={pageChange} handleContextMenu={handleContextMenu} handleCartClick={handleCartClick}>
                        </Footer>
                    </div>
                </div>
            </div>
            <GenderOption gender={gender} onclick={HandleGenderClick} />
            <MainOptions searchClick={searchClickHandle} isCameraOn={cameraOn} cameraOnClick={() => {setFaceFromPicture(null); setWebCamera(!cameraOn); }} getInfoToShare={getInfoToShare}/>
            {(page === 1) && 
                <ProductContainer prodVariationChange={prodVariationChange}/>
            }
            <VirtualPose cameraOn={cameraOn} cameraSuspend={cameraSuspend} faceFromPicture={faceFromPicture} keyPtsChange={keyPtsChange} keyPts={keyPts} />
            {cameraOn && isGestureOn && //keyPts?.faceIsOverAvatar.Center &&
                <GestureTracker keyPts={keyPts} /> 
            }
            <TempPopupMenu open={contextMenu !== null} position={contextMenu} menuItemClick={handleItemClick} menuClose={handleContextMenuClose} keepHair={keepHair} showStats={showStats} useGesture={useGesture} />
            <CartDialog isOpen={isCartOpen} selectedProducts={selectedProducts} cartProducts={cartProducts} handleCartClose={handleCartClose} getInfoToShare={getInfoToShare} />
            <form action="" style={{display: 'none'}}><input ref={fileInput} type="file" name="" id="" /></form>
        </div>
    );
}

export default Room;