import React from 'react';
import IntlFormatter from '../IntlFormatter/IntlFormatter';
import Slide from '@mui/material/Slide';

const ProductsSummary = ({summary}) => {

    console.log('render ProductsSummary');

    return (
        <Slide direction='down' in={true} mountOnEnter unmountOnExit timeout={500}>
            <div className='productsSummary smoothBorder'>
                <span className='psProductsRow'><span className='labelHor'>Products:</span><span>{summary.count}</span></span>
                <hr className='lineHorSeparator w100pc' />
                <span className='psTotalRow'><span className='labelHor'>Total:</span><span className='price'><IntlFormatter value={summary.total} /></span></span>
                {summary.total !== summary.totalDiscount &&
                    <span className='psDiscountRow'><span className='labelHor'>With discount:</span><span className='price'><IntlFormatter value={summary.totalDiscount} /></span></span>
                }
            </div>
        </Slide>
    )
}

export default React.memo(ProductsSummary);