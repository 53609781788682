const Direction = {
    LEFT: -1,
    RIGHT: 1,
    UP: -2,
    DOWN: 2,
}

//Possibili ottimizzazioni per la performance:
//  1) 32-bit Pixel Manipulation using typed arrays:
//       https://hacks.mozilla.org/2011/12/faster-canvas-pixel-manipulation-with-typed-arrays/
//       Archive: https://web.archive.org/web/20211207043654/https://hacks.mozilla.org/2011/12/faster-canvas-pixel-manipulation-with-typed-arrays/
//  2) creare indice dove comincia ogni riga??

class Pixel {
    #dataScanner;
    constructor(x, y, dataScanner) {
        this.#dataScanner = dataScanner;
        this.x = x;
        this.y = y;
    }

    get red() {
        return this.#dataScanner.getRed(this.x, this.y);
    }
    
    get green() {
        return this.#dataScanner.getGreen(this.x, this.y);
    }
    
    get blue() {
        return this.#dataScanner.getBlue(this.x, this.y);
    }
    
    get alpha() {
        return this.#dataScanner.getAlpha(this.x, this.y);
    }

    get color() {
        return this.#dataScanner.getPixel(this.x, this.y);
    }

    set red(value) {
        this.#dataScanner.setRed(this.x, this.y, value);
    }

    set green(value) {
        this.#dataScanner.setGreen(this.x, this.y, value);
    }

    set blue(value) {
        this.#dataScanner.setBlue(this.x, this.y, value);
    }

    set alpha(value) {
        this.#dataScanner.setAlpha(this.x, this.y, value);
    }

    set color(value) {
        this.#dataScanner.setPixel(this.x, this.y, value);
    }

}

class ImageDataScanner {
    #rawData;
    #offset = {
        x: 0,
        y: 0
    }
    constructor(source, ...args) {
        if (source instanceof ImageData && args.length === 0) {
            this.#rawData = source;
            return;
        }
        
        if (source instanceof CanvasRenderingContext2D) {
            let sx = 0, sy = 0, sw = source.canvas.width, sh = source.canvas.height;
            
            if (args.length === 4 || args.length === 5) {
                if (typeof args[0] === 'number' && typeof args[1] === 'number' && typeof args[2] === 'number' && typeof args[3] === 'number') {

                    sx = args[0];
                    sy = args[1];
                    sw = args[2];
                    sh = args[3];

                    let keepCoordAbsolute = args.length === 5 && typeof args[4] === 'boolean' && args[4];
                    if (keepCoordAbsolute) {
                        this.#offset.x = args[0];
                        this.#offset.y = args[1];
                    }
                }
            }
            this.#rawData = source.getImageData(sx, sy, sw, sh);
        }
    }

    #x(x) { return x - this.#offset.x};
    #y(y) { return y - this.#offset.y};

    #pixelRawPos(x, y) {
        return this.#y(y) * (this.#rawData.width * 4) + this.#x(x) * 4;
    }

    isValidX(x) { return (this.#x(x) >= 0 && this.#x(x) < this.#rawData.width); }
    isValidY(y) { return (this.#y(y) >= 0 && this.#y(y) < this.#rawData.height); }
    isValidCoord(x, y) { return (this.isValidX(x) && this.isValidY(y)); }

    get width() { return this.#rawData.width; }
    get height() { return this.#rawData.height; }


    getPixel(x, y) {
        const p = this.#pixelRawPos(x, y);
        let result = [this.#rawData.data[p], this.#rawData.data[p + 1], this.#rawData.data[p + 2], this.#rawData.data[p + 3]]
        return result;
    }

    getRed(x, y) {
        return this.getPixel(x, y)[0];
    }

    getGreen(x, y) {
        return this.getPixel(x, y)[1];
    }

    getBlue(x, y) {
        return this.getPixel(x, y)[2];
    }

    getAlpha(x, y) {
        return this.getPixel(x, y)[3];
    }

    setPixel(x, y, color) {
        if (Array.isArray(color) && (color.length === 3 || color.length === 4)) {
            const [R, G, B, A] = color;
            const p = this.#pixelRawPos(x, y);
            this.#rawData.data[p] = R;
            this.#rawData.data[p + 1] = G;
            this.#rawData.data[p + 2] = B;
            if (color.length === 4)
                this.#rawData.data[p + 3] = A;
        }
    }

    setRed(x, y, value) {
        this.#rawData.data[this.#pixelRawPos(x, y)] = Math.abs(value) % 256;
    }

    setGreen(x, y, value) {
        this.#rawData.data[this.#pixelRawPos(x, y) + 1] = Math.abs(value) % 256;
    }

    setBlue(x, y, value) {
        this.#rawData.data[this.#pixelRawPos(x, y) + 2] = Math.abs(value) % 256;
    }

    setAlpha(x, y, value) {
        this.#rawData.data[this.#pixelRawPos(x, y) + 3] = Math.abs(value) % 256;
    }

    scanAll(startX, startY, actionFunc) {
        const result = this.scan(startX, startY, Direction.DOWN, (iteration, pixel) => {
            const result = this.scan(pixel.x, pixel.y, Direction.RIGHT, actionFunc);
            if (result !== undefined) return result;
        });
        if (result !== undefined) return result;
    }

    scan(startX, startY, direction, actionFunc) {
        if (typeof actionFunc !== 'function') return;
        if (direction !== Direction.LEFT && direction !== Direction.RIGHT && direction !== Direction.UP && direction !== Direction.DOWN) return;
        
        let x = startX, y = startY, increment = Math.sign(direction);
        let result;
        const pixel = new Pixel(x, y, this);
        if (direction === Direction.LEFT || direction === Direction.RIGHT) {
            while (this.isValidX(x)) {
                pixel.x = x;
                result = actionFunc(Math.abs(x - startX) + 1, pixel);
                //se la funzione ritorna qualcosa si ferma il ciclo
                if (result !== undefined) return result;
                x = x + increment;
            }
        } else {
            while (this.isValidY(y)) {
                pixel.y = y;
                result = actionFunc(Math.abs(y - startY) + 1, pixel);
                //se la funzione ritorna qualcosa si ferma il ciclo
                if (result !== undefined) return result;
                y = y + increment;
            }
        }
    }
    
    // forEachUntil(startX, startY, direction, untilFunc) {
    //     if (typeof untilFunc !== 'function') return;

    //     let canContinue;
    //     return this.forEach(startX, startY, direction, (x, y, iteration, color) => {
    //         if (untilFunc(x, y, iteration, color)) return [];
    //     });
    // }

    put(dest, ...args) {
        if (dest instanceof CanvasRenderingContext2D) {
            let dx = this.#offset.x;
            let dy = this.#offset.y;
            
            if (args.length === 2 && typeof args[0] === 'number' && typeof args[1] === 'number') {
                dx = args[0];
                dy = args[1];
            }
            dest.putImageData(this.#rawData, dx, dy);
        }
    }
}

export { Direction, ImageDataScanner };