import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CustomDialog = ({title, isOpen, onClose, children, otherButtons, className}) => {

    // const [opened, setOpened] = React.useState(isOpened);

    // React.useEffect(() => {
    //     console.log(`useEffect. isOpen: ${isOpened}`);
    //     setOpened(isOpened);
    // }, [isOpened]);
    
    const handleClose = (e) => {
        
        // console.log(`handleClose. setOpened(false)`);
        // setOpened(false);
        if (typeof onClose === 'function') onClose(e);
        
    }
    console.log(`Render ShareDialog. isOpened: ${isOpen}`);

    return (
        <Dialog className={`customMUIDialog smoothBorder ${className}`} open={isOpen} TransitionComponent={Transition} transitionDuration={500} keepMounted onClose={handleClose} aria-describedby={title + ' dialog'}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                {otherButtons && otherButtons()}
            </DialogActions>
        </Dialog>
    )
}


export default CustomDialog;
