/*  Esempio pointcloud da immagine https://stackoverflow.com/questions/61510144/turning-an-image-into-point-cloud-in-three-js 
        //https://programmer.group/53-three.js-using-the.pointcloud-to-bulk-manage-particles.html
        //https://tympanus.net/codrops/2019/01/17/interactive-particles-with-three-js/

    Esempio aggiorna matrix:
        mesh.updateMatrix();
        mesh.geometry.applyMatrix(mesh.matrix);
        mesh.updateMatrixWorld(true);

    Esempio pick con GPU:
        https://github.com/bzztbomb/three_js_gpu_picking
*/

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import { point }  from '@flatten-js/core';
import { Direction, ImageDataScanner } from './ImageDataScanner';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import * as Geometry from './Geometry';
import Delaunator from 'delaunator';
import * as MediaPipeUtility from './MediaPipeUtility.js';
import averageColor from 'average-color';
import { Box2, BufferAttribute, Vector2, Vector3 } from 'three';
import * as ReallusionUtility from './ReallusionUtility.js';

//import { CSG } from 'three-csg-ts';     // CSG.subtract(box, sphere); CSG.union(box, sphere); CSG.intersect(box, sphere); https://www.npmjs.com/package/three-csg-ts

const idxMentoMesh=5685;   //Vertice mento nel modello MakeHuman

const MeshesType ={MESHVARIANTI:'ProdVariation',MODELBASE:'ModelBase',MODELVRM:'ModelVRM',UNKNOW:'Unknow',FACEWEBCAM:'FaceWebcam',HAIR:'Hair',PROGRESSMODELFACE:'ProgressModelFace'};

///// File utility

function loaderScene(urlfName,renderer,callback){
    const fLoader = new THREE.FileLoader();
    fLoader.load(urlfName, function (text) {
            let projJSON = JSON.parse(text);

            let project = projJSON.project;

            if (project.vr !== undefined) renderer.xr.enabled = project.vr;
            if (project.shadows !== undefined) renderer.shadowMap.enabled = project.shadows;
            if (project.shadowType !== undefined) renderer.shadowMap.type = project.shadowType;
            if (project.toneMapping !== undefined) renderer.toneMapping = project.toneMapping;
            if (project.toneMappingExposure !== undefined) renderer.toneMappingExposure = project.toneMappingExposure;
            if (project.physicallyCorrectLights !== undefined) renderer.physicallyCorrectLights = project.physicallyCorrectLights;

            let loader = new THREE.ObjectLoader();

            //if (callback) callback(loader.parse(projJSON.scene));
            if (callback) loader.parse(projJSON.scene, callback);
    });
}


function loaderMesh(urlOrFileList, ForceDepthWrite, callback) {
    var filename='';
    var extension ='glb';
    if (typeof urlOrFileList === 'object' && urlOrFileList.constructor.name === 'FileList') {
       filename = urlOrFileList[0].name;
       extension = filename.split( '.' ).pop().toLowerCase();
    } else {
        filename = urlOrFileList;
        extension = filename.split( '.' ).pop().toLowerCase(); 
    }
	
    var loadCallback=0;
    var loader=0;
    
    switch (extension)
    {
        case 'glb':
        {  
            loader = new GLTFLoader();
            loadCallback = (gltf) => {
                var object = gltf.scene;
                object.animations=gltf.animations;
                if(ForceDepthWrite){
                    object.traverse((child) => {
                        if (child.isMesh) {
                            child.material.depthWrite=true; //Per sicurezza , a volte EDITOR salva GLB con false specialmente se mesh da FMX...
                            child.material.format = THREE.RGBAFormat;   //Altrimenti a volte non funziona bene opacità....per sfumare durante il cambio di prodotto...se animato...
                        }
                    });
                }
                if (callback) callback(object);
            };
            break;
        }
        case 'fbx':
        {  
            loader = new FBXLoader();
            loadCallback = (fbx) => {
                var object = fbx;
                object.animations=fbx.animations;
                if(ForceDepthWrite){
                    object.traverse((child) => {
                        if (child.isMesh) {
                            child.material.depthWrite=true; //Per sicurezza , a volte EDITOR salva GLB con false specialmente se mesh da FMX...
                            child.material.format = THREE.RGBAFormat;   //Altrimenti a volte non funziona bene opacità....per sfumare durante il cambio di prodotto...se animato...
                        }
                    });
                }
                if (callback) callback(object);
            };
            break;
        }
    }    


    if (typeof urlOrFileList === 'object' && urlOrFileList.constructor.name === 'FileList') {
        
        const filename = urlOrFileList[0].name;
        const extension = filename.split( '.' ).pop().toLowerCase();
        if (extension !== 'glb') return;

        const reader = new FileReader();
        reader.addEventListener( 'load', async function ( event ) {

            loader.parse(event.target.result, '', loadCallback);

        }, false);

        reader.readAsArrayBuffer( urlOrFileList[0] );

    } else {
        loader.load(urlOrFileList, loadCallback);
    }
}

function loaderImage(urlfName, callback) {
    const loader = new THREE.ImageLoader();
    loader.load(urlfName,(img)=> {
        if (callback) callback(img);
    });
}

/*
const head = await new Promise((res, rej) => {
  loader.load(
    `./Assets/head.gltf`,
    (gltf) => {
      const object = gltf.scene;

      object.traverse((child) => {
        if (child.isMesh) {
          child.material.metalness = 0;
          child.material.vertexColors = false;
          child.material.map = texture;
        }
      });

      res(object);
    },
    (e) => rej(e)
  );
});*/

//////Scene utility

function sceneAddCategory(scene, obj, prodCat, type){
    obj.userData.tryon={type:type};
    if(prodCat)obj.userData.tryon.prodCat=prodCat;
    scene.add(obj);
}

function sceneAddProdVariation(mesh, prodVar) {
    if (typeof mesh !== 'object' || !mesh) return;
    if (typeof prodVar !== 'object' || !prodVar) return;

    if (mesh?.userData?.tryon && typeof mesh.userData.tryon === 'object') {
        mesh.userData.tryon.prodVar = prodVar;
    }
}

function sceneRemoveCategory(scene, excludeobj){
    let toRemove=[];
    if (!excludeobj.userData.tryon?.prodCat)return;
    scene.traverse( function ( child ) {

        if(sameCategory(child,excludeobj)){
            toRemove.push(child);
        }

//        if ( child !== excludeobj && child.userData.tryon?.prodCat) {  //Non rimuove se stesso...
//            if(child.userData.tryon.prodCat[0][2].name===excludeobj.userData.tryon.prodCat[0][2].name ){
//                toRemove.push(child);
//            }
//        }
    });
    for(var i=0; i<toRemove.length; i++){
        //scene.remove(toRemove[i]);    Può non funzionare perchè non ricorsivo...
        toRemove[i].parent.remove(toRemove[i])

    }
}

function sceneRemoveCategoryType(scene, type){
    let toRemove=[];
    scene.traverse( function ( child ) {
        if ( child.userData.tryon?.type===type) {  
            toRemove.push(child);
        }
    });
    for(var i=0; i<toRemove.length; i++){
        toRemove[i].parent.remove(toRemove[i])
    }
}

function sceneRemoveCategoryAll(scene){
    let toRemove=[];
    scene.traverse( function ( child ) {
        if(child.userData.tryon?.prodCat || child.name==='Pantaloni' || child.name==='Camicie'){
            toRemove.push(child);
        }
    });
    for(var i=0; i<toRemove.length; i++){
        toRemove[i].parent.remove(toRemove[i])
    }
}

function sameCategory(cRes,cObj){
    if(cRes !== cObj){
        if(cRes instanceof THREE.Mesh){
            if( cRes.name===cObj.userData.tryon.prodCat[0][2].name){  //'Plaid_Punk_Shirt'   'Pantaloni'    'Camicie'
                return(cRes);
            }
        }

        if(cRes.userData.tryon?.prodCat && cRes.userData.tryon?.prodCat[0][2].name===cObj.userData.tryon.prodCat[0][2].name ){
            return(cRes);
        }
    }
}

function sceneFindItemCategory(scene,obj){
    if (!obj.userData.tryon?.prodCat)return;
    let r;
    scene.traverse( function ( child ) {
        let res=sameCategory(child,obj)
        if(res)r=res;
     
/*        if(child instanceof THREE.Mesh && child.userData){
            if( child.userData.name==='Plaid_Punk_Shirt'){
                r=child;
            }
        }
        if(child !== obj && child.userData.tryon?.prodCat && child.userData.tryon?.prodCat[0][2].name===obj.userData.tryon.prodCat[0][2].name ){
            r=child;
        }*/
    });
    return(r);
}

function sceneFindItemCategoryRoot(scene,type){     //Più veloce perchè scorre solo i child nella root (senza annidamenti)
    for(var child = 0; child < scene.children.length ; child++){
        if(scene.children[child].userData.tryon && scene.children[child].userData.tryon?.type===type ){
            return({obj:scene.children[child], bolFind:true});
        }
    };
    return({obj:null, bolFind:false});
}

function sceneGetItemsCategoryProdCatRoot(scene){     //Ritorna array con le varianti inserite nella scena Più veloce perchè scorre solo i child nella root (senza guardare annidamenti)
    let r=[];
    for(var child = 0; child < scene.children.length ; child++){
        if(scene.children[child].userData.tryon && scene.children[child].userData.tryon?.prodCat){
            r.push(scene.children[child].userData.tryon.prodCat);
        }
    };
    return(r);
}

function getProdVariations(scene) {
    return scene.children.reduce((result, child) => {
        if (child.userData.tryon?.prodVar) result.push(child.userData.tryon?.prodVar);
        return result;
    }, []);
}

function rayFromMesh(mesh, VertexMesh, meshDest ){
    let raycaster = new THREE.Raycaster();
    const intersects = [];
    let meshPt=new THREE.Vector3();
    let meshNormal=new THREE.Vector3();
    let meshPtWord=new THREE.Vector3();
    mesh=getSkinnedMesh(mesh);

    meshPt.fromBufferAttribute(mesh.geometry.attributes.position,VertexMesh);
    meshPtWord=GetWordVertexCoord(mesh, VertexMesh);
    
    meshNormal.fromBufferAttribute(mesh.geometry.attributes.normal,VertexMesh);

    meshPtWord.add(meshNormal);//Porta il punto un pò all'esterno per essere sicuro che il raycast trovi la superfice (se punto sulla o sotto la superfice non lo troverebbe)

    meshNormal.multiply(new THREE.Vector3(-1,-1,-1));//Inverte direzione della normale

    raycaster.set(meshPtWord, meshNormal);
    raycaster.intersectObject( getSkinnedMesh(meshDest), false, intersects );

    if ( intersects.length > 0 ) {
        return(intersects[0])
    }
}

function rayCheckIntersection( x, y, camera, window, scene ) {       //https://github.com/mrdoob/three.js/blob/master/examples/webgl_decals.html

    if ( scene === undefined ) return;
    //mesh=getSkinnedMesh(mesh);
    let intersects = [];

    const mouse = new THREE.Vector2();
    let raycaster = new THREE.Raycaster();

    const geometry = new THREE.BufferGeometry();
    geometry.setFromPoints( [ new THREE.Vector3(), new THREE.Vector3() ] );
    let line = new THREE.Line( geometry, new THREE.LineBasicMaterial() );
    scene.add( line );    

    mouse.x = ( x / window.innerWidth ) * 2 - 1;
    mouse.y = - ( y / window.innerHeight ) * 2 + 1;

    raycaster.setFromCamera( mouse, camera );
    intersects=raycaster.intersectObjects( scene.children );

    if ( intersects.length > 0 ) {

            const p = intersects[ 0 ].point;

            const n = intersects[ 0 ].face.normal.clone();
            n.transformDirection( intersects[ 0 ].object.matrixWorld );
            n.multiplyScalar( 10 );
            n.add( intersects[ 0 ].point );

            const positions = line.geometry.attributes.position;
            positions.setXYZ( 0, p.x, p.y, p.z );
            positions.setXYZ( 1, n.x, n.y, n.z );
            positions.needsUpdate = true;

            intersects.length = 0;

    } else {


    }
}

function meshToCanvas(mesh, canvasWidth = 300) {
    if (!mesh) return;
    if (!(mesh.material instanceof THREE.MeshBasicMaterial)) {
        if (!mesh.material?.map) return;

        mesh.material = new THREE.MeshBasicMaterial({map: mesh.material.map});
    } 

    mesh.geometry.computeBoundingBox();
    mesh.updateMatrixWorld(true);
    const worldBB = mesh.geometry.boundingBox.clone().applyMatrix4(mesh.matrixWorld);
    const meshSize = new THREE.Vector3();
    worldBB.getSize(meshSize);
    
    const cnv = document.createElement('canvas');
    cnv.width = canvasWidth;
    cnv.height = cnv.width * (meshSize.y / meshSize.x);

    const renderer = new THREE.WebGLRenderer({ canvas: cnv, antialias: true, alpha: true });
    renderer.outputEncoding = THREE.sRGBEncoding;

    const camOffsetZ = 5;
    const camera = new THREE.OrthographicCamera(-meshSize.x / 2, meshSize.x / 2, meshSize.y / 2, -meshSize.y / 2, 0.1, camOffsetZ + meshSize.z + 1);
    camera.position.set((worldBB.min.x + worldBB.max.x) / 2, (worldBB.min.y + worldBB.max.y) / 2, worldBB.max.z + camOffsetZ);

    const scene = new THREE.Scene();
    scene.add(mesh);
    
    const prevFrustumCulled = mesh.frustumCulled;
    mesh.frustumCulled = false;
    renderer.render(scene, camera);
    mesh.frustumCulled = prevFrustumCulled;
    scene.remove(mesh);

    const resCnv = document.createElement('canvas');
    resCnv.width =cnv.width;
    resCnv.height=cnv.height;
    const resCtx = resCnv.getContext('2d');
    resCtx.drawImage(cnv, 0, 0);
    //downloadCanvas(resCnv);

    return resCnv;
}

///// Various utility

function addLabel(font, caption, location ) {
    const textGeo = new TextGeometry( caption, {
        font: font,
        size: 20,
        height: 1,
        curveSegments: 1
    } );
    const textMaterial = new THREE.MeshBasicMaterial();
    const textMesh = new THREE.Mesh( textGeo, textMaterial );
    textMesh.position.copy( location );
    return(textMesh);
}
//addLabel( "-gradientMap", new THREE.Vector3( - 350, 0, 0 ) );

function getSkinnedMesh(obj){       //Ritorna skinnedmesh da un modello...
    let r ;
    let r1;
    obj.traverse( function ( child ) {
        if(child.isMesh){       // isSkinnedMesh
            r = child;
            if(!r1)r1= ReallusionUtility.getMeshHead(child);//Prioritaria la mesh della testa se reallusion...
        }
    });
    if(r1)return(r1);
    return(r);
}

function traverseGetSkeleton(obj){       
    let r ;
    //let sk=getSkinnedMesh(obj).skeleton;
    //sk.bones[0].rotation.x=0.5
    //for(let i=0;i<sk.bones.length;i++){
      //  sk.bones[i].updateMatrixWorld()
    //}

    obj.traverse(function(child){
        if (child.isBone){
            child.updateMatrixWorld();
        }
    })

    obj.traverse(function(child){
        if(child.isSkinnedMesh && child.skeleton){
           // for(let i=0;i<child.skeleton.bones.length;i++){
             //   child.skeleton.bones[i].updateMatrixWorld()
            //}

            //child.skeleton.calculateInverses();
            child.skeleton.update()
            //child.skeleton.pose();
        }
    })

    //sk.calculateInverses();
    //sk.pose();

/*
    let skel = new THREE.Skeleton( sk.bones );
    obj.traverse( function ( child ) {
        if(child.isSkinnedMesh){
            if(child.skeleton){
                //child.skeleton=sk;
                child.bind(skel);
            }
        }
    });

    //skel.bones[0].rotation.x=0.5
    for(let i=0;i<skel.bones.length;i++){
        //skel.bones[i].updateMatrixWorld()
    }
    */

    //sk.boneInverses[0].copy(sk.bones[0].matrixWorld).invert();
    //skel.calculateInverses();
    //skel.update();
    //skel.pose();
}

function traverseBindSkeleton(obj,Model){       //Binda tutte le skinned mesh allo skeleton del modello
    let m=getSkinnedMesh(Model);
    if(!m.isSkinnedMesh || !m.skeleton)return;
    obj.traverse( function ( child ) {
        if(child.isSkinnedMesh){
            child.bind( new THREE.Skeleton(m.skeleton.bones) );
        }
    });
}

function traverse_Set_Reset_Transparent(obj,trasparent,origTrasparent=false){       //Imposta trasparenza con possibilità di ripristinare come originale...
    obj.traverse( function ( child ) {
        if(child.isMesh && child.material){
            if(child.material.saveTransparent===undefined )child.material.saveTransparent=child.material.transparent;  //Salva valore originale
            if(!origTrasparent){    //Se deve impostare un valore
                child.material.transparent=trasparent;    
            }else{                  //Se deve rimettere come originale
                if(child.material.saveTransparent!==undefined )child.material.transparent=child.material.saveTransparent;
            }
            child.material.needsUpdate=true;
        }
    });
}

function traverseSetOpacity(obj,val){       //Imposta opacità in tutte le mesh dell'oggetto
    obj.traverse( function ( child ) {
    if(child.isMesh && child.material){
        child.material.opacity=val ;
        child.material.needsUpdate=true;
    }
});
}

function traverseSetSide(obj,val){       
    obj.traverse( function ( child ) {
    if(child.isMesh && child.material){
        child.material.side=val ;
        child.material.needsUpdate=true;
    }
});
}

function traverseSetRenderOrder(obj,val){       //Imposta render order
    obj.traverse( function ( child ) {
    if(child.isMesh ){
        child.renderOrder=val ;
    }
});
}

function traverseSetFunctionBeforeRender(obj){       //Imposta funzione BeforeRender per azzerare la Z nel render...
    obj.traverse( function ( child ) {
    if(child.isMesh ){
        child.onBeforeRender = function (renderer) { 
            renderer.clearDepth(); 
        };
}
});
}

function traverseSetFrustumCull(obj,val){       //Imposta FrustumCull in tutte le mesh dell'oggetto
    obj.traverse( function ( child ) {
    if(child.isMesh && child.material){
        child.frustumCulled=val ;
    }
});
}

function reindexBufferGeometry(bufferGeometry,options){
    let nonIndexed = bufferGeometry.index ? bufferGeometry.toNonIndexed() : bufferGeometry   
    let attribs = nonIndexed.attributes
    let extractVertex = (index) =>{
        let out={}
        for(let j in attribs){
            let attr=attribs[j];
            let isz = attr.itemSize;
            let aout = out[j]=[]
            let aisz = index * isz
            for(let k=0;k<isz;k++)
                aout.push(attr.array[k+aisz])
        }
        return out
    }
    let hashVertex = (vtx)=>{
        return JSON.stringify(vtx)
    }
    let verts = attribs.position
    let uniqueVerts=[]
    let uniqueIndices={}
    let indices=[]
    for(let i=0,ct=verts.count;i<ct;i++){
        let vert = extractVertex(i)
        let hash = ''+JSON.stringify(vert)
        let idx = uniqueIndices[hash]
        if(idx===undefined){
            idx = uniqueIndices[hash] = uniqueVerts.length;
            uniqueVerts.push(vert)
        }
        indices.push(idx)
    }
    let outAttrs={}
    for(let j in attribs){
        let attr=attribs[j];
        outAttrs[j]=[];
       // console.log("before:",j,attr.count * attr.itemSize)
    }
    for(let i=0;i<uniqueVerts.length;i++){
        let vert = uniqueVerts[i]
        for(let j in vert)
            for(let k=0;k<vert[j].length;k++)
                outAttrs[j].push(vert[j][k])
    }
    for(let j in outAttrs){
        let attr=attribs[j];
      //  console.log("after:",j,attr.count * attr.itemSize,outAttrs[j].length)
    }
}


function From2dCanvsTo3d(clientX,clientY,camera) {
    const range = camera.position.z * Math.tan( camera.fov / 360.0 * Math.PI );
    const px = ( 2.0 * clientX - window.innerWidth ) / window.innerHeight * range;
    const py = -( 2.0 * clientY - window.innerHeight ) / window.innerHeight * range;
    return {x:px, y:py} ;
}

function from3DTo2D(coord, camera, canvas) {
    const lCoord = (coord instanceof THREE.Vector3) ? coord.clone() : new THREE.Vector3(coord.x, coord.y, coord.z);
    lCoord.project(camera);
    const center = {x: canvas.clientWidth / 2, y: canvas.clientHeight / 2};
    return {
        x: center.x + lCoord.x * center.x,
        y: center.y - lCoord.y * center.y,
    }
}

function normCoordScaler(width, height) {
    return function(normalizedCoor, fromCenter) {
        fromCenter = fromCenter || false;
        if (fromCenter) {
            const center = {x: width / 2, y: height / 2};
            return {
                x: center.x + normalizedCoor.x * center.x,
                y: center.y - normalizedCoor.y * center.y,
            }
        } else
        {
            return {
                x: normalizedCoor.x * width,
                y: normalizedCoor.y * height,
                z: normalizedCoor.z * width,
            }
        }
    }
}

function filterGreyScale(cnv , brightness) {
    var cnx = cnv.getContext('2d');
    var imgPixels = cnx.getImageData(0, 0, cnv.width, cnv.height);

    for(var y = 0; y < cnv.height; y++){
        for(var x = 0; x < cnv.width; x++){
            var i = (y * 4) * cnv.width + x * 4;
            var avg =((imgPixels.data[i + 0] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) / 3) +brightness;
            imgPixels.data[i + 0] = avg;
            imgPixels.data[i + 1] = avg;
            imgPixels.data[i + 2] = avg;
        }
    }
    cnx.putImageData(imgPixels, 0, 0, 0, 0, imgPixels.width, imgPixels.height);

    return cnv;
}

function filterAlphaScale(map , alpha) {
    let cnv = document.createElement('canvas');
    cnv.width  = map.image.width;    
    cnv.height = map.image.height;    
    let cnx = cnv.getContext('2d')
    cnx.drawImage(map.image, 0, 0);

    //var cnx = cnv.getContext('2d');
    var imgPixels = cnx.getImageData(0, 0, cnv.width, cnv.height);

    for(var y = 0; y < cnv.height; y++){
        for(var x = 0; x < cnv.width; x++){
            var i = (y * 4) * cnv.width + x * 4;
            //var avg =((imgPixels.data[i + 0] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) / 3) ;
            var avg =imgPixels.data[i + 3];
            if(avg >0)avg=(avg)*alpha;
            //if(avg ===0)avg=5;
            if(avg>255)avg=255;
            //imgPixels.data[i + 0] = avg;
            //imgPixels.data[i + 1] = avg;
            //imgPixels.data[i + 2] = avg;
            imgPixels.data[i + 3] = avg;
        }
    }
    cnx.putImageData(imgPixels, 0, 0, 0, 0, imgPixels.width, imgPixels.height);

    map= new THREE.CanvasTexture( cnv )
    map.flipY=false;//in new la rimette true...
    map.image.needsUpdate=true;
    
    return map;
}

function filterAlphaBorder(canv, borderWidth = 1) {
    const Ctx = canv.getContext('2d');
    const Data = Ctx.getImageData(0, 0, canv.width, canv.height);

    const alphaStep = 230 / borderWidth; //non avvicinarsi al 255 altrimenti l'ultima linea di sfumatura diventa praticamente opaca
    for (let countIdx = 1; countIdx <= borderWidth; countIdx++) {
        let idx=0;
        let idxChange=[];
        for (let y = 0; y <canv.height; y++) {
            for (let x = 0; x <canv.width; x++) {
                if(Data.data[idx+3]===255 ){
                    if (Data.data[idx+3+4] !== 255 || Data.data[idx+3-4] !== 255 ||  //Se left o right bordo
                       Data.data[idx+3+(canv.width*4)]!==255 || Data.data[idx+3-(canv.width*4)]!==255 //Se sopra o sotto bordo
                    )
                    {
                        idxChange.push(idx+3);  
                    }
                }
                idx+=4;
            }
        }

        for (var x = 0; x < idxChange.length; x++) {
             //Data.data[idxChange[x]-3]=255;
             //Data.data[idxChange[x]-2]=0;
             //Data.data[idxChange[x]-1]=0;
            Data.data[idxChange[x]] = alphaStep * countIdx;
        }
    }


    Ctx.putImageData(Data, 0, 0, 0, 0, Data.width, Data.height);

}

function FilterImgLightness(img, lightnessDelta) {
    const imgCtx = img.getContext('2d');
    const imgDataScanner = new ImageDataScanner(imgCtx);
    // var colorConverter = require('color-convert');
    let hsl;
    imgDataScanner.scanAll(0, 0, (iteration, pixel) => {
        hsl = RGBToHSL(pixel.color);
        hsl[2] += lightnessDelta;
        pixel.color = HSLToRGB(hsl);
    });
    imgDataScanner.put(imgCtx);

    return img;
}

function filterRGBToImg(R, G, B, LDelta, cnv) {

    if (! (cnv instanceof HTMLCanvasElement)) return;

    const mainHSL = RGBToHSL([R, G, B]);
    let imgCtx = cnv.getContext('2d');
    const imgDataScanner = new ImageDataScanner(imgCtx);
    imgDataScanner.scanAll(0, 0, (iteration, pixel) => {
        //let hsl = RGBToHSL(pixel.color);
        var avg =((pixel.color[0] + pixel.color[1] + pixel.color[2]) / 3)+LDelta;
        pixel.color=[(R+avg),(G+avg),(B+avg)];
    });
    imgDataScanner.put(imgCtx);
}
  
  function saveArrayBuffer(buffer, filename) {
    save(new Blob([buffer], { type: 'application/octet-stream' }), filename);
  }
  
  function save(blob, filename) {
    var link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }

function downloadGlb(obj,fname) {
    const exporter = new GLTFExporter();
    exporter.parse(
        obj,
        (result) => {
            saveArrayBuffer(result, fname);
        },
        () => {},
        { binary: true }
    );
}

function downloadCanvas(cnv, filename = 'Test.png'){
    var link=document.createElement('a')
    link.download=filename;
    link.href=cnv.toDataURL();
    link.click();
} 

function downloadImage(img, filename = 'Test.png'){
    let imgCanvas = document.createElement('canvas');
    imgCanvas.width  = img.width;    
    imgCanvas.height = img.height;    
    let ctx = imgCanvas.getContext('2d')
    ctx.drawImage(img, 0, 0);
    downloadCanvas(imgCanvas, filename);
}    

function GetWordVertexCoord(mesh, idxVertex){
    let meshPtWord=new THREE.Vector3();
    meshPtWord.fromBufferAttribute(mesh.geometry.attributes.position,idxVertex);
    mesh.localToWorld(meshPtWord);
    return(meshPtWord);
}

function SetVertexCoordFromMesh(meshSorg, meshDest, idxVertex){
    let PtSorg=GetWordVertexCoord(meshSorg, idxVertex);
    let PtDest=GetWordVertexCoord(meshDest, idxVertex);

    meshDest.geometry.attributes.position.array[(idxVertex*3)+0]+=(PtSorg.x-PtDest.x);        
    meshDest.geometry.attributes.position.array[(idxVertex*3)+1]+=(PtSorg.y-PtDest.y);        
    meshDest.geometry.attributes.position.array[(idxVertex*3)+2]+=(PtSorg.z-PtDest.z);        
}

function cloneMaterialFromMesh(Mesh){
    let modelMeshMat=getSkinnedMesh(Mesh).material;
    let material;
    if(false){   //Non funziona la trasparenza
        material=new THREE.MeshStandardMaterial().copy(modelMeshMat);
        if(modelMeshMat.saveColor)material.color =modelMeshMat.saveColor;
        if(modelMeshMat.saveMetalness)material.metalness =modelMeshMat.saveMetalness;
        if(modelMeshMat.saveRoughness)material.roughness =modelMeshMat.saveRoughness;    
        material.normalMap=null;
        material.transparent= true;
        material.opacity=1;
        material.side=2;
        //material.depthTest=false;
    }else{
        material = new THREE.MeshStandardMaterial({
            metalness:modelMeshMat.metalness,      
            roughness:modelMeshMat.roughness,   //aumentando si scurisce...
            //normalMap:modelMeshMat.normalMap,
            transparent: true,
            //wireframe:true,
            side:THREE.DoubleSide,
            //depthTest:false,
        });
    }
    return(material);
}

///// Face to mesh utility

function faceLandmarksGetInfo(faceLandmarks){       
    let maskWidth=new THREE.Vector2(faceLandmarks[MediaPipeUtility.idxFaceLeft].x,faceLandmarks[MediaPipeUtility.idxFaceLeft].y).distanceTo(new THREE.Vector2(faceLandmarks[MediaPipeUtility.idxFaceRight].x,faceLandmarks[MediaPipeUtility.idxFaceRight].y));
    let maskHeight=new THREE.Vector2(faceLandmarks[MediaPipeUtility.idxFaceTop].x,faceLandmarks[MediaPipeUtility.idxFaceTop].y).distanceTo(new THREE.Vector2(faceLandmarks[MediaPipeUtility.idxFaceBottom].x,faceLandmarks[MediaPipeUtility.idxFaceBottom].y));
    let halfWidthLeft=new THREE.Vector2(faceLandmarks[MediaPipeUtility.idxFaceLeft].x,faceLandmarks[MediaPipeUtility.idxFaceLeft].y).distanceTo(new THREE.Vector2(faceLandmarks[MediaPipeUtility.idxFaceCenter].x,faceLandmarks[MediaPipeUtility.idxFaceCenter].y));
    let halfWidthRight=new THREE.Vector2(faceLandmarks[MediaPipeUtility.idxFaceRight].x,faceLandmarks[MediaPipeUtility.idxFaceRight].y).distanceTo(new THREE.Vector2(faceLandmarks[MediaPipeUtility.idxFaceCenter].x,faceLandmarks[MediaPipeUtility.idxFaceCenter].y));
return({w:maskWidth, h:maskHeight, halfWidthLeft:halfWidthLeft, halfWidthRight:halfWidthRight})
}

const smoothColor = [80, 80, 80];  //Colore sfumatura mento
function cutRegionFace(keyPts, {smooth = 4} = {}) {
    if (! (keyPts.image && keyPts.segmentationMask && keyPts.faceLandmarks?.length)) return;
    smooth = (smooth >= 0) ? smooth : 0; //Just in case smooth was negative

    const coordScaler = normCoordScaler(keyPts.image.width, keyPts.image.height);

    //#region Compute face bound rect
    let bRect = keyPts.faceLandmarks.reduce((prevResult, pt) => {
        const realCoord = coordScaler(pt);
        return prevResult.merge(point(realCoord.x, realCoord.y).box);
    }, point(coordScaler(keyPts.faceLandmarks[0]).x, coordScaler(keyPts.faceLandmarks[0]).y).box);
    //#endregion
    
    const mentonPts = [                 // Keypoints map :  https://raw.githubusercontent.com/google/mediapipe/master/mediapipe/modules/face_geometry/data/canonical_face_model_uv_visualization.png
        // {x: 0, y: 0},
        // {...coordScaler(keyPts.faceLandmarks[58]), x: 0},
        coordScaler(keyPts.faceLandmarks[58]),
        coordScaler(keyPts.faceLandmarks[172]),
        coordScaler(keyPts.faceLandmarks[136]),
        coordScaler(keyPts.faceLandmarks[150]),
        coordScaler(keyPts.faceLandmarks[149]),
        coordScaler(keyPts.faceLandmarks[176]),
        coordScaler(keyPts.faceLandmarks[148]),
        coordScaler(keyPts.faceLandmarks[152]),
        coordScaler(keyPts.faceLandmarks[377]),
        coordScaler(keyPts.faceLandmarks[400]),
        coordScaler(keyPts.faceLandmarks[378]),
        coordScaler(keyPts.faceLandmarks[379]),
        coordScaler(keyPts.faceLandmarks[365]),
        coordScaler(keyPts.faceLandmarks[397]),
        coordScaler(keyPts.faceLandmarks[288]),
        // {...coordScaler(keyPts.faceLandmarks[288]), x: keyPts.segmentationMask.width - 1},
        // {...coordScaler(keyPts.faceLandmarks[288]), x: keyPts.segmentationMask.width - 1, y: 0},
    ];

    let maskCanvas = document.createElement('canvas');
    let imgCanvas = document.createElement('canvas');
    maskCanvas.width = imgCanvas.width = keyPts.segmentationMask.width;
    maskCanvas.height = imgCanvas.height = keyPts.segmentationMask.height;

    let maskCtx = maskCanvas.getContext('2d');
    let imgCtx = imgCanvas.getContext('2d');
    // #region Remove all content under head using a clip region
    // ctx.beginPath();
    // ctx.moveTo(mentonPts[0].x, mentonPts[0].y);
    // for (let i = 1; i < mentonPts.length; i++) {
    //     ctx.lineTo(mentonPts[i].x, mentonPts[i].y);
    // }
    // ctx.closePath();
    // ctx.clip();
    // #endregion
    
    maskCtx.drawImage(keyPts.segmentationMask, 0, 0);
    imgCtx.drawImage(keyPts.image, 0, 0);

    const maskDataScanner = new ImageDataScanner(maskCtx);
    const imgDataScanner = new ImageDataScanner(imgCtx);

    //ymax
    let ymax = Math.floor(bRect.ymax + smooth);

    //#region Utility functions
    // const smoothFrac = (smooth > 0) ? 255 / (smooth + 1) : 0;
    function isHair(pixel) {
        return false;
    }

    function scanDown(x, y, applySmooth) {
        maskDataScanner.scan(x, y + 1, Direction.DOWN, (iteration, pixel) => {
            if (isHair(pixel)) {
                //Don't touch pixel color, instead:
                //expand xmin, xmax, ymax
            } else
            if (applySmooth && iteration <= smooth) {
                const smoothFrac = (smooth > 0) ? /*pixel.alpha*/ 255 / (smooth + 1) : 0;
                pixel.alpha = (smooth + 1 - iteration) * smoothFrac;
            } else
            {
                pixel.color = [0, 0, 0, 0];
            }
        });
        
        if (applySmooth && smooth > 0) {
            imgDataScanner.scan(x, y + 1, Direction.DOWN, (iteration, pixel) => {
                if (iteration > smooth) return 1; //stop scan
                
                pixel.color = smoothColor;
            });
        }
    }
    //#endregion

    //#region Remove below mento
        //#region Remove below mento lines
        let pts = [];
        for (let i = 0; i < mentonPts.length-1; i++) {
            // const pt1 = mentonPts[i];
            // const pt2 = mentonPts[i+1];
            const pt1 = {x: Math.round(mentonPts[i].x), y: Math.round(mentonPts[i].y)};
            const pt2 = {x: Math.round(mentonPts[i+1].x), y: Math.round(mentonPts[i+1].y)};
            Geometry.getLinePts(pt1, pt2, pts);
            pts = Geometry.UniqueY(pts, 1);
            // pts.length--; //Remove the last to prevent repetitions.
        }
        //Add the last point because it was removed from loop
        // pts.push(mentonPts[mentonPts.length - 1]);

        for (let i = 0; i < pts.length; i++) {
            scanDown(pts[i].x, pts[i].y, smooth > 0);
        }
        //#endregion

        //#region process extremes on both sides
        pts.length = 0;
        Geometry.getLinePts({x: 0, y: mentonPts[0].y}, mentonPts[0], pts);
        pts.length--; //Remove last point because it was processed from previous loop
        Geometry.getLinePts({x: keyPts.segmentationMask.width - 1, y: mentonPts[mentonPts.length - 1].y}, mentonPts[mentonPts.length - 1], pts);
        pts.length--; //Remove last point because it was processed from previous loop
        for (let i = 0; i < pts.length; i++) {
            scanDown(pts[i].x, pts[i].y, false);
        }
        //#endregion
    //#endregion


    maskDataScanner.put(maskCtx);
    imgDataScanner.put(imgCtx);

    const minOpacity = 10;
    
    //#region Find ymin looking for hair
    let ymin = Math.floor(bRect.ymin); //si parte dal top del box de la faccia
    let isRowEmpty = false;
    while (!isRowEmpty && ymin > 0) {
        const result = maskDataScanner.scan(0, ymin - 1, Direction.RIGHT, (iteration, pixel) => {
            if (pixel.alpha > minOpacity) return 1;
        });
        isRowEmpty = result === undefined;
        if (!isRowEmpty) ymin--;
    }
    //#endregion

    //#region Find xmin looking for hair
    let xmin = Math.floor(bRect.xmin); //si parte dal left del box de la faccia
    let isColumnEmpty = false;
    while (!isColumnEmpty && xmin > 0) {
        const result = maskDataScanner.scan(xmin - 1, 0, Direction.DOWN, (iteration, pixel) => {
            if (pixel.y < ymax && pixel.alpha > minOpacity) return 1;
        });
        isColumnEmpty = result === undefined;
        if (!isColumnEmpty) xmin--;
    }
    //#endregion

    //#region Find xmax looking for hair
    let xmax = Math.floor(bRect.xmax); //si parte dal right del box de la faccia
    isColumnEmpty = false;
    while (!isColumnEmpty && xmax < maskCanvas.width - 1) {
        const result = maskDataScanner.scan(xmax + 1, 0, Direction.DOWN, (iteration, pixel) => {
            if (pixel.y < ymax && pixel.alpha > minOpacity) return 1;
        });
        isColumnEmpty = result === undefined;
        if (!isColumnEmpty) xmax++;
    }
    //#endregion

    // ctx.globalCompositeOperation = 'source-in';
    // ctx.drawImage(keyPts.image, 0, 0);
    // let rslCanvas = tmpCanvas;

    //#region Create result image
    let rslCanvas = document.createElement('canvas');
    rslCanvas.width = (xmax - xmin) + 1;
    rslCanvas.height = (ymax - ymin) + 1;
    let rslCtx = rslCanvas.getContext('2d');
    rslCtx.save();
    rslCtx.drawImage(maskCanvas, xmin, ymin, rslCanvas.width, rslCanvas.height, 0, 0, rslCanvas.width, rslCanvas.height);
    
    rslCtx.globalCompositeOperation = 'source-in';
    rslCtx.drawImage(imgCanvas, xmin, ymin, rslCanvas.width, rslCanvas.height, 0, 0, rslCanvas.width, rslCanvas.height);
    rslCtx.restore();
    //#endregion
    
    return {
        faceImg: rslCanvas,
        xPixelOffset:xmin,
        yPixelOffset:ymax,
        yMin: ymin //for test only (ExtractFace)
    };
}

function PositionMeshAtModelFace(faceMesh, modelMesh ) {
    const correctionY=0;//-0.5;
    const correctionZ=0;
    faceMesh.updateMatrixWorld(true);
    
    //Cerca coordinate globali vertice del modello
    let modelPtWord=GetWordVertexCoord(getSkinnedMesh(modelMesh), ReallusionUtility.idxMentoMesh);
    //Cerca coordinate globali vertice face
    let meshPtWord=GetWordVertexCoord(faceMesh, MediaPipeUtility.idxMentoMesh);
    
    let z;
    if(true){z=modelPtWord.z+correctionZ}else{z=modelMesh.position.z};
    faceMesh.position.set(faceMesh.position.x+(modelPtWord.x-meshPtWord.x)+0, faceMesh.position.y+(modelPtWord.y-meshPtWord.y)+correctionY, z);  

    //faceMesh.geometry.attributes.position.array[(10*3)+1] +=10;//modifica Y 
    faceMesh.updateMatrixWorld(true);
    
}

function createFaceMeshAndScale(keyPts, userFaceData , faceGlbMesh , modelMesh){       //fixedFaceGlb è la mesh di google della faccia da file GLB
    let facePlaneHeight = 17.6;//18.0 ;   //Dimensione pannello 3d solo faccia
    facePlaneHeight =Math.abs(GetWordVertexCoord(faceGlbMesh, MediaPipeUtility.idxFaceBottom).y- GetWordVertexCoord(faceGlbMesh, MediaPipeUtility.idxFaceTop).y); ////Dimensione pannello 3d solo faccia come riferimento alla mess fissa letta dal GLB
    const Ratio  = (facePlaneHeight/keyPts.faceDimension.y);
    // const h = userFaceData.faceImg.height *Ratio;

    let wMesh=userFaceData.faceImg.width *Ratio;
    let hMesh=userFaceData.faceImg.height *Ratio;
    //downloadImage(userFaceData.faceImg);

    var points3d = [];

    //Punti 2d (letti da dati texture) che rispecchiano la mesh aperta per evitare problemi di sovrapposizione dei vertici 3d quando farà il delunay
    let Pts2d=MediaPipeUtility.flatUVFacePoints;   
    for (let i = 0; i < Pts2d.length; i++){
        points3d.push(new THREE.Vector3(Pts2d[i].x*wMesh, (1-Pts2d[i].y)*hMesh, 0));
    }

    //Compila i 4 vertici del boundrect del pannello che conterrà oltre la faccia la fronte ed i capelli
    if(false){ //False per mettere solo la faccia
        points3d.push(new THREE.Vector3(0, 0, 0));
        points3d.push(new THREE.Vector3(wMesh, 0, 0));
        points3d.push(new THREE.Vector3(wMesh, hMesh, 0));
        points3d.push(new THREE.Vector3(0, hMesh, 0));
    }

    // Esegue triangolazione
    var indexDelaunay = Delaunator.from(
        points3d.map(v => {
           return [v.x, v.y];
        })
    );

    //Compila i vertici da mediapipe.  Legge 10 punti in meno perchè sono le pupille e non ci sono nel modello letto in precedenza
    if(true){
        for (let i = 0; i < keyPts.faceLandmarks.length-10; i++){
            let pt3D=new THREE.Vector3((keyPts.faceLandmarks[i].x)*keyPts.image.width,(keyPts.faceLandmarks[i].y)*-keyPts.image.height,-keyPts.faceLandmarks[i].z*1200);
            pt3D.x=pt3D.x-userFaceData.xPixelOffset;
            pt3D.y=pt3D.y+userFaceData.yPixelOffset;
            pt3D.x=pt3D.x*Ratio;
            pt3D.y=pt3D.y*Ratio;
            pt3D.z=pt3D.z*Ratio;
            if(points3d.length >i){points3d[i]=pt3D};
        }
    }

    var uv= []
    for (let i = 0; i < points3d.length; i++){
        uv.push(points3d[i].x/wMesh);
        uv.push(points3d[i].y/hMesh );
    }

    if(false){       //Proiezione texture su superfice piatta
        for (let i = 0; i < Pts2d.length; i+=2){
            points3d[i/2].x=Pts2d[i]*wMesh
            points3d[i/2].y=(1-Pts2d[i+1])*hMesh
            points3d[i/2].z=0;
        }
    }

    //Centra i punti per mettere la posizione 0 in centro alla mesh come standard... non serve più perchè dopo posiziona la mesh ...
//    for (let i = 0; i < points3d.length; i++){
//        points3d[i].y=points3d[i].y-(hMesh/2);
//        points3d[i].x=points3d[i].x-(wMesh/2);
 //   }

    let geometry = new THREE.BufferGeometry().setFromPoints(points3d);
    geometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(uv),2));
    //Aggiorna gli indici calcolati da Delunay
    geometry.setIndex(new THREE.BufferAttribute(indexDelaunay.triangles,1)); 
    geometry.computeVertexNormals();
   // geometry.getAttribute("uv").needsUpdate = true;

    //Copia materiale dal modello
    let material=cloneMaterialFromMesh(modelMesh);
    material.map=new THREE.CanvasTexture(userFaceData.faceImg);
    //material.depthTest=false;
    //material.wireframe=true;
    let meshFace = new THREE.Mesh(geometry, material);

    return meshFace;
}

function fitMeshFaceToFaceFixedGlb(faceGlbMesh,meshFace){   //Muove i punti della circonferenza sulla mesh fissa letta da GLB
    let Circ=MediaPipeUtility.faceBoundary();
    for(var i=0; i<Circ.length; i++){
        SetVertexCoordFromMesh(faceGlbMesh, meshFace, Circ[i]);
    }
}

/**
 * Convert color from RGB to HSL
 * @param {Array<number>} color Array with three RGB channels
 * @returns {Array<number>} Array with three HSL channels
 */
function RGBToHSL(color) {
    const tColor = new THREE.Color();
    tColor.setRGB(color[0] / 255, color[1] / 255, color[2] / 255);
    const result = {};
    tColor.getHSL(result);
    return [result.h * 360, result.s * 100, result.l * 100];
}

/**
 * Convert color from HSL to RGB
 * @param {Array<number>} color Array with three HSL channels
 * @returns {Array<number>} Array with three RGB channels
 */
function HSLToRGB(color) {
    const tColor = new THREE.Color();
    tColor.setHSL(color[0] / 360, color[1] / 100, color[2] / 100);
    return [tColor.r * 255, tColor.g * 255, tColor.b * 255];
}

/**
 * Convert non Canvas image to Canvas. If a Canvas is passed it is returned as is.
 * Could be used in two ways: passing the image only or in addition a rectangle to be converted (x, y, width, height)
 * @param {HTMLImageElement|ImageBitmap} img Image to be converted to canvas. Any type allowed by CanvasRenderingContext2D.drawImage()
 * @param {Number} x 
 * @param {Number} y
 * @param {Number} width
 * @param {Number} height
 * @returns {Array<number>} Array with three RGB channels
 */
function imgToCanvas(img, ...rest) {
    if (!img) return;
    if (img instanceof HTMLCanvasElement) return img;
    
    //Per default si prende tutta la immagine
    let sx = 0, sy = 0, sWidth = img.width, sHeight = img.height;

    //Fa la conversione solo di un pezzo della immagine originale
    if (rest.length === 4) {
        [sx, sy, sWidth, sHeight] = rest;
    }

    const cnv = document.createElement('canvas');
    cnv.width = sWidth;
    cnv.height = sHeight;
    const ctx = cnv.getContext('2d');
    
    ctx.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, sWidth, sHeight);

    return cnv;
}

function createCanvas(src) {
    const cnv = document.createElement('canvas');
    let w = 0, h = 0, copy = false;
    if (src instanceof HTMLCanvasElement) {
        w = src.width;
        h = src.height;
        copy = true;
    }

    cnv.width = w;
    cnv.height = h;

    if (copy) {
        const ctx = cnv.getContext('2d');
        ctx.drawImage(src, 0, 0);        
    }

    return cnv;
}

/**
 * Apply average color from a specific source canvas area to entire destination canvas.
 * Destination Lightness is not altered.
 * @param {HTMLCanvasElement} srcCanvas The source canvas to compute average HSL color
 * @param {THREE.Box2} srcArea Three.js Box2 object to compute average HSL color
 * @param {HTMLCanvasElement} destCanvas Array with three HSL channels
 */
function applyAvgColor(srcCanvas, srcArea, destCanvas) {

    //#region Compute average HSL color
    let imgCtx = srcCanvas.getContext('2d');
    const imgDataScanner = new ImageDataScanner(imgCtx, srcArea.min.x, srcArea.min.y, srcArea.width, srcArea.height);
    // var colorConverter = require('color-convert');
    const hslColors = [];
    imgDataScanner.scanAll(0, 0, (iteration, pixel) => {
        // hslColors.push(colorConverter.rgb.hsl(pixel.color));
        hslColors.push(RGBToHSL(pixel.color));
    });
    const avgColor = averageColor(hslColors);
    //#endregion

    //#region Apply average HSL to destination canvas
    let destCtx = destCanvas.getContext('2d');

    const ttrDataScanner = new ImageDataScanner(destCtx);
    ttrDataScanner.scanAll(0, 0, (iteration, pixel) => {
        // const hsl = colorConverter.rgb.hsl(pixel.color);
        const hsl = RGBToHSL(pixel.color);
        hsl[0] = avgColor[0];
        hsl[1] = avgColor[1];
        // pixel.color = colorConverter.hsl.rgb(hsl);
        pixel.color = HSLToRGB(hsl);
    });
    ttrDataScanner.put(destCtx);
    //#endregion
}

function applyModelColorFromFace(keyPts, material) {
    if (! (keyPts.image && keyPts.faceLandmarks?.length)) return;
    if (!material?.map?.image) return;

    //#region Find face area to calculate average HSL color
    const coordScaler = normCoordScaler(keyPts.image.width, keyPts.image.height);

    let box = new THREE.Box2();
    box.setFromPoints([
        coordScaler(new THREE.Vector2(keyPts.faceLandmarks[108].x, keyPts.faceLandmarks[108].y)),
        coordScaler(new THREE.Vector2(keyPts.faceLandmarks[337].x, keyPts.faceLandmarks[337].y)),
        coordScaler(new THREE.Vector2(keyPts.faceLandmarks[107].x, keyPts.faceLandmarks[107].y)),
        coordScaler(new THREE.Vector2(keyPts.faceLandmarks[336].x, keyPts.faceLandmarks[336].y)),
    ]);
    box = new THREE.Box2(box.min.round(), box.max.round());
    box.width = Math.abs(box.max.x - box.min.x);
    box.height = Math.abs(box.max.y - box.min.y);
    //#endregion

    //#region Apply average HSL to material texture
    const destCanvas = imgToCanvas(material.map.image);
    // if (material.map) {
    //     material.map.dispose();
    // }
    applyAvgColor(imgToCanvas(keyPts.image), box, destCanvas);
    material.map = new THREE.CanvasTexture(destCanvas);
    //#endregion

    //For test only (ExtractFace)
    return {
        img2: destCanvas,
        faceSegBRect: box
    }
}

function plotCirclePoints(radius, count) {
    const circleGeom = new THREE.CircleGeometry(radius, count);
    const positionAttr = circleGeom.getAttribute('position');
    const result = [];
    //(1) first point is the center
    //(2) first and last vertex are the same
    //so (1) and (2) are excluded from result, that is why: 1<=i<count-1
    for (let i = 1; i < positionAttr.count-1; i++) {
        result.push((new THREE.Vector3()).fromBufferAttribute(positionAttr, i));
    }

    return result;
}

function setfaceGlbMeshOverModel(modelMesh, faceGlbMesh,scene){
    if(faceGlbMesh){
        //Posiziona e ridimensiona la mesh GLB fissa che si userà come riferimento
        faceGlbMesh=getSkinnedMesh(faceGlbMesh);
        let modelPtWord=GetWordVertexCoord(getSkinnedMesh(modelMesh), ReallusionUtility.idxMentoMesh);
        let faceGlbPtWord=GetWordVertexCoord(faceGlbMesh, MediaPipeUtility.idxMentoMesh);

        faceGlbMesh.geometry.scale(0.92,1,1); //(0.90,0.96,1);
        //faceGlbMesh.geometry.applyMatrix( faceGlbMesh.matrixWorld ); provare...
        faceGlbMesh.updateMatrixWorld(true);
        faceGlbMesh.position.set(modelPtWord.x-faceGlbPtWord.x+0,modelPtWord.y-faceGlbPtWord.y,modelPtWord.z-faceGlbPtWord.z);

            let sphere = new THREE.Mesh( new THREE.SphereGeometry( 0.1, 5, 5 ), new THREE.MeshBasicMaterial( { color: 0xff00ff } ) );    
            sphere.position.set(modelPtWord.x,modelPtWord.y,modelPtWord.z);
            scene.add( sphere );     

        faceGlbMesh.updateMatrixWorld(true);
        faceGlbMesh.material.wireframe=true;
        return(faceGlbMesh);
    }

}

function excludeFaceWith3VertexInPerimeter(GeometryIndex,ExternalModelPerimeter){    //Toglie le facce che hanno 3 punti del perimetro perchè può sbagliare la procedura sort e anche per togliere i becchi
    let filteredIndex=[];
    let filterPerimeter=[];
    let optimizedIndexPerimeter=[]; //Indici richiamati nel perimetro .... ottimizza la ricerca nel sort
    for(var idx=0; idx<GeometryIndex.length; idx+=3){       //Scorre gli indici (facce)
        let count=[];

        for(var idx1=0; idx1<ExternalModelPerimeter.length; idx1++){   
            if(ExternalModelPerimeter[idx1]===GeometryIndex[idx+0]){count.push(idx1)};   
            if(ExternalModelPerimeter[idx1]===GeometryIndex[idx+1]){count.push(idx1)};   
            if(ExternalModelPerimeter[idx1]===GeometryIndex[idx+2]){count.push(idx1)};   
        }

        if(count.length >0){
            optimizedIndexPerimeter.push(GeometryIndex[idx+0]); 
            optimizedIndexPerimeter.push(GeometryIndex[idx+1]); 
            optimizedIndexPerimeter.push(GeometryIndex[idx+2]); 
        }

        if(count.length <3 ){
            filteredIndex.push(GeometryIndex[idx+0]);
            filteredIndex.push(GeometryIndex[idx+1]);
            filteredIndex.push(GeometryIndex[idx+2]);
            for(var ii=0; ii<count.length; ii++){   
                if(!filterPerimeter.some(i => i===ExternalModelPerimeter[count[ii]]))filterPerimeter.push(ExternalModelPerimeter[count[ii]]);//valore univoco
            }
        }
    };
    return({index:filteredIndex, perimter:filterPerimeter, optimizedIndex:optimizedIndexPerimeter});
}

function sortIndexPerimeter(ExternalModelPerimeter,GeometryIndex,modelGeometry,StartPos3d,ctx,h,w,scene){   //Ordina gli indici ExternalModelPerimeter in senso antiorario
    let vertex =[new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3()];
    const debugPts=[];
    let sortModelPerimeter=[];
    let bolTerminate=false;

    //Serve per togliere facce che contengono 3 punti perimetro perchè possono creare un problema nel cercare il bordo, Ritorna anche indici modello presenti nel contorno per performance migliore
    let tmpReturn=excludeFaceWith3VertexInPerimeter(GeometryIndex,ExternalModelPerimeter);
    GeometryIndex=tmpReturn.index;
    ExternalModelPerimeter=tmpReturn.perimter;

    //Cerca indice di partenza perimetro esterno corrispondente alla posizione di partenza perimetro faccia interna (la più vicina)
    let vv=new THREE.Vector3();
    let lastDist, nearestIdx,tmpDist;
    for(var idx=0; idx<ExternalModelPerimeter.length; idx++){     
        vv.fromBufferAttribute(modelGeometry.attributes.position,ExternalModelPerimeter[idx]);
        tmpDist=StartPos3d.distanceTo(vv);
        if(!lastDist || tmpDist <lastDist){
            lastDist=tmpDist;
            nearestIdx=idx;
        }          
    }

    //sortModelPerimeter.push(ExternalModelPerimeter.shift());//Indice di partenza
    sortModelPerimeter.push(ExternalModelPerimeter.splice(nearestIdx,1)[0]);//Indice di partenza
    while(!bolTerminate){
        bolTerminate=true;
        for(var idx=0; idx<tmpReturn.optimizedIndex.length; idx+=3){       //Scorre gli indici(ottimizzati , solo indici che contenevano il perimetro)

            vertex[0].fromBufferAttribute(modelGeometry.attributes.position,tmpReturn.optimizedIndex[idx+0]);
            vertex[1].fromBufferAttribute(modelGeometry.attributes.position,tmpReturn.optimizedIndex[idx+1]);
            vertex[2].fromBufferAttribute(modelGeometry.attributes.position,tmpReturn.optimizedIndex[idx+2]);
            
            //Guarda se la faccia contiene l'ultimo ExternalModelPerimeter trovato
            let FindIdx=-1;
            if(sortModelPerimeter[sortModelPerimeter.length-1]===tmpReturn.optimizedIndex[idx+0]){
                FindIdx=0;
            }else if(sortModelPerimeter[sortModelPerimeter.length-1]===tmpReturn.optimizedIndex[idx+1]){
                FindIdx=1;
            }else if(sortModelPerimeter[sortModelPerimeter.length-1]===tmpReturn.optimizedIndex[idx+2]){
                FindIdx=2;
            }

            if(FindIdx <0) continue;
            
            //Cerca nell'indice in senso orario della faccia che abbia un vertice di ExternalModelPerimeter
            //FindIdx++;    //senso orario
            //if(FindIdx >2)FindIdx=0;
            FindIdx--;  //Senso antiorario
            if(FindIdx <0)FindIdx=2;
            for(var idx1=0; idx1<ExternalModelPerimeter.length; idx1++){       //Scorre gli indici e cerca una faccia con 
                if(ExternalModelPerimeter[idx1]===tmpReturn.optimizedIndex[idx+FindIdx]){
                    bolTerminate=false;
                    sortModelPerimeter.push(ExternalModelPerimeter.splice(idx1,1)[0]);

/*                    let v =new THREE.Vector3();
                    v.fromBufferAttribute(modelGeometry.attributes.position,sortModelPerimeter[sortModelPerimeter.length-1]);
                    debugPts.push(v);

                    let uv =new THREE.Vector2();
                    uv.fromBufferAttribute(modelGeometry.attributes.uv,sortModelPerimeter[sortModelPerimeter.length-1]);
                    ctx.beginPath();
                    ctx.ellipse(Math.round(uv.x*h),Math.round(uv.y*w), 4, 4, 0, 0, 2*Math.PI);
                    ctx.stroke();*/
                }
            }
        }
    }

/*    const debugGeometry = new THREE.BufferGeometry().setFromPoints( debugPts );
    const debugLine = new THREE.Line( debugGeometry, new THREE.LineBasicMaterial({ color: 0x0000ff }))
    scene.add( debugLine );*/

    return({index:GeometryIndex, perimter:sortModelPerimeter});
}

function drawFlattingFaceToTexture(keyPts, ctxRes, faceGlbMesh, scene, sizeX,sizeY){//Ritorna l'immagine proiettata su un piano della faccia da mettere nella texture del modello
    if(!keyPts)return;

    let faceBrightness=-80;//Luminosità della faccia
    let Pts2d=MediaPipeUtility.flatUVFacePoints;   //Punti 2d proiezione sul piano
    let Ratio=0.2;
    let intersects=[];
    let veDirection=new THREE.Vector3();
    let vePosition=new THREE.Vector3();
    let raycaster = new THREE.Raycaster();
    let meshSize=9;   //Con dimensioni diverse non và !!!!!!....
    let box;

    faceGlbMesh.position.set(0,0,0);

    
    //Muove i vertici della faceGlbMesh e le sovrappone ai keyPts di mediapipe
    for (let i = 0; i < keyPts.faceLandmarks.length-10; i++){
        let pt3D=new THREE.Vector3(keyPts.faceLandmarks[i].x*keyPts.image.width,((1-keyPts.faceLandmarks[i].y)*keyPts.image.height),-keyPts.faceLandmarks[i].z*1200);
        faceGlbMesh.geometry.attributes.position.setXYZ(i,pt3D.x,pt3D.y,pt3D.z);    //mesh curva

        faceGlbMesh.geometry.attributes.uv.setXY(i,keyPts.faceLandmarks[i].x,1-keyPts.faceLandmarks[i].y);
    }
    faceGlbMesh.geometry.attributes.uv.needsUpdate=true;


    //Muove posizioni Mesh per proiezione su un piano
    box=new THREE.Box2();//Ricava bounding box
    for (let i = 0; i < faceGlbMesh.geometry.attributes.position.count; i++){  
        let pt2d=new THREE.Vector3((Pts2d[i].x*meshSize)-(meshSize/2),(1-Pts2d[i].y)*meshSize,0);
        faceGlbMesh.geometry.attributes.position.setXYZ(i,pt2d.x,pt2d.y,pt2d.z)
        box.expandByPoint(new Vector2(pt2d.x,pt2d.y))
    }


    //Usa texture da mediapipe
    let cv=document.createElement('canvas');
    cv.width=keyPts.image.width;
    cv.height=keyPts.image.height;
    let ctx = cv.getContext('2d');
    ctx.drawImage(keyPts.image,0,0); 
    faceGlbMesh.material.map= new THREE.CanvasTexture( cv )

    faceGlbMesh.geometry.attributes.position.needsUpdate=true;
    faceGlbMesh.geometry.attributes.uv.needsUpdate=true;
    faceGlbMesh.geometry.computeVertexNormals();
    faceGlbMesh.geometry.computeBoundingBox();
    faceGlbMesh.material.needsUpdate=true;
    faceGlbMesh.material.wireframe=false;
    faceGlbMesh.updateMatrixWorld()
    faceGlbMesh.frustumCulled=false;
    //faceGlbMesh.material.side=THREE.DoubleSide;
    //scene.add(faceGlbMesh);       //Debug

    //Se flat scorre x ed y per ricostruire l'immagine proiettata aperta
    let stepX=(box.max.x -box.min.x)/sizeX
    let stepY=(box.max.y -box.min.y)/sizeY
    let uvRes=new THREE.Vector2();
    let uvRes1=new THREE.Vector2();
    let uvTranslate=new THREE.Vector2(ctxRes.canvas.width*0.754,ctxRes.canvas.height*(0.431));
    for (let iy = box.min.y; iy <= box.max.y; iy+=stepY){
        uvRes.x=0
        for (let ix = box.min.x; ix <= box.max.x; ix+=stepX){
            vePosition.set(ix,iy,faceGlbMesh.position.z+20)
            veDirection.set(0,0,-1)
            raycaster.set(vePosition, veDirection);
            intersects.length=0;
            raycaster.intersectObject( faceGlbMesh, false, intersects );
            if(intersects.length>0){

                //Legge pixel da immagine mediapipe
                let imageData = ctx.getImageData(Math.round(intersects[0].uv.x*cv.width),Math.round((1-intersects[0].uv.y)*cv.height), 1, 1);
                //Trasforma scala di grigi
                let avg =((imageData.data[ 0] + imageData.data[ 1] + imageData.data[ 2]) / 3) +faceBrightness;
                imageData.data[ 0] = avg+10;
                imageData.data[ 1] = avg;
                imageData.data[ 2] = avg;

                uvRes1=uvRes.clone();
                uvRes1.rotateAround(new THREE.Vector3(sizeX/2,sizeY/2,0),THREE.Math.degToRad(90))
                //Scrive pixel su immagine result
                ctxRes.putImageData(imageData,uvRes1.x+uvTranslate.x,(sizeY-uvRes1.y)+uvTranslate.y)
                //let sphere = new THREE.Mesh( new THREE.SphereGeometry( 0.01, 5, 5 ), new THREE.MeshBasicMaterial( { color: 0xff00ff } ) );    
                //sphere.position.set(intersects[0].point.x,intersects[0].point.y,0);
                //sphere.position.set(intersects[0].uv.x*30,intersects[0].uv.y*30,0);
                //scene.add( sphere );     
            }else{
                //let sphere = new THREE.Mesh( new THREE.SphereGeometry( 0.01, 5, 5 ), new THREE.MeshBasicMaterial( { color: 0x00ffff } ) );    
                //sphere.position.set(ix,iy,0);
                //scene.add( sphere );     
            }
            uvRes.x++
        }
        uvRes.y++
    }

    //filterGreyScale(ctxRes.canvas ,-60);

    //downloadCanvas(ctxRes.canvas);
    //downloadImage(keyPts.image)
}    

function mergeMPipeMeshToModel(keyPts, modelMesh, faceGlbMesh, scene){  //Non si usa più...
//(NON USATA)Funziona solo su manichino MakeHuman .... modifica geometria modello per usare keypoints MediaPipe
    if(!modelMesh)return;
    if(!faceGlbMesh)return;

    let modelMaterial=getSkinnedMesh(modelMesh).material;
    let modelGeometry=getSkinnedMesh(modelMesh).geometry;

    if(!modelMaterial.map)return;   //Deve esserci la texture

    faceGlbMesh=setfaceGlbMeshOverModel(modelMesh, faceGlbMesh,scene);//Posiziona e ridimensiona la mesh GLB fissa che si userà come riferimento

    //Ciclo per tutti gli indici della circonferenza della faccia per cercare le coordinate UV per determinare il buco
    let Circ=MediaPipeUtility.faceBoundary();
    let CircIn=MediaPipeUtility.faceBoundaryInner();
    let intersection;
    let intersections=[];
    let facePerimeter2d=[]
    let maskColor=`${255}, ${0}, ${0}`;
    let sphere;
    let sphereMaterial;
    const SphereGeometry = new THREE.SphereGeometry( 0.1, 5, 5 );
    sphereMaterial = new THREE.MeshBasicMaterial( { color: 0x0000ff } );

    let w=modelMaterial.map.image.width;
    let h=modelMaterial.map.image.height;

    for(var idx=0; idx<Circ.length; idx++){
        intersection=rayFromMesh(faceGlbMesh, Circ[idx], modelMesh );
        if (intersection){
            intersections.push(intersection);
            facePerimeter2d.push(new THREE.Vector3(intersection.uv.x*w, intersection.uv.y*h,0))
            //Imposta vertice faccia sulla superfice del modello
            //faceGlbMesh.geometry.attributes.position.setXYZ(Circ[idx],intersection.point.x,intersection.point.y,intersection.point.z);
            //Solo debug
/*            sphere = new THREE.Mesh( SphereGeometry, sphereMaterial );
            sphere.position.set(intersection.point.x,intersection.point.y,intersection.point.z);
            scene.add( sphere );        */
        }
    }
    faceGlbMesh.geometry.attributes.position.needsUpdate=true;
    
    //Disegna il buco corrispondente alla circonferenza della faccia nella texture
    let ctx;
    let cv;
    if(facePerimeter2d.length>0){
        if(modelMaterial){
            cv=document.createElement('canvas');
            cv.width=w;
            cv.height=h;
            ctx = cv.getContext('2d');
            ctx.drawImage(modelMaterial.map.image,0,0); //Eventuale pixel con alpha 0 li usa per mettere la z=0 nel modello (parti interne bocca e occhi)
            
            ctx.save();
            ctx.beginPath();
            ctx.lineWidth = 1;
            ctx.lineCap = 'round';
            ctx.strokeStyle = `rgba(${maskColor}, 1)`;
            ctx.fillStyle   = `rgba(${maskColor}, 1)`;
            ctx.moveTo(facePerimeter2d[0].x, facePerimeter2d[0].y);

            //ctx.fillRect(0,0,cv.width,cv.height/2);
            for(var idx=0; idx<facePerimeter2d.length; idx++){
                ctx.lineTo(facePerimeter2d[idx].x, facePerimeter2d[idx].y);
            }
            //ctx.lineTo(facePerimeter2d[0].x, facePerimeter2d[0].y);
            ctx.closePath();
            ctx.stroke();
            ctx.fill();
            ctx.restore();
        }
        modelMaterial.needsUpdate=true;

    }

    //Toglie gli indici corrispondenti al buco nella texture...e crea un array con i vertici del modello per perimetro esterno alla faccia che poi sarà da triangolare con il perimetro faccia
    sphereMaterial = new THREE.MeshBasicMaterial( { color: 0xff00FF } );
    let vertex =[new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3()];
    let uv =[new THREE.Vector2(), new THREE.Vector2(), new THREE.Vector2()];
    let newIndex=[];
    let ExternalModelPerimeter=[];
    let GeometryIndex=modelGeometry.index.array;
    for(var idx=0; idx<GeometryIndex.length; idx+=3){

        vertex[0].fromBufferAttribute(modelGeometry.attributes.position,GeometryIndex[idx+0]);
        vertex[1].fromBufferAttribute(modelGeometry.attributes.position,GeometryIndex[idx+1]);
        vertex[2].fromBufferAttribute(modelGeometry.attributes.position,GeometryIndex[idx+2]);

        uv[0].fromBufferAttribute(modelGeometry.attributes.uv,GeometryIndex[idx+0]);
        uv[1].fromBufferAttribute(modelGeometry.attributes.uv,GeometryIndex[idx+1]);
        uv[2].fromBufferAttribute(modelGeometry.attributes.uv,GeometryIndex[idx+2]);
       
        let vertexInside=[false,false,false];
        for(var idx1=0; idx1<uv.length; idx1++){
            let imageData = ctx.getImageData(Math.round(uv[idx1].x*w),Math.round(uv[idx1].y*h), 1, 1);
            //Se corrisponde al buco della faccia
            if(`${imageData.data[0]}, ${imageData.data[1]}, ${imageData.data[2]}` ===maskColor ){
                vertexInside[idx1]=true;
                //sphere = new THREE.Mesh( SphereGeometry, sphereMaterial );
                //sphere.position.set(vertex[idx1].x,vertex[idx1].y,vertex[idx1].z);
                //scene.add(sphere);
            }
            //Se alpha zero indica di mettere la z a zero (organi interni bocca ed occhi)
            if(imageData.data[3]===0 ){
                modelGeometry.attributes.position.setXYZ(GeometryIndex[idx+idx1],vertex[idx1].x,vertex[idx1].y,0);
            }
        }
        if(!vertexInside[0] && !vertexInside[1] && !vertexInside[2] ){ //Crea nuova lista di indici esclusi quelli contenuti all'interno della faccia
            newIndex.push(GeometryIndex[idx+0],GeometryIndex[idx+1],GeometryIndex[idx+2]);
        }else {     //Crea lista di vertici che sono nel perimetro esterno fuori dalla faccia
            for(var idx1=0; idx1<vertex.length; idx1++){
                if(!vertexInside[idx1]){
                    //ExternalModelPerimeter.push(GeometryIndex[idx+idx1]);   //Punta agli indici
                    let v=GeometryIndex[idx+idx1];
                    if(!ExternalModelPerimeter.some(v3D => v3D===v))ExternalModelPerimeter.push(v);//Coordinate texture univoche (controlla che non ci siano già)
//                    let v=new THREE.Vector3(Math.round(uv[idx1].x*w),Math.round(uv[idx1].y*h), 0);
//                    if(!ExternalModelPerimeter.some(v3D => v3D.equals(v)))ExternalModelPerimeter.push(v);//Coordinate texture univoche (controlla che non ci siano già)
/*                    sphere = new THREE.Mesh( SphereGeometry, sphereMaterial );
                    sphere.position.set(vertex[idx1].x,vertex[idx1].y,vertex[idx1].z);
                    scene.add(sphere);*/
                }
            }
        }

//        if(PTINPOLYGON.pointInPolygon(uv[0].multiply(new THREE.Vector2(w,h)),facePerimeter2d)){
//        }

/*        sphere = new THREE.Mesh( SphereGeometry, sphereMaterial );
        sphere.position.set(vertex[0].x,vertex[0].y,vertex[0].z);
        scene.add(sphere);
        sphere = new THREE.Mesh( SphereGeometry, sphereMaterial );
        sphere.position.set(vertex[1].x,vertex[1].y,vertex[1].z);
        scene.add(sphere);
        sphere = new THREE.Mesh( SphereGeometry, sphereMaterial );
        sphere.position.set(vertex[2].x,vertex[2].y,vertex[2].z);
        scene.add(sphere);*/

    }
    
    //Serve solo per avere il canvas texture del modello modificabile per disegnare eventuali info di debug
    if(true){
        ctx.drawImage(modelMaterial.map.image,0,0); //Lo rimette originale perchè era stato usato in precedenza per determinare il buco della faccia
        modelMaterial.map= new THREE.CanvasTexture( cv )
        modelMaterial.map.flipY=false;//in new la rimette true...
        modelMaterial.map.image.crossOrigin = 'anonymous';
    }

    //Mette in ordine gli indici ExternalModelPerimeter come facePerimeter2d
    let tmpSortIndexPerimeter= sortIndexPerimeter(ExternalModelPerimeter,newIndex,modelGeometry,intersections[0].point,ctx,h,w,scene);
    newIndex=tmpSortIndexPerimeter.index;
    ExternalModelPerimeter=tmpSortIndexPerimeter.perimter
   
    //Crea nuova mesh con faccia e punti di collegamento con modello. Usa i punti 2d della texture per creare mesh da delunay unendo perimetro faccia con perimetro esterno modello
    //var ptsDelunay = [...new Set([...ExternalModelPerimeter, ...facePerimeter2d])];
    var ptsDelunay = [...new Set([...plotCirclePoints(30,ExternalModelPerimeter.length), ...plotCirclePoints(20,facePerimeter2d.length)])];
    var indexDelaunay = Delaunator.from(        // Esegue triangolazione
        ptsDelunay.map(v => {
            return [v.x, v.y];
        })
    );
    //Toglie i triangoli nella zona centrale dove ci deve essere il buco
    const FilteredDelaunaytriangle = [];
    for(var idx=0; idx<indexDelaunay.triangles.length; idx+=3){
        const belongModelPerimeter = indexDelaunay.triangles[idx+0]<ExternalModelPerimeter.length &&  indexDelaunay.triangles[idx+1]<ExternalModelPerimeter.length && indexDelaunay.triangles[idx+2]<ExternalModelPerimeter.length;
        const belongfacePerimeter = indexDelaunay.triangles[idx+0]>=ExternalModelPerimeter.length &&  indexDelaunay.triangles[idx+1]>=ExternalModelPerimeter.length && indexDelaunay.triangles[idx+2]>=ExternalModelPerimeter.length;
        if (!belongModelPerimeter && !belongfacePerimeter) {
            //Inverte anche i triangoli per fare combaciare con lo standard del modello
            FilteredDelaunaytriangle.push(indexDelaunay.triangles[idx+0],indexDelaunay.triangles[idx+2],indexDelaunay.triangles[idx+1])
        }
    }
//    for(var idx=0; idx<ptsDelunay.length; idx++){       //Temporaneo
//        ptsDelunay[idx].x=ptsDelunay[idx].x/10;
//        ptsDelunay[idx].y=ptsDelunay[idx].y/10;
//    }
    //Setta le posizioni dei punti dal delunay create come cerchio ...
    for(var idx=0; idx<ptsDelunay.length; idx++){
        if(idx <ExternalModelPerimeter.length){
            let v =new THREE.Vector3();
            v.fromBufferAttribute(modelGeometry.attributes.position,ExternalModelPerimeter[idx]);
            ptsDelunay[idx]=v;
        }else{
            ptsDelunay[idx]=intersections[idx-ExternalModelPerimeter.length].point; //Punti proiettati sulla superfice del modello
//            let v =new THREE.Vector3();
//            v.fromBufferAttribute(faceGlbMesh.attributes.position, ???[idx-ExternalModelPerimeter.length]);
//            ptsDelunay[idx]= v;
        }
    }
/*
    //Crea mesh dai triangoli creati        (solo debug)
    let tmpGeometry = new THREE.BufferGeometry().setFromPoints(ptsDelunay);
    //geometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(uv),2));
    //Aggiorna gli indici calcolati da Delunay
    tmpGeometry.setIndex(new THREE.BufferAttribute(Uint32Array.from(FilteredDelaunaytriangle),1)); 
    tmpGeometry.computeVertexNormals();
   // geometry.getAttribute("uv").needsUpdate = true;
    //Copia materiale dal modello
    let tmpMaterial=cloneMaterialFromMesh(modelMesh);
    tmpMaterial.wireframe=true;
    //material.map=new THREE.CanvasTexture(userFaceData.faceImg);
    //material.depthTest=false;
    //material.wireframe=true;
    let meshConnection = new THREE.Mesh(tmpGeometry, tmpMaterial);
    scene.add(meshConnection);     //Solo debug
*/



    var geom = new THREE.BufferGeometry(); 
    geom.setAttribute('position',new THREE.Float32BufferAttribute([...modelGeometry.getAttribute('position').array, ...faceGlbMesh.geometry.getAttribute('position').array], 3));
    geom.setAttribute('uv',new THREE.Float32BufferAttribute([...modelGeometry.getAttribute('uv').array, ...faceGlbMesh.geometry.getAttribute('uv').array], 2));
    let offs=geom.attributes.position.array.length-faceGlbMesh.geometry.attributes.position.array.length;
    let offsA=geom.getAttribute('position').count-faceGlbMesh.geometry.getAttribute('position').count;
    //Trasforma coordinate locali della faccia
    for(var idx=0; idx<faceGlbMesh.geometry.attributes.position.array.length; idx+=3){  
        geom.attributes.position.array[idx+offs+0] += faceGlbMesh.position.x -= modelMesh.position.x;     
        geom.attributes.position.array[idx+offs+1] += faceGlbMesh.position.y -= modelMesh.position.y;     
        geom.attributes.position.array[idx+offs+2] += faceGlbMesh.position.z -= modelMesh.position.z;     
    }
    //Setta i vertici del perimetro interno esattamente sulla superfice del modello
    for(var idx=0; idx<Circ.length; idx++){
        geom.attributes.position.setXYZ(offsA+Circ[idx],intersections[idx].point.x,intersections[idx].point.y,intersections[idx].point.z);
    }
    //Setta UV nei vertici aggiunti per la faccia
    let uvResize   =new THREE.Vector2(0.2,0.23);        //ATTENZIONE perchè dopo viene ruotato , quindi x e y si girano...
    let uvTranslate=new THREE.Vector2(0.76,0.401);
    let uv2=new THREE.Vector2();
    let box=new THREE.Box2();//Ricava bounding box
    for(var idx=offsA; idx<geom.attributes.uv.count; idx++){  
        //uv2=new THREE.Vector2().fromBufferAttribute(geom.attributes.uv,idx)
        uv2.x=MediaPipeUtility.flatUVFacePoints[idx-offsA].x
        uv2.y=MediaPipeUtility.flatUVFacePoints[idx-offsA].y
        uv2.rotateAround(new THREE.Vector3(0.5,0.5,0.5),THREE.Math.degToRad(-90))
        uv2.multiply(uvResize);
        uv2.add(uvTranslate);
        //Debug
        //ctx.beginPath();
        //ctx.ellipse(Math.round(uv2.x*w),Math.round(uv2.y*h), 4, 4, 0, 0, 2*Math.PI);
        //ctx.stroke();

        box.expandByPoint(new Vector2(uv2.x*w,uv2.y*h))//Ricava BBox
        geom.attributes.uv.setXY(idx,uv2.x,uv2.y);
    }
    //Aggiunge indici faccia
    for(var idx=0; idx<faceGlbMesh.geometry.index.count; idx++){   
        newIndex.push(faceGlbMesh.geometry.index.array[idx]+(modelGeometry.getAttribute('position').count));  
    }
    //Aggiunge indici mesh connessione
    for(var idx=0; idx<FilteredDelaunaytriangle.length; idx++){
        if(FilteredDelaunaytriangle[idx]<ExternalModelPerimeter.length){    //Indice perimetro esterno
            let i=ExternalModelPerimeter[FilteredDelaunaytriangle[idx]];
            newIndex.push(i);  
/*            let v =new THREE.Vector3(); DEBUG
            v.fromBufferAttribute(geom.attributes.position,i);
            sphere = new THREE.Mesh( SphereGeometry, sphereMaterial );
            sphere.position.set(v.x,v.y,v.z);
            scene.add(sphere);*/
        }else{  //Prende indice contorno della faccia aggiunta (Perimetro interno)
            let i=Circ[FilteredDelaunaytriangle[idx]-ExternalModelPerimeter.length];
            let ii=offsA+i
            newIndex.push(ii);
/*            let v =new THREE.Vector3();     //DEBUG
            v.fromBufferAttribute(geom.attributes.position,ii);
            sphere = new THREE.Mesh( SphereGeometry, sphereMaterial );
            sphere.position.set(v.x,v.y,v.z);
            scene.add(sphere);*/
        }
    }

    geom.setIndex(newIndex);

    //Modifica i vertici della faccia prendendoli da mediapipe (erano fissi da un glb)
    if(false && keyPts){
        //Salva posizioni sulla circonferenza per non prenderle da MediaPipe
        let SaveCirc=[];
        for (let i = 0; i < Circ.length; i++){
            SaveCirc.push(new THREE.Vector3().fromBufferAttribute(geom.attributes.position,offsA+Circ[i]))
        }
        for (let i = 0; i < CircIn.length; i++){
            SaveCirc.push(new THREE.Vector3().fromBufferAttribute(geom.attributes.position,offsA+CircIn[i]))
        }
        let geomFaceBottom=new THREE.Vector3().fromBufferAttribute(geom.attributes.position,offsA+MediaPipeUtility.idxFaceBottom);
        let geomFaceTop   =new THREE.Vector3().fromBufferAttribute(geom.attributes.position,offsA+MediaPipeUtility.idxFaceTop);
        let geomFaceLeft  =new THREE.Vector3().fromBufferAttribute(geom.attributes.position,offsA+MediaPipeUtility.idxFaceLeft);
        let geomFaceRight =new THREE.Vector3().fromBufferAttribute(geom.attributes.position,offsA+MediaPipeUtility.idxFaceRight);
        const RatioX  = Math.abs(geomFaceRight.x-geomFaceLeft.x)/faceLandmarksGetInfo(keyPts.faceLandmarks).w;
        const RatioY  = Math.abs(geomFaceBottom.y-geomFaceTop.y)/faceLandmarksGetInfo(keyPts.faceLandmarks).h;
        //modifica faccia del modello con le posizioni da mediapipe senza però modificare il perimetro
        for (let i = 0; i < keyPts.faceLandmarks.length-10; i++){
                let pt3D=new THREE.Vector3(keyPts.faceLandmarks[i].x,-keyPts.faceLandmarks[i].y,-keyPts.faceLandmarks[i].z);
                pt3D.x=pt3D.x*RatioX;
                pt3D.y=pt3D.y*RatioY;
                //pt3D.z=pt3D.z;
                geom.attributes.position.setXY(offsA+i,pt3D.x,pt3D.y);
        }
        //Sistema offset del mento rispetto alla posizione iniziale
        let tmpGeomFaceBottom=new THREE.Vector3().fromBufferAttribute(geom.attributes.position,offsA+MediaPipeUtility.idxFaceBottom);
        let tmpOffs=new THREE.Vector3(geomFaceBottom.x-tmpGeomFaceBottom.x,geomFaceBottom.y-tmpGeomFaceBottom.y,geomFaceBottom.z-tmpGeomFaceBottom.z);
        for (let i = 0; i < keyPts.faceLandmarks.length-10; i++){
            let pt3D=new THREE.Vector3().fromBufferAttribute(geom.attributes.position,offsA+i);
            geom.attributes.position.setXYZ(offsA+i,pt3D.x+tmpOffs.x,pt3D.y+tmpOffs.y,pt3D.z+tmpOffs.z);
        }
        //Mantiene posizioni circonferenza originali...
        for (let i = 0; i < SaveCirc.length; i++){
            geom.attributes.position.setXYZ(offsA+Circ[i],SaveCirc[i].x,SaveCirc[i].y,SaveCirc[i].z);
        }
    }

    //Proietta faceGlbMesh in un piano e disegna la faccia nella texture del modello
    drawFlattingFaceToTexture(keyPts, ctx, faceGlbMesh, scene,box.max.y-box.min.y,box.max.x-box.min.x);//Ritorna l'immagine proiettata su un piano della faccia da mettere nella texture del modello

    var object = new THREE.Mesh( geom, modelMaterial);//new THREE.MeshStandardMaterial( { color: 0xefefef } ));
    //object.material.wireframe=true;
    //object.material.side=THREE.DoubleSide;
    object.material.normalMap=modelMaterial.normalMap;
    object.geometry.attributes.position.needsUpdate=true;
    object.geometry.index.needsUpdate=true;
    object.geometry.attributes.uv.needsUpdate=true;
    object.geometry.computeVertexNormals();
    sceneRemoveCategoryType(scene, 'ModelBase');
    scene.add(object);


    //modelMaterial.map=null;
    //modelGeometry.setAttribute('normal',new THREE.Float32BufferAttribute([0,1,2], 3));
    //modelGeometry.setAttribute('uv',new THREE.Float32BufferAttribute([0,1], 2));
    modelGeometry.attributes.position.needsUpdate=true;
    modelGeometry.index.needsUpdate=true;
    modelGeometry.attributes.uv.needsUpdate=true;
    modelGeometry.computeVertexNormals();
    //modelMaterial.wireframe=true;

    //scene.add(faceGlbMesh);     //Solo debug

    //downloadCanvas(cv);  //Solo debug

    downloadGlb(object,'Test.glb');
}

function PhotoFaceToMesh(keyPts, modelMesh, faceGlbMesh, scene ){   //Prima versione con MakeHuman....non si usa più....
    if(!modelMesh)return;
    
    let modelHead=ReallusionUtility.getMeshHead(modelMesh);

    if(modelHead && faceGlbMesh){
        faceGlbMesh=setfaceGlbMeshOverModel(modelHead, faceGlbMesh,scene);//Posiziona e ridimensiona la mesh GLB fissa che si userà come riferimento
    }

    if(false)applyModelColorFromFace(keyPts, getSkinnedMesh(modelHead).material);

    //Ritaglia faccia e capelli sotto al mento per lasciare solo la testa e crea texture tagliando via tutto ciò che non serve
    let faceCuttingData = cutRegionFace(keyPts);

    //Riduce texture in scala di grigi
    filterGreyScale(faceCuttingData.faceImg,-15) 

    //downloadCanvas(faceCuttingData.faceImg);    downloadImage(keyPts.image);

    //Crea mesh e la dimensiona giusta
    let meshFace =createFaceMeshAndScale(keyPts, faceCuttingData, faceGlbMesh, modelHead);  

    //Posiziona la mesh nel modello
    PositionMeshAtModelFace(meshFace, modelHead);
    
    //Fit meshFace to fixed faceGlbMesh per fare combaciare il mento fino all'orecchio perfettamente
    if(true)fitMeshFaceToFaceFixedGlb(faceGlbMesh,meshFace );

//    sceneRemoveCategoryType(scene, 'ModelBase');
//    modelHead=CSG.subtract(getSkinnedMesh(modelHead), meshFace);        //make sure updateMatrix both meshes
//    scene.add(modelHead);
    
    //scene.add(faceGlbMesh);
    return meshFace;    
}


export { loaderScene , loaderMesh , loaderImage , sceneAddCategory 
    , sceneRemoveCategory , sceneRemoveCategoryAll , sceneFindItemCategory , sceneFindItemCategoryRoot ,sceneRemoveCategoryType , sceneGetItemsCategoryProdCatRoot, MeshesType
    , rayCheckIntersection, meshToCanvas, GetWordVertexCoord, cloneMaterialFromMesh, rayFromMesh
    , getSkinnedMesh ,addLabel
    , traverseSetOpacity, traverseBindSkeleton, traverse_Set_Reset_Transparent, traverseSetFrustumCull, traverseGetSkeleton, traverseSetRenderOrder, traverseSetFunctionBeforeRender, traverseSetSide
    , From2dCanvsTo3d, from3DTo2D
    , PhotoFaceToMesh, /*mergeMPipeMeshToModel,*/ cutRegionFace, faceLandmarksGetInfo
    , filterGreyScale, filterAlphaScale, FilterImgLightness, filterAlphaBorder, filterRGBToImg
    , normCoordScaler
    , sceneAddProdVariation, getProdVariations, 
    applyModelColorFromFace,
    imgToCanvas, createCanvas, downloadCanvas, downloadImage, downloadGlb}