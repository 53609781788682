import React from "react";
import ProductCard from "../ProductCard/ProductCard";

export default function Product({ product, isSelected, onclick }) {
    // const [id, setId] = React.useState(null);
    // const [isSelected, setIsSelected] = React.useState(false);
    const refProduct = React.useRef(product);

    // const ProductClicked = () => {
    //     setIsSelected(true);
    // }

    // React.useEffect(() => {
    //     // setId(product.id);
    //     productId = product.id;
    // }, []);

    console.log('Product render');

    //#region Find variation with higher discount
    let prodVariation = product.variations[0], maxDiscountPrice = 0;
    product.variations.forEach((prodVar) => {
        if (prodVar.discount > maxDiscountPrice) {
            maxDiscountPrice = prodVar.discount;
            prodVariation = prodVar;
        }
    });
    //#endregion

    return (
        <ProductCard prodVariation={prodVariation} headerText={product.brand.name} prodDescription={product.name} isSelected={isSelected} enableHover={true} onclick={() => { onclick(refProduct.current) }} />
    );
}