/*       da https://github.com/k-m-irfan/simplified_mediapipe_face_landmarks/blob/main/mpFaceSimplified.py
# ACTUAL MEDIAPIPE FACE LANDMARKS
# Left Eyebrow = [70,63,105,66,107,55,65,52,53,46]
# Right Eyebrow = [300,293,334,296,336,285,295,282,283,276]
# Left Eye = [33,246,161,160,159,158,157,173,133,155,154,153,145,144,163,7]
# Right Eye = [263,466,388,387,386,385,384,398,362,382,381,380,374,373,390,249]
# Inner Lip = [78,191,80,81,82,13,312,311,310,415,308,324,318,402,317,14,87,178,88,95]
# Outer Lip = [61,185,40,39,37,0,267,269,270,409,291,375,321,405,314,17,84,181,91,146]
# Face Boundary = [10,338,297,332,284,251,389,356,454,323,361,288,397,365,379,378,400,377,152,148,176,149,150,136,172,58,132,93,234,127,162,21,54,103,67,109]
# Left iris = [468,469,470,471,472]
# Right iris = [473,474,475,476,477]
# Nose = [64,4,294]

# SIMPLIFIED FACE LANDMARKS AFTER SEQUENCING
# Left Eyebrow (0->9)
# right Eyebrow (10->19)
# Left Eye (20->35)
# Right Eye (36->51)
# iner Lip (52->71)
# outer Lip (72->91)
# face boundary (92->127)
# Left iris (128->132)
# Right iris (133->137)
# Nose (138 -> 140)
*/
import * as THREE from 'three';
import { LinearFilter, RGBAFormat } from 'three';
import * as ThreeManUtility from './ThreeManUtility.js';

const idxMentoMesh=152;     //Vertice mento nella faccia
const idxFaceLeft=234;      //Vertice faccia left
const idxFaceRight=454;     //Vertice faccia right
const idxFaceBottom=idxMentoMesh;    //Vertice faccia basso
const idxFaceTop=10;        //Vertice faccia alto
const idxFaceCenter=195;    //Vertice faccia considerato come centro
const idxSideMento =172;     //Vertice sotto l'orecchio sotto al quale inizia il mento

function getAxisFaceAngles(keyPts) {
    const result = new THREE.Vector3();

    const scaler = ThreeManUtility.normCoordScaler(keyPts.image.width, keyPts.image.height);
    let pt1, pt2;
    const rad90 = Math.PI / 2;

    //Angle axis Z
    pt1 = scaler(keyPts.faceLandmarks[idxFaceTop]);
    pt2 = scaler(keyPts.faceLandmarks[idxFaceBottom]);
    // pt1 = keyPts.faceLandmarks[idxFaceTop];
    // pt2 = keyPts.faceLandmarks[idxFaceBottom];
    result.z = rad90 - (Math.atan2(pt2.y - pt1.y, pt2.x - pt1.x));

    //Angle axis X
    result.x = rad90 - (Math.atan2(pt2.y - pt1.y, pt2.z - pt1.z));

    //Angle axis Y
    pt1 = scaler(keyPts.faceLandmarks[idxFaceLeft]);
    pt2 = scaler(keyPts.faceLandmarks[idxFaceRight]);
    result.y = rad90 - (Math.atan2(pt2.x - pt1.x, pt2.z - pt1.z));

    return result;
}

function getUV(keyPts,idx,normalize=false){    //Dal vertice ritorna la posizione nel texture
   let uv2d=new THREE.Vector2(keyPts.faceLandmarks[idx].x,keyPts.faceLandmarks[idx].y);
   if(!normalize){uv2d.multiply(new THREE.Vector2(keyPts.image.width,keyPts.image.height))}
   return(uv2d);
}


function idxFaceBoundRect(keyPts){
   return(new THREE.Box2(new THREE.Vector2(getUV(keyPts,idxFaceLeft,false).x,getUV(keyPts,idxFaceTop,false).y),new THREE.Vector2(getUV(keyPts,idxFaceRight,false).x,getUV(keyPts,idxFaceBottom,false).y)))
}

function TranslateLip(Geometry, x,y,z){   //Transla solo lip dando meno peso alla circonferenza esterna
   let weight; //Peso 
   for (let iLip = 0; iLip < lip.length; iLip++) {
      switch (iLip) {
         case 0: weight=0.2; break;    //Peso minore nella circonferenza più esterna
         default: weight=1 ; break;
      }
      for (let i = 0; i < lip[iLip].length; i++) {
         let pt3D=new THREE.Vector3().fromBufferAttribute(Geometry.attributes.position,lip[iLip][i]);
         Geometry.attributes.position.setXYZ(lip[iLip][i],pt3D.x+(x*weight),pt3D.y+(y*weight),pt3D.z+(z*weight));   
      }
   }
}

function MeshGLBApplyFromMPipe(faceGlbMesh,keyPts, scale3D_X,scale3D_Y){  //Compila UV e texture nella faceGlbMesh e resiza  
    
    faceGlbMesh.rotation.set(0,0,0);
    faceGlbMesh.scale.set(1,1,1);
    faceGlbMesh.position.set(0,0,0);
    faceGlbMesh.material.map=new THREE.CanvasTexture(keyPts.image);
    for (let i = 0; i < keyPts.faceLandmarks.length; i++) {
        if(true){   //Se si vuole usare i vertici da MPipe , ma bisogna usare faceGlbMesh.geometry.scale(0.92,1,1);   per resize
            let pt3D=new THREE.Vector3((keyPts.faceLandmarks[i].x*keyPts.image.width),(1-keyPts.faceLandmarks[i].y*keyPts.image.height),-(keyPts.faceLandmarks[i].z*keyPts.image.width));
            faceGlbMesh.geometry.attributes.position.setXYZ(i,pt3D.x,pt3D.y,pt3D.z);   
        }
        faceGlbMesh.geometry.attributes.uv.setXY(i,keyPts.faceLandmarks[i].x,keyPts.faceLandmarks[i].y);
    }
    
    let angle=getAxisFaceAngles(keyPts)  //Aggiusta mesh per metterla perpendicolare alla vista
    faceGlbMesh.scale.set(scale3D_X*1,scale3D_Y,scale3D_X);
    faceGlbMesh.rotation.y=-angle.y// THREE.MathUtils.degToRad(-6);     //Guarda verso dx
    faceGlbMesh.rotation.x=-(angle.x+ THREE.MathUtils.degToRad(-6)) //-4 Guarda verso il basso
    faceGlbMesh.rotation.z=-angle.z// THREE.MathUtils.degToRad(-2.80)//-4.75);  //Guarda con un occhio più alto dell'altro
    //faceGlbMesh.updateMatrix();
    faceGlbMesh.updateMatrixWorld(true);    
    //faceGlbMesh.rotation.x=0;     
    //faceGlbMesh.rotation.y=0;     
    //faceGlbMesh.rotation.z=0;     
    
    faceGlbMesh.geometry.needsUpdate=true;
    faceGlbMesh.geometry.computeVertexNormals();
    faceGlbMesh.material.side=THREE.FrontSide;
    faceGlbMesh.material.map.flipY=false;
    faceGlbMesh.material.map.minFilter=THREE.LinearFilter
    faceGlbMesh.material.map.encoding=THREE.sRGBEncoding;
    faceGlbMesh.material.map.format=THREE.RGBAFormat;
}

const lip = [
   [164,393,391,322,410,287,273,335,406,313,18,83,182,106,43,57,186,92,165,167],
   [0,267,269,270,409,291,375,321,405,314,17,84,181,91,146,61,185,40,39,37],
   [11,302,303,304,408,306,307,320,404,315,16,85,180,90,77,76,184,74,73,72],
   [12,268,271,272,407,292,325,319,403,316,15,86,179,89,96,62,183,42,41,38],
   [13,312,311,310,415,308,324,318,402,317,14,87,178,88,95,78,191,80,81,82]
];

//Coordinate 2d UV della faccia con proporzione W ed H corrette ma con un bordo aggiuntivo intorno 
const flatUVFacePoints = [ 
    new THREE.Vector2(0.499976992607117, 0.652534008026123),
    new THREE.Vector2(0.500025987625122, 0.547487020492554),
    new THREE.Vector2(0.499974012374878, 0.602371990680695),
    new THREE.Vector2(0.482113003730774, 0.471979022026062),
    new THREE.Vector2(0.500150978565216, 0.527155995368958),
    new THREE.Vector2(0.499909996986389, 0.498252987861633),
    new THREE.Vector2(0.499523013830185, 0.40106201171875),
    new THREE.Vector2(0.289712011814117, 0.380764007568359),
    new THREE.Vector2(0.499954998493195, 0.312398016452789),
    new THREE.Vector2(0.499987006187439, 0.269918978214264),
    new THREE.Vector2(0.500023007392883, 0.107050001621246),
    new THREE.Vector2(0.500023007392883, 0.666234016418457),
    new THREE.Vector2(0.5000159740448, 0.679224014282227),
    new THREE.Vector2(0.500023007392883, 0.692348003387451),
    new THREE.Vector2(0.499976992607117, 0.695277988910675),
    new THREE.Vector2(0.499976992607117, 0.70593398809433),
    new THREE.Vector2(0.499976992607117, 0.719385027885437),
    new THREE.Vector2(0.499976992607117, 0.737019002437592),
    new THREE.Vector2(0.499967992305756, 0.781370997428894),
    new THREE.Vector2(0.499816000461578, 0.562981009483337),
    new THREE.Vector2(0.473773002624512, 0.573909997940063),
    new THREE.Vector2(0.104906998574734, 0.254140973091125),
    new THREE.Vector2(0.365929991006851, 0.409575998783112),
    new THREE.Vector2(0.338757991790771, 0.41302502155304),
    new THREE.Vector2(0.311120003461838, 0.409460008144379),
    new THREE.Vector2(0.274657994508743, 0.389131009578705),
    new THREE.Vector2(0.393361985683441, 0.403706014156342),
    new THREE.Vector2(0.345234006643295, 0.344011008739471),
    new THREE.Vector2(0.370094001293182, 0.346076011657715),
    new THREE.Vector2(0.319321990013123, 0.347265005111694),
    new THREE.Vector2(0.297903001308441, 0.353591024875641),
    new THREE.Vector2(0.24779200553894, 0.410809993743896),
    new THREE.Vector2(0.396889001131058, 0.842755019664764),
    new THREE.Vector2(0.280097991228104, 0.375599980354309),
    new THREE.Vector2(0.106310002505779, 0.399955987930298),
    new THREE.Vector2(0.2099249958992, 0.391353011131287),
    new THREE.Vector2(0.355807989835739, 0.534406006336212),
    new THREE.Vector2(0.471751004457474, 0.65040397644043),
    new THREE.Vector2(0.474155008792877, 0.680191993713379),
    new THREE.Vector2(0.439785003662109, 0.657229006290436),
    new THREE.Vector2(0.414617002010345, 0.66654098033905),
    new THREE.Vector2(0.450374007225037, 0.680860996246338),
    new THREE.Vector2(0.428770989179611, 0.682690978050232),
    new THREE.Vector2(0.374971002340317, 0.727805018424988),
    new THREE.Vector2(0.486716985702515, 0.547628998756409),
    new THREE.Vector2(0.485300987958908, 0.527395009994507),
    new THREE.Vector2(0.257764995098114, 0.314490020275116),
    new THREE.Vector2(0.401223003864288, 0.455172002315521),
    new THREE.Vector2(0.429818987846375, 0.548614978790283),
    new THREE.Vector2(0.421351999044418, 0.533740997314453),
    new THREE.Vector2(0.276895999908447, 0.532056987285614),
    new THREE.Vector2(0.483370006084442, 0.499586999416351),
    new THREE.Vector2(0.33721199631691, 0.282882988452911),
    new THREE.Vector2(0.296391993761063, 0.293242990970612),
    new THREE.Vector2(0.169294998049736, 0.193813979625702),
    new THREE.Vector2(0.447580009698868, 0.302609980106354),
    new THREE.Vector2(0.392390012741089, 0.353887975215912),
    new THREE.Vector2(0.354490011930466, 0.696784019470215),
    new THREE.Vector2(0.067304998636246, 0.730105042457581),
    new THREE.Vector2(0.442739009857178, 0.572826027870178),
    new THREE.Vector2(0.457098007202148, 0.584792017936707),
    new THREE.Vector2(0.381974011659622, 0.694710969924927),
    new THREE.Vector2(0.392388999462128, 0.694203019142151),
    new THREE.Vector2(0.277076005935669, 0.271932005882263),
    new THREE.Vector2(0.422551989555359, 0.563233017921448),
    new THREE.Vector2(0.385919004678726, 0.281364023685455),
    new THREE.Vector2(0.383103013038635, 0.255840003490448),
    new THREE.Vector2(0.331431001424789, 0.119714021682739),
    new THREE.Vector2(0.229923993349075, 0.232002973556519),
    new THREE.Vector2(0.364500999450684, 0.189113974571228),
    new THREE.Vector2(0.229622006416321, 0.299540996551514),
    new THREE.Vector2(0.173287004232407, 0.278747975826263),
    new THREE.Vector2(0.472878992557526, 0.666198015213013),
    new THREE.Vector2(0.446828007698059, 0.668527007102966),
    new THREE.Vector2(0.422762006521225, 0.673889994621277),
    new THREE.Vector2(0.445307999849319, 0.580065965652466),
    new THREE.Vector2(0.388103008270264, 0.693961024284363),
    new THREE.Vector2(0.403039008378983, 0.706539988517761),
    new THREE.Vector2(0.403629004955292, 0.693953037261963),
    new THREE.Vector2(0.460041999816895, 0.557139039039612),
    new THREE.Vector2(0.431158006191254, 0.692366003990173),
    new THREE.Vector2(0.452181994915009, 0.692366003990173),
    new THREE.Vector2(0.475387006998062, 0.692366003990173),
    new THREE.Vector2(0.465828001499176, 0.779190003871918),
    new THREE.Vector2(0.472328990697861, 0.736225962638855),
    new THREE.Vector2(0.473087012767792, 0.717857003211975),
    new THREE.Vector2(0.473122000694275, 0.704625964164734),
    new THREE.Vector2(0.473033010959625, 0.695277988910675),
    new THREE.Vector2(0.427942007780075, 0.695277988910675),
    new THREE.Vector2(0.426479011774063, 0.703539967536926),
    new THREE.Vector2(0.423162013292313, 0.711845993995667),
    new THREE.Vector2(0.4183090031147, 0.720062971115112),
    new THREE.Vector2(0.390094995498657, 0.639572978019714),
    new THREE.Vector2(0.013953999616206, 0.560034036636353),
    new THREE.Vector2(0.499913990497589, 0.58014702796936),
    new THREE.Vector2(0.413199990987778, 0.69539999961853),
    new THREE.Vector2(0.409626007080078, 0.701822996139526),
    new THREE.Vector2(0.468080013990402, 0.601534962654114),
    new THREE.Vector2(0.422728985548019, 0.585985004901886),
    new THREE.Vector2(0.463079988956451, 0.593783974647522),
    new THREE.Vector2(0.37211999297142, 0.47341400384903),
    new THREE.Vector2(0.334562003612518, 0.496073007583618),
    new THREE.Vector2(0.411671012639999, 0.546965003013611),
    new THREE.Vector2(0.242175996303558, 0.14767599105835),
    new THREE.Vector2(0.290776997804642, 0.201445996761322),
    new THREE.Vector2(0.327338010072708, 0.256527006626129),
    new THREE.Vector2(0.399509996175766, 0.748921036720276),
    new THREE.Vector2(0.441727995872498, 0.261676013469696),
    new THREE.Vector2(0.429764986038208, 0.187834024429321),
    new THREE.Vector2(0.412198007106781, 0.108901023864746),
    new THREE.Vector2(0.288955003023148, 0.398952007293701),
    new THREE.Vector2(0.218936994671822, 0.435410976409912),
    new THREE.Vector2(0.41278201341629, 0.398970007896423),
    new THREE.Vector2(0.257135003805161, 0.355440020561218),
    new THREE.Vector2(0.427684992551804, 0.437960982322693),
    new THREE.Vector2(0.448339998722076, 0.536936044692993),
    new THREE.Vector2(0.178560003638268, 0.45755398273468),
    new THREE.Vector2(0.247308000922203, 0.457193970680237),
    new THREE.Vector2(0.286267012357712, 0.467674970626831),
    new THREE.Vector2(0.332827985286713, 0.460712015628815),
    new THREE.Vector2(0.368755996227264, 0.447206974029541),
    new THREE.Vector2(0.398963987827301, 0.432654976844788),
    new THREE.Vector2(0.476410001516342, 0.405806005001068),
    new THREE.Vector2(0.189241006970406, 0.523923993110657),
    new THREE.Vector2(0.228962004184723, 0.348950982093811),
    new THREE.Vector2(0.490725994110107, 0.562400996685028),
    new THREE.Vector2(0.404670000076294, 0.485132992267609),
    new THREE.Vector2(0.019469000399113, 0.401564002037048),
    new THREE.Vector2(0.426243007183075, 0.420431017875671),
    new THREE.Vector2(0.396993011236191, 0.548797011375427),
    new THREE.Vector2(0.266469985246658, 0.376977026462555),
    new THREE.Vector2(0.439121007919312, 0.51895797252655),
    new THREE.Vector2(0.032313998788595, 0.644356966018677),
    new THREE.Vector2(0.419054001569748, 0.387154996395111),
    new THREE.Vector2(0.462783008813858, 0.505746960639954),
    new THREE.Vector2(0.238978996872902, 0.779744982719421),
    new THREE.Vector2(0.198220998048782, 0.831938028335571),
    new THREE.Vector2(0.107550002634525, 0.540755033493042),
    new THREE.Vector2(0.183610007166862, 0.740257024765015),
    new THREE.Vector2(0.134409993886948, 0.333683013916016),
    new THREE.Vector2(0.385764002799988, 0.883153975009918),
    new THREE.Vector2(0.490967005491257, 0.579378008842468),
    new THREE.Vector2(0.382384985685349, 0.508572995662689),
    new THREE.Vector2(0.174399003386497, 0.397670984268188),
    new THREE.Vector2(0.318785011768341, 0.39623498916626),
    new THREE.Vector2(0.343364000320435, 0.400596976280212),
    new THREE.Vector2(0.396100014448166, 0.710216999053955),
    new THREE.Vector2(0.187885001301765, 0.588537991046906),
    new THREE.Vector2(0.430987000465393, 0.944064974784851),
    new THREE.Vector2(0.318993002176285, 0.898285031318665),
    new THREE.Vector2(0.266247987747192, 0.869701027870178),
    new THREE.Vector2(0.500023007392883, 0.190576016902924),
    new THREE.Vector2(0.499976992607117, 0.954452991485596),
    new THREE.Vector2(0.366169989109039, 0.398822009563446),
    new THREE.Vector2(0.393207013607025, 0.39553701877594),
    new THREE.Vector2(0.410373002290726, 0.391080021858215),
    new THREE.Vector2(0.194993004202843, 0.342101991176605),
    new THREE.Vector2(0.388664990663528, 0.362284004688263),
    new THREE.Vector2(0.365961998701096, 0.355970978736877),
    new THREE.Vector2(0.343364000320435, 0.355356991291046),
    new THREE.Vector2(0.318785011768341, 0.35834002494812),
    new THREE.Vector2(0.301414996385574, 0.363156020641327),
    new THREE.Vector2(0.058132998645306, 0.319076001644135),
    new THREE.Vector2(0.301414996385574, 0.387449026107788),
    new THREE.Vector2(0.499987989664078, 0.618434011936188),
    new THREE.Vector2(0.415838003158569, 0.624195992946625),
    new THREE.Vector2(0.445681989192963, 0.566076993942261),
    new THREE.Vector2(0.465844005346298, 0.620640993118286),
    new THREE.Vector2(0.49992299079895, 0.351523995399475),
    new THREE.Vector2(0.288718998432159, 0.819945991039276),
    new THREE.Vector2(0.335278987884521, 0.852819979190826),
    new THREE.Vector2(0.440512001514435, 0.902418971061707),
    new THREE.Vector2(0.128294005990028, 0.791940987110138),
    new THREE.Vector2(0.408771991729736, 0.373893976211548),
    new THREE.Vector2(0.455606997013092, 0.451801002025604),
    new THREE.Vector2(0.499877005815506, 0.908990025520325),
    new THREE.Vector2(0.375436991453171, 0.924192011356354),
    new THREE.Vector2(0.11421000212431, 0.615022003650665),
    new THREE.Vector2(0.448662012815475, 0.695277988910675),
    new THREE.Vector2(0.4480200111866, 0.704632043838501),
    new THREE.Vector2(0.447111994028091, 0.715808033943176),
    new THREE.Vector2(0.444831997156143, 0.730794012546539),
    new THREE.Vector2(0.430011987686157, 0.766808986663818),
    new THREE.Vector2(0.406787008047104, 0.685672998428345),
    new THREE.Vector2(0.400738000869751, 0.681069016456604),
    new THREE.Vector2(0.392399996519089, 0.677703022956848),
    new THREE.Vector2(0.367855995893478, 0.663918972015381),
    new THREE.Vector2(0.247923001646996, 0.601333022117615),
    new THREE.Vector2(0.452769994735718, 0.420849978923798),
    new THREE.Vector2(0.43639200925827, 0.359887003898621),
    new THREE.Vector2(0.416164010763168, 0.368713974952698),
    new THREE.Vector2(0.413385987281799, 0.692366003990173),
    new THREE.Vector2(0.228018000721931, 0.683571994304657),
    new THREE.Vector2(0.468268007040024, 0.352671027183533),
    new THREE.Vector2(0.411361992359161, 0.804327011108398),
    new THREE.Vector2(0.499989002943039, 0.469825029373169),
    new THREE.Vector2(0.479153990745544, 0.442654013633728),
    new THREE.Vector2(0.499974012374878, 0.439637005329132),
    new THREE.Vector2(0.432112008333206, 0.493588984012604),
    new THREE.Vector2(0.499886006116867, 0.866917014122009),
    new THREE.Vector2(0.49991300702095, 0.821729004383087),
    new THREE.Vector2(0.456548988819122, 0.819200992584229),
    new THREE.Vector2(0.344549000263214, 0.745438992977142),
    new THREE.Vector2(0.37890899181366, 0.574010014533997),
    new THREE.Vector2(0.374292999505997, 0.780184984207153),
    new THREE.Vector2(0.319687992334366, 0.570737957954407),
    new THREE.Vector2(0.357154995203018, 0.604269981384277),
    new THREE.Vector2(0.295284003019333, 0.621580958366394),
    new THREE.Vector2(0.447750002145767, 0.862477004528046),
    new THREE.Vector2(0.410986006259918, 0.508723020553589),
    new THREE.Vector2(0.31395098567009, 0.775308012962341),
    new THREE.Vector2(0.354128003120422, 0.812552988529205),
    new THREE.Vector2(0.324548006057739, 0.703992962837219),
    new THREE.Vector2(0.189096003770828, 0.646299958229065),
    new THREE.Vector2(0.279776990413666, 0.71465802192688),
    new THREE.Vector2(0.1338230073452, 0.682700991630554),
    new THREE.Vector2(0.336768001317978, 0.644733011722565),
    new THREE.Vector2(0.429883986711502, 0.466521978378296),
    new THREE.Vector2(0.455527991056442, 0.548622965812683),
    new THREE.Vector2(0.437114000320435, 0.558896005153656),
    new THREE.Vector2(0.467287987470627, 0.529924988746643),
    new THREE.Vector2(0.414712011814117, 0.335219979286194),
    new THREE.Vector2(0.37704598903656, 0.322777986526489),
    new THREE.Vector2(0.344107985496521, 0.320150971412659),
    new THREE.Vector2(0.312875986099243, 0.32233202457428),
    new THREE.Vector2(0.283526003360748, 0.333190023899078),
    new THREE.Vector2(0.241245999932289, 0.382785975933075),
    new THREE.Vector2(0.102986000478268, 0.468762993812561),
    new THREE.Vector2(0.267612010240555, 0.424560010433197),
    new THREE.Vector2(0.297879010438919, 0.433175981044769),
    new THREE.Vector2(0.333433985710144, 0.433878004550934),
    new THREE.Vector2(0.366427004337311, 0.426115989685059),
    new THREE.Vector2(0.396012008190155, 0.416696012020111),
    new THREE.Vector2(0.420121014118195, 0.41022801399231),
    new THREE.Vector2(0.007561000064015, 0.480777025222778),
    new THREE.Vector2(0.432949006557465, 0.569517970085144),
    new THREE.Vector2(0.458638995885849, 0.479089021682739),
    new THREE.Vector2(0.473466008901596, 0.545744001865387),
    new THREE.Vector2(0.476087987422943, 0.563830018043518),
    new THREE.Vector2(0.468472003936768, 0.555056989192963),
    new THREE.Vector2(0.433990985155106, 0.582361996173859),
    new THREE.Vector2(0.483518004417419, 0.562983989715576),
    new THREE.Vector2(0.482482999563217, 0.57784903049469),
    new THREE.Vector2(0.42645001411438, 0.389798998832703),
    new THREE.Vector2(0.438998997211456, 0.39649498462677),
    new THREE.Vector2(0.450067013502121, 0.400434017181396),
    new THREE.Vector2(0.289712011814117, 0.368252992630005),
    new THREE.Vector2(0.276670008897781, 0.363372981548309),
    new THREE.Vector2(0.517862021923065, 0.471948027610779),
    new THREE.Vector2(0.710287988185883, 0.380764007568359),
    new THREE.Vector2(0.526226997375488, 0.573909997940063),
    new THREE.Vector2(0.895093023777008, 0.254140973091125),
    new THREE.Vector2(0.634069979190826, 0.409575998783112),
    new THREE.Vector2(0.661242008209229, 0.41302502155304),
    new THREE.Vector2(0.688880026340485, 0.409460008144379),
    new THREE.Vector2(0.725341975688934, 0.389131009578705),
    new THREE.Vector2(0.606630027294159, 0.40370500087738),
    new THREE.Vector2(0.654766023159027, 0.344011008739471),
    new THREE.Vector2(0.629905998706818, 0.346076011657715),
    new THREE.Vector2(0.680678009986877, 0.347265005111694),
    new THREE.Vector2(0.702096998691559, 0.353591024875641),
    new THREE.Vector2(0.75221198797226, 0.410804986953735),
    new THREE.Vector2(0.602918028831482, 0.842862963676453),
    new THREE.Vector2(0.719901978969574, 0.375599980354309),
    new THREE.Vector2(0.893692970275879, 0.399959981441498),
    new THREE.Vector2(0.790081977844238, 0.391354024410248),
    new THREE.Vector2(0.643998026847839, 0.534487962722778),
    new THREE.Vector2(0.528249025344849, 0.65040397644043),
    new THREE.Vector2(0.525849997997284, 0.680191040039062),
    new THREE.Vector2(0.560214996337891, 0.657229006290436),
    new THREE.Vector2(0.585384011268616, 0.66654098033905),
    new THREE.Vector2(0.549625992774963, 0.680860996246338),
    new THREE.Vector2(0.57122802734375, 0.682691991329193),
    new THREE.Vector2(0.624852001667023, 0.72809898853302),
    new THREE.Vector2(0.513050019741058, 0.547281980514526),
    new THREE.Vector2(0.51509702205658, 0.527251958847046),
    new THREE.Vector2(0.742246985435486, 0.314507007598877),
    new THREE.Vector2(0.598631024360657, 0.454979002475739),
    new THREE.Vector2(0.570338010787964, 0.548575043678284),
    new THREE.Vector2(0.578631997108459, 0.533622980117798),
    new THREE.Vector2(0.723087012767792, 0.532054007053375),
    new THREE.Vector2(0.516445994377136, 0.499638974666595),
    new THREE.Vector2(0.662801027297974, 0.282917976379395),
    new THREE.Vector2(0.70362401008606, 0.293271005153656),
    new THREE.Vector2(0.830704987049103, 0.193813979625702),
    new THREE.Vector2(0.552385985851288, 0.302568018436432),
    new THREE.Vector2(0.607609987258911, 0.353887975215912),
    new THREE.Vector2(0.645429015159607, 0.696707010269165),
    new THREE.Vector2(0.932694971561432, 0.730105042457581),
    new THREE.Vector2(0.557260990142822, 0.572826027870178),
    new THREE.Vector2(0.542901992797852, 0.584792017936707),
    new THREE.Vector2(0.6180260181427, 0.694710969924927),
    new THREE.Vector2(0.607590973377228, 0.694203019142151),
    new THREE.Vector2(0.722943007946014, 0.271963000297546),
    new THREE.Vector2(0.577413976192474, 0.563166975975037),
    new THREE.Vector2(0.614082992076874, 0.281386971473694),
    new THREE.Vector2(0.616907000541687, 0.255886018276215),
    new THREE.Vector2(0.668509006500244, 0.119913995265961),
    new THREE.Vector2(0.770092010498047, 0.232020974159241),
    new THREE.Vector2(0.635536015033722, 0.189248979091644),
    new THREE.Vector2(0.77039098739624, 0.299556016921997),
    new THREE.Vector2(0.826722025871277, 0.278755009174347),
    new THREE.Vector2(0.527121007442474, 0.666198015213013),
    new THREE.Vector2(0.553171992301941, 0.668527007102966),
    new THREE.Vector2(0.577238023281097, 0.673889994621277),
    new THREE.Vector2(0.554691970348358, 0.580065965652466),
    new THREE.Vector2(0.611896991729736, 0.693961024284363),
    new THREE.Vector2(0.59696102142334, 0.706539988517761),
    new THREE.Vector2(0.596370995044708, 0.693953037261963),
    new THREE.Vector2(0.539958000183105, 0.557139039039612),
    new THREE.Vector2(0.568841993808746, 0.692366003990173),
    new THREE.Vector2(0.547818005084991, 0.692366003990173),
    new THREE.Vector2(0.52461302280426, 0.692366003990173),
    new THREE.Vector2(0.534089982509613, 0.779141008853912),
    new THREE.Vector2(0.527670979499817, 0.736225962638855),
    new THREE.Vector2(0.526912987232208, 0.717857003211975),
    new THREE.Vector2(0.526877999305725, 0.704625964164734),
    new THREE.Vector2(0.526966989040375, 0.695277988910675),
    new THREE.Vector2(0.572058022022247, 0.695277988910675),
    new THREE.Vector2(0.573521018028259, 0.703539967536926),
    new THREE.Vector2(0.57683801651001, 0.711845993995667),
    new THREE.Vector2(0.581691026687622, 0.720062971115112),
    new THREE.Vector2(0.609944999217987, 0.639909982681274),
    new THREE.Vector2(0.986046016216278, 0.560034036636353),
    new THREE.Vector2(0.5867999792099, 0.69539999961853),
    new THREE.Vector2(0.590372025966644, 0.701822996139526),
    new THREE.Vector2(0.531915009021759, 0.601536989212036),
    new THREE.Vector2(0.577268004417419, 0.585934996604919),
    new THREE.Vector2(0.536915004253387, 0.593786001205444),
    new THREE.Vector2(0.627542972564697, 0.473352015018463),
    new THREE.Vector2(0.665585994720459, 0.495950996875763),
    new THREE.Vector2(0.588353991508484, 0.546862006187439),
    new THREE.Vector2(0.757824003696442, 0.14767599105835),
    new THREE.Vector2(0.709249973297119, 0.201507985591888),
    new THREE.Vector2(0.672684013843536, 0.256581008434296),
    new THREE.Vector2(0.600408971309662, 0.74900496006012),
    new THREE.Vector2(0.55826598405838, 0.261672019958496),
    new THREE.Vector2(0.570303976535797, 0.187870979309082),
    new THREE.Vector2(0.588165998458862, 0.109044015407562),
    new THREE.Vector2(0.711045026779175, 0.398952007293701),
    new THREE.Vector2(0.781069993972778, 0.435405015945435),
    new THREE.Vector2(0.587247014045715, 0.398931980133057),
    new THREE.Vector2(0.742869973182678, 0.355445981025696),
    new THREE.Vector2(0.572156012058258, 0.437651991844177),
    new THREE.Vector2(0.55186802148819, 0.536570012569427),
    new THREE.Vector2(0.821442008018494, 0.457556009292603),
    new THREE.Vector2(0.752701997756958, 0.457181990146637),
    new THREE.Vector2(0.71375697851181, 0.467626988887787),
    new THREE.Vector2(0.66711300611496, 0.460672974586487),
    new THREE.Vector2(0.631101012229919, 0.447153985500336),
    new THREE.Vector2(0.6008620262146, 0.432473003864288),
    new THREE.Vector2(0.523481011390686, 0.405627012252808),
    new THREE.Vector2(0.810747981071472, 0.523926019668579),
    new THREE.Vector2(0.771045982837677, 0.348959028720856),
    new THREE.Vector2(0.509127020835876, 0.562718033790588),
    new THREE.Vector2(0.595292985439301, 0.485023975372314),
    new THREE.Vector2(0.980530977249146, 0.401564002037048),
    new THREE.Vector2(0.573499977588654, 0.420000016689301),
    new THREE.Vector2(0.602994978427887, 0.548687994480133),
    new THREE.Vector2(0.733529984951019, 0.376977026462555),
    new THREE.Vector2(0.560611009597778, 0.519016981124878),
    new THREE.Vector2(0.967685997486115, 0.644356966018677),
    new THREE.Vector2(0.580985009670258, 0.387160003185272),
    new THREE.Vector2(0.537728011608124, 0.505385041236877),
    new THREE.Vector2(0.760966002941132, 0.779752969741821),
    new THREE.Vector2(0.801778972148895, 0.831938028335571),
    new THREE.Vector2(0.892440974712372, 0.54076099395752),
    new THREE.Vector2(0.816350996494293, 0.740260004997253),
    new THREE.Vector2(0.865594983100891, 0.333687007427216),
    new THREE.Vector2(0.614073991775513, 0.883246004581451),
    new THREE.Vector2(0.508952975273132, 0.579437971115112),
    new THREE.Vector2(0.617941975593567, 0.508316040039062),
    new THREE.Vector2(0.825608015060425, 0.397674977779388),
    new THREE.Vector2(0.681214988231659, 0.39623498916626),
    new THREE.Vector2(0.656635999679565, 0.400596976280212),
    new THREE.Vector2(0.603900015354156, 0.710216999053955),
    new THREE.Vector2(0.81208598613739, 0.588539004325867),
    new THREE.Vector2(0.56801301240921, 0.944564998149872),
    new THREE.Vector2(0.681007981300354, 0.898285031318665),
    new THREE.Vector2(0.733752012252808, 0.869701027870178),
    new THREE.Vector2(0.633830010890961, 0.398822009563446),
    new THREE.Vector2(0.606792986392975, 0.39553701877594),
    new THREE.Vector2(0.589659988880157, 0.391062021255493),
    new THREE.Vector2(0.805015981197357, 0.342108011245728),
    new THREE.Vector2(0.611334979534149, 0.362284004688263),
    new THREE.Vector2(0.634037971496582, 0.355970978736877),
    new THREE.Vector2(0.656635999679565, 0.355356991291046),
    new THREE.Vector2(0.681214988231659, 0.35834002494812),
    new THREE.Vector2(0.698584973812103, 0.363156020641327),
    new THREE.Vector2(0.941866993904114, 0.319076001644135),
    new THREE.Vector2(0.698584973812103, 0.387449026107788),
    new THREE.Vector2(0.584177017211914, 0.624107003211975),
    new THREE.Vector2(0.554318010807037, 0.566076993942261),
    new THREE.Vector2(0.534153997898102, 0.62064003944397),
    new THREE.Vector2(0.711217999458313, 0.819975018501282),
    new THREE.Vector2(0.664629995822906, 0.852871000766754),
    new THREE.Vector2(0.559099972248077, 0.902631998062134),
    new THREE.Vector2(0.871706008911133, 0.791940987110138),
    new THREE.Vector2(0.591234028339386, 0.373893976211548),
    new THREE.Vector2(0.544341027736664, 0.451583981513977),
    new THREE.Vector2(0.624562978744507, 0.924192011356354),
    new THREE.Vector2(0.88577002286911, 0.615028977394104),
    new THREE.Vector2(0.551338016986847, 0.695277988910675),
    new THREE.Vector2(0.551980018615723, 0.704632043838501),
    new THREE.Vector2(0.552887976169586, 0.715808033943176),
    new THREE.Vector2(0.555167973041534, 0.730794012546539),
    new THREE.Vector2(0.569944024085999, 0.767035007476807),
    new THREE.Vector2(0.593203008174896, 0.685675978660583),
    new THREE.Vector2(0.599261999130249, 0.681069016456604),
    new THREE.Vector2(0.607599973678589, 0.677703022956848),
    new THREE.Vector2(0.631937980651855, 0.663500010967255),
    new THREE.Vector2(0.752032995223999, 0.601315021514893),
    new THREE.Vector2(0.547226011753082, 0.420395016670227),
    new THREE.Vector2(0.563543975353241, 0.359827995300293),
    new THREE.Vector2(0.583841025829315, 0.368713974952698),
    new THREE.Vector2(0.586614012718201, 0.692366003990173),
    new THREE.Vector2(0.771915018558502, 0.683578014373779),
    new THREE.Vector2(0.531597018241882, 0.352482974529266),
    new THREE.Vector2(0.588370978832245, 0.804440975189209),
    new THREE.Vector2(0.52079701423645, 0.442565023899078),
    new THREE.Vector2(0.567984998226166, 0.493479013442993),
    new THREE.Vector2(0.543282985687256, 0.819254994392395),
    new THREE.Vector2(0.655317008495331, 0.745514988899231),
    new THREE.Vector2(0.621008992195129, 0.574018001556396),
    new THREE.Vector2(0.625559985637665, 0.78031200170517),
    new THREE.Vector2(0.680198013782501, 0.570719003677368),
    new THREE.Vector2(0.64276397228241, 0.604337990283966),
    new THREE.Vector2(0.704662978649139, 0.621529996395111),
    new THREE.Vector2(0.552012026309967, 0.862591981887817),
    new THREE.Vector2(0.589071989059448, 0.508637011051178),
    new THREE.Vector2(0.685944974422455, 0.775357007980347),
    new THREE.Vector2(0.645735025405884, 0.812640011310577),
    new THREE.Vector2(0.675342977046967, 0.703978002071381),
    new THREE.Vector2(0.810858011245728, 0.646304965019226),
    new THREE.Vector2(0.72012197971344, 0.714666962623596),
    new THREE.Vector2(0.866151988506317, 0.682704985141754),
    new THREE.Vector2(0.663187026977539, 0.644596993923187),
    new THREE.Vector2(0.570082008838654, 0.466325998306274),
    new THREE.Vector2(0.544561982154846, 0.548375964164734),
    new THREE.Vector2(0.562758982181549, 0.558784961700439),
    new THREE.Vector2(0.531987011432648, 0.530140042304993),
    new THREE.Vector2(0.585271000862122, 0.335177004337311),
    new THREE.Vector2(0.622952997684479, 0.32277899980545),
    new THREE.Vector2(0.655896008014679, 0.320163011550903),
    new THREE.Vector2(0.687132000923157, 0.322345972061157),
    new THREE.Vector2(0.716481983661652, 0.333200991153717),
    new THREE.Vector2(0.758756995201111, 0.382786989212036),
    new THREE.Vector2(0.897013008594513, 0.468769013881683),
    new THREE.Vector2(0.732392013072968, 0.424547016620636),
    new THREE.Vector2(0.70211398601532, 0.433162987232208),
    new THREE.Vector2(0.66652500629425, 0.433866024017334),
    new THREE.Vector2(0.633504986763, 0.426087975502014),
    new THREE.Vector2(0.603875994682312, 0.416586995124817),
    new THREE.Vector2(0.579657971858978, 0.409945011138916),
    new THREE.Vector2(0.992439985275269, 0.480777025222778),
    new THREE.Vector2(0.567192018032074, 0.569419980049133),
    new THREE.Vector2(0.54136598110199, 0.478899002075195),
    new THREE.Vector2(0.526564002037048, 0.546118021011353),
    new THREE.Vector2(0.523913025856018, 0.563830018043518),
    new THREE.Vector2(0.531529009342194, 0.555056989192963),
    new THREE.Vector2(0.566035985946655, 0.582329034805298),
    new THREE.Vector2(0.51631098985672, 0.563053965568542),
    new THREE.Vector2(0.5174720287323, 0.577877044677734),
    new THREE.Vector2(0.573594987392426, 0.389806985855103),
    new THREE.Vector2(0.560697972774506, 0.395331978797913),
    new THREE.Vector2(0.549755990505219, 0.399751007556915),
    new THREE.Vector2(0.710287988185883, 0.368252992630005),
    new THREE.Vector2(0.723330020904541, 0.363372981548309),
];

function flatUVFacePointsStrech() {   //punti esattamente sul boundrect (x=da 0 a 1;  y= da 0 a 1)
    const scaleX = 1 / (flatUVFacePoints[idxFaceRight].x - flatUVFacePoints[idxFaceLeft].x);
    const scaleY = 1 / (flatUVFacePoints[idxFaceBottom].y - flatUVFacePoints[idxFaceTop].y);
    const m = new THREE.Matrix3();
    m.scale(scaleX, scaleY);
    m.translate(-flatUVFacePoints[idxFaceLeft].x * scaleX, -flatUVFacePoints[idxFaceTop].y * scaleY);

    return flatUVFacePoints.map((vec) => vec.clone().applyMatrix3(m));
}

function  faceBoundary() {return [      //Indici circonferenza esterna
   234, 93, 132, 58, 172, 136, 150, 149, 176, 148, 152, 377, 400, 378, 379, 365, 397, 288, 361, 323, 454, 356, 389,
   251,284, 332, 297, 338, 10, 109, 67, 103, 54, 21, 162, 127
]}

function  faceBoundaryInner() {return [      //Indici circonferenza interna
   227, 137, 177, 215, 138, 135, 169, 170, 140, 171, 175, 396, 369, 395, 394, 364, 367, 435, 401, 366, 447, 264,
   368, 301, 298, 333, 299, 337, 151, 108, 69, 104, 68, 71, 139, 34
]}

function  faceBoundaryInnerLR() {return [      //Indici circonferenza interna ai lati ed esterna sopra sotto
   227, 137, 177, 215, 138,
   //135, 169, 170, 140, 171, 175, 396, 369, 395, 394, 364,        //cambiare con circ esterna
   136, 150, 149, 176, 148, 152, 377, 400, 378, 379, 365,
   367, 435, 401, 366, 447, 264,                               
   //368, 301,298, 333, 299, 337, 151, 108, 69, 104, 68,                     //cambiare con circ esterna      
   389, 251, 284, 332, 297, 338, 10, 109, 67, 103, 54, 21, 162,      
   34
]}

function filterMask(keyPts, mask) {    //Ritorna un canvas con immagina mascherata con segmentazione di MPipe 
    if (!mask) {
        mask = keyPts.segmentationMask;
    }

    const cnvResult = document.createElement('canvas');
    cnvResult.width = mask.width;
    cnvResult.height = mask.height;
    const ctx = cnvResult.getContext('2d');

    ctx.save();
    ctx.drawImage(mask, 0, 0);
    ctx.globalCompositeOperation = 'source-in';
    ctx.drawImage(keyPts.image, 0, 0);
    ctx.restore();

    return cnvResult;
}

export {faceBoundary, faceBoundaryInner, faceBoundaryInnerLR, 
      idxFaceBoundRect, getUV,TranslateLip,MeshGLBApplyFromMPipe, 
      idxMentoMesh,idxSideMento,idxFaceLeft,idxFaceRight,idxFaceBottom,idxFaceTop,idxFaceCenter, flatUVFacePoints, flatUVFacePointsStrech, getAxisFaceAngles, filterMask}