import React from 'react';
import {BiPlay, BiStop} from 'react-icons/bi';  
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';


const TempPopupMenu = ({open, position, menuItemClick, menuClose, keepHair, showStats, useGesture}) => {

    const [menuState, setMenuState] = React.useState(
        [
            {text: 'Open GLB file', value: 'OpenGLB'},
            {text: 'Save GLB file', value: 'SaveGLB'},
            {text: 'Open scene', value: 'OpenScene'},
            {text: 'Save scene', value: 'SaveScene'},
            {text: '', value: ''},
            {text: 'Remove clothes', value: 'RemoveClothes'},
            {text: 'Convert selected mesh to SkinnedMesh', value: 'ConvertToSkinnedMesh'},
            {text: '', value: ''},
            {text: 'Start animation', value: 'StartAnimation'},
            {text: 'Stop animation', value: 'StopAnimation'},
            {text: 'Initial pose', value: 'InitialPose'},
            {text: '', value: ''},
            {text: 'Face from picture', value: 'FaceFromPicture'},
            {text: 'Keep hair', value: 'KeepHair', checked: keepHair},
            {text: '', value: ''},
            {text: 'Start recording canvas', value: 'StartPauseRecordCanvas', icon: <BiPlay />},
            {text: 'Stop recording canvas', value: 'StopRecordCanvas', icon: <BiStop />},
            {text: '', value: ''},
            {text: 'Show stats', value: 'ShowStats', checked: showStats},
            {text: 'Use Gesture', value: 'UseGesture', checked: useGesture},
          ]
    );
    const handleMenuItemClick = (menuItem) => {

        return (e) => {
            // if (menuItem.hasOwnProperty('checked')) {
            //     menuItem.checked = !menuItem.checked;
            //     setMenuState([...menuState]);
            // }
            menuItemClick(menuItem);
        }
    };
    
    React.useEffect(() => {
        const el = menuState.find(item => item.value === 'KeepHair');
        if (el) {
            el.checked = keepHair;
            setMenuState([...menuState]);
        }
    }, [keepHair]);
    
    React.useEffect(() => {
        const el = menuState.find(item => item.value === 'ShowStats');
        if (el) {
            el.checked = showStats;
            setMenuState([...menuState]);
        }
    }, [showStats]);
    
    React.useEffect(() => {
        const el = menuState.find(item => item.value === 'UseGesture');
        if (el) {
            el.checked = useGesture;
            setMenuState([...menuState]);
        }
    }, [useGesture]);
    
    const anchorPosition = position !== null ? { top: position.mouseY, left: position.mouseX } : undefined;
    
    const options = menuState;
    
    return (
        <Menu variant='menu' open={open} onClose={menuClose} anchorReference="anchorPosition" anchorPosition={anchorPosition}>
            {options.map((option) => {

                if (option.text === '') {
                    return <Divider key={Math.round(Math.random() * 100000)} />
                } else {
                    return (
                        <MenuItem key={option.value} selected={option.hasOwnProperty('checked') && option.checked} onClick={handleMenuItemClick(option)}>
                            <ListItemIcon>
                                {option.icon}
                            </ListItemIcon>
                            <ListItemText>{option.text}</ListItemText>
                        </MenuItem>
                    )
                }
            })}
        </Menu>
    );
};

export default React.memo(TempPopupMenu);
